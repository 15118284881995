import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from '../../../util/number';
import { translate } from "../../../util/other";

export class AddonDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: "message_box_back.png",
            light: "card_light.png",
            // scale: this.config.scale || [1, 1],
            title: {
                text: translate('addon'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 150,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);

        this.chip_back = this.scene.add.graphics({
            x: -170,
            y: 110,
            fillStyle: {
                color: 0xeeeeee,
                alpha: 1
            }
        })
        this.chip_back.fillRoundedRect(0, 0, 340, 50, 25);
        this.cardContainer.add(this.chip_back);

        this.chip_icon = this.scene.add.sprite(this.config.x - 160, this.config.y + 135, this.config.atlas, "tournament_prize_icon.png").setOrigin(0, 0.5);
        this.add(this.chip_icon);

        this.chip_value = TextClass.plainText1(this.scene, {
            x: 0,
            y: 135,
            text: NumberUtil.convertNumberWithComma(this.config.amount),
            fontSize: 32,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            color: "#222222"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.chip_value);

        this.detail_back_graphics = this.scene.add.graphics({
            x: -210,
            y: 170,
            fillStyle: {
                color: 0x777777,
                alpha: 1
            }
        });
        // this.detail_back_graphics.fillRect(0, 0, 420, 100);
        this.detail_back_graphics.fillRect(139, 15, 2, 70);
        this.detail_back_graphics.fillRect(279, 15, 2, 70);
        this.cardContainer.add(this.detail_back_graphics);

        this.entry_label = TextClass.plainText1(this.scene, {
            x: -140,
            y: 200,
            text: translate('entry'),
            fontSize: 20,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.entry_label);

        this.entry_value = TextClass.plainText1(this.scene, {
            x: -140,
            y: 240,
            text: this.config.player_count + "/" + this.config.last_prize_rank,
            fontSize: 24,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.entry_value);

        this.addontimes_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 200,
            text: translate('tournament_addon_count'),
            fontSize: 20,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.addontimes_label);

        this.addontimes_value = TextClass.plainText1(this.scene, {
            x: 0,
            y: 240,
            text: this.config.times,
            fontSize: 24,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.addontimes_value);

        this.avstack_label = TextClass.plainText1(this.scene, {
            x: 140,
            y: 200,
            text: translate('av_stack'),
            fontSize: 20,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.avstack_label);

        this.avstack_value = TextClass.plainText1(this.scene, {
            x: 140,
            y: 240,
            text: NumberUtil.convertNumberWithComma(this.config.av_stack),
            fontSize: 24,
            color: "#39de87"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.avstack_value);

        this.btn_cancel = new IconButton(this.scene, {
            x: -180,
            y: 280,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png',
            },
            hover: {
                back: 'btn_cancel_back_hover.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png'
            },
            scale: [0.8, 0.8],
            text: {
                x: 58,
                y: 10,
                value: translate('cancel'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnCancel.bind(this)
        });
        this.cardContainer.add(this.btn_cancel);

        this.btn_ok = new IconButton(this.scene, {
            x: 0,
            y: 280,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_ok_back_normal.png',
            },
            hover: {
                back: 'btn_ok_back_hover.png'
            },
            clicked: {
                back: 'btn_ok_back_clicked.png'
            },
            scale: [0.8, 0.8],
            text: {
                x: 58,
                y: 10,
                value: translate('ok'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnOk.bind(this)
        });
        this.cardContainer.add(this.btn_ok);
    }

    setConfig(amount, player_count, last_prize_rank, times, av_stack) {
        this.chip_value.setText(NumberUtil.convertNumberWithComma(amount));
        this.entry_value.setText(player_count + "/" + last_prize_rank);
        this.addontimes_value.setText(times.toString());
        this.avstack_value.setText(NumberUtil.convertNumberWithComma(av_stack));
    }

    close() {
        this.setVisible(false);
    }

    onClickedBtnOk() {
        this.scene.requestAddon(1);
    }

    onClickedBtnCancel() {
        this.scene.requestAddon(0);
    }
}