var ByteArray = require('./Utils/ByteArray')
class ProtocolDecoder {

}

ProtocolDecoder.byteArray = new ByteArray()
ProtocolDecoder.setBodyData = function (__bodyByteArray) {
    ProtocolDecoder.byteArray = __bodyByteArray
}

ProtocolDecoder.ReadFloat = function () {
    return ProtocolDecoder.byteArray.readFloat()
}

ProtocolDecoder.ReadBYTE = function () {
    return ProtocolDecoder.byteArray.readByte()
}

ProtocolDecoder.ReadInt = function () {
    return ProtocolDecoder.byteArray.readInt()
}

ProtocolDecoder.ReadString = function () {
    return ProtocolDecoder.byteArray.readStringUShort()
}

ProtocolDecoder.ReadShort = function () {
    return ProtocolDecoder.byteArray.readShort()
}

ProtocolDecoder.ReadBool = function () {
    return ProtocolDecoder.byteArray.readBool()
}

ProtocolDecoder.ReadWORD = function () {
    return ProtocolDecoder.byteArray.readUShort()
}

ProtocolDecoder.ReadDouble = function () {
    return ProtocolDecoder.byteArray.readDouble()
}

ProtocolDecoder.Offset = function () {
    
}
module.exports = ProtocolDecoder