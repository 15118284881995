import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class TabHistoryData extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.chartData = {}
        this.initGraph();
    }

    initGraph() {
        let circlePosition = {
            x: this.scene.orientation == "pc" ? 87 : 295,
            y: this.scene.orientation == "pc" ? 350 : 420
        }

        let VPIP_label = TextClass.plainText1(this.scene, {
            x: circlePosition.x,
            y: circlePosition.y + 7,
            text: translate('vpip'),
            fontSize: this.scene.orientation == "pc" ? 16 : 21,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.add(VPIP_label);

        this.VPIP_value = TextClass.plainText1(this.scene, {
            x: circlePosition.x,
            y: circlePosition.y - 7,
            text: (this.chartData.vpip || 0) + "%",
            fontSize: this.scene.orientation == "pc" ? 36 : 48,
            fontFamily: "NanumSquareL",
            color: "#ff0066"
        }).setOrigin(0.5, 1);
        this.add(this.VPIP_value);

        let PFR_label = TextClass.plainText1(this.scene, {
            x: circlePosition.x,
            y: circlePosition.y + (this.scene.orientation == "pc" ? 70 : 92),
            text: translate('pfr'),
            fontSize: this.scene.orientation == "pc" ? 16 : 21,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.add(PFR_label);

        this.PFR_value = TextClass.plainText1(this.scene, {
            x: circlePosition.x,
            y: circlePosition.y + (this.scene.orientation == "pc" ? 64 : 82),
            text: (this.chartData.pfr || 0) + "%",
            fontSize: this.scene.orientation == "pc" ? 26 : 35,
            fontFamily: "NanumSquareL",
            color: "#6095ff"
        }).setOrigin(0.5, 1);
        this.add(this.PFR_value);

        this.chartContainer = this.scene.add.container(0, 0);
        this.circleChart = this.scene.add.graphics({
            x: circlePosition.x,
            y: circlePosition.y,
        });
        
        this.chartContainer.add(this.circleChart);
        let radius = this.scene.orientation == "pc" ? 87 : 117;
        let lineWidth = this.scene.orientation == "pc" ? 14 : 20;
        let angle = Math.asin(lineWidth / (radius * 2));
        let x = - radius * Math.sin(angle + Math.PI / 4);
        let y = radius * Math.cos(angle + Math.PI / 4);
        let maskGraphic = this.scene.make.graphics({
            x: circlePosition.x + (this.scene.orientation == "pc" ? 490 : 80),
            y: circlePosition.y + (this.scene.orientation == "pc" ? 333 : 170),
            fillStyle: {
                color: 0xffff00,
                alpha: 0
            }
        });
        maskGraphic.beginPath();
        maskGraphic.moveTo(x, y);
        maskGraphic.arc(x, y, lineWidth/2, 0, Math.PI * 2);
        maskGraphic.moveTo(-x, y);
        maskGraphic.arc(-x, y, lineWidth/2, 0, Math.PI * 2);
        maskGraphic.moveTo(0, 0);
        maskGraphic.arc(0, 0, radius + lineWidth/2, Phaser.Math.DegToRad(135) + angle, Phaser.Math.DegToRad(45) - angle);
        maskGraphic.fillPath();
        maskGraphic.closePath();
        let mask = maskGraphic.createGeometryMask();
        this.chartContainer.mask = mask;
        this.add(this.chartContainer);

        this.pageName = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 215 : circlePosition.x,
            y: this.scene.orientation == "pc" ? 251 : 245,
            text: "",
            fontSize: this.scene.orientation == "pc" ? 22 : 24,
            color: "#ffffff" 
        }).setOrigin(this.scene.orientation == "pc" ? 0 : 0.5, this.scene.orientation == "pc" ? 0 : 0.5);
        this.add(this.pageName);

        this.WTSD_value = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 212 : 76,
            y: this.scene.orientation == "pc" ? 320 : 615,
            text: (this.chartData.wssd || 0) + "%",
            fontSize: this.scene.orientation == "pc" ? 26 : 35,
            fontFamily: "NanumSquareL",
            color: "#ffa200"
        }).setOrigin(0, 1);
        this.add(this.WTSD_value);

        let WTSD_label = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 271 : 155,
            y: this.scene.orientation == "pc" ? 320 : 615,
            text: translate('wtsd'),
            fontSize: this.scene.orientation == "pc" ? 16 : 21,
            color: "#777777"
        }).setOrigin(0, 1);
        this.add(WTSD_label);
        
        this.WSSD_value = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 426 : 362,
            y: this.scene.orientation == "pc" ? 320 : 615,
            text: (this.chartData.wssd || 0) + "%",
            fontSize: this.scene.orientation == "pc" ? 26 : 35,
            color: "#00e793"
        }).setOrigin(0, 1);
        this.add(this.WSSD_value);

        let WSSD_label = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 493 : 452,
            y: this.scene.orientation == "pc" ? 320 : 615,
            text: translate('wssd'),
            fontFamily: "NanumSquareL",
            fontSize: this.scene.orientation == "pc" ? 16 : 21,
            color: "#777777"
        }).setOrigin(0, 1);
        this.add(WSSD_label);
        
        this.wtsdChartContainer = this.scene.add.container(0, 0);
        this.add(this.wtsdChartContainer);
        this.wtsdChart = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 212 : 76,
            y: this.scene.orientation == "pc" ? 329 : 625
        });
        this.wtsdChartContainer.add(this.wtsdChart);
        let wtsdChartMask = this.scene.make.graphics({
            x: (this.scene.orientation == "pc" ? 212 : 76) + (this.scene.orientation == "pc" ? 490 : 80),
            y: (this.scene.orientation == "pc" ? 329 : 625) + (this.scene.orientation == "pc" ? 333 : 170),
            fillStyle: {
                color: 0xffff00,
                alpha: 0.6
            }
        });
        let wtsdChartWidth = this.scene.orientation == "pc" ? 330 : 442;
        let wtsdChartHeight = this.scene.orientation == "pc" ? 10 : 12;
        wtsdChartMask.fillRoundedRect(0, 0, wtsdChartWidth, wtsdChartHeight, wtsdChartHeight / 2);
        this.wtsdChartContainer.mask = wtsdChartMask.createGeometryMask();

        
        let stillChartWidth = this.scene.orientation == "pc" ? 150 : 201;
        let stillChartHeight = this.scene.orientation == "pc" ? 10 : 12;

        this.still_value = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 212 : 76,
            y: this.scene.orientation == "pc" ? 386 : 705,
            text: (this.chartData.still || 0) + "%",
            fontFamily: "NanumSquareL",
            fontSize: this.scene.orientation == "pc" ? 22 : 30,
            color: "#ffffff"
        }).setOrigin(0, 1);
        this.add(this.still_value);

        let still_label = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 212 : 76) + stillChartWidth,
            y: this.scene.orientation == "pc" ? 385 : 705,
            text: `${translate('steal')}%`,
            fontSize: this.scene.orientation == "pc" ? 13 : 17,
            color: "#777777"
        }).setOrigin(1, 1);
        this.add(still_label);

        this.stillChartContainer = this.scene.add.container(0, 0);
        this.add(this.stillChartContainer);
        this.stillChart = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 212 : 76,
            y: this.scene.orientation == "pc" ? 395 : 715
        });
        this.stillChartContainer.add(this.stillChart);
        let stillChartMask = this.scene.make.graphics({
            x: (this.scene.orientation == "pc" ? 212 : 76) + (this.scene.orientation == "pc" ? 490 : 80),
            y: (this.scene.orientation == "pc" ? 395 : 715) + (this.scene.orientation == "pc" ? 333 : 170),
            fillStyle: {
                color: 0xffff00,
                alpha: 0.6
            }
        });
        stillChartMask.fillRoundedRect(0, 0, stillChartWidth, stillChartHeight, stillChartHeight / 2);
        this.stillChartContainer.mask = stillChartMask.createGeometryMask();

        this.check_raise_value = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 393 : 317,
            y: this.scene.orientation == "pc" ? 385 : 705,
            text: (this.chartData.check_raise || 0) + "%",
            fontFamily: "NanumSquareL",
            fontSize: this.scene.orientation == "pc" ? 22 : 30,
            color: "#ffffff"
        }).setOrigin(0, 1);
        this.add(this.check_raise_value);

        let check_raise_label = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 393 : 317) + stillChartWidth,
            y: this.scene.orientation == "pc" ? 385 : 705,
            text: translate('check_raise'),
            fontSize: this.scene.orientation == "pc" ? 13 : 17,
            color: "#777777"
        }).setOrigin(1, 1);
        this.add(check_raise_label);

        

        this.checkRaiseChartContainer = this.scene.add.container(0, 0);
        this.add(this.checkRaiseChartContainer);
        this.checkRaiseChart = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 393 : 317,
            y: this.scene.orientation == "pc" ? 395 : 715
        });
        this.checkRaiseChartContainer.add(this.checkRaiseChart);
        let checkRaiseChartMask = this.scene.make.graphics({
            x: (this.scene.orientation == "pc" ? 393 : 317) + (this.scene.orientation == "pc" ? 490 : 80),
            y: (this.scene.orientation == "pc" ? 395 : 715) + (this.scene.orientation == "pc" ? 333 : 170),
            fillStyle: {
                color: 0xffff00,
                alpha: 0.6
            }
        });
        checkRaiseChartMask.fillRoundedRect(0, 0, stillChartWidth, stillChartHeight, stillChartHeight / 2);
        this.checkRaiseChartContainer.mask = checkRaiseChartMask.createGeometryMask();

        let fontSize = {
            pc: [22, 13],
            mobile: [26, 16]
        };
        let textPosition = {
            pc: {
                x: 617,
                y: [310, 379]
            },
            mobile: {
                x: 125,
                y: [815, 898]
            }
        }

        let padding = {
            pc: {
                x: 47,
                y: 20
            },
            mobile: {
                x: 58,
                y: 28
            }
        }

        let labels = [
            translate('three_bet'),
            translate('fold_to_three_bet'),
            translate('c_bet'),
            translate('fold_to_c_bet'),
            translate('total_game'),
            translate('total_hand'),
            translate('win_count'),
            translate('roi'),
        ];
        this.values = [];
        for(let i = 0 ; i < 8 ; i ++) {
            let label = TextClass.plainText1(this.scene, {
                x: textPosition[this.scene.orientation].x + padding[this.scene.orientation].x * 2 * (i % 4),
                y: textPosition[this.scene.orientation].y[Math.floor(i / 4)] + padding[this.scene.orientation].y,
                text: labels[i],
                color: "#777777",
                fontSize: fontSize[this.scene.orientation][1]
            }).setOrigin(0.5, 0.5);
            this.add(label);

            this.values[i] = TextClass.plainText1(this.scene, {
                x: textPosition[this.scene.orientation].x + padding[this.scene.orientation].x * 2 * (i % 4),
                y: textPosition[this.scene.orientation].y[Math.floor(i / 4)],
                text: '0' + ((i < 4 || i == 7) ? "%" : ''),
                color: "#ffffff",
                fontSize: fontSize[this.scene.orientation][1],
                fontFamily: "NanumSquareL"
            }).setOrigin(0.5, 0.5);
            this.add(this.values[i]);

            if(i % 4 < 3) {
                let col_divider = this.scene.add.sprite(textPosition[this.scene.orientation].x + padding[this.scene.orientation].x * (2 * (i % 4) + 1), textPosition[this.scene.orientation].y[Math.floor(i / 4)] + padding[this.scene.orientation].y / 2, this.config.atlas, 'col_divider.png');
                col_divider.setOrigin(0.5, 0.5);
                this.add(col_divider);
            }
        }
        let row_divider = this.scene.add.graphics({
            x: textPosition[this.scene.orientation].x,
            y: textPosition[this.scene.orientation].y[0] + padding[this.scene.orientation].y * 2
        });
        this.add(row_divider);
        row_divider.lineStyle(1, 0x222222, 1);
        row_divider.beginPath();
        row_divider.moveTo(-45, 0);
        row_divider.lineTo(padding[this.scene.orientation].x * 6 + 45, 0);
        row_divider.closePath();
        row_divider.strokePath();
    }

    refreshChart() {
        // circle chart
        let radius = this.scene.orientation == "pc" ? 87 : 117;
        let lineWidth = this.scene.orientation == "pc" ? 14 : 20;
        this.circleChart.clear();
        this.circleChart.beginPath();
        this.circleChart.lineStyle(lineWidth, 0x1f2121, 1);
        this.circleChart.arc(0, 0, radius, Phaser.Math.DegToRad(135), Phaser.Math.DegToRad(45));
        this.circleChart.strokePath();
        this.circleChart.closePath();
        this.circleChart.beginPath();
        this.circleChart.lineStyle(lineWidth, 0x4c88ff, 1);
        this.circleChart.arc(0, 0, radius, Phaser.Math.DegToRad(135), Phaser.Math.DegToRad(135 + 270 * (this.chartData.pfr || 0) / 100));
        this.circleChart.strokePath();
        this.circleChart.closePath();
        this.circleChart.beginPath();
        this.circleChart.lineStyle(lineWidth, 0xd70056, 1);
        this.circleChart.arc(0, 0, radius, Phaser.Math.DegToRad(135 + 270 * (this.chartData.pfr || 0) / 100), Phaser.Math.DegToRad(135 + 270 * ((this.chartData.pfr || 0) + (this.chartData.vpip || 0)) / 100));
        this.circleChart.strokePath();
        this.circleChart.closePath();

        // WTSD, W$SD 차트
        let wtsdChartWidth = this.scene.orientation == "pc" ? 330 : 442;
        let wtsdChartHeight = this.scene.orientation == "pc" ? 10 : 12;
        this.wtsdChart.clear();
        this.wtsdChart.fillStyle(0x4b4d51, 1);
        this.wtsdChart.fillRect(0, 0, wtsdChartWidth, wtsdChartHeight);
        this.wtsdChart.fillStyle(0xffa200, 1);
        this.wtsdChart.fillRect(0, 0, wtsdChartWidth * (this.chartData.wtsd || 0) / 100, wtsdChartHeight)
        this.wtsdChart.fillStyle(0x00e793, 1);
        this.wtsdChart.fillRect(0, 0, wtsdChartWidth * (this.chartData.wtsd || 0) / 100 * (this.chartData.wssd || 0) / 100, wtsdChartHeight);

        // 스틸차트
        let stillChartWidth = this.scene.orientation == "pc" ? 150 : 201;
        let stillChartHeight = this.scene.orientation == "pc" ? 10 : 12;
        this.stillChart.clear();
        this.stillChart.fillStyle(0x4b4d51, 1);
        this.stillChart.fillRect(0, 0, stillChartWidth, stillChartHeight);
        this.stillChart.fillStyle(0x00e793, 1);
        this.stillChart.fillRect(0, 0, stillChartWidth * (this.chartData.still || 0) / 100, stillChartHeight);

        // 체크-레이즈 차트
        this.checkRaiseChart.clear();
        this.checkRaiseChart.fillStyle(0x4b4d51, 1);
        this.checkRaiseChart.fillRect(0, 0, stillChartWidth, stillChartHeight);
        this.checkRaiseChart.fillStyle(0x00e793, 1);
        this.checkRaiseChart.fillRect(0, 0, stillChartWidth * (this.chartData.check_raise || 0) / 100, stillChartHeight);
    }

    setData(data) {
        data.type && this.pageName.setText(data.type);
        this.VPIP_value.setText(data.vpip + "%");
        this.chartData.vpip = data.vpip;
        this.PFR_value.setText(data.pfr + "%");
        this.chartData.pfr = data.pfr;
        this.WTSD_value.setText(data.wtsd + "%");
        this.chartData.wtsd = data.wtsd
        this.WSSD_value.setText(data.wssd + "%");
        this.chartData.wssd = data.wssd;
        this.still_value.setText(data.still + "%");
        this.chartData.still = data.still;
        this.check_raise_value.setText(data.check_raise + "%");
        this.chartData.check_raise = data.check_raise;
        this.refreshChart();
        this.values[0].setText(data.three_bet + "%");
        this.values[1].setText(data.fold_to_three_bet + "%");
        this.values[2].setText(data.c_bet + "%");
        this.values[3].setText(data.fold_to_c_bet + "%");
        this.values[4].setText(data.total_game);
        this.values[5].setText(data.total_hand);
        this.values[6].setText(data.winner);
        this.values[7].setText(data.ROI + "%");
    }
}