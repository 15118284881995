import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import HoldemDef from "../../../def/holdemdef";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class TabEmoticon extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();

        let content = this.config.emoticon_type ? HoldemDef.animations.self : HoldemDef.animations.other;
        this.setContent(content);
    }

    initGraph() {
        this.emoticonListContainer = this.scene.add.container(0, 0);

        this.emoticon_count = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 60 : 80,
            text: `${translate('emoticon_left_count')}: ` + CommonDef.Me.emoticonCount,
            fontSize: 24,
            color: "#777777"
        }).setOrigin(0, 0);
        this.add(this.emoticon_count);
        
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 110 : 130,
            type: "horizontal",
            width: this.scene.orientation == "pc" ? 530 : 590,
            height: this.scene.orientation == "pc" ? 115 : 140,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.emoticonListContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 695 : 80,
                y: this.scene.orientation == "pc" ? 790 : 765
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 530 : 590,
                height: this.scene.orientation == "pc" ? 100 : 110
            }
        });
        this.add(this.scrollView);
    }

    setContent(content) {
        let left = 0;
        let keys = Object.keys(content)
        for(let i = 0 ; i < keys.length ; i++) {
            let item = this.scene.add.container(left, 0);
            let back = this.scene.add.graphics({
                x: 50,
                y: 0,
                fillStyle: {
                    color: 0xffffff,
                    alpha: 0.3
                }
            });
            back.fillCircle(0, 50, 50);
            item.add(back);

            let image_name = content[keys[i]].prefix;
            for(let zero = 0 ; zero < content[keys[i]].zeroPad ; zero ++) {
                image_name += "0";
            }
            image_name += ".png";
            let emoticon = this.scene.add.sprite(50, 50, content[keys[i]].atlas, image_name);
            emoticon.setOrigin(0.5, 0.5);
            emoticon.setScale(content[keys[i]].scale);
            item.add(emoticon);
            this.emoticonListContainer.add(item);
            left += 120;
            item.width = 100;
            item.click_area = {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }
            item.onClick = () => {
                this.onClickEmoticon(keys[i])
            }
        }
        this.emoticonListContainer.width = left;
        this.scrollView.updateScroll();
    }

    onClickEmoticon(key) {
        if(CommonDef.Me.emoticonCount > 0) {
            this.config.emoticon_clicked(key);
        } else {
            // console.log("이모티콘 갯수가 없어요.");
        }
    }
}