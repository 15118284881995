export class SpriteButton extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.btnImg = this.scene.add.sprite(0, 0, this.config.atlas, this.config.normal);
        this.add(this.btnImg);
        if(this.config.origin) this.btnImg.setOrigin(this.config.origin[0], this.config.origin[1]);
        else this.btnImg.setOrigin(0.5, 0.5);
        if(this.config.scale) this.btnImg.setScale(this.config.scale);
        let action_rect = new Phaser.Geom.Rectangle(-10, -10, this.btnImg.width * (this.config.scale ? this.config.scale : 1) + 20, this.btnImg.height * (this.config.scale ? this.config.scale : 1) + 20);
        this.btnImg.setInteractive(action_rect, Phaser.Geom.Rectangle.Contains);
        this.btnImg.on('pointerup', () => {
            if(this.config.hover) {
                this.btnImg.setTexture(this.config.atlas, this.config.hover);
            }
            this.btnImg.setScale(1);
            if(this.isDown && this.config.onClicked) this.config.onClicked();
            this.isDown = false;
        });

        this.btnImg.setInteractive();

        if(this.config.hover) {
            this.btnImg.on("pointerover", () => {
                this.btnImg.setTexture(this.config.atlas, this.config.hover);
            });
        }

        this.btnImg.on("pointerdown", () => {
            this.isDown = true;
            if(this.config.clicked) {
                this.btnImg.setTexture(this.config.atlas, this.config.clicked);
                this.scene.audio.playEffect("BtnClick");
            } else {
                this.btnImg.setScale(1.03);
            }
        });

        this.btnImg.on("pointerout", () => {
            this.btnImg.setTexture(this.config.atlas, this.config.normal);
        });
    }
}