import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { InputField } from "../../../components/input/input";
import { SliderBar } from "../../../components/slider/sliderbar";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { DepositWithdrawItem } from "./deposit_withdraw_item";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class PointDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.myPoint = CommonDef.Me.point;

        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.pointContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x - 505 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 170);
        this.add(this.pointContainer);

        this.myPointText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: translate('current_point'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.pointContainer.add(this.myPointText);
        let myPointValueBack = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 62 : 66, this.config.atlas, "input_back_" + this.scene.orientation + "_long.png").setOrigin(0, 0.5);
        this.pointContainer.add(myPointValueBack);
        this.myPointValue = TextClass.plainText1(this.scene, {
            x: 20,
            y: this.scene.orientation == "pc" ? 62 : 66,
            text: NumberUtil.convertNumberWithComma(this.myPoint),
            color: "#ffffff",
            fontSize: 24
        }).setOrigin(0, 0.5);
        this.pointContainer.add(this.myPointValue);

        this.pointText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 122 : 133,
            text: translate('convert_point'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.pointContainer.add(this.pointText);

        this.pointField = new InputField(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 155 : 165,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 483 : 333
                },
                type: "number",
                width: this.scene.orientation == "pc" ? 615 : 650,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: translate('amount_placeholder'),
                color: "#ffffff",
                fontSize: 24
            },
            onChange: this.onChangePointField.bind(this)
        });
        this.pointContainer.add(this.pointField);
        this.pointField.setValue(0);

        this.sliderBar = new SliderBar(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 244 : 276,
            absoluteX: this.scene.orientation == "pc" ? this.config.x - 505 : 40,
            atlas: this.config.atlas,
            width: this.scene.orientation == "pc" ? 530 : 560,
            height: 20,
            ruler: true,
            onChange: this.onChangeSlider.bind(this)
        });
        this.pointContainer.add(this.sliderBar);

        this.btn_MAX = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 639 : 670,
            y: this.scene.orientation == "pc" ? 234 : 267,
            origin: [1, 0],
            atlas: this.config.atlas,
            normal: "max_normal.png",
            hover: "max_hover.png",
            clicked: "max_clicked.png",
            onClicked: this.onClickedBtnMax.bind(this)
        });
        this.pointContainer.add(this.btn_MAX);


        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x + 175 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 712);
        this.add(this.historyContainer);

        let historyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: translate('convert_point_history'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(historyText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 2,
            text: translate('refresh'),
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 288 : 630,
            y: 0,
            atlas: this.config.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            CommonDef.gameNet.requestPointExchangeList();
        });

        this.historyContent = this.scene.add.container(0, 0);
        this.scrollViewContainer = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 323 : 667,
            height: this.scene.orientation == "pc" ? 382 : 434,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 1125 : 40,
                y: this.scene.orientation == "pc" ? 362 : 744
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 300 : 640,
                height: this.scene.orientation == "pc" ? 382 : 434,
            }
        });
        this.historyContainer.add(this.scrollViewContainer);

        this.btn_deposit = new IconButton(this.scene, {
            x: this.config.x - 93,
            y: this.config.y + (this.scene.orientation == "pc" ? 585 : 550),
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: translate('convert_point_btn'),
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff']
            },
            onClicked: () => {
                this.scene.requestPointExchange(this.pointField.value);
            }
        });
        this.add(this.btn_deposit);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onChangePointField(value) {
        if(value < 0) {
            this.pointField.setValue(0);
            this.sliderBar.setValue(0);
        } else if(value > this.myPoint) {
            value = Math.floor(value / 10);
            this.pointField.setValue(value);
        } else {
            this.sliderBar.setValue(value / this.myPoint);
        }
    }

    onChangeSlider(value) {
        this.pointField.setValue(Math.floor(this.myPoint * value));
    }

    onClickedBtnMax() {
        this.sliderBar.setValue(1);
        this.pointField.setValue(this.myPoint);
    }

    setHistoryContent(content) {
        this.historyContent.removeAll(true);
        let height = 90;
        for(let i = 0 ; i < content.length ; i ++) {
            let item = new DepositWithdrawItem(this.scene, {
                x: 0,
                y: i * height,
                atlas: this.config.atlas,
                ...content[i]
            });
            this.historyContent.add(item);
        }
        this.historyContent.height = content.length * height;
        this.scrollViewContainer.updateScroll();
    }

    popupAnimation() {
        this.pointContainer.setVisible(false);
        this.historyContainer.setVisible(false);
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.pointContainer.setVisible(true);
                this.historyContainer.setVisible(true);
            }
        })
    }

    updateMyPoint() {
        this.myPoint = CommonDef.Me.point;
        this.myPointValue.setText(NumberUtil.convertNumberWithComma(this.myPoint));
        this.sliderBar.setValue(0)
        this.onChangeSlider(0);
    }
}