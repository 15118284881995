import { TextClass } from "../../../components/text/text";
import { EventItem } from "./event_item";

export class TabEventContent extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let data = this.config.data || [];
        let letter_count_limit = (this.scene.orientation == "pc" ? 34 : 38);
        for(let i = 0 ; i < data.length ; i ++) {
            let item = new EventItem(this.scene, {
                x: 0,
                y: i * 60,
                height: 60,
                id: data[i].id,
                type: data[i].type,
                title: data[i].title,
                letter_count_limit: letter_count_limit,
                content: data[i].content,
                date: data[i].created_at
            })
            this.add(item);
        }
        this.height = 60 * data.length;
    }

    setContent(content) {
        this.removeAll(true);
        this.config.data = content;
        this.initGraph();
    }
}