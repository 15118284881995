import { FilterButton } from "../../../components/button/filterbutton";
import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { InputField } from "../../../components/input/input";
import { SliderBar } from "../../../components/slider/sliderbar";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { DepositWithdrawItem } from "./deposit_withdraw_item";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class DepositWithdrawDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.myMoney = CommonDef.Me.money;
        this.myStoreMoney = CommonDef.Me.storemoney;

        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        this.moneyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x - 505 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 170);
        this.add(this.moneyContainer);

        this.myMoneyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 90 : 110,
            text: translate('current_money'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.moneyContainer.add(this.myMoneyText);
        let myMoneyValueBack = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 152 : 177, this.config.atlas, "input_back_" + this.scene.orientation + "_short.png").setOrigin(0, 0.5);
        this.moneyContainer.add(myMoneyValueBack);
        this.myMoneyValue = TextClass.plainText1(this.scene, {
            x: 20,
            y: this.scene.orientation == "pc" ? 152 : 177,
            text: NumberUtil.convertNumberWithComma(this.myMoney),
            color: "#ffffff",
            fontSize: 24
        }).setOrigin(0, 0.5);
        this.moneyContainer.add(this.myMoneyValue);

        this.myStoreMoneyText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 330 : 345,
            y: this.scene.orientation == "pc" ? 90 : 110,
            text: translate('safe_stored_money'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.moneyContainer.add(this.myStoreMoneyText);
        let myStoreMoneyValueBack = this.scene.add.sprite(this.scene.orientation == "pc" ? 330 : 345, this.scene.orientation == "pc" ? 152 : 177, this.config.atlas, "input_back_" + this.scene.orientation + "_short.png").setOrigin(0, 0.5);
        this.moneyContainer.add(myStoreMoneyValueBack);
        this.myStoreMoneyValue = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 350 : 365,
            y: this.scene.orientation == "pc" ? 152 : 177,
            text: NumberUtil.convertNumberWithComma(this.myStoreMoney),
            color: "#ffffff",
            fontSize: 24
        }).setOrigin(0, 0.5);
        this.moneyContainer.add(this.myStoreMoneyValue);

        this.moneyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 212 : 243,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.moneyContainer.add(this.moneyText);

        this.moneyField = new InputField(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 245 : 275,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 575 : 446
                },
                type: "number",
                width: this.scene.orientation == "pc" ? 615 : 650,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: translate('amount_placeholder'),
                color: "#ffffff",
                fontSize: 24
            },
            onChange: this.onChangeMoneyField.bind(this)
        });
        this.moneyContainer.add(this.moneyField);
        this.moneyField.setValue(0);

        

        this.btn_deposit = new IconButton(this.scene, {
            x: this.config.x - 93,
            y: this.config.y + (this.scene.orientation == "pc" ? 585 : 660),
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: translate('deposit'),
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff']
            },
            onClicked: () => {
                this.scene.requestDeposit(this.moneyField.value);
            }
        });
        this.add(this.btn_deposit);

        this.btn_withdraw = new IconButton(this.scene, {
            x: this.config.x - 93,
            y: this.config.y + (this.scene.orientation == "pc" ? 585 : 660),
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: translate('withdraw'),
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff']
            },
            onClicked: () => {
                this.scene.requestWithdraw(this.moneyField.value);
            }
        });
        this.add(this.btn_withdraw);

        this.btn_filter = new FilterButton(this.scene, {
            x: this.scene.orientation == "pc" ? 0 : 185,
            y: 0,
            atlas: this.config.atlas,
            back: "filter_back.png",
            button_back: "filter_btn_back.png",
            list: [{
                text: translate('deposit'),
                x: 82,
                y: 0,
                value: "deposit"
            }, {
                text: translate('withdraw'),
                x: 232,
                y: 0,
                value: "withdraw"
            }],
            color: {
                active: "#ffffff",
                inactive: "#662600"
            },
            fontSize: 20,
            onChange: this.onChnageFilter.bind(this)
        });
        this.moneyContainer.add(this.btn_filter);

        this.sliderBar = new SliderBar(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 334 : 386,
            absoluteX: this.scene.orientation == "pc" ? this.config.x - 505 : 40,
            atlas: this.config.atlas,
            width: this.scene.orientation == "pc" ? 530 : 560,
            height: 20,
            ruler: true,
            onChange: this.onChangeSlider.bind(this)
        });
        this.moneyContainer.add(this.sliderBar);

        this.btn_MAX = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 639 : 670,
            y: this.scene.orientation == "pc" ? 324 : 376,
            origin: [1, 0],
            atlas: this.config.atlas,
            normal: "max_normal.png",
            hover: "max_hover.png",
            clicked: "max_clicked.png",
            onClicked: this.onClickedBtnMax.bind(this)
        });
        this.moneyContainer.add(this.btn_MAX);

        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x + 175 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 822);
        this.add(this.historyContainer);

        let historyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: translate("safe_history"),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(historyText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 2,
            text: translate('refresh'),
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 288 : 630,
            y: 0,
            atlas: this.config.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            CommonDef.gameNet.requestStoreHistory();
        });

        this.historyContent = this.scene.add.container(0, 0);
        this.scrollViewContainer = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 323 : 667,
            height: this.scene.orientation == "pc" ? 382 : 434,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 1125 : 40,
                y: this.scene.orientation == "pc" ? 362 : 854
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 300 : 640,
                height: this.scene.orientation == "pc" ? 382 : 434,
            }
        });
        this.historyContainer.add(this.scrollViewContainer);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onChnageFilter(value) {
        this.moneyText.setText(translate(value == "deposit" ? "deposit_amount" : "withdraw_amount"));
        this.btn_deposit.setVisible(value == "deposit");
        this.btn_withdraw.setVisible(value == "withdraw");
        this.sliderBar && this.sliderBar.setValue(0);
        this.moneyField.setValue(0);
    }

    onClickedBtnMax() {
        this.sliderBar.setValue(1);
        this.moneyField.setValue(this.btn_filter.value == "deposit" ? this.myMoney : this.myStoreMoney);
    }

    onChangeSlider(value) {
        this.moneyField.setValue(Math.floor((this.btn_filter.value == "deposit" ? this.myMoney : this.myStoreMoney) * value));
    }

    onChangeMoneyField(value) {
        if(value < 0) {
            this.moneyField.setValue(0);
            this.sliderBar.setValue(0);
        } else if(value > (this.btn_filter.value == "deposit" ? this.myMoney : this.myStoreMoney)) {
            value = Math.floor(value / 10);
            this.moneyField.setValue(value);
        } else {
            this.sliderBar.setValue(value / (this.btn_filter.value == "deposit" ? this.myMoney : this.myStoreMoney));
        }
    }

    setHistoryContent(content) {
        this.historyContent.removeAll(true);
        let height = 90;
        for(let i = 0 ; i < content.length ; i ++) {
            let item = new DepositWithdrawItem(this.scene, {
                x: 0,
                y: i * height,
                atlas: this.config.atlas,
                ...content[i]
            });
            this.historyContent.add(item);
        }
        this.historyContent.height = content.length * height;
        this.scrollViewContainer.updateScroll();
    }

    popupAnimation() {
        this.moneyContainer.setVisible(false);
        this.historyContainer.setVisible(false);
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.moneyContainer.setVisible(true);
                this.historyContainer.setVisible(true);
            }
        })
    }

    updateMyMoney() {
        this.myMoney = CommonDef.Me.money;
        this.myStoreMoney = CommonDef.Me.storemoney;
        this.myStoreMoneyValue.setText(NumberUtil.convertNumberWithComma(this.myStoreMoney))
        this.myMoneyValue.setText(NumberUtil.convertNumberWithComma(this.myMoney));
        this.sliderBar && this.sliderBar.setValue(0);
        this.moneyField.setValue(0);
    }
}