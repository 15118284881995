import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { ContentCard } from "../../../../components/card/contentcard";
import { TabContainer } from "../../../../components/container/tabcontainer";
import { MobileDialog } from "../../../../components/dialog/mobile_dialog";
import { BlindsTable } from "./blinds_table";
import { TabDetail } from "./tab_detail";
import { TabEntry } from "./tab_entry";
import { TabPrize } from "./tab_prize";
import { TabTable } from "./tab_table";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class TournamentDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.tournamentid = 0;
        this.ticketid = 0;
        this.buyin = 0;
        this.fee = 0;
        this.rebuy = 0;
        this.partin = 0;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.tabContainer = new TabContainer(this.scene, {
            x: this.scene.orientation == "pc" ? -266 : 80,
            y: this.scene.orientation == "pc" ? 125 : 170,
            atlas: this.config.atlas,
            tabs: [{
                x: 0,
                y: 0,
                text: translate('tournament_details'),
                content: new TabDetail(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 85 : 95,
                    atlas: this.config.atlas,
                    openBlindsTable: this.openBlindsTable.bind(this)
                })
            }, {
                x: this.scene.orientation == "pc" ? 133 : 148,
                y: 0,
                text: translate('entry'),
                content: new TabEntry(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 85 : 95,
                    atlas: this.config.atlas
                })
            }, {
                x: this.scene.orientation == "pc" ? 266 : 296,
                y: 0,
                text: translate('table'),
                content: new TabTable(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 85 : 95,
                    atlas: this.config.atlas
                })
            }, {
                x: this.scene.orientation == "pc" ? 399 : 444,
                y: 0,
                text: translate('prize'),
                content: new TabPrize(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 88 : 113,
                    atlas: this.config.atlas
                })
            }],
            tab_line: {
                x: 0,
                y: this.scene.orientation == "pc" ? 49 : 55,
                texture: "tournament_tab_line.png"
            },
            scale: this.scene.orientation == "pc" ? [0.8, 1] : [1.3, 0.94],
            tabChanged: this.tabChanged.bind(this)
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.tabContainer);
        if(this.config.from != "game") {
            this.btn_register = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 270 : 300, // 170 : 200
                y: this.scene.orientation == "pc" ? 680 : 770,
                atlas: this.config.atlas,
                normal: {
                    back: "btn_confirm_back_normal.png",
                },
                hover: {
                    back: "btn_confirm_back_hover.png"
                },
                clicked: {
                    back: "btn_confirm_back_clicked.png"
                },
                text: {
                    x: 40,
                    y: 15,
                    value: "",
                    fontSize: 30,
                    gradient: ['#ffe0cf', '#ffffff']
                },
                onClicked: this.onClickedBtnRegister.bind(this)
            });
            this.tabContainer.add(this.btn_register);

            this.btn_observing = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 70 : 100,
                y: this.scene.orientation == "pc" ? 680 : 770,
                atlas: this.config.atlas,
                normal: {
                    back: "btn_confirm_back_normal.png",
                },
                hover: {
                    back: "btn_confirm_back_hover.png"
                },
                clicked: {
                    back: "btn_confirm_back_clicked.png"
                },
                text: {
                    x: 40,
                    y: 15,
                    value: translate('observe'),
                    fontSize: 30,
                    gradient: ['#ffe0cf', '#ffffff']
                },
                onClicked: this.onClickedBtnObserving.bind(this)
            });
            this.tabContainer.add(this.btn_observing);
        }

        this.blindsTable = new BlindsTable(this.scene, {
            x: this.scene.orientation == "pc" ? -266 : 80,
            y: this.scene.orientation == "pc" ? 125 : 170,
            atlas: this.config.atlas
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.blindsTable);
        this.blindsTable.setVisible(false);
    }
    
    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            scale: [0.5, 1.3],
            light: 'card_back_light.png',
            title: {
                text: translate('game_info'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 260,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: translate('game_info'),
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    show() {
        this.setVisible(true);
        if(this.config.from != "game") {
            this.btn_register.setVisible(false);
            this.btn_observing.setVisible(false);
        }
    }

    close() {
        this.setDetail(null);
        this.setEntry(0, []);
        this.setTable([]);
        this.setPrize([]);
        this.setVisible(false);
        this.config.from == "lobby" && this.scene.requestLobbyList();
    }

    setTournamentID(tournamentid) {
        this.tournamentid = tournamentid;
    }

    setTicketID(ticketid) {
        this.ticketid = ticketid;
    }

    initTab() {
        this.tabContainer.setTab(0, false);
    }

    tabChanged(tabIndex) {
        if(tabIndex == 0) this.scene.requestTournamentDetail();
        if(tabIndex == 1) this.scene.requestTournamentEntry();
        if(tabIndex == 2) this.scene.requestTournamentTable();
        if(tabIndex == 3) this.scene.requestTournamentPrize();
        if(this.config.from != "game") {
            this.btn_register.setVisible(false);
            this.btn_observing.setVisible(false);
        }
    }

    openBlindsTable() {
        this.blindsTable.setVisible(true);
        this.setBlinds([]);
        this.scene.requestTournamentBlinds();
    }

    setDetail(detail) {
        this.tabContainer.tabs[0].content.setDetail(detail);
        if(detail == null) return;
        this.state = detail.state;
        this.buyin = detail.buyin;
        this.fee = detail.fee;
        this.rebuyamount = detail.rebuy_amount;
        this.starttime = detail.starttime;
        this.partin = detail.partin;
        // this.btn_register.setVisible(!!this.btn_register);
        this.config.from != "game" && this.setBtnState();
    }

    setBlinds(blinds) {
        this.blindsTable.setData(blinds);
    }

    setEntry(tournamentstate, entries) {
        this.tabContainer.tabs[1].content.setData(tournamentstate, entries);
    }

    setTable(tables) {
        this.tabContainer.tabs[2].content.setData(tables);
    }

    setPrize(prize) {
        this.tabContainer.tabs[3].content.setData(prize)
    }

    setBtnState() {
        if(this.state == CommonDef.tournament_state.WAITING) {
            this.btn_register.changeText(translate(this.partin == 0 ? 'tournament_register_confirm' : 'tournament_register_cancel'));
            this.btn_register.x = this.scene.orientation == "pc" ? 170 : 200;
            this.btn_register.setVisible(true);
            this.btn_observing.setVisible(false);
        } else if(this.state > CommonDef.tournament_state.WAITING && this.state < CommonDef.tournament_state.MIDDLE) {
            let btn_label = "";
            if(this.partin == 0) btn_label = translate('tournament_register_confirm');
            else if(this.partin == 1) btn_label = translate('join');
            else if(this.partin == 2) btn_label = translate('tournament_register_again');
            this.btn_register.changeText(btn_label);
            this.btn_register.x = this.scene.orientation == "pc" ? (this.partin == 1 ? 170 : 270) : (this.partin == 1 ? 200 : 300);
            this.btn_observing.x = this.scene.orientation == "pc" ? 70 : 100;
            this.btn_register.setVisible(true);
            this.btn_observing.setVisible(this.partin != 1);
        } else {
            if(this.partin == 1) {
                this.btn_register.changeText(translate('join'));
                this.btn_register.x = this.scene.orientation == "pc" ? 170 : 200;
                this.btn_register.setVisible(true);
                this.btn_observing.setVisible(false);
            } else {
                this.btn_observing.x = this.scene.orientation == "pc" ? 170 : 200;
                this.btn_register.setVisible(false);
                this.btn_observing.setVisible(true);
            }
        }
    }

    onClickedBtnRegister() {
        if((this.partin == 0 || this.partin == 2) && this.state <= CommonDef.tournament_state.ADDON) this.scene.openRegisterDialog(this.tournamentid, this.buyin, this.fee, this.rebuyamount, this.starttime, this.partin == 2);
        else if(this.partin == 1) {
            if(this.state > CommonDef.tournament_state.WAITING) this.scene.requestEnterTournament();
            else this.scene.requestUnregisterTournament();
        }
    }

    onClickedBtnObserving() {
        this.scene.requestObservingTournament();
    }

    updateServerTime() {
        this.tabContainer.indexValue == 0 && this.tabContainer.tabs[0].content.startInterval();
    }
}