export class SelectButton extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.back);
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.selectList = [];
        this.value = null;

        for(let i = 0 ; i < this.config.list.length ; i ++) {
            let button = this.scene.add.sprite(this.config.list[i].x, this.config.list[i].y, this.config.atlas, this.config.list[i].normal);
            button.setOrigin(0, 0);
            this.add(button);
            this.selectList.push(button);

            button.setInteractive();
            button.on('pointerdown', () => {
                this.setValue(this.config.list[i].value);
            });

            button.on("pointerover", () => {
                if(this.value == this.config.list[i].value) return;
                button.setTexture(this.config.atlas, this.config.list[i].hover);
            })

            button.on("pointerout", () => {
                if(this.value == this.config.list[i].value) return;
                button.setTexture(this.config.atlas, this.config.list[i].normal);
            })
        }
        this.setValue(this.config.list[0].value);
    }

    setValue(value) {
        this.value = value;
        let index = this.config.list.findIndex(x => x.value == value);
        if(index < 0) return;
        for(let i = 0 ; i < this.config.list.length ; i ++) {
            this.selectList[i].setTexture(this.config.atlas, i == index ? this.config.list[i].clicked : this.config.list[i].normal);
        }
        this.config.onChange && this.config.onChange(value);
    }
}