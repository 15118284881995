import { IconButton } from "../../../../components/button/iconbutton";
import { TextClass } from "../../../../components/text/text";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class CardItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        if(this.config.icon) {
            this.img = this.scene.add.sprite(this.scene.orientation == "pc" ? 60 : 0, this.scene.orientation == "pc" ? 42 : 10, "TemaAtlas", this.config.icon + "_" + this.scene.tema + ".png");
            this.img.setOrigin(0, 0);
            this.img.setScale(206 / this.img.width, 116 / this.img.height);
        } else {
            this.img = this.scene.add.graphics({
                x: this.scene.orientation == "pc" ? 60 : 0,
                y: this.scene.orientation == "pc" ? 42 : 10,
                fillStyle: {
                    color: 0x181818,
                    alpha: 1
                }
            });
            this.img.fillRect(0, 0, 206, 116);
        }
        this.add(this.img)

        this.nameText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 60 : 0,
            y: this.scene.orientation == "pc" ? 13 : 154,
            text: this.config.name,
            fontSize: 18,
            color: "#ffffff"
        }).setOrigin(0, 0.5);
        this.add(this.nameText);

        if(this.config.id != CommonDef.Me.cardtemaid) {
            this.btn_apply = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 181 : 119,
                y: this.scene.orientation == "pc" ? 0 : 140,
                atlas: this.config.atlas,
                origin: [0, 0],
                normal: {
                    back: this.config.isAvailable ? 'btn_unlock_back_normal.png' : 'btn_apply_back_normal.png'
                },
                hover: {
                    back: this.config.isAvailable ? 'btn_unlock_back_hover.png' : 'btn_apply_back_hover.png'
                },
                clicked: {
                    back: this.config.isAvailable ? 'btn_unlock_back_clicked.png' : 'btn_apply_back_clicked.png'
                },
                text: {
                    x: 17,
                    y: 5,
                    value: translate(this.config.isAvailable ? 'release' : 'apply'),
                    fontSize: 16,
                    gradient: this.config.isAvailable ? ['#d6cece', '#ffffff'] : ['#ffe0cf', '#ffffff']
                },
                // onClicked: this.onClickedBtnCharge.bind(this)
            });
            this.add(this.btn_apply);

            this.click_area = {
                x: this.scene.orientation == "pc" ? 181 : 119,
                y: this.scene.orientation == "pc" ? -1 : 140,
                width: 80,
                height: 30
            }
        }

        let icon = "";
        if(this.config.id == CommonDef.Me.tabletemaid) icon = "icon_tema_active.png";
        if(!this.config.isAvailable) icon = "icon_tema_lock.png";
        if(icon) {
            this.statusIcon = this.scene.add.sprite(this.scene.orientation == "pc" ? 0 : 176, this.scene.orientation == "pc" ? 0 : 0, this.config.atlas, icon);
            this.statusIcon.setOrigin(0, 0);
            this.add(this.statusIcon);
        }
    }

    onHover() {
        this.btn_apply && this.btn_apply.setHover();
    }

    onRelease() {
        this.btn_apply && this.btn_apply.setRelease();
    }

    clickedEffect() {
        this.btn_apply && this.btn_apply.setClicked();
    }

}