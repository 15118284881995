import { Avatar } from "../../../components/avatar.js/avatar";
import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TabContainer } from "../../../components/container/tabcontainer";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { TextClass } from "../../../components/text/text";
import { TabHistoryHelp } from "../my_page/tab_history_help";
import { HoldemHelp } from "./help";
import { TabEmoticon } from "./tab_emoticon";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class PlayerProfile extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.topContainer = this.scene.add.container(this.scene.orientation == "pc" ? -305 : 0, 0);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.topContainer);

        this.avatar = new Avatar(this.scene, {
            x: this.scene.orientation == "pc" ? 90 : 135,
            y: this.scene.orientation == "pc" ? 125 : 170,
            atlas: "AvatarAtlas",
            avatar: this.config.profile.avatar,
            border: this.config.profile.border,
            mask: {
                x: this.scene.orientation == "pc" ? 745 : 135,
                y: this.scene.orientation == "pc" ? 315 : 226
            },
            scale: this.scene.orientation == "pc" ? (90 / 110) : (101 / 110)
        });
        this.topContainer.add(this.avatar);

        this.nickname = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 219,
            y: this.scene.orientation == "pc" ? 135 : 182,
            text: this.config.profile.nickname,
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 18 : 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.topContainer.add(this.nickname);

        this.id = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 219,
            y: this.scene.orientation == "pc" ? 163 : 213,
            text: "ID : " + this.config.profile.id,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 20 : 22,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.topContainer.add(this.id);

        this.ip = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 219,
            y: this.scene.orientation == "pc" ? 201 : 255,
            text: this.config.profile.ip,
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 18 : 20,
            color: "#777777"
        }).setOrigin(0, 0);
        this.topContainer.add(this.ip);

        if(!this.config.isMe) {
            this.btn_block = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 360 : 438,
                y: this.scene.orientation == "pc" ? 145 : 193,
                atlas: this.config.atlas,
                normal: {
                    back: 'btn_profile_back_normal.png',
                    icon: 'icon_block.png',
                    icon_position: {
                        x: this.scene.orientation == "pc" ? 16 : 17,
                        y: this.scene.orientation == "pc" ? 12 : 13
                    }
                },
                hover: {
                    back: 'btn_profile_back_hover.png'
                },
                clicked: {
                    back: 'btn_profile_back_clicked.png'
                },
                onClicked: () => {
                    this.onClickedBtnBlockUser(this.config.profile.id)
                }
            });
            this.topContainer.add(this.btn_block);
            this.btn_report = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 430 : 516,
                y: this.scene.orientation == "pc" ? 145 : 193,
                atlas: this.config.atlas,
                normal: {
                    back: 'btn_profile_back_normal.png',
                    icon: 'report.png',
                    icon_position: {
                        x: this.scene.orientation == "pc" ? 16 : 20,
                        y: this.scene.orientation == "pc" ? 8 : 10
                    },
                    icon_scale: 1.1
                },
                hover: {
                    back: 'btn_profile_back_hover.png'
                },
                clicked: {
                    back: 'btn_profile_back_clicked.png'
                },
                onClicked: () => {
                    this.onClickedBtnReport(this.config.profile.id)
                }
            });
            this.topContainer.add(this.btn_report);
        }

        this.btn_help = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 500 : 594,
            y: this.scene.orientation == "pc" ? 145 : 193,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_profile_back_normal.png',
                icon: 'icon_help.png',
                icon_position: {
                    x: this.scene.orientation == "pc" ? 19 : 20,
                    y: this.scene.orientation == "pc" ? 13 : 15
                }
            },
            hover: {
                back: 'btn_profile_back_hover.png'
            },
            clicked: {
                back: 'btn_profile_back_clicked.png'
            },
            onClicked: this.onClickedBtnHelp.bind(this)
        });
        this.topContainer.add(this.btn_help);

        let details = [
            ["VPIP", "PFR", "Steal", "Check_Raise"],
            ["Three_Bet", "Fold_To_Three_Bet", "C_Bet", "Fold_To_C_Bet"],
            ["Total_Hand", "Winner", "WTSD", "WSSD"]
        ];
        let keys = {
            VPIP: translate('vpip'),
            PFR: translate('pfr'),
            Steal: `${translate('steal')} %`,
            Check_Raise: translate('check_raise'),
            Three_Bet: translate('three_bet'),
            Fold_To_Three_Bet: translate('fold_to_three_bet'),
            C_Bet: translate('c_bet'),
            Fold_To_C_Bet: translate('fold_to_c_bet'),
            Total_Hand: translate('total_hand'),
            Winner: translate('win_count'),
            WTSD: translate('wtsd'),
            WSSD: `${translate('wssd')} %`
        }
        let detail_position = {
            pc: {
                x: [95, 165, 235, 305, 375, 445, 515],
                y: [286, 295, 314]
            },
            mobile: {
                x: [139, 218, 297, 376, 455, 534, 613],
                y: [348, 360, 380]
            }
        }
        let detail_row_height = this.scene.orientation == "pc" ? 85 : 94;

        for(let i = 0 ; i < details.length ; i ++) {
            for(let j = 0 ; j < details[i].length ; j ++) {
                let value = TextClass.plainText1(this.scene, {
                    x: detail_position[this.scene.orientation].x[j * 2],
                    y: detail_position[this.scene.orientation].y[0] + i * detail_row_height,
                    text: this.config.profile[details[i][j]] + ((i > 1 && j < 2) ? "" : "%"),
                    fontFamily: "NanumSquareL",
                    fontSize: this.scene.orientation == "pc" ? 28 : 31,
                    color: "#ffffff"
                }).setOrigin(0.5, 0.5);
                this.topContainer.add(value);

                let key = TextClass.plainText1(this.scene, {
                    x: detail_position[this.scene.orientation].x[j * 2],
                    y: detail_position[this.scene.orientation].y[2] + i * detail_row_height,
                    text: keys[details[i][j]],
                    fontSize: this.scene.orientation == "pc" ? 16 : 18,
                    color: "#777777"
                }).setOrigin(0.5, 0.5);
                this.topContainer.add(key);
                if(j > 0) {
                    let divider = this.scene.add.sprite(detail_position[this.scene.orientation].x[j * 2 - 1], detail_position[this.scene.orientation].y[1] + i * detail_row_height, this.config.atlas, "detail_divider.png").setOrigin(0.5, 0.5);
                    this.topContainer.add(divider);
                    divider.setScale(this.scene.orientation == "pc" ? 0.6 : 0.7)
                }
            }
            if(i > 0) {
                let line = this.scene.add.graphics({
                    x: this.scene.orientation == "pc" ? 39 : 80,
                    y: detail_position[this.scene.orientation].y[1] + (i - 0.5) * detail_row_height,
                    fillStyle: {
                        color: 0x222222,
                        alpha: 1
                    }
                });
                line.fillRect(0, 0, this.scene.orientation == "pc" ? 530 : 588 , 1);
                this.topContainer.add(line);
            }
        }

        if(this.config.observing === false) {
            this.tabContainer = new TabContainer(this.scene, {
                x: this.scene.orientation == "pc" ? this.config.x - 266 : 80,
                y: this.scene.orientation == "pc" ? this.config.y + 554 : 648,
                atlas: this.config.atlas,
                tabs: [{
                    x: 0,
                    y: 0,
                    text: translate('emoticon'),
                    content: new TabEmoticon(this.scene, {
                        x: 0,
                        y: 0,
                        atlas: this.config.atlas,
                        emoticon_type: this.config.isMe,
                        emoticon_clicked: this.onClickedEmoticon.bind(this)
                    })
                }],
                tab_line: {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 49 : 54,
                    texture: 'tab_line.png'
                },
                scale: this.scene.orientation == "pc" ? [1, 1] : [1.3, 0.92]
            });
            this.add(this.tabContainer);
        }
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'profile_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 230,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.destroy();
    }

    onClickedBtnHelp() {
        let helpPage = new HoldemHelp(this.scene, {
            x: this.scene.orientation == "pc" ? 20 : 60,
            y: this.scene.orientation == "pc" ? 100 : 170,
            onClose: () => {
                this.tabContainer.setVisible(true);
            }
        });
        this.topContainer.add(helpPage);
        this.tabContainer.setVisible(false);
    }

    onClickedEmoticon(key) {
        this.scene.requestSendEmoticon(this.config.profile.id, key);
    }

    onClickedBtnBlockUser(userid) {
        this.scene.requestBlockUser(userid);
    }

    setBlockState(state) {
        if(!this.btn_block) return;
        this.btn_block.changeIcon(state ? "icon_unblock.png" : "icon_block.png");
    }

    onClickedBtnReport(userid) {
        this.scene.reportUser(userid, CommonDef.reportType.ProfileReport);
    }
}