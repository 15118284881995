import CommonDef from "../def/commondef";

export class GameSound {
    constructor(scene) {
        this.scene = scene;
        scene.sound.pauseOnBlur = false;
    }

    playEffect(key, infiniate = false) {
        if(document.hidden) return;
        try{
            let sound = this.scene.sound.add(key, {loop: infiniate, volume: (localStorage.getItem("vol_effect") || 1)});
            sound.play();
            CommonDef.soundStack.push({
                key: key,
                sound: sound,
                type: "effect"
            });
        }
        catch(err){
        }
    }

    playBGM(key) {
        if(document.hidden) return;
        if(CommonDef.soundStack.findIndex(x => x.key == key) > -1) return;
        try{
            let sound = this.scene.sound.add(key, {loop: true, volume: (localStorage.getItem("vol_back") || 1)});
            sound.play();
            CommonDef.soundStack.push({
                key: key,
                sound: sound,
                type: "BGM"
            })
        }
        catch(err){
        }
    }

    stopEffect(keyArray, except = []) {
        for(let i = 0 ; i < CommonDef.soundStack.length ; i ++) {
            let key = CommonDef.soundStack[i].key;
            if(except.indexOf(key) > -1) continue;
            if(!keyArray || keyArray.length < 1 || keyArray.indexOf(key) > -1) {
                CommonDef.soundStack[i].sound.stop();
                CommonDef.soundStack[i].sound.destroy();
                CommonDef.soundStack.splice(i, 1);
                i--;
            }
        }
    }

    changeVolume(type, vol) {
        for(let i = 0 ; i < CommonDef.soundStack.length ; i ++) {
            if(CommonDef.soundStack[i].type == type) CommonDef.soundStack[i].sound.volume = vol;
        }
    }

    static setMute(flag) {
        for(let i = 0 ; i < CommonDef.soundStack.length ; i++){
            CommonDef.soundStack[i].sound.mute = flag;
        }
    }
}