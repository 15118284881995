import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { TextClass } from "../../../components/text/text";
import { NoteItem } from "./note_item";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class NoteDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.atlas = "DialogAtlas";
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? -505 : 40, this.scene.orientation == "pc" ? 120 : 150);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.historyContainer);
        this.unreadCountText = TextClass.plainText1(this.scene, {
            x: 20,
            y: 0,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(this.unreadCountText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 870 : 532,
            y: 2,
            text: translate('refresh'),
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 963 : 630,
            y: 0,
            atlas: this.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 870 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            CommonDef.gameNet.requestNoteList();
        });

        this.historyContent = this.scene.add.container(0, 0);
        this.historyContainer.add(this.historyContent);

        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 997 : 667,
            height: this.scene.orientation == "pc" ? 500 : 1100,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 455 : 40,
                y: this.scene.orientation == "pc" ? 352 : 183
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 970 : 640,
                height: this.scene.orientation == "pc" ? 385 : 985,
            }
        });
        this.historyContainer.add(this.scrollView);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            // scale: [1.2, this.config.yScale],
            title: {
                text: translate('note_box'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 440,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: translate('note_box'),
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        if(CommonDef.Me.unreadNoteCount > 0) {
            this.scene.showToast("warning", translate('have_unread_notes'));
            return;
        }
        this.scene.updateMyInfo && this.scene.updateMyInfo();
        this.destroy();
    }

    setContent(noteList) {
        this.historyContent.removeAll(true);
        let height = 0;
        for(let i = 0 ; i < noteList.length ; i ++) {
            let item = new NoteItem(this.scene, {
                x: 10,
                y: height,
                atlas: this.config.atlas,
                arrangeContent: this.arrangeContent.bind(this),
                ...noteList[i]
            });
            this.historyContent.add(item);
            height += item.height;
            height += 15;
        }
        this.unreadCountText.setText(`${translate('unread_notes')} ${CommonDef.Me.unreadNoteCount}${translate('note_count_unit')}`);
        this.unreadCountText.setVisible(CommonDef.Me.unreadNoteCount > 0);
        this.historyContent.height = height;
        this.scrollView.updateScroll();
    }

    arrangeContent() {
        let height = 0;
        for(let i = 0 ; i < this.historyContent.list.length ; i ++) {
            this.historyContent.list[i].y = height;
            height += this.historyContent.list[i].height + 15;
        }
        this.historyContent.height = height;
        this.scrollView.updateScroll(false);
    }

    setNoteRead(noteid) {
        let index = this.historyContent.list.findIndex(x => x.config.id == noteid);
        if(index < 0) return;
        this.unreadCountText.setText(`${translate('unread_notes')} ${CommonDef.Me.unreadNoteCount}${translate('note_count_unit')}`);
        this.unreadCountText.setVisible(CommonDef.Me.unreadNoteCount > 0);
        this.historyContent.list[index].setNoteRead();
    }

    removeNote(noteid) {
        let index = this.historyContent.list.findIndex(x => x.config.id == noteid);
        if(index < 0) return;
        let item = this.historyContent.list.splice(index, 1)[0];
        item.destroy();
        this.arrangeContent();
    }
}