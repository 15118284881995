export class Avatar extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let size = 110;
        let border_width = 5;
        let paddingTop = 11;
        let scale = this.config.scale || 1;
        
        let y = 0;
        if(this.config.border == "border_2.png") y = -10;

        this.border = this.scene.add.sprite(0, y, this.config.atlas, this.config.border || "border_1.png");
        this.border.setOrigin(0.5, 0);
        this.add(this.border);
        this.border.setDepth(1);

        this.avatarBack = this.scene.add.sprite(0, size / 2 + border_width + paddingTop, this.config.atlas, "avatar_back.png");
        this.avatarBack.setOrigin(0.5, 0.5);
        this.add(this.avatarBack);

        this.avatar = this.scene.add.sprite(0, size / 2 + border_width);
        this.avatar.setOrigin(0.5, 0.5);
        this.add(this.avatar);
        if(this.config.avatar === null) { 
            
        } else {
            if(this.config.avatar && this.config.avatar.indexOf("http") < 0) {
                this.avatar.setTexture("DefaultAvatarAtlas", this.config.avatar);
                this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
            } else {
                this.avatar.setTexture(this.config.atlas, "default_avatar.png");
                if(this.config.avatar) {
                    if(this.scene.textures.exists(this.config.avatar)) {
                        this.avatar.setTexture(this.config.avatar);
                        this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
                    } else {
                        this.scene.load.image(this.config.avatar, this.config.avatar);
                        this.scene.load.once(Phaser.Loader.Events.COMPLETE, (resource, success) => {
                            if(success) {
                                try {
                                    this.avatar.setTexture(this.config.avatar);
                                    this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
                                } catch (e) {
                                    console.error("The avatar was destroyed.");
                                }
                            }
                        });
                        this.scene.load.start();
                    }
                }
            }
        }

        this.avatarMask = this.scene.make.graphics({
            x : this.config.mask ? this.config.mask.x : this.config.x,
            y : this.config.mask ? this.config.mask.y : this.config.y + size / 2 + border_width,
            fillStyle : {
                color : 0xffff00,
                alpha : 0.8
            },
        });
        this.avatarMask.fillCircle(0, 0, size / 2);
        let mask = new Phaser.Display.Masks.GeometryMask(this.scene, this.avatarMask);
        this.avatar.mask = mask;

        if(this.config.onClick) {
            this.action_area = this.scene.add.graphics({
                x : 0,
                y : size / 2 + border_width,
                fillStyle : {
                    color : 0xffffff,
                    alpha : 0.9
                },
            });
            this.add(this.action_area);
            let action_rect = new Phaser.Geom.Circle(0, 0, size / 2 * scale);
            this.action_area.setInteractive(action_rect, Phaser.Geom.Circle.Contains);
            this.action_area.on("pointerdown", () => {
                this.config.onClick();
            })
        }


        this.sort("depth");
        this.setScale(scale);
    }

    updateAvatar(avatar) {
        if(avatar == this.config.avatar) return;
        this.config.avatar = avatar;
        if(this.config.avatar) {
            this.avatar.setTexture("DefaultAvatarAtlas", this.config.avatar);
            this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
            return;
            if(this.scene.textures.exists(this.config.avatar)) {
                this.avatar.setTexture(this.config.avatar);
                this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
            } else {
                this.scene.load.setCORS('anonymous')
                this.scene.load.setCORS('Anonymous')
                this.scene.load.setCORS(true)
                this.scene.load.image(this.config.avatar, this.config.avatar);
                this.scene.load.once(Phaser.Loader.Events.COMPLETE, (resource, success) => {
                    if(success) {
                        if(this.avatar) {
                            this.avatar.setTexture(this.config.avatar);
                            this.avatar.setScale(Math.max(110 / this.avatar.width, 110 / this.avatar.height));
                        }
                    }
                });
                this.scene.load.start();
            }
        } else {
            this.avatar.setTexture();
        }
    }

    updateBorder(border) {
        border && this.border.setTexture(this.config.atlas, border);
    }

    move(deltaX, deltaY, animFlag = false) {
        if(animFlag) {
            try {
                this.scene.tweens.add({
                    targets: this.avatarMask,
                    x: "+=" + deltaX,
                    y: "+=" + deltaY,
                    duration: 300,
                    ease: "Power2",
                })
            } catch (e) {
                console.error("아바타 move error");
            }
        } else {
            this.avatarMask.y += deltaY;
            this.avatarMask.x += deltaX;
        }
    }

    destroy() {
        this.avatarMask && this.avatarMask.destroy();
        super.destroy();
    }

    setScale(scale) {
        this.avatarMask.setScale(scale);
        super.setScale(scale);
    }
}