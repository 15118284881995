import { TextClass } from "../../../../components/text/text";
import { translate } from "../../../../util/other";

export class AnteItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.anteText = TextClass.plainText1(this.scene, {
            x: 10,
            y: 0,
            text: this.config.ante > 0 ? `${translate('ante_yes')} (${this.config.ante})` : translate('ante_no'),
            color: "#ffffff",
            fontSize: 20
        }).setOrigin(0, 0);
        this.add(this.anteText);
        
        let action_area = this.scene.add.graphics({
            x: 10,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.6
            }
        });
        this.add(action_area);
        let rect = new Phaser.Geom.Rectangle(0, 0, 300, 30);
        action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        action_area.on("pointerdown", () => {
            this.config.onClicked && this.config.onClicked(this.config.ante);
        })
    }
}