export class HoldemChip extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.value = config.value;
        this.initGraph();
    }

    initGraph() {
        let icon = this.scene.add.sprite(0, 0, this.config.atlas, this.config.icon).setOrigin(0.0, 0.5);
        this.add(icon);
        icon.setScale(this.config.scale || 1);
    }
}