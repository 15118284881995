import gameConfig from './gameconfig.js';
import CommonDef from '../def/commondef.js';
import { GameNet } from '../net/game_net';
import { GameSound } from '../sound/game_sound';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

class Game extends Phaser.Game {
  constructor(){
    super(window.isMobile ? gameConfig.mobile : gameConfig.pc)
  }
}

window.onload = function(){
  window.game = new Game();
  CommonDef.gameNet = new GameNet();
  Sentry.init({
    dsn: "https://02d3deb36eeb414c8203f8e4f83f886b@o1352657.ingest.sentry.io/6634164",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  window.addEventListener('resize', _ => {
    resize();
  })
  resize();
}


const resize = () => {
  console.log('AAA')
  let width = document.documentElement.clientWidth
  let height = document.documentElement.clientHeight

  if(!window.isMobile) {
    CommonDef.ClientSize.width = width;
    CommonDef.ClientSize.height = height;
    return;
  }

  // 폰에서 키패드 나올 경우 resize 안함
  if(CommonDef.orientation == "portrait" && Math.abs(width - height) < 300) {
    console.log("phone key pad => no resize 1");
    return;
  }
  if(CommonDef.orientation == "landscape" && width > height * 3) {
    console.log("phone key pad => no resize 2");
    return;
  }

  let w = width;
  let h = height;
  CommonDef.ClientSize.width = width;
  CommonDef.ClientSize.height = height;
  let orientation = width > height ? "landscape" : "portrait";
  if(orientation != CommonDef.orientation) {
    CommonDef.orientation = orientation; 
    if(width > height) {
      game.scale.resize(CommonDef.ScreenSize.pc.width, CommonDef.ScreenSize.pc.height);
      if(w / h > CommonDef.ScreenSize.pc.width / CommonDef.ScreenSize.pc.height) {
        w = h * CommonDef.ScreenSize.pc.width / CommonDef.ScreenSize.pc.height;
      } else {
        h = w * CommonDef.ScreenSize.pc.height / CommonDef.ScreenSize.pc.width
      }
    } else {
      game.scale.resize(CommonDef.ScreenSize.mobile.width, CommonDef.ScreenSize.mobile.height);
      if(w / h > CommonDef.ScreenSize.mobile.width / CommonDef.ScreenSize.mobile.height) {
        w = h * CommonDef.ScreenSize.mobile.width / CommonDef.ScreenSize.mobile.height;
      } else {
        h = w * CommonDef.ScreenSize.mobile.height / CommonDef.ScreenSize.mobile.width;
      }
    }

    game.canvas.style.width = w + 'px'
    game.canvas.style.height = h + 'px'
    game.canvas.style.position = "absolute";
    game.canvas.style.top = (height - h) / 2 + 'px'
    game.canvas.style.left = (width - w) / 2 + 'px'
    game.canvas.style.marginTop = '0px !important'
    game.canvas.style.marginLeft = '0px !important';
    // game.canvas.style.zIndex = -1;
    game.scale.refresh();
    if(CommonDef.ScreenModeChanged == true) {
      CommonDef.ScreenModeChanged = false;
      CommonDef.currentScene && CommonDef.currentScene.create();
    }
  }
}


document.addEventListener("visibilitychange", visibilityChange);
function visibilityChange(){
  if (document.hidden) {
    GameSound.setMute(true);
  }
  else {
    GameSound.setMute(false);
    CommonDef.currentScene && CommonDef.currentScene.hideAlert && CommonDef.currentScene.hideAlert();
    if(CommonDef.currentScene && CommonDef.currentScene.scene) {
      switch (CommonDef.currentScene.scene.key) {
        case "LobbyScene":
          CommonDef.currentScene.initTab();
          break;
        case "HoldemScene":
        case "OmahaScene":
          CommonDef.currentScene.startGame();
          CommonDef.currentScene.requestRoomInfo();
          break;
        case "TournamentHoldemScene":
          CommonDef.currentScene.startGame();
          CommonDef.currentScene.requestTournamentTableInfo();
          break;
      }
    } else {
      window.location.reload();
    }
    document.removeEventListener("visibilitychange", this.visibilityChange);
  }
}