import { Avatar } from "../../../../components/avatar.js/avatar";
import { ScrollView } from "../../../../components/container/scrollview";
import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class TabEntry extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.topBorder = this.scene.add.graphics({
            x: 0,
            y: 0,
        });
        this.topBorder.lineStyle(1, 0x222222, 1);
        this.topBorder.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 530 : 590, this.scene.orientation == "pc" ? 50 : 56, this.scene.orientation == "pc" ? 25 : 28);
        this.add(this.topBorder);

        this.icon_user = this.scene.add.sprite(this.scene.orientation == "pc" ? 42 : 46, this.scene.orientation == "pc" ? 25 : 28, this.config.atlas, "icon_user.png").setOrigin(0.5, 0.5);
        this.add(this.icon_user);

        this.registeredPlayerCountText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 62 : 66,
            y: this.scene.orientation == "pc" ? 25 : 28,
            text: `${translate('tournament_register')} 0`,
            fontSize: 16,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0.5);
        this.add(this.registeredPlayerCountText);

        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 70 : 80,
            width: this.scene.orientation == "pc" ? 525 : 585,
            height: this.scene.orientation == "pc" ? 484 : 540,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 690 : 70,
                y: this.scene.orientation == "pc" ? 377 : 345
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 500 : 560,
                height: this.scene.orientation == "pc" ? 484 : 540,
            }
        });
        this.add(this.scrollView);
    }

    setData(tournamentstate, players) {
        this.listContainer.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 120 : 133;
        for(let i = 0 ; i < players.length ; i ++) {
            let item = this.scene.add.container(0, item_height * i);
            let back = this.scene.add.sprite(this.scene.orientation == "pc" ? 74 : 82, this.scene.orientation == "pc" ? 23 : 24, this.config.atlas, "tournament_registered_player_back.png");
            back.setOrigin(0, 0);
            item.add(back);

            let nickname = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 120 : 130,
                y: this.scene.orientation == "pc" ? 40 : 44,
                text: players[i].nickname.length <= 7 ? players[i].nickname : players[i].nickname.substring(0, 6) + "...",
                fontFamily: "GmarketSansMedium",
                fontSize: this.scene.orientation == "pc" ? 18 : 20,
                color: "#f58a00"
            }).setOrigin(0, 0)
            item.add(nickname);

            let playerID = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 120 : 130,
                y: this.scene.orientation == "pc" ? 67 : 74,
                text: "ID : " + (players[i].userid.length <= 8 ? players[i].userid : players[i].userid.substring(0, 7) + "..."),
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: this.scene.orientation == "pc" ? 16 : 18,
                color: "#ffffff"
            }).setOrigin(0, 0)
            item.add(playerID);
            if(tournamentstate > CommonDef.tournament_state.WAITING) {
                if(!players[i].gameover) {
                    let balance_label = TextClass.plainText1(this.scene, {
                        x: this.scene.orientation == "pc" ? 260 : 280,
                        y: this.scene.orientation == "pc" ? 40 : 44,
                        text: translate('player_chip'),
                        fontFamily: "GmarketSansMedium",
                        fontSize: this.scene.orientation == "pc" ? 18 : 20,
                        color: "#f58a00"
                    }).setOrigin(0, 0)
                    item.add(balance_label);

                    let balance_value = TextClass.plainText1(this.scene, {
                        x: this.scene.orientation == "pc" ? 260 : 280,
                        y: this.scene.orientation == "pc" ? 67 : 74,
                        text: NumberUtil.convertNumberAsBlind(players[i].balance),
                        fontFamily: "GmarketSansMedium",
                        fontSize: this.scene.orientation == "pc" ? 18 : 20,
                        color: "#f58a00"
                    }).setOrigin(0, 0)
                    item.add(balance_value);
                }

                let addcount = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 440 : 500,
                    y: this.scene.orientation == "pc" ? 54 : 59,
                    text: `R${players[i].rebuycount}/A${players[i].addoncount % 2}`, // 0: 안했음, 1: 했음, 2: 취소
                    fontFamily: "GmarketSansMedium",
                    fontSize: this.scene.orientation == "pc" ? 18 : 20,
                    color: "#ffffff"
                }).setOrigin(0.5, 0)
                item.add(addcount);

                let rank = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 360 : 410,
                    y: this.scene.orientation == "pc" ? 40 : 44,
                    text: players[i].rank + translate('rank_unit'),
                    fontFamily: "GmarketSansMedium",
                    fontSize: this.scene.orientation == "pc" ? 18 : 20,
                    color: "#f58a00"
                }).setOrigin(0.5, 0)
                item.add(rank);

                // let prizeIcon = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 67 : 74, this.config.atlas, 'tournament_prize_icon.png').setOrigin(0, 0);
                // item.add(prizeIcon);
                // prizeIcon.setScale(0.5);
                let prize = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 360 : 410,
                    y: this.scene.orientation == "pc" ? 67 : 74,
                    text: NumberUtil.convertNumberAsBlind(players[i].prize),
                    fontFamily: "GmarketSansMedium",
                    fontSize: this.scene.orientation == "pc" ? 18 : 20,
                    color: "#f58a00"
                }).setOrigin(0.5, 0)
                item.add(prize);
                // prizeIcon.x = (this.scene.orientation == "pc" ? 440 : 480) - (prizeIcon.width * 0.6 + 10 + prize.width) / 2;
                // prize.x = prizeIcon.x + prizeIcon.width * 0.5 + 10;

                if(!players[i].gameover) {
                    rank.setAlpha(0.6);
                    // prizeIcon.setAlpha(0.7);
                    prize.setAlpha(0.6);
                }
                
                let gameovermask = this.scene.add.graphics({
                    x: this.scene.orientation == "pc" ? 74 : 82,
                    y: this.scene.orientation == "pc" ? 23 : 24,
                    fillStyle: {
                        color: 0x000000,
                        alpha: 0.5
                    }
                });
                gameovermask.fillRect(0, 0, back.width, back.height);
                gameovermask.setVisible(players[i].gameover);
                item.add(gameovermask);
            }
            
            let avatar = new Avatar(this.scene, {
                x: 61,
                y: 10,
                atlas: "AvatarAtlas",
                avatar: players[i].avatar,
                border: players[i].border,
                mask: {
                    x: this.scene.orientation == "pc" ? 756 : 140,
                    y: (this.scene.orientation == "pc" ? 440 : 413) + item_height * i
                }
            });
            avatar.setScale(this.scene.orientation == "pc" ? 0.85 : 0.95);
            item.add(avatar);

            item.onMove = (deltaX, deltaY, animFlag) => {
                avatar.move(deltaX, deltaY, animFlag)
            }
            this.listContainer.add(item);
        }
        this.listContainer.height = item_height * players.length;
        this.scrollView.updateScroll();
        this.registeredPlayerCountText.setText(`${translate('tournament_register')} ${players.length}`);
    }
}