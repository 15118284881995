import { TextClass } from "../text/text";
import CommonDef from "../../def/commondef";

export class TextBox extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;

        this.value = config.input.value || '';
        this.editStatus = false;
        let top = this.scene.game.canvas.style.top
        this.canvasAbsoluteTop = [parseInt(top.substring(0, top.length - 2)), parseInt(top.substring(0, top.length - 2))];
        this.initGraph();
    }

    initGraph() {
        if(this.config.back) {
            this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.back);
            this.back.setOrigin(0, 0);
            this.add(this.back);
        } else {
            this.back = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: 0x333333,
                    alpha: 1
                }
            });
            this.back.fillRect(-5, 0, this.config.input.width + 10, this.config.input.height);
            this.add(this.back);
        }
        let align_origin = {
            left: 0,
            center: 0.5,
            right: 1
        }
        if(!this.config.align) this.config.align = "left";

        this.displayText = TextClass.plainText1(this.scene, {
            x: this.config.input.x + this.config.input.width * align_origin[this.config.align],
            y: !!this.config.displayTextY ? this.config.displayTextY : 10,
            text: this.value || this.config.input.placeholder,
            color: this.config.input.color,
            fontFamily: this.config.input.fontFamily,
            // gradient: this.config.input.gradient,
            fontSize: this.config.input.fontSize
        }).setOrigin(this.config.align ? align_origin[this.config.align] : 0, 0);
        this.add(this.displayText);
        this.displayText.setStyle({
            wordWrap: { width: this.config.input.width, useAdvancedWrap: true }
        });
        this.displayText.setLineSpacing(8);

        this.maskGraphic = this.scene.make.graphics({
            x: this.config.input.absolute.x,
            y: this.config.input.absolute.y,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.5
            }
        });
        this.maskGraphic.fillRect(0, 0, this.config.input.width, this.config.input.height);
        let mask = new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphic);
        this.displayText.mask = mask;

        this.action_area = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        })
        this.action_rect = new Phaser.Geom.Rectangle(this.config.input.x, this.config.input.y, this.config.input.width, this.config.input.height);
        this.action_area.setInteractive(this.action_rect, Phaser.Geom.Rectangle.Contains);
        this.add(this.action_area);

        this.action_area.on("pointerdown", () => {
            this.displayText.setVisible(false);
            this.action_area.setVisible(false);
            let rate = Math.min(CommonDef.ClientSize.width / this.scene.ScreenWidth, CommonDef.ClientSize.height / this.scene.ScreenHeight);
            this.inputTag = document.createElement("textarea");
            this.inputTag.setAttribute("spellcheck", "false");

            let keyboard_height = 400;
            if(window.isMobile && this.config.input.absolute.y * rate + this.canvasAbsoluteTop[0] * 2 + keyboard_height > CommonDef.ClientSize.height) {
                this.canvasAbsoluteTop[1] = -(this.config.input.absolute.y * rate + this.canvasAbsoluteTop[0] + keyboard_height - CommonDef.ClientSize.height);
                let a = (CommonDef.ClientSize.height - keyboard_height - this.canvasAbsoluteTop[0] * 2 - this.config.input.absolute.y * rate) / rate;
                this.scene.cameras.main.y = a;
            }
            window.onresize = () => {
                this.onResize();
            }

            this.inputTag.style.position = "absolute";
            this.inputTag.style.top = Math.floor(this.config.input.absolute.y * rate + (this.canvasAbsoluteTop[1] || (document.documentElement.clientHeight - this.scene.ScreenHeight * rate) / 2)) + "px"; 
            this.inputTag.style.left = Math.floor((this.config.input.absolute.x + this.config.input.x) * rate + (document.documentElement.clientWidth - this.scene.ScreenWidth * rate) / 2) + "px";
            this.inputTag.style.width = Math.floor(this.config.input.width * rate) + "px";
            this.inputTag.style.height = Math.floor(this.config.input.height * rate) + "px";
            this.inputTag.style.border = "none";
            this.inputTag.style.outline = "none";
            this.inputTag.style.background = "none";
            this.inputTag.style.paddingLeft = "0px";
            this.inputTag.style.paddingTop = "5px";
            this.inputTag.style.resize = "none";
            this.inputTag.style.color = this.config.input.color;
            this.config.input.fontFamily && (this.inputTag.style.fontFamily = this.config.input.fontFamily);
            this.inputTag.style.fontSize = Math.floor(this.config.input.fontSize * rate) + "px";
            this.inputTag.style.textAlign = this.config.align;
            this.inputTag.value = this.value;
            this.inputTag.placeholder = this.config.input.placeholder;
            document.body.appendChild(this.inputTag);
            this.inputTag.focus();
            this.inputTag.onkeyup = () => {
                this.config.onChange && this.config.onChange(this.inputTag.value);
            }
            document.getElementById("main").onpointerdown = () => {
                this.htmlBodyClickEvent();
            }
            setTimeout(() => {
                this.editStatus = true;
            }, 100)
        })
    }

    htmlBodyClickEvent() {
        if(!this.inputTag) return;
        this.onBlurInputTag();
    }

    onBlurInputTag() {
        if(!this.editStatus) return;
        try {
            if(this.value != this.inputTag.value && this.config.onChange) {
                this.config.onChange(this.inputTag.value);
            }
            this.value = this.inputTag.value;
            this.displayText.setText(this.generateDisplayString(this.value));
            this.displayText.setVisible(true);
            this.action_area.setVisible(true);
            this.editStatus = false;
            this.canvasAbsoluteTop[1] = this.canvasAbsoluteTop[0];
            window.isMobile && (this.scene.cameras.main.y = 0);
            this.inputTag.remove();
            this.inputTag = null;
        } catch(ex) {
            console.log("The textarea tag was removed", ex);
        }
    }

    generateDisplayString(value) {
        if(value == "") return this.config.input.placeholder;
        if(this.config.input.type == "password") {
            let str = '';
            for(let i = 0 ; i < value.length ; i ++) {
                str += 'ㆍ';
            }
            return str;
        } else return value;
    }

    setValue(value) {
        this.displayText.setText(value);
        this.value = value;
        this.inputTag && (this.inputTag.value = value);
    }

    destroy() {
        this.inputTag && this.inputTag.remove();
        // window.removeEventListener('resize', this.onResize.bind(this));
        super.destroy();
    }

    onResize() {
        if(document.documentElement.clientHeight == CommonDef.ClientSize.height) {
            this.scene && (this.scene.cameras.main.y = 0);
            this.onBlurInputTag();
        }
    }
}