import CommonDef from '../../def/commondef';
import HoldemDef from '../../def/holdemdef';
import { Avatar } from "../avatar.js/avatar";
import { TextClass } from "../text/text";
import * as NumberUtil from '../../util/number';

export class OmahaPlayer extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.active = true;
        this.cardAtlas = "HoldemDefaultCardAtlas_" + this.scene.tema;
        this.balance = 0;
        this.round_chip = 0;
        this.state = 0;
        this.userid = "";
        this.turn_countdown = false; // 턴을 받아서 마지막 3초동안 countdown 음성 울리는 상태
        this.isTurn = false; // 현재 턴인가?
        this.initGraph();
    }

    initGraph() {
        this.avatar = new Avatar(this.scene, {
            x: 0,
            y: 0,
            atlas: "AvatarAtlas",
            avatar: this.config.avatar.url,
            scale: this.config.avatar.scale,
            mask: {
                x: this.config.x,
                y: this.config.y + 60 * this.config.avatar.scale
            },
            onClick: this.onClickedAvatar.bind(this)
        });
        this.avatar.setDepth(1);
        this.add(this.avatar);

        

        this.infoContainer = this.scene.add.container(0, 105 * this.config.avatar.scale);
        this.add(this.infoContainer);
        this.player_info_back = this.scene.add.sprite(0, 0, this.config.atlas, "player_" + (this.config.type == 0 ? "me" : "other") + "_back.png");
        this.player_info_back.setOrigin(0.5, 0);
        this.infoContainer.add(this.player_info_back);
        this.player_info_back.setScale(this.config.avatar.scale);

        this.nickname = TextClass.plainText1(this.scene, {
            x: 0,
            y: 26 * this.config.avatar.scale,
            text: this.config.name,
            fontFamily: "GmarketSansMedium",
            fontSize: 18 * this.config.avatar.scale,
            color: "#f58a00"
        }).setOrigin(0.5, 0);
        this.infoContainer.add(this.nickname);

        this.sittingoutBack = this.scene.add.graphics({
            x: 0,
            y: 20 * this.config.avatar.scale,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.sittingoutBack.fillRect(-70, 0, 140, 30);
        this.infoContainer.add(this.sittingoutBack);
        this.sittingoutText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 26 * this.config.avatar.scale,
            text: "자리비움",
            fontFamily: "GmarketSansMedium",
            fontSize: 18 * this.config.avatar.scale,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.infoContainer.add(this.sittingoutText);
        this.setSittingOut(false);

        this.coins = TextClass.plainText1(this.scene, {
            x: 0,
            y: 54 * this.config.avatar.scale,
            text: NumberUtil.convertNumberWithComma(this.config.coins),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20 * this.config.avatar.scale,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0.5, 0);
        this.infoContainer.add(this.coins);

        // Odds
        this.oddsContainer = this.scene.add.container(0, 105 * this.config.avatar.scale);
        this.add(this.oddsContainer);
        this.oddsBack = this.scene.add.sprite(0, 0, this.config.atlas, "player_" + (this.config.type == 0 ? "me" : "other") + "_back.png");
        this.oddsBack.setOrigin(0.5, 0);
        this.oddsContainer.add(this.oddsBack);
        this.oddsBack.setScale(this.config.avatar.scale);

        this.oddsWinValue = TextClass.plainText1(this.scene, {
            x: 0,
            y: 30 * this.config.avatar.scale,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: 18 * this.config.avatar.scale,
            color: "#f58a00"
        }).setOrigin(0.5, 0);
        this.oddsContainer.add(this.oddsWinValue);

        this.oddsTieValue = TextClass.plainText1(this.scene, {
            x: 0,
            y: 56 * this.config.avatar.scale,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: 18 * this.config.avatar.scale,
            color: "#f58a00"
        }).setOrigin(0.5, 0);
        this.oddsContainer.add(this.oddsTieValue);
        this.hideOdds();

        // 베팅 액션
        this.bettingContainer = this.scene.add.container(0, 0);
        let avatar_mask = this.scene.add.graphics({
            x: 0,
            y: 5 + 55 * this.config.avatar.scale,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        avatar_mask.fillCircle(0, 0, 56 * this.config.avatar.scale);
        this.bettingContainer.add(avatar_mask);
        this.betting_amount_back = this.scene.add.sprite(-35 * this.config.avatar.scale * ((this.scene.orientation == "mobile" && this.config.type == 1) ? -1 : 1), 39 * this.config.avatar.scale, this.config.atlas, "betting_amount_back.png").setOrigin((this.scene.orientation == "mobile" && this.config.type == 1) ? 0 : 1, 0);
        this.bettingContainer.add(this.betting_amount_back);
        this.betting_amount_back.setScale(this.config.avatar.scale);
        if(this.scene.orientation == "mobile" && this.config.type == 1) {
            this.betting_amount_back.flipX = true;
        }
        this.betting_amount = TextClass.plainText1(this.scene, {
            x: -95 * this.config.avatar.scale * ((this.scene.orientation == "mobile" && this.config.type == 1) ? -1 : 1),
            y: 60 * this.config.avatar.scale,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 18 * this.config.avatar.scale,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.bettingContainer.add(this.betting_amount);
        this.betting_action_icon = this.scene.add.sprite(0, 50 * this.config.avatar.scale).setOrigin(0.5, 0.5);
        this.betting_action_icon.setScale(this.config.avatar.scale);
        this.bettingContainer.add(this.betting_action_icon);
        this.betting_action_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 78 * this.config.avatar.scale,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20 * this.config.avatar.scale,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.bettingContainer.add(this.betting_action_label);
        this.add(this.bettingContainer);
        
        this.bettingContainer.setDepth(2);
        this.bettingContainer.setVisible(false);

        // 마크
        this.mark = this.scene.add.sprite(70 * this.config.avatar.scale * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1), -5);
        this.mark.setOrigin((this.scene.orientation == "mobile" && this.config.type == 2) ? 0 : 1, 0)
        this.mark.setScale(this.config.avatar.scale);
        this.add(this.mark);
        this.mark.setDepth(3);

        // 래빗 & 타임뱅크
        this.sign_rabbit = this.scene.add.sprite(70 * this.config.avatar.scale * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1), -5, this.scene.ShopAtlasName, "rabbit_hunting.png").setOrigin((this.scene.orientation == "mobile" && this.config.type == 2) ? 0 : 1, 0);
        this.add(this.sign_rabbit);
        this.sign_rabbit.setScale(0.5);
        this.sign_rabbit.setDepth(4);
        this.sign_timebank = this.scene.add.sprite(70 * this.config.avatar.scale * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1), -5, this.scene.ShopAtlasName, "clock.png").setOrigin((this.scene.orientation == "mobile" && this.config.type == 2) ? 0 : 1, 0);
        this.add(this.sign_timebank);
        this.sign_timebank.setScale(0.5);
        this.sign_timebank.setDepth(4);

        // 타이밍
        this.turnTimeLength = 6000; // 턴 시간
        this.turnTimerWidth = 150 * this.config.avatar.scale;
        this.turnTimerContainer = this.scene.add.container(0, 200 * this.config.avatar.scale);
        this.turn_timer_back = this.scene.add.graphics({
            x: -this.turnTimerWidth / 2,
            y: 0,
            fillStyle: {
                color: 0x572203,
                alpha: 1
            }
        });
        this.turn_timer_back.lineStyle(1, 0x692e00, 2);
        this.turn_timer_back.fillRoundedRect(0, 0, this.turnTimerWidth, 6, 3);
        this.turn_timer_back.strokeRoundedRect(0, 0, this.turnTimerWidth, 6, 3);
        this.turnTimerContainer.add(this.turn_timer_back);
        this.add(this.turnTimerContainer);
        this.turn_left_time_bar = this.scene.add.graphics({
            x: -this.turnTimerWidth / 2 + 1,
            y: 1,
            fillStyle: {
                color: 0xf5a603,
                alpha: 1
            }
        });
        this.turn_left_time_bar.fillRoundedRect(0, 0, this.turnTimerWidth - 2, 4, 2);
        this.turnTimerContainer.add(this.turn_left_time_bar);
        this.turnTimerContainer.setVisible(false);

        this.pattern = TextClass.plainText1(this.scene, {
            x: 0,
            y: 220 * this.config.avatar.scale,
            text: "",
            fontSize: 24 * this.config.avatar.scale,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.add(this.pattern);

        this.handCardList = [];

        let winnerContainerX = 0;
        let winnerContainerY = this.scene.orientation == "pc" ? 85 : 0;
        if(this.config.type == 0) winnerContainerY = -80;
        this.winnerContainer = this.scene.add.container(winnerContainerX, winnerContainerY);
        this.add(this.winnerContainer);
        this.winnerContainer.setDepth(4);
        this.winnerPattern = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 160 : 120) * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1),
            y: 60 * this.config.avatar.scale,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 24,
            gradient: ["#ffffff", "#c6c6c6"]
        }).setOrigin(0.5, 0.5);
        this.winnerPattern.setStyle({
            shadow: {
                offsetX: 0,
                offsetY: 2,
                color: '#000000',
                blur: 4,
                stroke: true,
                fill: true
            }
        });
        this.winnerContainer.add(this.winnerPattern);
        this.winMoney = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 160 : 120) * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1),
            y: 60 * this.config.avatar.scale,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 36 : 32,
            gradient: ["#fee160", "#aa7a35"]
        }).setOrigin(0.5, 0.5);
        this.winMoney.setStyle({
            shadow: {
                offsetX: 0,
                offsetY: 4,
                color: '#000000',
                blur: 5,
                stroke: true,
                fill: true
            }
        });
        this.winnerContainer.add(this.winMoney);
        this.winnerLabel = this.scene.add.sprite(-10 * this.config.avatar.scale, 60 * this.config.avatar.scale, this.config.atlas, "winner_label.png").setOrigin(0, 0.5);
        this.winnerContainer.add(this.winnerLabel);
        this.winnerCup = this.scene.add.sprite(0, 60 * this.config.avatar.scale, this.config.atlas, "winner_cup.png").setOrigin(0.5, 0.5);
        this.winnerContainer.add(this.winnerCup);
        this.winnerContainer.setVisible(false);
        this.winnerContainer.setScale(0.7 * this.config.avatar.scale);

        this.sort("depth");
    }

    showMessage(message) {
        let messageContainer = this.scene.add.container(0, -60 * this.config.avatar.scale);
        let messageBack = this.scene.add.sprite(0, 0, this.config.atlas, "player_message_back.png").setOrigin((this.scene.orientation == "mobile" && this.config.type == 2) ? 1 : 0, 0);
        if(this.scene.orientation == "mobile" && this.config.type == 2) {
            messageBack.flipX = true;
        }
        messageContainer.add(messageBack);
        let messageText = TextClass.plainText1(this.scene, {
            x: 20 * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1),
            y: 10,
            text: message,
            fontSize: this.scene.orientation == "pc" ? 22 : 18,
            color: "#000000"
        }).setOrigin((this.scene.orientation == "mobile" && this.config.type == 2) ? 1 : 0, 0);
        messageContainer.add(messageText);
        messageBack.setScale((messageText.width + 40) / messageBack.width, 1);
        this.add(messageContainer);
        setTimeout(() => {
            messageContainer.destroy();
        }, 2000);
    }

    setMark(mark) { // mark should be one of "dealer", "SB", "BB"
        this.mark.setTexture(this.config.atlas, "icon_" + mark + ".png");
        this.mark.setVisible(true);
    }

    clearMark() {
        this.mark.setVisible(false);
    }

    betting(action, amount) {
        this.bettingContainer.setVisible(true);
        this.betting_amount_back.setVisible(amount != 0);
        this.betting_amount.setVisible(amount != 0);
        this.betting_amount.setText(NumberUtil.convertNumberWithComma(amount));
        if(action == "") {
            this.betting_action_icon.setVisible(false);
            this.betting_action_label.setVisible(false);
        } else {
            this.betting_action_icon.setVisible(true);
            this.betting_action_label.setVisible(true);
            this.betting_action_icon.setTexture(this.config.atlas, "icon_" + (action == "bet" ? "raise" : action) + ".png");
            this.betting_action_label.setText(action.toUpperCase());
            if(amount > 0 || (action != "fold" && action != "allin")) {
                this.playBettingVoice(action);
            }
        }
        if(action == "allin") {
            this.betting_action_icon.setScale(0.6 * this.config.avatar.scale)
        } else {
            this.betting_action_icon.setScale(this.config.avatar.scale)
        }

        if(action == "fold") {
            this.handCardList.map(x => x.setOpen(false));
            if(document.hidden) {
                this.clearHandCards();
            } else {
                try {
                    this.scene.tweens.add({
                        targets: this.handCardList,
                        angle: 0,
                        alpha: 0,
                        x: HoldemDef.pot[this.scene.orientation].icon.x - this.config.x,
                        y: HoldemDef.pot[this.scene.orientation].icon.y - this.config.y,
                        duration: 300,
                        ease: "Power2",
                        onComplete: () => {
                            this.clearHandCards();
                        }
                    })
                } catch (e) {
                    console.error("player fold --- holdem")
                }
            }
            this.config.seat == HoldemDef.MySeat && this.scene.hideBettingButton();
        }
    }

    playBettingVoice(action) {
        this.scene.audio.playEffect(action + "_" + (localStorage.getItem("voice") || "male"));
    }

    clearBetting() {
        this.bettingContainer.setVisible(false);
        this.round_chip = 0;
    }

    setTurn(duration, past) {
        this.turnTimeLength = duration;
        CommonDef.turnStartTime = new Date().getTime() - past;
        if(this.config.seat == HoldemDef.MySeat){
            this.scene.audio.playEffect("SetTurn");
        } 
        this.stopTurn();
        this.playTurnTimerAnimation();
        this.isTurn = true;
    }

    stopTurn() {
        this.scene.audio.stopEffect(["CountDown"]);
        this.turn_countdown = false;
        this.stopTurnTimerAnimation();
        this.isTurn = false;
        this.setTimeBank(false);
    }

    playTurnTimerAnimation() {
        this.turnTimerContainer.setVisible(true);
        this.turnTimerInterval = setInterval(() => {
            let currentTime = new Date().getTime();
            let past_time = currentTime - CommonDef.turnStartTime;
            if(past_time >= this.turnTimeLength) {
                clearInterval(this.turnTimerInterval);
                return;
            }
            if(this.turnTimeLength - past_time < 3000 && !this.turn_countdown) {
                this.config.seat == HoldemDef.MySeat && this.scene.audio.playEffect("CountDown", true);
                this.turn_countdown = true;
            }
            this.turn_left_time_bar.clear();
            this.turn_left_time_bar.fillRoundedRect(0, 0, (this.turnTimerWidth - 2) * (this.turnTimeLength - past_time) / this.turnTimeLength, 4, 2);
        }, 40)
    }

    stopTurnTimerAnimation() {
        this.turnTimerInterval && clearInterval(this.turnTimerInterval);
        this.turnTimerContainer.setVisible(false);
    }

    clearHandCards() {
        try {
            for(let i = 0 ; i < this.handCardList.length ; i ++) {
                this.handCardList[i].destroy();
            }
            this.handCardList = [];
        } catch (e) {
            console.error("player clearHandCards error");
        }
    }

    addHandCard(card, animFlag = true) {
        let x = [];
        let y = [];
        let cardScale = 1;
        if(this.scene.orientation == "pc") {
            if(this.config.type == 0) {
                x = [140, 191, 242, 293];
                y = [0, -10, -10, 0]
            } else {
                x = [95, 120, 145, 170];
                y = [20, 13, 13, 20]
                cardScale = 51 / 87
            }
        } else {
            if(this.config.type == 0) {
                x = [110, 160, 210, 260];
                y = [13, 3, 3, 13]
            } else if(this.config.type == 1) {
                x = [-73, -53, -33, -13];
                y = [-23, -30, -30, -23];
                cardScale = 36 / 87
            }
            else {
                x = [13, 33, 53, 73];
                y = [-23, -30, -30, -23];
                cardScale = 36 / 87
            }
        }
        animFlag = animFlag && !document.hidden;
        if(animFlag) {
            try {
                this.scene.tweens.add({
                    targets: card,
                    x: x[this.handCardList.length],
                    y: y[this.handCardList.length],
                    scale: cardScale,
                    alpha: {
                        from: 0,
                        to: 1
                    },
                    duration: 100,
                    ease: "Power2"
                });
            } catch (e) {
                console.error("add hand card error -- holdem");
            }
        } else {
            card.setPosition(x[this.handCardList.length], y[this.handCardList.length]);
            card.setScale(cardScale);
        }
        this.handCardList.push(card);
        this.add(card);
        card.setDepth(3);
        this.sort("depth");
    }

    initialize() {
        this.clearHandCards();
        this.clearMark();
        this.clearBetting();
        this.stopTurn();
        this.hideOdds();
        this.winnerContainer.setVisible(false);
        this.setDepth(1);
        this.setRabbit(false);
        this.scene.backContainer.sort("depth");
    }

    onClickedAvatar() {
        this.scene.openPlayerProfile && this.scene.requestPlayerProfile(this.userid);
    }

    setWinner(pattern, winMoney) {
        if(document.hidden) return;
        this.setDepth(10);
        this.scene.backContainer.sort("depth");
        this.winnerContainer.setVisible(true);
        this.winnerLabel.setVisible(false);
        let direct = (this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1

        this.winnerPattern.setText(pattern);
        this.winMoney.setText(0);
        this.winMoney.x = (this.scene.orientation == "pc" ? 160 : 120) * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1);
        this.winMoney.y = 60 * this.config.avatar.scale;
        this.winnerPattern.x = (this.scene.orientation == "pc" ? 160 : 120) * ((this.scene.orientation == "mobile" && this.config.type == 2) ? -1 : 1);
        this.winnerPattern.y = 60 * this.config.avatar.scale;
        this.winMoney.setVisible(false);
        this.winnerPattern.setVisible(false);

        try {
            this.scene.tweens.addCounter({
                from: 0,
                to: 100,
                duration: 500,
                onUpdate: (tween) => {
                    this.winnerContainer.x = tween.getValue() * this.config.avatar.scale * direct;
                    let scale = 1;
                    if(tween.getValue() <= 50) {
                        scale += tween.getValue() / 50;
                        this.winnerContainer.setAlpha(tween.getValue() / 50);
                    } else {
                        scale += (100 - tween.getValue()) / 50;
                        this.winnerContainer.setAlpha(1);
                    }
                    this.winnerCup.setScale(scale);
                },
                onComplete: () => {
                    this.winnerLabel.setOrigin(direct == 1 ? 0 : 1, 0.5);
                    this.winnerLabel.x = -30 * this.config.avatar.scale * direct;
                    this.winnerLabel.setVisible(true);
                    this.winnerLabel.setAlpha(0.5);
                    this.scene.tweens.add({
                        targets: this.winnerLabel,
                        x: 30 * this.config.avatar.scale * direct - (direct == -1 ? 10 : 0),
                        alpha: 1,
                        duration: 200,
                        ease: "Power2",
                        onComplete: () => {
                            this.winMoney.setVisible(true);
                            this.winnerPattern.setVisible(true);
                            this.scene.tweens.addCounter({
                                from: 0,
                                to: 50,
                                duration: 200,
                                onUpdate: (tween) => {
                                    this.winnerPattern.y = 60 * this.config.avatar.scale + tween.getValue() * 0.8 * this.config.avatar.scale;
                                    this.winMoney.y = 60 * this.config.avatar.scale - tween.getValue() * this.config.avatar.scale;
                                },
                                onComplete: () => {
                                    this.scene.tweens.addCounter({
                                        from: 0,
                                        to: winMoney,
                                        duration: 500,
                                        onUpdate: (tween) => {
                                            this.winMoney.setText(NumberUtil.convertNumberWithComma(tween.getValue()));
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        } catch (e) {
            console.error("winner anim error -- holdem");
        }
    }

    destroy() {
        this.turnTimerInterval && clearInterval(this.turnTimerInterval);
        super.destroy();
    }

    setActive(flag) {
        if(this.active == flag) return;
        this.scene.audio.playEffect(flag ? "EnterRoom" : "LeaveRoom");
        this.initialize();
        this.active = flag;
        if(!flag) this.config.seat = -1;
        this.setVisible(flag);
        this.state = 0;
    }

    setPlayerInfo(info, pushChip = true) {
        this.config.seat = info.seat;
        this.userid = info.loginid;
        this.nickname.setText(info.nickname);
        this.coins.setText(NumberUtil.convertNumberWithComma(info.coins));
        this.avatar.updateAvatar(info.avatar);
        this.avatar.updateBorder(info.border);
        // this.stopTurn();
        this.setHandCards(info.handcards);
        this.setUserState(info.state, info.round_chip, this.balance - info.coins, pushChip);
        if(info.sittingout !== undefined) {
            this.setSittingOut(info.sittingout)
            this.userid == CommonDef.Me.loginid && this.scene.showBtnImBack(info.sittingout);
        }
        this.setPattern(info.pattern);
        this.balance = info.coins;
        this.round_chip = info.round_chip;
    }

    setHandCards(handcards) {
        try {
            for(let i = 0 ; i < 4 ; i ++) {
                if(!handcards[i]) break;
                if(!this.handCardList[i]) {
                    this.scene.dealCard(this.config.seat, "0");
                }
                if(this.handCardList[i].opened) continue;
                this.handCardList[i].setValue(handcards[i]);
                if(handcards[i] != "0") this.handCardList[i].setOpen(true);
                if(document.hidden) {
                    this.handCardList[i].angle = -15 + 10 * i;
                } else {
                    this.scene.tweens.add({
                        targets: this.handCardList[i],
                        angle: -15 + 10 * i,//i == 0 ? -15 : 15,
                        duration: 400,
                        ease: "Power2"
                    })
                }
            }
        } catch (e) {
            console.error("player set hand card error -- holdem", e);
            // CommonDef.gameNet.reportError("holdemplayer => setHandCards", handcards, e);
        }
    }

    setUserState(state, round_chip, betting_chip, pushChip) {
        if(this.round_chip == round_chip && this.state == state) return;
        this.stopTurn();
        this.state = state;
        if(state < 10 || state >= 100) { // 100: 새로 입장한 유저
            this.clearBetting();
        }
        let action = Math.floor(state / 10);
        let position = state % 10;
        if(position == 1) this.setMark("dealer");
        if(position == 2) this.setMark("SB");
        if(position == 3) this.setMark("BB");
        if(action < HoldemDef.userBettingState.CHECK) {
            this.clearBetting();
        } else {
            let betting_action = "";
            if(action > HoldemDef.userBettingState.NONE && action < HoldemDef.userBettingState.DEALER) {
                let bettingAction = ["check", "allin", "call", "fold", "raise", "bet"];
                betting_action = bettingAction[action - 1];
            }
            if(action == HoldemDef.userBettingState.FOLD) {
                round_chip = 0;
            }
            this.betting(betting_action, round_chip);
        }
        pushChip && this.scene.pushChip(this.config.seat, betting_chip);
    }

    setPattern(patternIndex) {
        if(patternIndex < 0) this.pattern.setVisible(false);
        if(patternIndex > 12) return;
        else {
            this.pattern.setText(HoldemDef.patternList[patternIndex]);
            this.pattern.setVisible(true);
        }
    }

    setSittingOut(flag) {
        this.sittingoutBack.setVisible(flag);
        this.sittingoutText.setVisible(flag);
    }

    setRabbit(flag = true) {
        this.sign_rabbit.setVisible(flag);
    }

    setTimeBank(flag = true) {
        this.sign_timebank.setVisible(flag);
    }

    showOdds(win, tie) {
        this.oddsWinValue.setText("Win: " + win + "%");
        this.oddsTieValue.setText("Tie: " + tie + "%");
        this.oddsContainer.setVisible(true);
    }

    hideOdds() {
        this.oddsContainer.setVisible(false);
    }
}