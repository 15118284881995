import { Avatar } from "../../../components/avatar.js/avatar";
import { TextClass } from "../../../components/text/text";
import * as DateTimeUtil from "../../../util/datetime";
import CommonDef from "../../../def/commondef";
import { SpriteButton } from "../../../components/button/spritebutton";

export class MessageHistoryItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGrahp();
    }

    initGrahp() {
        if(!!this.config.data.user_id) {
            this.avatar = new Avatar(this.scene, {
                x: this.config.data.user_id == CommonDef.Me.loginid ? 490 : 70,
                y: 0,
                atlas: "AvatarAtlas",
                avatar: this.config.data.avatar,
                border: this.config.data.border,
                scale: 0.7,
                mask: {
                    x: this.config.data.user_id == CommonDef.Me.loginid ? 490 : 70,
                    y: this.config.y + 70 + 60 * 0.7,
                },
            });
            this.add(this.avatar);
        }

        if(this.config.data.user_id !== CommonDef.Me.loginid) {
            this.nickname = TextClass.plainText1(this.scene, {
                x: this.config.data.user_id == CommonDef.Me.loginid ? 420 : 140,
                y: 10,
                text: this.config.data.user_nickname ? this.config.data.user_nickname : "관리자",
                fontFamily: "NanumSquareR",
                fontSize: 20,
                color: "#f66d01"
            }).setOrigin(this.config.data.user_id == CommonDef.Me.loginid ? 1 : 0, 0);
            this.add(this.nickname);
            this.nickname.setDepth(1);
        }

        this.message = TextClass.plainText1(this.scene, {
            x: this.config.data.user_id == CommonDef.Me.loginid ? 420 : 140,
            y: 40,
            text: this.config.data.message,
            fontFamily: "NanumSquareR",
            fontSize: 20,
            color: !!this.config.data.user_id ? "#000000" : "#ffff00"
        }).setOrigin(this.config.data.user_id == CommonDef.Me.loginid ? 1 : 0, 0);
        this.message.setStyle({
            wordWrap: { width: 300, useAdvancedWrap: true }
        });
        // this.message.setLineSpacing(5);
        this.add(this.message);
        this.message.setDepth(1);

        this.createdat = TextClass.plainText1(this.scene, {
            x: this.config.data.user_id == CommonDef.Me.loginid ? 420 : 140 + Math.max(this.message.width, this.config.min_width),
            y: 10,
            text: DateTimeUtil.convertShotTime(this.config.data.createdat),
            fontFamily: "NanumSquareL",
            fontSize: 16,
            color: !!this.config.data.user_id ? "#333333" : "#cccccc"
        }).setOrigin(1, 0);
        this.add(this.createdat);
        this.createdat.setDepth(1);

        if(!!this.config.data.user_id && this.config.data.user_id != CommonDef.Me.loginid && this.config.data.isreport == false) {
            this.btn_report = new SpriteButton(this.scene, {
                x: this.createdat.x - 80,
                y: 0,
                origin: [0, 0],
                scale: 0.9,
                atlas: this.scene.HoldemAtlasName,
                normal: "report.png"
            });
            this.add(this.btn_report);
            this.btn_report.setDepth(1);
            this.click_area = {
                x: this.createdat.x - 80,
                y: 0,
                width: 40,
                height: 40
            }
        } else {
            this.click_area = {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            }
        }

        this.messageBack = this.scene.add.graphics({
            x: this.config.data.user_id == CommonDef.Me.loginid ? 430 : 130,
            y: 0,
            fillStyle: {
                color: !!this.config.data.user_id ? 0xffffff : 0x000000,//0xcdb8ff,
                alpha: 1
            }
        });
        this.messageBack.fillRoundedRect(this.config.data.user_id == CommonDef.Me.loginid ? -(Math.max(this.message.width + 20, this.config.min_width + 20)) : 0, 0, Math.max(this.message.width + 20, this.config.min_width + 20), this.message.height + 50, 5);
        this.add(this.messageBack);
        this.sort("depth");
        
        this.height = Math.max(this.message.height + 90, 120);

    }

    onMove(deltaX, deltaY, animFlag) {
        this.avatar && this.avatar.move(deltaX, deltaY, animFlag);
    }

    onClick() {
        this.scene.reportUser(this.config.data.user_id, CommonDef.reportType.MessageReport, this.config.data.message, this.config.data.index);
    }
}