import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { translate } from "../../../util/other";

export class ShopCardDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.priorityList = this.initPriorityList();
        
        this.durationIndex = -1;

        if(this.config.availableDays == 30) this.durationIndex = 0;
        if(this.config.availableDays == 90) this.durationIndex = 1;
        if(this.config.availableDays == 365) this.durationIndex = 2;
        if(this.durationIndex < 0) {
        }
        
        this.initGraph();
        this.popupAnimation();
    }

    initPriorityList() {
        return [{
            name: translate('allin_equity'),
            silver: true,
            black: true,
            gold: true
        }, {
            name: translate('rabbit_huntting'),
            silver: true,
            black: true,
            gold: true
        }, {
            name: translate('private_border'),
            silver: true,
            black: true,
            gold: true
        }, {
            name: translate('carrier_report'),
            silver: false,
            black: true,
            gold: true
        }, {
            name: translate('lival_data'),
            silver: false,
            black: true,
            gold: true
        }, {
            name: translate('lival_senior_data'),
            silver: false,
            black: false,
            gold: true
        }, /*{
            name: "추가\n디스커넥트\n프로텍션",
            silver: false,
            black: false,
            gold: true
        }, {
            name: "전용\n이모티콘",
            silver: 3,
            black: 3,
            gold: 3
        }, */{
            name: translate('free_emoticon'),
            silver: [200, 600, 2400],
            black: [800, 2400, 9600],
            gold: [1200, 3600, 14400]
        }, {
            name: translate('free_timebank'),
            silver: [15, 45, 180],
            black: [80, 240, 960],
            gold: [120, 360, 1440]
        }];
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.fillRect(0, -10, this.scene.orientation == "pc" ? 1000 : 600, this.scene.orientation == "pc" ? 540 : 1140);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 530 : 1110);
        this.add(this.backGraphic);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.border = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.border.lineStyle(1, 0x222222, 1);
        this.border.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 1000 : 590, this.scene.orientation == "pc" ? 520 : 1130, 10);
        this.add(this.border);
        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 23 : 24,
            text: `${translate('vip_privilege')} (${this.config.availableDays}${translate('date_unit')})`,
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 940 : 525,
            y: this.scene.orientation == "pc" ? 21 : 22,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.tableContainer = this.scene.add.container(this.scene.orientation == "pc" ? 30 : 66, this.scene.orientation == "pc" ? 93 : 105);
        this.add(this.tableContainer);
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.btn_buy = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 380 : 135,
            y: this.scene.orientation == "pc" ? 332 : 918,
            atlas: this.config.atlas,
            normal: {
                back: "btn_buy_back_normal.png"
            },
            hover: {
                back: "btn_buy_back_hover.png"
            },
            clicked: {
                back: "btn_buy_back_clicked.png"
            },
            scale: [1.38, 1.5],
            text: {
                x: 70 * 1.38,
                y: 10 * 1.5,
                value: NumberUtil.convertNumberWithComma(this.config.price) + "php",
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: () => {
                this.config.onClickBuy(this.config.id);
            }
        });
        this.tableContainer.add(this.btn_buy)
    }

    initGraphForPC() {
        let width = [124, 102];
        let height = [80, 70];
        for(let i = 0 ; i < 5 ; i ++) {
            let line = this.scene.add.graphics({
                x: 0,
                y: i < 2 ? i * height[0] : height[0] + (i - 1) * height[1]
            });
            line.fillStyle(i < 2 ? 0x999999 : 0x444444, 1);
            line.fillRect(0, 0, 940, 1);
            this.tableContainer.add(line);

            let card_img_name = "";
            if(i == 1) card_img_name = "vip_card_silver.png";
            if(i == 2) card_img_name = "vip_card_black.png";
            if(i == 3) card_img_name = "vip_card_gold.png";
            if(card_img_name) {
                let card = this.scene.add.sprite(width[0] / 2, height[0] + (i - 1 + 0.5) * height[1] + 3, this.config.atlas, card_img_name);
                card.setOrigin(0.5, 0.5);
                this.tableContainer.add(card);
            }
        }

        let card_type = ["silver", "black", "gold"];
        for(let i = 0 ; i < this.priorityList.length ; i ++) {
            let line = this.scene.add.graphics({
                x: width[0] + width[1] * i,
                y: 0
            });
            line.fillStyle(0x444444, 1);
            line.fillRect(0, 0, 1, 290);
            this.tableContainer.add(line);

            let name = TextClass.plainText1(this.scene, {
                x: width[0] + width[1] * (i + 0.5),
                y: height[0] / 2,
                text: this.priorityList[i].name,
                fontSize: 18,
                color: "#aaaaaa"
            }).setOrigin(0.5, 0.5);
            this.tableContainer.add(name);
            if(i < 6) {
                for(let ci = 0 ; ci < 3 ; ci ++) {
                    if(this.priorityList[i][card_type[ci]]) {
                        let value = this.scene.add.sprite(width[0] + width[1] * (i + 0.5), height[0] + height[1] * (ci + 0.5), this.config.atlas, "check.png");
                        value.setOrigin(0.5, 0.5);
                        this.tableContainer.add(value);
                    }
                }
            } else {
                for(let ci = 0 ; ci < 3 ; ci ++) {
                    let value = TextClass.plainText1(this.scene, {
                        x: width[0] + width[1] * (i + 0.5), 
                        y: height[0] + height[1] * (ci + 0.5), 
                        text: this.priorityList[i][card_type[ci]][this.durationIndex],
                        fontSize: 20,
                        color: "#d7bba3"
                    });
                    value.setOrigin(0.5, 0.5);
                    this.tableContainer.add(value);
                }
            }
        }

        let active_index = this.config.card_type - 1;
        if(active_index > -1) {
            this.active_card_border = this.scene.add.sprite(470, height[0] + active_index * height[1], this.config.atlas, "vip_card_active_border_pc.png");
            this.active_card_border.setOrigin(0.5, 0);
            this.tableContainer.add(this.active_card_border);
        }
    }

    initGraphForMobile() {
        let width = 113;
        let height = [97, 97];
        for(let i = 1 ; i < 4 ; i ++) {
            let line = this.scene.add.graphics({
                x: width * i,
                y: 0
            });
            line.fillStyle(i < 2 ? 0x999999 : 0x444444, 1);
            line.fillRect(0, 0, 1, 873);
            this.tableContainer.add(line);

            let card_img_name = "";
            if(i == 1) card_img_name = "vip_card_silver.png";
            if(i == 2) card_img_name = "vip_card_black.png";
            if(i == 3) card_img_name = "vip_card_gold.png";
            if(card_img_name) {
                let card = this.scene.add.sprite(width * (i + 0.5), height[0] / 2 + 3, this.config.atlas, card_img_name);
                card.setOrigin(0.5, 0.5);
                this.tableContainer.add(card);
            }
        }

        let card_type = ["silver", "black", "gold"];
        for(let i = -1 ; i < this.priorityList.length + 1 ; i ++) {
            let line = this.scene.add.graphics({
                x: 0,
                y: i < 0 ? 0 : height[0] + height[1] * i
            });
            line.fillStyle(0x444444, 1);
            line.fillRect(0, 0, 455, 1);
            this.tableContainer.add(line);

            if(i < 0 || i == this.priorityList.length) continue;

            let name = TextClass.plainText1(this.scene, {
                x: width / 2,
                y: height[0] + height[1] * (i + 0.5),
                text: this.priorityList[i].name,
                fontSize: 18,
                color: "#aaaaaa"
            }).setOrigin(0.5, 0.5);
            this.tableContainer.add(name);
            if(i < 6) {
                for(let ci = 0 ; ci < 3 ; ci ++) {
                    if(this.priorityList[i][card_type[ci]]) {
                        let value = this.scene.add.sprite(width * (ci + 1.5), height[0] + height[1] * (i + 0.5), this.config.atlas, "check.png");
                        value.setOrigin(0.5, 0.5);
                        this.tableContainer.add(value);
                    }
                }
            } else {
                for(let ci = 0 ; ci < 3 ; ci ++) {
                    let value = TextClass.plainText1(this.scene, {
                        x: width * (ci + 1.5), 
                        y: height[0] + height[1] * (i + 0.5), 
                        text: this.priorityList[i][card_type[ci]],
                        fontSize: 20,
                        color: "#d7bba3"
                    });
                    value.setOrigin(0.5, 0.5);
                    this.tableContainer.add(value);
                }
            }
        }

        let active_index = this.config.card_type - 1;
        if(active_index > -1) {
            this.active_card_border = this.scene.add.sprite(width * (active_index + 1.5), 0, this.config.atlas, "vip_card_active_border_mobile.png");
            this.active_card_border.setOrigin(0.5, 0);
            this.tableContainer.add(this.active_card_border);
        }
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        this.scene.tweens.add({
            targets: this.tableContainer,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.tableContainer.x + (this.scene.orientation == "pc" ? 200 : 290),
                to: this.tableContainer.x
            },
            y: {
                from: 60,
                to: this.tableContainer.y
            },
            duration: 150,
            ease: "Back"
        })
    }
}