import CommonDef from '../../def/commondef';
import { SpriteButton } from '../button/spritebutton';
import { TextClass } from '../text/text';

export class Toast extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, CommonDef.toast[scene.orientation].x, CommonDef.toast[scene.orientation].y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        if(this.config.type != "success" && this.config.type != "warning" && this.config.type != "failed") return;
        this.back = this.scene.add.sprite(0, 0, "DialogAtlas", "toast_back_" + this.scene.tema + ".png");
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.icon = this.scene.add.sprite(CommonDef.toast.icon.x, CommonDef.toast.icon.y, 'DialogAtlas', 'toast_' + this.config.type + "_icon_" + this.scene.tema + ".png");
        this.icon.setOrigin(0, 0);
        this.add(this.icon);

        this.message = TextClass.plainText1(this.scene, {
            x: CommonDef.toast.text.x,
            y: CommonDef.toast.text.y,
            text: this.config.text,
            fontSize: 24,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.add(this.message);
        this.message.setStyle({
            wordWrap: { width: 430, useAdvancedWrap: true }
        });
        this.message.setLineSpacing(10);
        this.message.y = (this.back.height - 25 - this.message.height) / 2;

        this.btn_close = new SpriteButton(this.scene, {
            x: CommonDef.toast.close.x,
            y: CommonDef.toast.close.y,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + '.png',
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);
    }

    close() {
        this.destroy();
    }
}