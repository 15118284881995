import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import { CouponHistoryItem } from "./coupon_history_item";

export class CouponDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        this.couponContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x - 505 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 170);
        this.add(this.couponContainer);
        let couponText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: "쿠폰 코드",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.couponContainer.add(couponText);

        this.coupon_field = new InputField(this.scene, {
            x: 0,
            y: 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + ".png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 455 : 40,
                    y: this.scene.orientation == "pc" ? 361 : 202
                },
                width: 600,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: "쿠폰 코드를 입력해주세요. (필수)",
                color: "#777777",
                fontSize: 24
            }
        });
        this.couponContainer.add(this.coupon_field);

        this.warning_icon = this.scene.add.sprite(this.scene.orientation == "pc" ? 320 : 334, 284, this.config.atlas, 'warning.png').setOrigin(0.5, 1);
        this.couponContainer.add(this.warning_icon);
        let text_start_posX = this.scene.orientation == "pc" ? 94 : 84;
        let warning_text1 = TextClass.plainText1(this.scene, {
            x: text_start_posX,
            y: 318,
            text: "쿠폰 제도 악용시 ",
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.couponContainer.add(warning_text1);
        let warning_text2 = TextClass.plainText1(this.scene, {
            x: text_start_posX + warning_text1.width,
            y: 318,
            text: "탈퇴",
            fontSize: 20,
            color: "#ffd222"
        }).setOrigin(0, 0);
        this.couponContainer.add(warning_text2);
        let warning_text3 = TextClass.plainText1(this.scene, {
            x: text_start_posX + warning_text1.width + warning_text2.width,
            y: 318,
            text: "처리 및 ",
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.couponContainer.add(warning_text3);
        let warning_text4 = TextClass.plainText1(this.scene, {
            x: text_start_posX + warning_text1.width + warning_text2.width + warning_text3.width,
            y: 318,
            text: "이용불가",
            fontSize: 20,
            color: "#ffd222"
        }).setOrigin(0, 0);
        this.couponContainer.add(warning_text4);
        let warning_text5 = TextClass.plainText1(this.scene, {
            x: text_start_posX + warning_text1.width + warning_text2.width + warning_text3.width + warning_text4.width,
            y: 318,
            text: "처리 되실 수 있습니다.",
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.couponContainer.add(warning_text5);

        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x + 175 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 730)
        this.add(this.historyContainer);

        let historyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: "사용내역",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(historyText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 2,
            text: "새로고침",
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 288 : 630,
            y: 0,
            atlas: this.config.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            console.log("request refresh");
        });

        this.historyContent = this.scene.add.container(0, 0);
        this.scrollViewContainer = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 323 : 667,
            height: this.scene.orientation == "pc" ? 382 : 434,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 1125 : 40,
                y: this.scene.orientation == "pc" ? 362 : 762
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 300 : 640,
                height: this.scene.orientation == "pc" ? 382 : 434,
            }
        });
        this.historyContainer.add(this.scrollViewContainer);

        this.btn_confirm = new IconButton(this.scene, {
            x: this.config.x - 93,
            y: this.config.y + (this.scene.orientation == "pc" ? 585 : 567),
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: "사용하기",
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff']
            }
        });
        this.add(this.btn_confirm);

        this.setHistoryContent([{
            date_time: "2021.12.21 10:38:34",
            amount: 50000
        }, {
            date_time: "2021.12.20 12:08:04",
            amount: 50000
        }, {
            date_time: "2021.12.19 15:55:32",
            amount: 50000
        }, {
            date_time: "2021.12.21 10:38:34",
            amount: 50000
        }, {
            date_time: "2021.12.21 10:38:34",
            amount: 50000
        }, {
            date_time: "2021.12.21 10:38:34",
            amount: 50000
        }])
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    setHistoryContent(content) {
        this.historyContent.removeAll(true);
        let height = 90;
        for(let i = 0 ; i < content.length ; i ++) {
            let item = new CouponHistoryItem(this.scene, {
                x: 0,
                y: i * height,
                atlas: this.config.atlas,
                ...content[i]
            });
            this.historyContent.add(item);
        }
        this.historyContent.height = content.length * height;
        this.scrollViewContainer.updateScroll();
    }

    popupAnimation() {
        this.couponContainer.setVisible(false);
        this.historyContainer.setVisible(false);
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.couponContainer.setVisible(true);
                this.historyContainer.setVisible(true);
            }
        })
    }
}