import { IconButton } from "../../../components/button/iconbutton";
import { TextClass } from "../../../components/text/text";

export class ServerMaintainDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: -this.config.x,
            y: -this.config.y,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "login_form_back.png").setOrigin(0.5, 0);
        this.add(this.back);
        this.title = TextClass.plainText1(this.scene, {
            x: 0,
            y: 60,
            text: "점검중",
            fontSize: 48,
            fontFamily: isMobile < 2 ? "GMarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0.5, 0);
        this.add(this.title);
        this.description = TextClass.plainText1(this.scene, {
            x: 0,
            y: 160,
            text: "서버가 점검중입니다.\n후에 다시 시도해주세요.",
            fontSize: 30,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.description.setLineSpacing(15);
        this.add(this.description);

        this.btn_confirm = new IconButton(this.scene, {
            x: -116,
            y: 280,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_login_back_normal.png'
            },
            hover: {
                back: 'btn_login_back_hover.png'
            },
            clicked: {
                back: 'btn_login_back_clicked.png'
            },
            text: {
                x: 80,
                y: 16,
                value: '확 인',
                fontSize: 32,
                gradient: ['#ffe2d1', '#ffffff']
            },
            onClicked: this.onClickBtnConfirm.bind(this)
        })
        this.add(this.btn_confirm);
    }

    onClickBtnConfirm() {
        this.close();
    }

    close() {
        this.setVisible(false);
    }
}