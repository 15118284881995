export default {
    card: {
        y: 110,
        title: {
            y: 45
        }
    },
    content: {
        pc: {
            x: [-540, 20, -270],
            y: [156, 170, 278, 292, 400, 414, 522, 536, 644, 658],
            input_button: {
                x: 395,
                y: 8
            }
        },
        mobile: {
            x: [40, 385],
            y: [188, 202, 320, 334, 452, 464, 582, 596, 714, 728, 846, 860, 978, 992, 1110, 1124],
            input_button: {
                x: 542,
                y: 13
            }
        }
    },
    button: {
        pc: {
            x: [710, 970],
            y: 930
        },
        mobile: {
            x: [155, 385],
            y: 1230
        }
    }
}