import CommonDef from "../../def/commondef";
export class LoadingPage extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.loadingLogoSize = 200;
        this.turnTime = 500;
        this.initGraph();
    }

    initGraph() {
        this.background = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.8
            }
        });
        this.background.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.background);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.background.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.logo = this.scene.add.sprite(this.scene.ScreenWidth / 2, this.scene.ScreenHeight / 2, "LoadingAtlas", "Pre-comp 1_00000.png").setOrigin(0.5, 0.5);
        this.add(this.logo);
        this.logo.setScale(this.loadingLogoSize  / this.logo.width);
        let key = "loading";
        let animConfig = CommonDef.animations[key];
        this.scene.anims.create({
            key: key,
            frames: this.scene.anims.generateFrameNames(animConfig.atlas, {
                start: 0,
                end: animConfig.last,
                zeroPad: animConfig.zeroPad,
                prefix: animConfig.prefix,
                suffix: '.png',
            }),
            frameRate: 1 / animConfig.speed,
            repeat: animConfig.repeat
        })
        this.logo.play(key);
        this.stopLoading();
    }

    playLoading() {
        this.setVisible(true);
    }

    stopLoading() {
        this.setVisible(false);
    }
}