export const convertNumberWithComma = (value) => {
    let str = "";
    let sign = value < 0 ? "-" : "";
    value = Math.round(Math.abs(value));
    while(value > 999) {
        let rest = value % 1000;
        str = "," + convert3Digits(rest) + str;
        value = Math.floor(value / 1000)
    }
    str = value + str;
    return sign + str;
}

const convert3Digits = (value) => {
    let str = "000" + value.toString();
    return str.substring(str.length - 3);
}

export const convertNumbersWithUnit = (value, lang = 'en') => {
    if(value < 1000) return value.toString();
    let sign = 1;
    if(value < 0) sign = -1
    let unit = {
        ko: ['만', '억', '조'],
        en: ['K', 'M', 'G']
    }

    let split = 1000;
    if(lang == 'ko') split = 10000;
    let str = "";
    let count = -1;
    while(value > 0) {
        let rest = value % split;
        value = Math.floor(value / split);
        if(rest > 0) {
            let buf = rest;
            count > -1 && (buf += unit[lang][count])
            str = buf + str + (str ? " " : "");
        }
        count ++;
    }
    return (sign < 0 ? "-" : "") + str;
}

export const convertNumberAsBlind = (value) => {
    if(value < 1000) return value;
    value /= 1000;
    if(value >= 10) value = Math.round(value * 10) / 10;
    else value = Math.round(value * 100) / 100;
    return value + "K";
}