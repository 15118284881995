import { SpriteButton } from "../../../components/button/spritebutton";
import { ScrollView } from "../../../components/container/scrollview";
import { HoldemCard } from "../../../components/holdem/card";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class GameHistoryDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "message_box_back.png").setOrigin(0, 0);
        this.add(this.back);
        
        this.title = TextClass.plainText1(this.scene, {
            x: 60,
            y: 25,
            text: translate('game_history'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 30,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0);
        this.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: 400,
            y: 25,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.col_X = [100, 250, 350];
        let headers = [translate('hand_card'), translate('betting_amount'), translate('result')];
        for(let i = 0 ; i < 3 ; i ++) {
            let headerLabel = TextClass.plainText1(this.scene, {
                x: this.col_X[i],
                y: 70,
                text: headers[i],
                fontFamily: "GmarketSansMedium",
                fontSize: 18,
                color: "#777777"
            }).setOrigin(0.5, 0);
            this.add(headerLabel);
        }

        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: 110,
            width: 430,
            height: 220,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 98 : 38,
                y: this.scene.orientation == "pc" ? 758 : 1080
            },
            action_rect: {
                width: 410,
                height: 220
            }
        });
        this.add(this.scrollView);
    }

    close() {
        this.setVisible(false);
    }

    setContent(content) {
        this.listContainer.removeAll(true);
        let item_height = 80;
        
        for(let i = 0 ; i < content.length ; i ++) {
            let item = this.scene.add.container(0, item_height * i);
            for(let hi = 0 ; hi < content[i].handcards.length ; hi ++) {
                let handcard = new HoldemCard(this.scene, {
                    x: this.col_X[0] - 20 + 40 * hi,
                    y: 0,
                    atlas: "HoldemDefaultCardAtlas_" + this.scene.tema,
                    value: content[i].handcards[hi]
                });
                handcard.setScale(0.4)
                handcard.setOpen(true, false);
                item.add(handcard);
            }
            let betLabel = TextClass.plainText1(this.scene, {
                x: this.col_X[1],
                y: 15,
                text: content[i].bet,
                fontSize: 22,
                color: "#ffffff"
            }).setOrigin(0.5, 0);
            item.add(betLabel);
            let winLabel = TextClass.plainText1(this.scene, {
                x: this.col_X[2],
                y: 15,
                text: content[i].win,
                fontSize: 22,
                color: "#ffffff"
            }).setOrigin(0.5, 0);
            item.add(winLabel);
            this.listContainer.add(item);
            item.height = 70;
        }
        this.listContainer.height = item_height * content.length;
        this.scrollView.updateScroll();
    }
}