import { ScrollView } from "../../../../components/container/scrollview";
import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import { translate } from "../../../../util/other";

export class TabTable extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.topBorder = this.scene.add.graphics({
            x: 0,
            y: 0,
        });
        this.topBorder.lineStyle(1, 0x222222, 1);
        this.topBorder.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 530 : 590, this.scene.orientation == "pc" ? 50 : 56, this.scene.orientation == "pc" ? 25 : 28);
        this.add(this.topBorder);

        this.leftTableCountText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 42 : 46,
            y: this.scene.orientation == "pc" ? 25 : 28,
            text: `${translate('left_tables')} : 0`,
            fontSize: 16,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0.5);
        this.add(this.leftTableCountText);

        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 70 : 80,
            width: this.scene.orientation == "pc" ? 525 : 585,
            height: this.scene.orientation == "pc" ? 484 : 540,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 690 : 80,
                y: this.scene.orientation == "pc" ? 377 : 345
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 500 : 560,
                height: this.scene.orientation == "pc" ? 484 : 540,
            }
        });
        this.add(this.scrollView);

        // let testTableData = [];
        // for(let i = 0 ; i < 27 ; i++) {
        //     testTableData.push({
        //         id: i,
        //         index: (i + 1),
        //         players: 8,
        //         min: 19872,
        //         max: 439873
        //     });
        // }
        // this.setData(testTableData);
    }

    setData(tables) {
        this.listContainer.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 85 : 95
        for(let i = 0 ; i < tables.length ; i ++) {
            let item = this.scene.add.container(0, item_height * i);
            let back = this.scene.add.sprite(-8, 0, this.config.atlas, "tournament_table_back.png").setOrigin(0, 0);
            item.add(back);

            let indexBack = this.scene.add.sprite(this.scene.orientation == "pc" ? 20 : 22, this.scene.orientation == "pc" ? 16 : 18, this.config.atlas, "tournament_table_index_back.png").setOrigin(0, 0);
            item.add(indexBack);

            let indexText = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 56 : 62,
                y: this.scene.orientation == "pc" ? 24 : 28,
                text: "No." + tables[i].index,
                fontSize: this.scene.orientation == "pc" ? 20 : 22,
                gradient: ['#ffe0cf', "#ffffff"]
            }).setOrigin(0.5, 0);
            item.add(indexText);

            let icon_user = this.scene.add.sprite(this.scene.orientation == "pc" ? 123 : 137, this.scene.orientation == "pc" ? 36 : 38, this.config.atlas, "icon_tournament_table_user.png").setOrigin(0.5, 0.5);
            item.add(icon_user);

            let player_count = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 145 : 160,
                y: this.scene.orientation == "pc" ? 36 : 39,
                text: tables[i].players,
                fontSize: this.scene.orientation == "pc" ? 24 : 26,
                gradient: ['#d7cfcf', '#ffffff']
            }).setOrigin(0, 0.5);
            item.add(player_count);

            let icon_chip = this.scene.add.sprite(this.scene.orientation == "pc" ? 180 : 200, this.scene.orientation == "pc" ? 36 : 38, this.config.atlas, "icon_tournament_table_chip.png").setOrigin(0, 0.5);
            item.add(icon_chip);

            let chipState = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 220 : 240,
                y: this.scene.orientation == "pc" ? 36 : 39,
                text: NumberUtil.convertNumberWithComma(tables[i].min) + "/" + NumberUtil.convertNumberWithComma(tables[i].max),
                fontSize: this.scene.orientation == "pc" ? 24 : 26,
                gradient: ['#d7cfcf', '#ffffff']
            }).setOrigin(0, 0.5);
            item.add(chipState);

            let icon_enter = this.scene.add.sprite(this.scene.orientation == "pc" ? 450 : 500, this.scene.orientation == "pc" ? 38 : 42, this.config.atlas, "btn_join_icon.png").setOrigin(0, 0.5);
            icon_enter.setScale(1.5)
            item.add(icon_enter);

            item.height = item_height;
            item.onClick = () => {
                this.onClickedTable(tables[i].index);
            }

            this.listContainer.add(item);
        }
        this.listContainer.height = item_height * tables.length;
        this.scrollView.updateScroll();
        this.leftTableCountText.setText(`${translate('left_tables')} : ${tables.length}`);
    }

    onClickedTable(table_index) {
        this.scene.requestObservingTournament(table_index);
    }
}