export class ScrollView extends Phaser.GameObjects.Container {
    constructor(scene, config){
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;

        this.scroll_type = this.config.type || "vertical";
        this.scroll_range = [24, this.scroll_type == "horizontal" ? this.config.width - 25 : this.config.height - 25];

        this.scroll_type = this.config.type || "vertical";
        this.initGraph();
    }

    initGraph() {
        this.removeAll(true);
        if(this.config.back) {
            
        }
        
        this.content = this.config.content;
        this.add(this.content);
        this.scroll_panel = this.scene.add.graphics({
            x: this.scroll_type == "horizontal" ? 0 : this.config.width - 3,
            y: this.scroll_type == "horizontal" ? this.config.height - 4 : 0,
            fillStyle: {
                color: 0x2a1100,
                alpha: 1
            }
        });
        this.scroll_panel.fillRect(0, 0, this.scroll_type == "horizontal" ? this.config.width : 8, this.scroll_type == "horizontal" ? 8 : this.config.height);
        this.add(this.scroll_panel);

        this.scroll_thumb = this.scene.add.sprite(this.scroll_type == "horizontal" ? this.scroll_range[0] : this.config.width + 1, this.scroll_type == "horizontal" ? this.config.height : this.scroll_range[0], this.config.atlas, this.config.thumb);
        this.scroll_thumb.setOrigin(0.5, 0.5);
        this.add(this.scroll_thumb);
        this.scroll_type == "horizontal" && (this.scroll_thumb.angle = 90);

        this.maskGraphic = this.scene.make.graphics({
            x: this.config.mask.x,
            y: this.config.mask.y,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.5
            }
        });
        this.maskGraphic.fillRect(0, 0, this.config.width, this.config.height);
        let mask = new Phaser.Display.Masks.GeometryMask(this.scene, this.maskGraphic);
        this.content.mask = mask;

        this.scroll_thumb_hit_area = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.5
            }
        });
        let scroll_thumb_action_rect = new Phaser.Geom.Rectangle(this.scroll_type == "horizontal" ? 0 : this.config.width - 20, this.scroll_type == "horizontal" ? this.config.height - 20 : 0, this.scroll_type == "horizontal" ? this.config.width : 40, this.scroll_type == "horizontal" ? 40 : this.config.height);
        this.add(this.scroll_thumb_hit_area);
        this.scroll_thumb_hit_area.setInteractive(scroll_thumb_action_rect, Phaser.Geom.Rectangle.Contains);


        this.scroll_thumb_clicked_Y = 0;
        this.scroll_thumb_clicked_X = 0;

        this.scroll_thumb.setInteractive();
        this.scroll_thumb_hit_area.on("pointerdown", (e) => {
            this.scroll_thumb_clicked_Y = e.y;
            this.scroll_thumb_clicked_X = e.x;
            if(this.scroll_type == "horizontal") {
                let preveX = this.scroll_thumb.x;
                this.scroll_thumb.x = this.scroll_thumb_clicked_X - this.config.mask.x;
                if(this.scroll_thumb.x > this.scroll_range [1]) this.scroll_thumb.x = this.scroll_range [1];
                if(this.scroll_thumb.x < this.scroll_range [0]) this.scroll_thumb.x = this.scroll_range [0];
                let deltaX = this.scroll_thumb.x - preveX;
                this.content.x = -(this.content.width - this.config.width) * (this.scroll_thumb.x - this.scroll_range[0]) / (this.scroll_range[1] - this.scroll_range[0]);
            }
            if(this.scroll_type == "vertical") {
                let prevY = this.scroll_thumb.y
                this.scroll_thumb.y = this.scroll_thumb_clicked_Y - this.config.mask.y;
                if(this.scroll_thumb.y > this.scroll_range [1]) this.scroll_thumb.y = this.scroll_range [1];
                if(this.scroll_thumb.y < this.scroll_range [0]) this.scroll_thumb.y = this.scroll_range [0];
                let deltaY = this.scroll_thumb.y - prevY
                this.content.y = -(this.content.height - this.config.height) * (this.scroll_thumb.y - this.scroll_range[0]) / (this.scroll_range[1] - this.scroll_range[0]);
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    this.config.content.list[i].onMove && this.config.content.list[i].onMove(0, -(this.content.height - this.config.height) * deltaY / (this.scroll_range[1] - this.scroll_range[0]));
                }
            }
        });
        this.scroll_thumb_hit_area.on("pointermove", (e) => {
            if(this.scroll_type == "vertical" && this.scroll_thumb_clicked_Y > 0) {
                let delta = e.y - this.scroll_thumb_clicked_Y;
                let prevY = this.scroll_thumb.y;
                this.scroll_thumb.y += delta;
                if(this.scroll_thumb.y > this.scroll_range [1]) this.scroll_thumb.y = this.scroll_range [1];
                if(this.scroll_thumb.y < this.scroll_range [0]) this.scroll_thumb.y = this.scroll_range [0];
                this.scroll_thumb_clicked_Y = e.y;
                this.content.y = -(this.content.height - this.config.height) * (this.scroll_thumb.y - this.scroll_range[0]) / (this.scroll_range[1] - this.scroll_range[0]);
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    this.config.content.list[i].onMove && this.config.content.list[i].onMove(0, -(this.content.height - this.config.height) * (this.scroll_thumb.y - prevY) / (this.scroll_range[1] - this.scroll_range[0]));
                }
            }
            if(this.scroll_type == "horizontal" && this.scroll_thumb_clicked_X > 0) {
                let delta = e.x - this.scroll_thumb_clicked_X;
                this.scroll_thumb.x += delta;
                if(this.scroll_thumb.x > this.scroll_range [1]) this.scroll_thumb.x = this.scroll_range [1];
                if(this.scroll_thumb.x < this.scroll_range [0]) this.scroll_thumb.x = this.scroll_range [0];
                this.scroll_thumb_clicked_X = e.x;
                this.content.x = -(this.content.width - this.config.width) * (this.scroll_thumb.x - this.scroll_range[0]) / (this.scroll_range[1] - this.scroll_range[0]);
            }
        });
        this.scroll_thumb_hit_area.on("pointerup", (e) => {
            this.scroll_thumb_clicked_Y = 0;
            this.scroll_thumb_clicked_X = 0;
        });
        // this.scroll_thumb_hit_area.on("pointerout", () => {
        //     console.log("out");
        //     this.scroll_thumb_clicked_Y = 0;
        // })

        this.scroll_thumb_hit_area.on("pointerout", () => {
            this.scroll_thumb_clicked_Y = 0;
            this.scroll_thumb_clicked_X = 0;
        })
        

        this.scroll_content_hit_area = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.5
            }
        });
        let scroll_content_action_rect = new Phaser.Geom.Rectangle(0, 0, this.config.action_rect.width, this.config.action_rect.height);
        this.add(this.scroll_content_hit_area);
        this.scroll_content_hit_area.setInteractive(scroll_content_action_rect, Phaser.Geom.Rectangle.Contains);

        this.scroll_content_clicked_Y = 0;
        this.scroll_content_first_clicked_Y = 0;
        this.scroll_content_hit_area.on("pointerdown", (e) => {
            this.scroll_content_clicked_Y = e.y;
            this.scroll_content_clicked_X = e.x;
            this.scroll_type == "horizontal" && (this.scroll_content_first_clicked_X = e.x);
            this.scroll_type == "vertical" && (this.scroll_content_first_clicked_Y = e.y);
            let posX = e.x;
            let posY = e.y;
            posX -= this.config.mask.x;
            posX -= this.content.x;
            posY -= this.config.mask.y;
            posY -= this.content.y;
            if(this.config.content.list) {
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    if(this.config.content.list[i].click_area) {
                        if(posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                        posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width &&
                        posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                        posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height) {
                            this.config.content.list[i].clickedEffect && this.config.content.list[i].clickedEffect();
                        }
                    }
                }
            }
        });
        this.scroll_content_hit_area.on("pointermove", (e) => {
            if(this.scroll_type == "vertical" && this.scroll_content_clicked_Y > 0) {
                let deltaY = e.y - this.scroll_content_clicked_Y
                this.content.y += deltaY;
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    this.config.content.list[i].onMove && this.config.content.list[i].onMove(0, deltaY);
                }
                this.scroll_content_clicked_Y = e.y;
                this.scroll_thumb.y = this.scroll_range[0] + (this.scroll_range[1] - this.scroll_range[0]) * (-this.content.y / (this.content.height - this.config.height));
                if(this.scroll_thumb.y > this.scroll_range [1]) this.scroll_thumb.y = this.scroll_range [1];
                if(this.scroll_thumb.y < this.scroll_range [0]) this.scroll_thumb.y = this.scroll_range [0];
            }
            if(this.scroll_type == "horizontal" && this.scroll_content_clicked_X > 0) {
                let deltaX = e.x - this.scroll_content_clicked_X
                this.content.x += deltaX;
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    this.config.content.list[i].onMove && this.config.content.list[i].onMove(deltaX, 0);
                }
                this.scroll_content_clicked_X = e.x;
                this.scroll_thumb.x = this.scroll_range[0] + (this.scroll_range[1] - this.scroll_range[0]) * (-this.content.x / (this.content.width - this.config.width));
                if(this.scroll_thumb.x > this.scroll_range [1]) this.scroll_thumb.x = this.scroll_range [1];
                if(this.scroll_thumb.x < this.scroll_range [0]) this.scroll_thumb.x = this.scroll_range [0];
            }

            let posX = e.x;
            let posY = e.y;
            posX -= this.config.mask.x;
            posX -= this.content.x;
            posY -= this.config.mask.y;
            posY -= this.content.y;
            if(this.config.content.list) {
                for(let i = 0 ; i < this.config.content.list.length ; i++) {
                    if(this.config.content.list[i].click_area) {
                        if(posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                        posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width &&
                        posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                        posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height) {
                            this.config.content.list[i].onHover && this.config.content.list[i].onHover();
                        } else {
                            this.config.content.list[i].onRelease && this.config.content.list[i].onRelease();
                        }
                    }
                }
            }
        });
        this.scroll_content_hit_area.on("pointerup", (e) => {
            if(this.scroll_type == "vertical" && Math.abs(this.scroll_content_clicked_Y - this.scroll_content_first_clicked_Y) < 5) {
                let posX = e.x;
                let posY = e.y;
                posX -= this.config.mask.x;
                posX -= this.content.x;
                posY -= this.config.mask.y;
                posY -= this.content.y;
                if(this.config.content.list) {
                    for(let i = 0 ; i < this.config.content.list.length ; i ++) {
                        this.config.content.list[i].onRelease && this.config.content.list[i].onRelease();
                        if(posY >= this.config.content.list[i].y && posY < this.config.content.list[i].y + this.config.content.list[i].height) {
                            if(this.config.content.list[i].width) {
                                if(posX > this.config.content.list[i].x && posX < this.config.content.list[i].x + this.config.content.list[i].width) {
                                    if(this.config.content.list[i].click_area) {
                                        if(posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                                        posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width &&
                                        posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                                        posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height) {
                                            this.config.content.list[i].onClick && this.config.content.list[i].onClick(posX, posY - this.config.content.list[i].y);
                                            break;
                                        }
                                    } else {
                                        this.config.content.list[i].onClick && this.config.content.list[i].onClick(posX, posY - this.config.content.list[i].y);
                                        break;
                                    }
                                }
                            } else {
                                if(this.config.content.list[i].click_area) { 
                                    if(posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                                    posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width &&
                                    posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                                    posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height) {
                                        this.config.content.list[i].onClick && this.config.content.list[i].onClick(posX, posY - this.config.content.list[i].y);
                                        break;
                                    }
                                } else {
                                    this.config.content.list[i].onClick && this.config.content.list[i].onClick(posX, posY - this.config.content.list[i].y);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            if(this.scroll_type == "horizontal" && Math.abs(this.scroll_content_clicked_X - this.scroll_content_first_clicked_X) < 5) {
                let posX = e.x;
                let posY = e.y;
                posX -= this.config.mask.x;
                posX -= this.content.x;
                posY -= this.config.mask.y;
                posY -= this.content.y;
                if(this.config.content.list) {
                    for(let i = 0 ; i < this.config.content.list.length ; i ++) {
                        this.config.content.list[i].onRelease && this.config.content.list[i].onRelease();
                        if(posX >= this.config.content.list[i].x && posX < this.config.content.list[i].x + this.config.content.list[i].width) {
                            if(this.config.content.list[i].height) {
                                if(posX > this.config.content.list[i].y && posY < this.config.content.list[i].Y + this.config.content.list[i].height) {
                                    if(this.config.content.list[i].click_area) {
                                        if(posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                                        posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height &&
                                        posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                                        posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width) {
                                            this.config.content.list[i].onClick && this.config.content.list[i].onClick(e);
                                            break;
                                        }
                                    } else {
                                        this.config.content.list[i].onClick && this.config.content.list[i].onClick(e);
                                        break;
                                    }
                                }
                            } else {
                                if(this.config.content.list[i].click_area) { 
                                    if(posX > this.config.content.list[i].x + this.config.content.list[i].click_area.x &&
                                    posX < this.config.content.list[i].x + this.config.content.list[i].click_area.x + this.config.content.list[i].click_area.width &&
                                    posY > this.config.content.list[i].y + this.config.content.list[i].click_area.y &&
                                    posY < this.config.content.list[i].y + this.config.content.list[i].click_area.y + this.config.content.list[i].click_area.height) {
                                        this.config.content.list[i].onClick && this.config.content.list[i].onClick(e);
                                        break;
                                    }
                                } else {
                                    this.config.content.list[i].onClick && this.config.content.list[i].onClick(e);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            this.scroll_content_clicked_Y = 0;
            this.scroll_content_first_clicked_Y = 0;
            this.scroll_content_clicked_X = 0;
            this.scroll_content_first_clicked_X = 0;
            this.arrangeContent();
        })
        this.scroll_content_hit_area.on("pointerout", () => {
            this.scroll_content_clicked_X = 0;
            this.scroll_content_first_clicked_X = 0;
            this.scroll_content_clicked_Y = 0;
            this.scroll_content_first_clicked_Y = 0;
            this.arrangeContent();
        })
        this.updateScroll();
    }

    arrangeContent() {
        let dest = 0;
        let delta = 0;
        let flag = false;
        if(this.scroll_type == "vertical") {
            if(this.content.y > 0) {
                dest = 0;
                delta = -this.content.y;
                flag = true;
            }
            if(this.content.y < -(this.content.height - this.config.height)) {
                dest = -(this.content.height - this.config.height);
                delta = -(this.content.height - this.config.height) - this.content.y;
                flag = true;
            }
            if(flag) {
                if(this.config.height > this.content.height) {
                    dest = 0;
                    delta = -this.content.y;
                }
                for(let i = 0 ; i < this.content.list.length ; i ++) {
                    this.content.list[i].onMove && this.content.list[i].onMove(0, delta, true);
                }
                this.scene.tweens.add({
                    targets: this.content,
                    duration: 300,
                    y: dest,
                    ease: "Power2"
                });
            }
        } else if(this.scroll_type == "horizontal") {
            if(this.content.x > 0) {
                dest = 0;
                delta = -this.content.x;
                flag = true;
            }
            if(this.content.x < -(this.content.width - this.config.width)) {
                dest = -(this.content.width - this.config.width);
                delta = -(this.content.width - this.config.width) - this.content.x;
                flag = true;
            }
            if(flag) {
                if(this.config.width > this.content.width) {
                    dest = 0;
                    delta = -this.content.x;
                }
                for(let i = 0 ; i < this.content.list.length ; i ++) {
                    this.content.list[i].onMove && this.content.list[i].onMove(delta, 0, true);
                }
                this.scene.tweens.add({
                    targets: this.content,
                    duration: 300,
                    x: dest,
                    ease: "Power2"
                });
            }
        }
    }

    updateScroll(initScroll = true) {
        initScroll && this.initScroll();
        if(this.scroll_type == "horizontal") {
            let flag = this.config.width < this.content.width;
            this.scroll_thumb.setVisible(flag);
            this.scroll_thumb_hit_area.setVisible(flag);
        } else {
            let flag = this.config.height < this.content.height;
            this.scroll_thumb.setVisible(flag);
            this.scroll_thumb_hit_area.setVisible(flag);
            // this.scroll_content_hit_area.setVisible(flag);
        }
    }

    scrollToBottom() {
        if(this.config.height > this.content.height) return;
        this.content.y = this.config.height - this.content.height;
        this.scroll_thumb.y = this.scroll_range[1];
        for(let i = 0 ; i < this.config.content.list.length ; i++) {
            this.config.content.list[i].onMove && this.config.content.list[i].onMove(0, this.config.height - this.content.height);
        }
    }

    initScroll() {
        this.content.x = 0;
        this.content.y = 0;
        this.scroll_thumb.x = this.scroll_type == "horizontal" ? this.scroll_range[0] : this.config.width + 1;
        this.scroll_thumb.y = this.scroll_type == "horizontal" ? this.config.height : this.scroll_range[0];
    }
}