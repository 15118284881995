import { SpriteButton } from "../../../../components/button/spritebutton";
import { UnderlineTabContainer } from "../../../../components/container/underline_tabcontainer";
import { translate } from "../../../../util/other";
import { ChangeCardDialog } from "./change_card";
import { ChangeDisplayDialog } from "./change_display";

export class ChangeTemaDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.underlineTabContainer.setActive(this.config.type == "display" ? 0 : 1);
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 595, this.scene.orientation == "pc" ? 430 : 800)
        this.back.fillRectShape(rect);
        this.add(this.back);
        this.back.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        
        this.back.lineStyle(1, 0x222222, 1);
        this.back.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 940 : 595, this.scene.orientation == "pc" ? 430 : 800, 20);

        this.underlineTabContainer = new UnderlineTabContainer(this.scene, {
            x: 40,
            y: 12,
            width: this.scene.orientation == "pc" ? 450 : 590,
            height: this.scene.orientation == "pc" ? 50 : 66,
            tabs: [{
                name: translate('game_display'),
                x: 0,
                y: 0,
                width: 190,
                content: new ChangeDisplayDialog(this.scene, {
                    x: 0,
                    y: 65,
                    atlas: this.config.atlas
                })
            }, {
                name: translate('card_style'),
                x: 190,
                y: 0,
                width: 190,
                atlas: this.config.atlas,
                content: new ChangeCardDialog(this.scene, {
                    x: 0,
                    y: 65,
                    atlas: this.config.atlas
                })
            }],
            atlas: this.config.atlas,
            active_underline: "tab_underline.png",
            color: {
                inactive: '#777777',
                active: '#ffffff'
            },
            fontSize: 20
        });

        this.add(this.underlineTabContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 895 : 540,
            y: 46,
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);
    }

    close() {
        this.destroy();
    }
}