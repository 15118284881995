import CommonDef from "../def/commondef";
import { LoadingScene } from "./loading";

export class SceneManager {
    static changeScene(sceneManager, currentSceneKey, nextSceneKey) {
        if(sceneManager.isActive(nextSceneKey)) return;
        CommonDef.LoadingScene = nextSceneKey;
        (currentSceneKey == "HoldemScene" || currentSceneKey == "TournamentHoldemScene") && CommonDef.currentScene.clearTimerEvents();
        if(currentSceneKey == "LoadingScene") {
            let waitInterval = setInterval(() => {
                if(CommonDef.currentScene.scene.key != "LoadingScene") {
                    clearInterval(waitInterval);
                    if(nextSceneKey == CommonDef.currentScene.scene.key) {
                        return;
                    }
                    CommonDef.currentScene.scene.stop(CommonDef.currentScene.scene.key);
                    CommonDef.currentScene.scene.add("LoadingScene", new LoadingScene(), true);
                }
            }, 100);
        } else {
            sceneManager.stop(currentSceneKey);
            sceneManager.add("LoadingScene", new LoadingScene(), true);
        }
    }
}