import { TextClass } from "../text/text";

export class UnderlineTabContainer extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        if(this.config.back) {
            this.back = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: this.config.back,
                    alpha: 1
                }
            });
            this.back.fillRect(0, 0, this.config.width, this.config.height);
            this.add(this.back);
        }

        this.tabs = [];
        for(let i = 0 ; i < this.config.tabs.length ; i ++) {
            let tab_name = TextClass.plainText1(this.scene, {
                x: this.config.tabs[i].x + this.config.tabs[i].width / 2,
                y: this.config.height / 2,
                text: this.config.tabs[i].name,
                color: this.config.color.inactive,
                fontSize: this.config.fontSize,
                fontFamily: this.config.fontFamily
            }).setOrigin(0.5, 0.5);
            this.add(tab_name);

            let action_area = this.scene.add.graphics({
                x: this.config.tabs[i].x,
                y: 0,
                fillStyle: {
                    color: 0x000000,
                    alpha: 0.5
                }
            });
            this.add(action_area);
            let rect = new Phaser.Geom.Rectangle(0, 0, this.config.tabs[i].width, this.config.height);
            action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
            action_area.on("pointerdown", () => {
                this.setActive(i);
            });

            if(this.config.tabs[i].content) {
                this.add(this.config.tabs[i].content);
            }

            this.tabs.push({
                tab_name: tab_name,
                content: this.config.tabs[i].content
            })
        }

        this.active_underline = this.scene.add.sprite(this.config.tabs[0].width / 2, this.config.height-2, this.config.atlas, this.config.active_underline);
        this.active_underline.setOrigin(0.5, 0.5);
        this.add(this.active_underline);
        this.active_underline.setDepth(1);

        this.sort("depth");
        this.setActive(0);
    }

    setActive(index) {
        for(let i = 0 ; i < this.tabs.length ; i++) {
            this.tabs[i].tab_name.setColor(index == i ? this.config.color.active : this.config.color.inactive);
            this.tabs[i].content && this.tabs[i].content.setVisible(i == index);
        }
        this.active_underline.x = this.config.tabs[index].x + this.config.tabs[index].width / 2;
    }
}