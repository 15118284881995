import { SpriteButton } from "../../../../components/button/spritebutton";
import { ContentCard } from "../../../../components/card/contentcard";
import { TabContainer } from "../../../../components/container/tabcontainer";
import { MobileDialog } from "../../../../components/dialog/mobile_dialog";
import { translate } from "../../../../util/other";
import { SettingSystem } from "./system";
import { SettingTema } from "./tema";

export class SettingDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.tabContainer = new TabContainer(this.scene, {
            x: this.scene.orientation == "pc" ? - 470 : 80,
            y: this.scene.orientation == "pc" ? 140 : 170,
            atlas: this.config.atlas,
            tabs: [{
                x: 0,
                y: 0,
                text: translate('system'),
                content: new SettingSystem(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 50 : 56,
                    atlas: this.config.atlas,
                    close: this.close.bind(this)
                })
            }, {
                x: this.scene.orientation == "pc" ? 170 : 148,
                y: 0,
                text: translate('template'),
                content: new SettingTema(this.scene, {
                    x: 0,
                    y: this.scene.orientation == "pc" ? 50 : 56,
                    atlas: this.config.atlas,
                    close: this.close.bind(this)
                })
            }],
            tab_line: {
                x: 0,
                y: this.scene.orientation == "pc" ? 49 : 55,
                texture: "setting_tab_line_" + this.scene.orientation + ".png"
            },
            scale: this.scene.orientation == "pc" ? [1, 1] : [1.3, 1]
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.tabContainer);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    setTableTemaList(list) {
        this.tabContainer.tabs[1].content.setTableTemaList(list);
    }

    setCardTemaList(list) {
        this.tabContainer.tabs[1].content.setCardTemaList(list);
    }
}