import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { ChargeHistoryItem } from "./charge_history_item";
import CommonDef from "../../../def/commondef";
import { DropDown } from "../../../components/dropdown.js/dropdown";
import { ChargeBonusItem } from "./charge_bonus_item";
import LobbyDef from "../../../def/lobbydef";

export class ChangeDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.amount = 0;
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.chargeContainer = this.scene.add.container(this.scene.orientation == "pc" ? -505 : 40, this.scene.orientation == "pc" ? 140 : 170);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.chargeContainer);

        // let warningIcon = this.scene.add.sprite(0, this.scene.orientation == "pc" ? -40 : -50, this.config.atlas, "warning.png").setOrigin(0, 0).setScale(0.45);
        // this.chargeContainer.add(warningIcon);

        // let warningText = TextClass.plainText1(this.scene, {
        //     x: 80,
        //     y: this.scene.orientation == "pc" ? -40 : -50,
        //     text: "#환전 운영시간 09:00~23:59#\n#그 외 시간의 신청은 다음 운영시간에 순차적으로 처리됩니다#",
        //     fontFamily: "GmarketSansMedium",
        //     fontSize: 20,
        //     color: "#f58a00"
        // }).setOrigin(0, 0);
        // this.chargeContainer.add(warningText);

        let bankNameText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: "Bank Name",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(bankNameText);

        this.bankNameField = new InputField(this.scene, {
            x: 0,
            y: 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 358 : 200
                },
                width: 270,
                height: this.scene.orientation == "pc" ? 60 : 70,
                value: CommonDef.Me.bankname,
                disabled: true,
                placeholder: "",
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.bankNameField);

        let accountNumberText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 330 : 350,
            y: 0,
            text: "Account Number",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(accountNumberText);

        this.accountNumberField = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 330 : 350,
            y: 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 790 : 390,
                    y: this.scene.orientation == "pc" ? 358 : 200
                },
                width: 270,
                height: this.scene.orientation == "pc" ? 60 : 70,
                value: CommonDef.Me.accountnumber,
                disabled: true,
                placeholder: "",
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.accountNumberField);

        let ownerNameText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 122 : 132,
            text: "Owner Name",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(ownerNameText);

        this.ownerNameField = new InputField(this.scene, {
            x: 0,
            y: (this.scene.orientation == "pc" ? 122 : 132) + 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 480 : 332
                },
                width: 270,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: "",
                disabled: true,
                value: CommonDef.Me.ownername,
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.ownerNameField);

        let playerNameText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 330 : 350,
            y: this.scene.orientation == "pc" ? 122 : 132,
            text: "Your Balance",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(playerNameText);
        let myMoneyBack = this.scene.add.sprite(this.scene.orientation == "pc" ? 330 : 350, (this.scene.orientation == "pc" ? 122 : 132) + 32, this.config.atlas, "input_back_" + this.scene.orientation + "_short.png");
        this.chargeContainer.add(myMoneyBack);
        myMoneyBack.setOrigin(0, 0);
        this.myMoneyValue = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 330 : 350) + 20,
            y: this.scene.orientation == "pc" ? 174 : 187,
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.money),
            fontSize: 24,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.chargeContainer.add(this.myMoneyValue);

        let amountText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 244 : 264,
            text: "Withdraw Amount",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(amountText);

        this.amountField = new InputField(this.scene, {
            x: 0,
            y: (this.scene.orientation == "pc" ? 244 : 264) + 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 602 : 464
                },
                type: "number",
                width: 590,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: "Input amount. (required)",
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.amountField);

        let amountList = [0, 10000, 50000, 100000, 500000];
        for(let i = 0 ; i < 5 ; i ++) {
            let btn = new IconButton(this.scene, {
                x: (this.scene.orientation == "pc" ? 130 : 136) * i - 8,
                y: this.scene.orientation == "pc" ? 352 : 387,
                atlas: this.config.atlas,
                normal: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_normal.png"
                },
                hover: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_hover.png"
                },
                clicked: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_clicked.png"
                },
                text: {
                    x: 70,
                    y: this.scene.orientation == "pc" ? 15 : 20,
                    value: i == 0 ? "RESET" : NumberUtil.convertNumberWithComma(amountList[i]),
                    fontSize: 24,
                    gradient: ['#d7d0d0', '#ffffff'],
                    origin: [0.5, 0]
                },
                onClicked: () => {
                    this.onClickedBtnAmount(amountList[i]);
                }
            });
            this.chargeContainer.add(btn);
        }

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 407 : 243,
            y: this.scene.orientation == "pc" ? 445 : 497,
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 95,
                y: 15,
                value: "Confirm",
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: this.onClickedBtnConfirm.bind(this)
        });
        this.chargeContainer.add(this.btn_confirm);
        

        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x + 175 : 40, this.scene.orientation == "pc" ? this.config.y + 140 : 800)
        this.add(this.historyContainer);

        let historyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: "Withdraw History",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(historyText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 2,
            text: "Refresh",
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 288 : 630,
            y: 0,
            atlas: this.config.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            CommonDef.gameNet.requestPayoutList();
        });

        this.historyContent = this.scene.add.container(0, 0);

        this.scrollViewContainer = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 323 : 667,
            height: this.scene.orientation == "pc" ? 487 : 474,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 1125 : 40,
                y: this.scene.orientation == "pc" ? 362 : 832
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 300 : 640,
                height: this.scene.orientation == "pc" ? 487 : 474,
            }
        });
        this.historyContainer.add(this.scrollViewContainer);

        // this.setHistoryContent([{
        //     date_time: "2021.12.21 10:38:34",
        //     amount: 50000,
        //     status: "success",
        // }])
        this.setHistoryContent([], this.config.history || []);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: "Withdraw",
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: "Withdraw",
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onClickedBtnAmount(amount) {
        amount == 0 && (this.amount = 0);
        this.amount += amount;
        this.amountField.setValue(this.amount);
    }

    setHistoryContent(content) {
        this.historyContent.removeAll(true);
        let height = 90;
        for(let i = 0 ; i < content.length ; i ++) {
            let item = new ChargeHistoryItem(this.scene, {
                x: 0,
                y: i * height,
                atlas: this.config.atlas,
                ...content[i]
            });
            this.historyContent.add(item);
        }
        this.historyContent.height = content.length * height;
        this.scrollViewContainer.updateScroll();
    }

    onClickedBtnConfirm() {
        if(!this.amountField.value || this.amountField.value < 0) return;
        this.scene.requestPayout(this.amountField.value);
    }

    popupAnimation() {
        this.chargeContainer.setVisible(false);
        this.historyContainer.setVisible(false);
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.chargeContainer.setVisible(true);
                this.historyContainer.setVisible(true);
            }
        })
    }

    onChangeBonusItem(type, text) {
        this.bonusType.setValue(type);
        this.bonusType.setDisplayText(text);
        this.bonusType.activeDropDown(false);
    }
}