import { IconButton } from '../components/button/iconbutton';
import { SpriteButton } from '../components/button/spritebutton';
import { ContentCard } from '../components/card/contentcard';
import { ScrollView } from '../components/container/scrollview';
import { MobileDialog } from '../components/dialog/mobile_dialog';
import { Toast } from '../components/dialog/toast';
import { DropDown } from '../components/dropdown.js/dropdown';
import { InputField } from '../components/input/input';
import { TextClass } from '../components/text/text';
import CommonDef from '../def/commondef';
import RegisterDef from '../def/registerdef';
import { LoadingScene } from './loading';
import { BankItem } from './pages/register/bank_item';
import { SceneManager } from './scene_manager';
import MessageCode from '../net/message_code';
import { GameSound } from '../sound/game_sound';
import { LoadingPage } from './pages/loadingPage';
import { Lang } from "../lang/index";

export class RegisterScene extends Phaser.Scene {
    constructor() {
        super({
            key: "RegisterScene"
        })
    }

    preload() {

    }

    create() {
        if(this.backContainer) {
            this.backContainer.removeAll(true);    
        } else {
            this.backContainer = this.add.container(0, 0);
        }
        this.initSound();  
        this.initGraph();
        this.initLoading();
        CommonDef.currentScene = this;
    }

    initSound() {
        this.audio = new GameSound(this);
    }

    initGraph() {
        this.bankList = [];
        
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.RegisterAtlasName = 'RegisterAtlas_' + this.tema;
        this.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        // let bankList = [
        //     "한국은행",
        //     "KB국민은행",
        //     "신한은행",
        //     "우리은행",
        //     // "하나은행",
        //     // "케이뱅크",
        //     "카카오뱅크",
        //     "토스뱅크",
        //     "산업은행",
        //     // "기업은행",
        //     "농협",
        //     "대구은행",
        //     "부산은행",
        //     "경남은행",
        //     "광주은행",
        //     "전북은행",
        //     "제주은행",
        //     "새마을금고"
        // ];
        let bankList = [
            "BDO UniBank",
            "Unionbank",
            "East west Bank",
            "Asia United Bank",
            "Metrobank",
            "Bpi savings bank",
            "UCPB",
            "GCash",
            "Paymaya",
            "PNB",
            "Landbank",
            "RCBC"
        ];
        this.setBankList(bankList);
        this.onChangePartnerid("");
    }

    initLoading() {
        this.loadingPage = new LoadingPage(this, {
            atlas: 'LoadingAtlas'
        });
        this.loadingPage.setDepth(1000);
    }

    playLoading() {
        this.loadingPage && this.loadingPage.playLoading();
    }

    stopLoading() {
        this.loadingPage && this.loadingPage.stopLoading();
    }

    initGraphForPC() {
        this.back = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, this.RegisterAtlasName, "background.png");
        this.back.setOrigin(0.5, 0.5);
        this.backContainer.add(this.back);

        this.cardContainer = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: RegisterDef.card.y,
            atlas: this.RegisterAtlasName,
            back: 'card_back.png',
            light: 'content_light.png',
            title: {
                text: Lang[CommonDef.Lang].register,
                y: RegisterDef.card.title.y,
                gradient: ['#ffe1d0', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 36
            }
        });

        this.id_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[0],
            text: "* " + Lang[CommonDef.Lang].login_id,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.id_label);

        this.id_input_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[1],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 420,
                    y: 280
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].user_id_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.idCheckComplete(false)
            }
        });
        this.cardContainer.add(this.id_input_field);

        this.btn_id_double_check = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].double_check,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestIdDoubleCheck.bind(this)
        });
        this.id_input_field.add(this.btn_id_double_check);

        this.btn_id_double_check_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.id_input_field.add(this.btn_id_double_check_completed);
        this.btn_id_double_check_completed.setVisible(false);

        this.bank_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[0],
            text: "* " + Lang[CommonDef.Lang].payment_method,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.bank_label);

        this.dropdownListContent = this.add.container(0, 0);
        this.dropdownListContent.height = Math.max(50 * this.bankList.length, 281);

        this.dropdownContent = new ScrollView(this, {
            x: 35,
            y: 86,
            width: 464,
            height: 280,
            atlas: this.RegisterAtlasName,
            thumb: 'scroll_thumb.png',
            content: this.dropdownListContent,
            mask: {
                x: 1005,
                y: 366
            },
            action_rect: {
                width: 420,
                height: 280
            }
        })

        this.bank_dropdown = new DropDown(this, {
            x: RegisterDef.content[this.orientation].x[1] - 7,
            y: RegisterDef.content[this.orientation].y[1],
            atlas: this.RegisterAtlasName,
            normal: {
                back: "dropdown_normal_back_pc.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 490,
                    y: 23
                }
            },
            clicked: {
                back: "dropdown_clicked_back_pc.png",
            },
            hover: {
                back: "dropdown_hover_back_pc.png"
            },
            dropdown_back: 'dropdown_back_pc.png',
            placeholder: Lang[CommonDef.Lang].payment_method_placeholder,
            dropdown_content: this.dropdownContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.cardContainer.add(this.bank_dropdown);
        this.bank_dropdown.setDepth(1);


        this.password_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[2],
            text: "* " + Lang[CommonDef.Lang].password,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.password_label);

        this.password_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[3],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 420,
                    y: 403
                },
                type: "password",
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].password_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.password_field);


        this.account_number_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[2],
            text: "* " + Lang[CommonDef.Lang].account_number,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.account_number_label);

        this.account_number_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[3],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 980,
                    y: 403
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].account_number_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.account_number_field);


        this.password_confirm_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[4],
            text: "* " + Lang[CommonDef.Lang].password_confirm,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.password_confirm_label);

        this.password_confirm_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[5],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 420,
                    y: 523
                },
                type: "password",
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].password_confirm_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.password_confirm_field);

        this.bank_owner_name_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[4],
            text: "* " + Lang[CommonDef.Lang].bank_owner,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.bank_owner_name_label);

        this.bank_owner_name_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[5],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 980,
                    y: 523
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].bank_owner_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.bank_owner_name_field);


        this.nickname_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[6],
            text: "* " + Lang[CommonDef.Lang].nickname,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.nickname_label);

        this.nickname_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[7],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 420,
                    y: 645
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].nickname_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.nicknameCheckComplete(false)
            }
        });
        this.cardContainer.add(this.nickname_field);

        this.btn_nickname_double_check = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].double_check,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestNicknameDoubleCheck.bind(this)
        });
        this.nickname_field.add(this.btn_nickname_double_check);

        this.btn_nickname_double_check_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.nickname_field.add(this.btn_nickname_double_check_completed);
        this.btn_nickname_double_check_completed.setVisible(false);


        this.phone_number_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[6],
            text: "* " + Lang[CommonDef.Lang].phone_number,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.phone_number_label);

        this.phone_number_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[7],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 980,
                    y: 645
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].phone_number_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.phoneNumberAccepted(false);
                this.phoneNumberVerifyCompleted(false);
            }
        });
        this.cardContainer.add(this.phone_number_field);

        this.btn_verify_phone_number = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].verify,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestPhoneNumberVerify.bind(this)
        });
        this.phone_number_field.add(this.btn_verify_phone_number);

        this.btn_phone_number_accepted = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_accepted.png",
            origin: [0, 0]
        });
        this.phone_number_field.add(this.btn_phone_number_accepted);
        this.btn_phone_number_accepted.setVisible(false);

        this.partner_id_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[8],
            text: "* " + Lang[CommonDef.Lang].signup_code,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.partner_id_label);

        this.partner_id_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[9],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            scale: [1, 1],
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 420,
                    y: 767
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].signup_code_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: (value) => {
                this.onChangePartnerid(value);
            }
        });
        this.cardContainer.add(this.partner_id_field);

        this.verify_code_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[8],
            text: "* " + Lang[CommonDef.Lang].verify_code,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.verify_code_label);

        this.verify_code_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[9],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 980,
                    y: 767
                },
                width: 350,
                height: 60,
                placeholder: Lang[CommonDef.Lang].verify_code_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.verify_code_field);

        this.btn_verify_code = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 45,
                y: 11,
                value: Lang[CommonDef.Lang].confirm,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.sendVerifyCode.bind(this)
        });
        this.verify_code_field.add(this.btn_verify_code);
        this.btn_verify_code.setDisable(true);

        this.btn_verify_code_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.verify_code_field.add(this.btn_verify_code_completed);
        this.btn_verify_code_completed.setVisible(false);


        this.userid_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[2],
            y: RegisterDef.content[this.orientation].y[8],
            text: "* " + Lang[CommonDef.Lang].recommander_id,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.userid_label);

        this.userid_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[2],
            y: RegisterDef.content[this.orientation].y[9],
            atlas: this.RegisterAtlasName,
            back: "input_back_pc.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 690,
                    y: 767
                },
                width: 450,
                height: 60,
                placeholder: Lang[CommonDef.Lang].recommander_id_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.cardContainer.add(this.userid_field);
        this.userid_field.setScale(0.48, 1);

        this.cardContainer.sort("depth");

        this.backContainer.add(this.cardContainer);

        this.btn_cancel = new IconButton(this, {
            x: RegisterDef.button[this.orientation].x[0],
            y: RegisterDef.button[this.orientation].y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_cancel_back_normal_pc.png',
            },
            hover: {
                back: 'btn_cancel_back_hover_pc.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked_pc.png'
            },
            text: {
                x: 90,
                y: 22,
                value: Lang[CommonDef.Lang].cancel,
                fontSize: 34,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.onClickedCancel.bind(this)
        });
        this.backContainer.add(this.btn_cancel);

        this.btn_ok = new IconButton(this, {
            x: RegisterDef.button[this.orientation].x[1],
            y: RegisterDef.button[this.orientation].y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_ok_back_normal_pc.png',
            },
            hover: {
                back: 'btn_ok_back_hover_pc.png'
            },
            clicked: {
                back: 'btn_ok_back_clicked_pc.png'
            },
            disabled: {
                back: 'btn_ok_back_disable_pc.png'
            },
            text: {
                x: 60,
                y: 22,
                value: Lang[CommonDef.Lang].signup_ok,
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            },
            onClicked: this.requestRegister.bind(this)
        });
        this.backContainer.add(this.btn_ok);
        this.btn_ok.setDisable(true);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this, {
            title: Lang[CommonDef.Lang].register,
            onClickClose: this.onClickedCancel.bind(this)
        });

        this.id_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[0],
            text: "* " + Lang[CommonDef.Lang].login_id,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.id_label);

        this.id_input_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[1],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[1]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].user_id_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.idCheckComplete(false)
            }
        });
        this.mobileDlg.add(this.id_input_field);

        this.btn_id_double_check = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].double_check,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestIdDoubleCheck.bind(this)
        });
        this.id_input_field.add(this.btn_id_double_check);

        this.btn_id_double_check_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.id_input_field.add(this.btn_id_double_check_completed);
        this.btn_id_double_check_completed.setVisible(false);

        this.password_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[2],
            text: "* " + Lang[CommonDef.Lang].password,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.password_label);

        this.password_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[3],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[3]
                },
                type: "password",
                width: 320,
                height: 70,
                placeholder: Lang[CommonDef.Lang].password_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.password_field);

        this.password_confirm_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[3],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[1],
                    y: RegisterDef.content[this.orientation].y[3]
                },
                type: "password",
                width: 320,
                height: 70,
                placeholder: Lang[CommonDef.Lang].password_confirm_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.password_confirm_field);

        this.nickname_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[4],
            text: "* " + Lang[CommonDef.Lang].nickname,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.nickname_label);

        this.nickname_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[5],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[5]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].nickname_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.nicknameCheckComplete(false);
            }
        });
        this.mobileDlg.add(this.nickname_field);

        this.btn_nickname_double_check = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].double_check,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestNicknameDoubleCheck.bind(this)
        });
        this.nickname_field.add(this.btn_nickname_double_check);

        this.btn_nickname_double_check_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.nickname_field.add(this.btn_nickname_double_check_completed);
        this.btn_nickname_double_check_completed.setVisible(false);

        this.partner_id_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[6],
            text: "* " + Lang[CommonDef.Lang].signup_code,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.partner_id_label);

        this.partner_id_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[7],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[7]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].signup_code_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: (value) => {
                this.onChangePartnerid(value);
            }
        });
        this.mobileDlg.add(this.partner_id_field);

        this.bank_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[8],
            text: "* " + Lang[CommonDef.Lang].payment_method,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.bank_label);

        this.dropdownListContent = this.add.container(0, 0);
        this.dropdownListContent.height = Math.max(50 * this.bankList.length, 281);

        this.dropdownContent = new ScrollView(this, {
            x: 35,
            y: 95,
            width: 610,
            height: 280,
            atlas: this.RegisterAtlasName,
            thumb: 'scroll_thumb.png',
            content: this.dropdownListContent,
            mask: {
                x: 35,
                y: 825
            },
            action_rect: {
                width: 590,
                height: 280
            }
        })

        this.bank_dropdown = new DropDown(this, {
            x: RegisterDef.content[this.orientation].x[0] - 7,
            y: RegisterDef.content[this.orientation].y[9],
            atlas: this.RegisterAtlasName,
            normal: {
                back: "dropdown_normal_back_mobile.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 635,
                    y: 28
                }
            },
            clicked: {
                back: "dropdown_clicked_back_mobile.png"
            },
            hover: {
                back: "dropdown_hover_back_mobile.png"
            },
            dropdown_back: 'dropdown_back_mobile.png',
            placeholder: Lang[CommonDef.Lang].payment_method_placeholder,
            dropdown_content: this.dropdownContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.bank_dropdown);
        this.bank_dropdown.setDepth(1);

        this.account_number_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[10],
            text: "* " + Lang[CommonDef.Lang].account_number,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.account_number_label);

        this.account_number_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[11],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[11]
                },
                width: 320,
                height: 70,
                placeholder: Lang[CommonDef.Lang].account_number_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.account_number_field);

        this.bank_owner_name_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[10],
            text: "* " + Lang[CommonDef.Lang].bank_owner,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.bank_owner_name_label);

        this.bank_owner_name_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[11],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[1],
                    y: RegisterDef.content[this.orientation].y[11]
                },
                width: 320,
                height: 70,
                placeholder: Lang[CommonDef.Lang].bank_owner_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.bank_owner_name_field);

        this.phone_number_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[12],
            text: "* " + Lang[CommonDef.Lang].phone_number,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.phone_number_label);

        this.phone_number_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[13],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[13]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].phone_number_placeholder,
                color: "#777777",
                fontSize: 24
            },
            onChange: () => {
                this.phoneNumberAccepted(false);
                this.phoneNumberVerifyCompleted(false);
            }
        });
        this.mobileDlg.add(this.phone_number_field);

        this.btn_verify_phone_number = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 25,
                y: 11,
                value: Lang[CommonDef.Lang].verify,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.requestPhoneNumberVerify.bind(this)
        });
        this.phone_number_field.add(this.btn_verify_phone_number);

        this.btn_phone_number_accepted = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_accepted.png",
            origin: [0, 0]
        });
        this.phone_number_field.add(this.btn_phone_number_accepted);
        this.btn_phone_number_accepted.setVisible(false);

        this.verify_code_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[14],
            text: "* " + Lang[CommonDef.Lang].verify_code,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.verify_code_label);

        this.verify_code_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[0],
            y: RegisterDef.content[this.orientation].y[15],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[0],
                    y: RegisterDef.content[this.orientation].y[15]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].verify_code_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.verify_code_field);

        this.btn_verify_code = new IconButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 45,
                y: 11,
                value: Lang[CommonDef.Lang].confirm,
                fontSize: 20,
                color: '#ffffff'
            },
            onClicked: this.sendVerifyCode.bind(this)
        });
        this.verify_code_field.add(this.btn_verify_code);
        this.btn_verify_code.setDisable(true);

        this.btn_verify_code_completed = new SpriteButton(this, {
            x: RegisterDef.content[this.orientation].input_button.x,
            y: RegisterDef.content[this.orientation].input_button.y,
            atlas: this.RegisterAtlasName,
            normal: "btn_check_completed.png",
            origin: [0, 0]
        });
        this.verify_code_field.add(this.btn_verify_code_completed);
        this.btn_verify_code_completed.setVisible(false);


        this.userid_label = TextClass.plainText1(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[6],
            text: "* " + Lang[CommonDef.Lang].recommander_id,
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.userid_label);

        this.userid_field = new InputField(this, {
            x: RegisterDef.content[this.orientation].x[1],
            y: RegisterDef.content[this.orientation].y[7],
            atlas: this.RegisterAtlasName,
            back: "input_back_moble_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: RegisterDef.content[this.orientation].x[1],
                    y: RegisterDef.content[this.orientation].y[7]
                },
                width: 530,
                height: 70,
                placeholder: Lang[CommonDef.Lang].recommander_id_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.mobileDlg.add(this.userid_field);
        this.userid_field.setScale(0.48, 1);
        this.btn_cancel = new IconButton(this, {
            x: RegisterDef.button[this.orientation].x[0],
            y: RegisterDef.button[this.orientation].y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_cancel_back_normal_mobile.png',
            },
            hover: {
                back: 'btn_cancel_back_hover_mobile.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked_mobile.png'
            },
            text: {
                x: 80,
                y: 17,
                value: Lang[CommonDef.Lang].cancel,
                fontSize: 34,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.onClickedCancel.bind(this)
        });
        this.mobileDlg.add(this.btn_cancel);

        this.btn_ok = new IconButton(this, {
            x: RegisterDef.button[this.orientation].x[1],
            y: RegisterDef.button[this.orientation].y,
            atlas: this.RegisterAtlasName,
            normal: {
                back: 'btn_ok_back_normal_mobile.png',
            },
            hover: {
                back: 'btn_ok_back_hover_mobile.png'
            },
            clicked: {
                back: 'btn_ok_back_clicked_mobile.png'
            },
            disabled: {
                back: 'btn_ok_back_disable_mobile.png'
            },
            text: {
                x: 45,
                y: 17,
                value: Lang[CommonDef.Lang].signup_ok,
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            },
            onClicked: this.requestRegister.bind(this)
        });
        this.mobileDlg.add(this.btn_ok);
        this.btn_ok.setDisable(true);
        this.mobileDlg.sort("depth");

        this.backContainer.add(this.mobileDlg);
    }

    setBankList(banks) {
        this.bankList = [];
        for(let i = 0 ; i < banks.length ; i ++) {
            this.bankList.push({
                id: banks[i],
                name: banks[i]
            });
        }
        this.dropdownListContent.removeAll(true);
        for(let i = 0 ; i < this.bankList.length ; i++) {
            let bank = new BankItem(this, {
                x: 0,
                y: i * 50,
                height: 50,
                id: this.bankList[i].id,
                name: this.bankList[i].name,
                onClicked: (id) => {
                    this.onChangeBank(id);   
                }
            });
            this.dropdownListContent.add(bank);
        }
        this.dropdownListContent.height = Math.max(50 * this.bankList.length, 281);
        this.dropdownContent.updateScroll();
    }

    onChangeBank(id) {
        this.bank_dropdown.setValue(id);
        let bank = this.bankList.find(x => x.id == id);
        this.bank_dropdown.setDisplayText(bank.name);
        this.bank_dropdown.activeDropDown(false);
    }

    onClickedCancel() {
        CommonDef.gameNet.logout();
    }

    requestIdDoubleCheck() {
        if(!this.id_input_field.value) {
            this.showToast("failed", "Please input user id.");
            return;
        }
        let regType = /^[a-z0-9+]{4,12}$/;
        if(!regType.test(this.id_input_field.value)) {
            this.showToast("failed", "Invalid user id.");
            return;
        } 
        CommonDef.gameNet.requestSignup(MessageCode.SIGNUOCODE.IDCHECK, [this.id_input_field.value])
    }

    idCheckComplete(flag) {
        this.btn_id_double_check.setVisible(!flag);
        this.btn_id_double_check_completed.setVisible(flag);
        this.checkOkBtnActive();
    }

    requestNicknameDoubleCheck() {
        if(!this.nickname_field.value) {
            this.showToast("failed", "Please input nickname.");
            return;
        }
        // let regType = /^[a-z0-9+]{4,12}$/;
        // if(!regType.test(this.nickname_field.value)) {
        //     this.showToast("failed", "Invalid nickname.");
        //     return;
        // } 
        CommonDef.gameNet.requestSignup(MessageCode.SIGNUOCODE.NICKNAMECHECK, [this.nickname_field.value])
    }

    nicknameCheckComplete(flag) {
        this.btn_nickname_double_check.setVisible(!flag);
        this.btn_nickname_double_check_completed.setVisible(flag);
        this.checkOkBtnActive();
    }

    requestPhoneNumberVerify() {
        if(!this.phone_number_field.value) {
            this.showToast("failed", "Please input phone number.");
            return;
        }
        CommonDef.gameNet.requestSignup(MessageCode.SIGNUOCODE.PHONENUMBER, [this.phone_number_field.value])
        this.btn_verify_code.setDisable(false);
        this.btn_verify_phone_number.setDisable(true);
    }

    phoneNumberAccepted(flag) {
        this.btn_phone_number_accepted.setVisible(flag);
        this.btn_verify_phone_number.setVisible(!flag);
        if(flag) {
            this.showToast("success", Lang[CommonDef.Lang].phone_verify_code_sent);
        }
    }

    sendVerifyCode() {
        CommonDef.gameNet.requestSignup(MessageCode.SIGNUOCODE.VERRIFYCODE, [this.verify_code_field.value])
    }

    phoneNumberVerifyCompleted(flag) {
        this.btn_verify_code.setVisible(!flag);
        this.btn_verify_code_completed.setVisible(flag);
        !flag && this.btn_verify_phone_number.setDisable(false);
        this.checkOkBtnActive();
    }

    onChangePartnerid(value) {
        let flag = value == CommonDef.SignupCode;
        this.userid_field.setVisible(flag);
        this.userid_label.setVisible(flag);
        if(flag) {
            this.partner_id_field.setScale(0.48, 1);
        } else {
            this.partner_id_field.setScale(1, 1);
            this.userid_field.setValue("");
        }

    }

    checkOkBtnActive() {
        this.btn_ok.setDisable(this.btn_id_double_check.visible || this.btn_nickname_double_check.visible || this.btn_verify_code.visible);
    }

    requestRegister() {
        if(!this.nickname_field.value) {
            this.showToast("failed", "Please input nickname.");
            return;
        }
        if(!this.password_field.value) {
            this.showToast("failed", "Please input your password.");
            return;
        }
        if(this.password_field.value != this.password_confirm_field.value) {
            this.showToast("failed", "Password is not matched.");
            return;
        }
        if(!this.partner_id_field.value) {
            this.showToast("failed", "Please input signup code.");
            return;
        }
        if(!this.bank_dropdown.value) {
            this.showToast("failed", "Please select bank.");
            return;
        }
        if(!this.account_number_field.value) {
            this.showToast("failed", "Please input account number.");
            return;
        }
        if(!this.bank_owner_name_field.value) {
            this.showToast("failed", "Please input bank owner name.");
            return;
        }
        CommonDef.gameNet.requestSignup(MessageCode.SIGNUOCODE.FINAL, [
            this.id_input_field.value,
            this.password_field.value,
            this.password_confirm_field.value,
            this.nickname_field.value,
            this.partner_id_field.value,
            this.userid_field.value,
            this.bank_dropdown.value,
            this.account_number_field.value,
            this.bank_owner_name_field.value,
            this.phone_number_field.value,
        ]);
    }

    showToast(type, text) {
        if(this.toast) this.toast.close();
        this.toast = new Toast(this, {
            type: type,
            text: text
        });
        this.backContainer.add(this.toast);
        setTimeout(() => {
            this.toast && this.toast.close();
        }, 2000);
    }
}