import CommonDef from "../../def/commondef";
import { TextClass } from "../text/text";
export class TabContainer extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);

        this.scene = scene;
        this.config = config;
        this.DialogAtlasName = "DialogAtlas";
        this.initGraph();
    }

    initGraph() {
        this.tabs = [];

        for(let i = 0 ; i < this.config.tabs.length ; i ++) {
            let tab_active = this.scene.add.sprite(this.config.tabs[i].x, this.config.tabs[i].y, this.DialogAtlasName, "tab_active_" + this.scene.orientation + "_" + this.scene.tema + ".png");
            tab_active.setOrigin(0, 0);
            if(this.config.scale) tab_active.setScale(this.config.scale[0], this.config.scale[1]);
            this.add(tab_active);
            tab_active.setDepth(2);
            let text_active = TextClass.plainText1(this.scene, {
                x: this.config.tabs[i].x + tab_active.width / 2 * (this.config.scale ? this.config.scale[0] : 1),
                y: this.config.tabs[i].y + tab_active.height / 2 * (this.config.scale ? this.config.scale[1] : 1),
                text: this.config.tabs[i].text,
                fontSize: this.scene.orientation == "pc" ? 24 : 28,
                fontFamily: "NanumSquareB",
                gradient: ["#ffe1d0", "#ffffff"]
            }).setOrigin(0.5, 0.5);
            this.add(text_active);
            text_active.setDepth(2);

            let tab_inactive = this.scene.add.sprite(this.config.tabs[i].x, this.config.tabs[i].y, this.DialogAtlasName, "tab_inactive_" + this.scene.orientation + "_" + this.scene.tema + ".png");
            tab_inactive.setOrigin(0, 0);
            if(this.config.scale) tab_inactive.setScale(this.config.scale[0], this.config.scale[1]);
            this.add(tab_inactive);
            let text_inactive = TextClass.plainText1(this.scene, {
                x: this.config.tabs[i].x + tab_active.width / 2 * (this.config.scale ? this.config.scale[0] : 1),
                y: this.config.tabs[i].y + tab_active.height / 2 * (this.config.scale ? this.config.scale[1] : 1),
                text: this.config.tabs[i].text,
                fontSize: this.scene.orientation == "pc" ? 24 : 28,
                fontFamily: "NanumSquareB",
                color: "#444444"
            }).setOrigin(0.5, 0.5);
            this.add(text_inactive);

            tab_inactive.setInteractive();
            tab_inactive.on('pointerdown', () => {
                this.setTab(i);
            });
            if(this.config.tabs[i].content) {
                this.add(this.config.tabs[i].content);
                this.config.tabs[i].content.setDepth(3);
            }

            this.tabs.push({
                active: tab_active,
                inactive: tab_inactive,
                content: this.config.tabs[i].content,
                text_active: text_active,
                text_inactive: text_inactive
            })
        }

        if(this.config.tab_line) {
            this.tab_line = this.scene.add.sprite(this.config.tab_line.x, this.config.tab_line.y, this.config.atlas, this.config.tab_line.texture);
            this.tab_line.setOrigin(0, 0);
            this.add(this.tab_line);
            this.tab_line.setDepth(1);
        }

        this.sort("depth");
        this.indexValue = 0;
        this.setTab(this.indexValue, false);
    }

    setTab(index, callback = true) {
        this.tabs[this.indexValue].content.closeAllChildDialog && this.tabs[this.indexValue].content.closeAllChildDialog();
        this.indexValue = index;
        for(let i = 0 ; i < this.tabs.length ; i++) {
            if(i == index) {
                this.tabs[i].active.setVisible(true);
                this.tabs[i].inactive.setVisible(false);
                this.tabs[i].content && this.tabs[i].content.setVisible(true);
                this.tabs[i].text_active && this.tabs[i].text_active.setVisible(true);
                this.tabs[i].text_inactive && this.tabs[i].text_inactive.setVisible(false);
            } else {
                this.tabs[i].active.setVisible(false);
                this.tabs[i].inactive.setVisible(true);
                this.tabs[i].content && this.tabs[i].content.setVisible(false);
                this.tabs[i].text_active && this.tabs[i].text_active.setVisible(false);
                this.tabs[i].text_inactive && this.tabs[i].text_inactive.setVisible(true);
            }
        }
        callback && this.config.tabChanged && this.config.tabChanged(index);
    }
}