import { TextClass } from '../components/text/text.js';
import CommonDef from '../def/commondef.js';
import LoginDef from '../def/logindef.js';
import WaitingRoomDef from '../def/waitingroomdef.js';
import HoldemDef from '../def/holdemdef.js';
import TournamentHoldemDef from '../def/tournament_holdemdef';
import { HoldemScene } from './holdem.js';
import { LobbyScene } from './lobby.js';
import { LoginScene } from './login.js';
import { RegisterScene } from './register.js';
import { WaitingRoomScene } from './waiting_room.js';
import { TournamentHoldemScene } from './tournament_holdem.js';
import { ConnectErrorDialog } from './pages/loading/connect_error_dialog.js';
import { VersionCheckDialog } from './pages/loading/version_check_dialog.js';
import { ConnectLimitDialog } from './pages/loading/connect_limit_dialog.js';
import { OmahaScene } from './omaha.js';

export class LoadingScene extends Phaser.Scene {
    constructor(){
        super({
            key:"LoadingScene",
        })
    }

    preload(){
        this.nextSceneKey = CommonDef.LoadingScene || "LoginScene";
        this.nextScene = null;
        CommonDef.currentScene = this;
        this.load.atlas('DialogAtlas', 'images/dialog_atlas.png', 'images/dialog_atlas.json');
        this.load.atlas('AvatarAtlas', 'images/profile_avatar.png', 'images/profile_avatar.json');
        this.load.atlas('ShopAtlas_red', 'images/shop_atlas_red.png', 'images/shop_atlas_red.json');
        this.load.atlas('LobbyDialogAtlas_red', 'images/lobby_dialog_atlas_red.png', 'images/lobby_dialog_atlas_red.json');
        this.load.atlas('TemaAtlas', 'images/tema_atlas.png', 'images/tema_atlas.json');
        this.load.atlas('LoadingAtlas', 'images/loading_atlas.png', 'images/loading_atlas.json');
        this.load.atlas('DefaultAvatarAtlas', 'images/avatar_atlas.png', 'images/avatar_atlas.json');
        for(let i = 0 ; i < CommonDef.Sounds.length ; i ++) {
            this.load.audio(CommonDef.Sounds[i].key, CommonDef.Sounds[i].file);
        }
        switch(this.nextSceneKey) {
            case "LoginScene":
                for(let i = 0 ; i < LoginDef.Sounds.length ; i ++) {
                    this.load.audio(LoginDef.Sounds[i].key, LoginDef.Sounds[i].file);
                }
                this.nextScene = new LoginScene();
                break;
            case "WaitingRoomScene":
                this.nextScene = new WaitingRoomScene();
                this.load.image("WaitingRoomBackground_pc_red", 'images/waitingroom_background_pc_red.png');
                this.load.atlas('WaitingRoomAtlas_pc_red', 'images/waiting_room_atlas_pc_red.png', 'images/waiting_room_atlas_pc_red.json');
                this.load.atlas('WaitingRoomAtlas_mobile_red', 'images/waiting_room_atlas_mobile_red.png', 'images/waiting_room_atlas_mobile_red.json');
                this.load.atlas('MyPageAtlas_red', 'images/my_page_atlas_red.png', 'images/my_page_atlas_red.json');
                this.load.atlas('LobbyAtlas_pc_red', 'images/lobby_atlas_pc_red.png', 'images/lobby_atlas_pc_red.json');
                this.load.atlas('LobbyAtlas_mobile_red', 'images/lobby_atlas_mobile_red.png', 'images/lobby_atlas_mobile_red.json');
                for(let i = 0 ; i < WaitingRoomDef.Sounds.length ; i ++) {
                    this.load.audio(WaitingRoomDef.Sounds[i].key, WaitingRoomDef.Sounds[i].file);
                }
                break;
            case "LobbyScene":
                this.nextScene = new LobbyScene();
                this.load.image('LobbyBackground_pc', 'images/lobby_background_pc.png');
                this.load.atlas('LobbyAtlas_pc_red', 'images/lobby_atlas_pc_red.png', 'images/lobby_atlas_pc_red.json');
                this.load.atlas('LobbyAtlas_mobile_red', 'images/lobby_atlas_mobile_red.png', 'images/lobby_atlas_mobile_red.json');
                this.load.image('EventBack_pc_red', 'images/event_back_pc_red.png');
                this.load.image('EventBack_mobile_red', 'images/event_back_mobile_red.png');
                break;
            case "RegisterScene":
                this.nextScene = new RegisterScene();
                this.load.atlas('RegisterAtlas_red', 'images/register_atlas_red.png', 'images/register_atlas_red.json');
                break;
            case "HoldemScene":
                this.nextScene = new HoldemScene();
                this.load.image('HoldemBackground_pc_red', 'images/holdem_background_pc_red.png');
                this.load.image('HoldemBackground_mobile_red', 'images/holdem_background_mobile_red.png');
                this.load.image('HoldemShowDownBack_pc_red', 'images/holdem_showdown_back_pc_red.png');
                this.load.image('HoldemShowDownBack_mobile_red', 'images/holdem_showdown_back_mobile_red.png');
                this.load.atlas('HoldemAtlas_pc_red', 'images/holdem_atlas_pc_red.png', 'images/holdem_atlas_pc_red.json');
                this.load.atlas('HoldemAtlas_mobile_red', 'images/holdem_atlas_mobile_red.png', 'images/holdem_atlas_mobile_red.json');
                this.load.atlas('HoldemDefaultCardAtlas_red', 'images/holdem_default_card_atlas_red.png', 'images/holdem_default_card_atlas_red.json');
                this.load.atlas('EmoticonAtlas1', 'images/emoticon_atlas_1.png', 'images/emoticon_atlas_1.json');
                this.load.atlas('EmoticonAtlas2', 'images/emoticon_atlas_2.png', 'images/emoticon_atlas_2.json');
                this.load.atlas('EmoticonAtlas3', 'images/emoticon_atlas_3.png', 'images/emoticon_atlas_3.json');
                this.load.atlas('EmoticonAtlas4', 'images/emoticon_atlas_4.png', 'images/emoticon_atlas_4.json');
                for(let i = 0 ; i < HoldemDef.Sounds.length ; i ++) {
                    this.load.audio(HoldemDef.Sounds[i].key, HoldemDef.Sounds[i].file);
                }
                break;
            case "OmahaScene":
                this.nextScene = new OmahaScene();
                this.load.image('HoldemBackground_pc_red', 'images/holdem_background_pc_red.png');
                this.load.image('HoldemBackground_mobile_red', 'images/holdem_background_mobile_red.png');
                this.load.image('HoldemShowDownBack_pc_red', 'images/holdem_showdown_back_pc_red.png');
                this.load.image('HoldemShowDownBack_mobile_red', 'images/holdem_showdown_back_mobile_red.png');
                this.load.atlas('HoldemAtlas_pc_red', 'images/holdem_atlas_pc_red.png', 'images/holdem_atlas_pc_red.json');
                this.load.atlas('HoldemAtlas_mobile_red', 'images/holdem_atlas_mobile_red.png', 'images/holdem_atlas_mobile_red.json');
                this.load.atlas('HoldemDefaultCardAtlas_red', 'images/holdem_default_card_atlas_red.png', 'images/holdem_default_card_atlas_red.json');
                this.load.atlas('EmoticonAtlas1', 'images/emoticon_atlas_1.png', 'images/emoticon_atlas_1.json');
                this.load.atlas('EmoticonAtlas2', 'images/emoticon_atlas_2.png', 'images/emoticon_atlas_2.json');
                this.load.atlas('EmoticonAtlas3', 'images/emoticon_atlas_3.png', 'images/emoticon_atlas_3.json');
                this.load.atlas('EmoticonAtlas4', 'images/emoticon_atlas_4.png', 'images/emoticon_atlas_4.json');
                for(let i = 0 ; i < HoldemDef.Sounds.length ; i ++) {
                    this.load.audio(HoldemDef.Sounds[i].key, HoldemDef.Sounds[i].file);
                }
                break;
            case "TournamentHoldemScene":
                this.nextScene = new TournamentHoldemScene();
                this.load.image('HoldemBackground_pc_red', 'images/holdem_background_pc_red.png');
                this.load.image('HoldemBackground_mobile_red', 'images/holdem_background_mobile_red.png');
                this.load.image('HoldemShowDownBack_pc_red', 'images/holdem_showdown_back_pc_red.png');
                this.load.image('HoldemShowDownBack_mobile_red', 'images/holdem_showdown_back_mobile_red.png');
                this.load.image('RankWinnerFlair', 'images/rank_winner_flair.png');
                this.load.atlas('HoldemAtlas_pc_red', 'images/holdem_atlas_pc_red.png', 'images/holdem_atlas_pc_red.json');
                this.load.atlas('HoldemAtlas_mobile_red', 'images/holdem_atlas_mobile_red.png', 'images/holdem_atlas_mobile_red.json');
                this.load.atlas('HoldemDefaultCardAtlas_red', 'images/holdem_default_card_atlas_red.png', 'images/holdem_default_card_atlas_red.json');
                this.load.atlas('EmoticonAtlas1', 'images/emoticon_atlas_1.png', 'images/emoticon_atlas_1.json');
                this.load.atlas('EmoticonAtlas2', 'images/emoticon_atlas_2.png', 'images/emoticon_atlas_2.json');
                this.load.atlas('EmoticonAtlas3', 'images/emoticon_atlas_3.png', 'images/emoticon_atlas_3.json');
                this.load.atlas('EmoticonAtlas4', 'images/emoticon_atlas_4.png', 'images/emoticon_atlas_4.json');
                for(let i = 0 ; i < TournamentHoldemDef.Sounds.length ; i ++) {
                    this.load.audio(TournamentHoldemDef.Sounds[i].key, TournamentHoldemDef.Sounds[i].file);
                }
                break;
        }
        
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.LoginAtlasName = 'LoginAtlas_' + this.tema;
        this.progress = {
            pc: {
                x: 700,
                y: 800,
                width: 520,
                height: 30
            },
            mobile: {
                x: 125,
                y: 1000,
                width: 500,
                height: 30
            }
        }
        this.initGraph();
        this.load.on("progress", (percent) => {
            if(this.progressBar) {
                this.progressBar.clear();
                this.progressBar.fillStyle(0xed470b, 1);
                this.progressBar.fillRoundedRect(0, 0, this.progress[this.orientation].width * percent, this.progress[this.orientation].height, this.progress[this.orientation].height / 2);
            }
            this.progressText && this.progressText.setText(Math.round(percent * 100) + "%");
        });
    }

    create(){
        if(this.nextSceneKey == "LoginScene") {
            this.pingToServer();
        } else {
            this.goToNextScene();
        }
    }

    initGraph() {
        // if(this.backContainer) {
        //     this.backContainer.removeAll(true);
        // } else {
            this.backContainer = this.add.container(0,0);
        // }
        this.back = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "LoginBackground_" + this.orientation).setOrigin(0.5, 0.5);
        this.backContainer.add(this.back);
        this.card_left = this.add.sprite(this.ScreenWidth/2 + LoginDef.card.x[0], LoginDef.logo_position_y[this.orientation] + LoginDef.card.y[0], this.LoginAtlasName, "card_left.png").setOrigin(0, 0);
        this.backContainer.add(this.card_left);
        this.card_right = this.add.sprite(this.ScreenWidth/2 + LoginDef.card.x[1], LoginDef.logo_position_y[this.orientation] + LoginDef.card.y[1], this.LoginAtlasName, "card_right.png").setOrigin(1, 0);
        this.backContainer.add(this.card_right);
        this.logo = this.add.sprite(this.ScreenWidth/2, LoginDef.logo_position_y[this.orientation], this.LoginAtlasName, 'logo.png');
        this.logo.setOrigin(0.5, 0.5);
        this.logo.setScale(LoginDef.logo_scale[this.orientation]);
        this.backContainer.add(this.logo);
    
        this.progressBack = this.add.graphics({
            x: this.progress[this.orientation].x,
            y: this.progress[this.orientation].y,
            fillStyle: {
                color: 0x222222,
                alpha: 1
            }
        });
        this.progressBack.fillRoundedRect(0, 0, this.progress[this.orientation].width, this.progress[this.orientation].height, this.progress[this.orientation].height / 2);
        this.backContainer.add(this.progressBack);

        this.progressBar = this.add.graphics({
            x: this.progress[this.orientation].x,
            y: this.progress[this.orientation].y
        });

        this.progressText = TextClass.plainText1(this, {
            x: this.ScreenWidth / 2,
            y: this.progress[this.orientation].y - 10,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 28,
            color: "#ffffff"
        }).setOrigin(0.5, 1);
        this.backContainer.add(this.progressText);

        this.connectErrorDlg = new ConnectErrorDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 300 : 400,
            atlas: this.LoginAtlasName
        });
        this.backContainer.add(this.connectErrorDlg);
        this.connectErrorDlg.close();

        this.versionCheckDialog = new VersionCheckDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 300 : 400,
            atlas: this.LoginAtlasName
        })
        this.backContainer.add(this.versionCheckDialog);
        this.versionCheckDialog.close();

        this.connectLimitDlg = new ConnectLimitDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 300 : 400,
            atlas: this.LoginAtlasName
        });
        this.backContainer.add(this.connectLimitDlg);
        this.connectLimitDlg.close();
    }

    pingToServer() {
        this.goToNextScene();
        return;
        try {
            $.ajax({
                url: CommonDef.APIServer + "/shine/setting",
                method: "GET",
                success: (response) => {
                    CommonDef.ServerMaintain = response.check;
                    CommonDef.SignupCode = response.code;
                    if(CommonDef.Version != response.version) {
                        this.versionCheckDialog.setVisible(true);
                    } else {
                        this.goToNextScene();
                    }
                },
                error: (error) => {
                    this.connectLimitDlg.setVisible(true);
                    this.connectLimitDlg.initState();
                }
            })
        } catch (e) {
            this.connectErrorDlg.setVisible(true);
        }
    }

    goToNextScene() {
        this.scene.remove(this.nextSceneKey);
        this.nextScene && this.scene.add(this.nextSceneKey, this.nextScene, true);
        this.scene.remove("LoadingScene");
    }
}