import { ScrollView } from "../container/scrollview";
import { TextClass } from "../text/text";

export class DropDown extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.value = null;
    }

    initGraph() {
        

        this.dropdownContainer = this.scene.add.container(0, 0);
        this.add(this.dropdownContainer);

        this.backGraphics = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x0000ff,
                alpha: 0.3
            }
        });
        
        this.backGraphics_action_rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.dropdownContainer.add(this.backGraphics);
        this.backGraphics.setInteractive(this.backGraphics_action_rect, Phaser.Geom.Rectangle.Contains);
        this.backGraphics.on("pointerdown", () => {
            this.activeDropDown(false);
        });


        if(this.config.dropdown_back) {
            this.dropdown_back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.dropdown_back);
            this.dropdown_back.setOrigin(0, 0);
            this.dropdownContainer.add(this.dropdown_back);
        } else {
            this.dropdown_back = this.scene.add.graphics({
                x: 7 * (this.config.scale ? this.config.scale[0] : 1),
                y: 0,
                fillStyle: {
                    color: 0x111111,
                    alpha: 1
                }
            });
            this.dropdown_back.fillRoundedRect(0, 0, this.config.width, this.config.height, 3);
            this.dropdownContainer.add(this.dropdown_back);
        }
        this.dropdownContainer.setVisible(false);

        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.normal.back);
        this.back.setOrigin(0, 0);
        this.add(this.back);
        if(this.config.scale) {
            this.back.setScale(this.config.scale[0], this.config.scale[1]);
        }

        this.arrow = this.scene.add.sprite(this.config.normal.arrow.x * (this.config.scale ? this.config.scale[0] : 1), this.config.normal.arrow.y, this.config.atlas, this.config.normal.arrow.texture);
        this.arrow.setOrigin(0, 0);
        this.add(this.arrow);

        this.dropdownContainer.add(this.config.dropdown_content)

        this.displayText = TextClass.plainText1(this.scene, {
            x: this.config.displayText.x,
            y: this.config.displayText.y,
            text: this.config.placeholder,
            color: this.config.displayText.color,
            gradient: this.config.displayText.gradient,
            fontFamily: this.config.displayText.fontFamily,
            fontSize: this.config.displayText.fontSize
        }).setOrigin(0, 0);
        this.add(this.displayText);

        this.action_area = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, this.back.width * (this.config.scale ? this.config.scale[0] : 1), this.back.height * (this.config.scale ? this.config.scale[1] : 1));
        this.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.action_area.on("pointerdown", () => {
            this.activeDropDown(!this.dropdownContainer.visible);
        });

        this.action_area.on("pointerover", () => {
            if(this.config.hover && this.config.hover.back) this.back.setTexture(this.config.atlas, this.config.hover.back);
            if(this.config.hover && this.config.hover.arrow) this.arrow.setTexture(this.config.atlas, this.config.hover.arrow.texture);
        });

        this.action_area.on("pointerout", () => {
            this.back.setTexture(this.config.atlas, this.config.normal.back);
            this.arrow.setTexture(this.config.atlas, this.config.normal.arrow.texture);
        })
    }

    activeDropDown(flag) {
        this.dropdownContainer.setVisible(flag)
        if(flag) {
            if(this.config.clicked && this.config.clicked.back) this.back.setTexture(this.config.atlas, this.config.clicked.back);
            if(this.config.clicked && this.config.clicked.arrow) this.arrow.setTexture(this.config.atlas, this.config.clicked.arrow.texture);
            let x = 0;
            let y = 0;
            let container = this;
            while(container.parentContainer) {
                x += container.x;
                y += container.y;
                container = container.parentContainer;
            }
            this.backGraphics_action_rect.x = -x;
            this.backGraphics_action_rect.y = -y;
        } /* else {
            if(this.config.hover && this.config.hover.back) this.back.setTexture(this.config.atlas, this.config.hover.back);
            if(this.config.hover && this.config.hover.arrow) this.arrow.setTexture(this.config.atlas, this.config.hover.arrow.texture);
        } */
    }

    setValue(id) {
        this.value = id;
    }

    setDisplayText(text) {
        this.displayText.setText(text);
    }
}