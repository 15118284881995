import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { InputField } from "../../../components/input/input";
import { TextBox } from "../../../components/input/textbox";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class CustomerQuestionDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.waitingResponse = false;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.lineStyle(1, 0x222222, 1);
        this.backGraphic.fillRect(0, 0, this.scene.orientation == "pc" ? 1010 : 674, this.scene.orientation == "pc" ? 570 : 1190);
        this.backGraphic.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 1010 : 674, this.scene.orientation == "pc" ? 520 : 1140, 10);
        this.add(this.backGraphic);
        let rect = new Phaser.Geom.Rectangle(0, 60, this.scene.orientation == "pc" ? 1010 : 674, this.scene.orientation == "pc" ? 520 : 1140);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 935 : 595,
            y: 24,
            origin: [0, 0],
            scale: 0.9,
            atlas: "DialogAtlas",
            normal: "close_red.png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.questionText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 23 : 25,
            text: translate('question'),
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ["#ffe0cf", "#ffffff"]
        }).setOrigin(0, 0);
        this.add(this.questionText);

        this.titleField = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 75 : 90,
            atlas: this.config.atlas,
            back: "customer_input_title_" + this.scene.orientation +".png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 488 : 80,
                    y: this.scene.orientation == "pc" ? 384 : 241,
                },
                width: this.scene.orientation == "pc" ? 870 : 550,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: translate('question_title_placeholder'),
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.titleField);

        this.questionField = new TextBox(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 155 : 180,
            atlas: this.config.atlas,
            back: "customer_input_content_" + this.scene.orientation +".png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 488 : 80,
                    y: this.scene.orientation == "pc" ? 464 : 331,
                },
                width: this.scene.orientation == "pc" ? 890 : 550,
                height: this.scene.orientation == "pc" ? 190 : 780,
                placeholder: translate('question_content_placeholder'),
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.questionField);

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 400 : 250,
            y: this.scene.orientation == "pc" ? 420 : 1033,
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: translate('customer_confirm'),
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff']
            },
            onClicked: this.onClickConfirm.bind(this)
        });
        this.add(this.btn_confirm);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onClickConfirm() {
        if(this.waitingResponse) return;
        this.waitingResponse = true;
        this.scene.requestCreateNewQuestion(this.titleField.value, this.questionField.value);
    }
}