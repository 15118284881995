import { TextClass } from "../text/text";

export class MobileDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        
        this.back = this.scene.add.graphics({
            x: 0, 
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.back.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.back);
        this.title_back = this.scene.add.sprite(this.scene.ScreenWidth / 2, 0, "DialogAtlas", "title_back_mobile_" + this.scene.tema + ".png");
        this.title_back.setOrigin(0.5, 0);
        this.add(this.title_back);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: 35,
            gradient: this.scene.tema == "red" ? ['#ffe1d0', '#ffffff'] : ['#0000ff', '#ffffff'],
            fontSize: 34,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            text: this.config.title
        }).setOrigin(0.5, 0);
        this.add(this.title);

        this.btn_close = this.scene.add.sprite(695, 35, "DialogAtlas", "close_" + this.scene.tema + ".png");
        this.btn_close.setOrigin(0.5, 0);
        this.add(this.btn_close);

        this.btn_close.setInteractive();
        this.btn_close.on("pointerup", () => {
            this.config.onClickClose();
        })
    }
} 