import { IconButton } from "../../../../components/button/iconbutton";
import { TextClass } from "../../../../components/text/text";
import { translate } from "../../../../util/other";

export class EventItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let back = this.scene.add.sprite(0, 0, this.config.atlas, "event_item_back.png").setOrigin(0.5, 0);
        this.add(back);
        if(this.config.day == 7) {
            let under_light = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 208 : 238, this.config.atlas, "event_item_under_light.png").setOrigin(0.5, 1);
            this.add(under_light);
            let top_light = this.scene.add.sprite(0, 0, this.config.atlas, "card_back_light.png").setOrigin(0.5, 0.5);
            this.add(top_light);
            top_light.setScale(0.4)
        }
        let day = TextClass.plainText1(this.scene, {
            x: 0,
            y: 20,
            text: "Day " + this.config.day,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0.5, 0);
        this.add(day);

        let name = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == 'pc' ? 59 : 56,
            text: this.config.name,
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 18 : 18,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.add(name)

        let icon = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 115 : 128, "ShopAtlas_" + this.scene.tema, this.config.icon).setOrigin(0.5, 0.5);
        this.add(icon);
        icon.setScale(this.config.scale * (this.scene.orientation == "pc" ? 0.7 : 1));

        let btn = new IconButton(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 152 : 182,
            origin: [0.5, 0],
            atlas: this.config.atlas,
            normal: {
                back: 'btn_accept_back_normal.png'
            },
            hover: {
                back: 'btn_accept_back_hover.png'
            },
            clicked: {
                back: 'btn_accept_back_clicked.png'
            },
            disabled: {
                back: 'btn_accept_back_disabled.png'
            },
            text: {
                x: 0,
                x_pos: true,
                y: this.scene.orientation == "pc" ? 6 : 8,
                origin: [0.5, 0],
                value: translate(!this.config.accepted ? 'accept' : 'accepted'),
                fontSize: 20,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: () => {
                this.config.onAccept(this.config)
            }
        });
        if(!this.config.pass || this.config.accepted) btn.setDisable(true);
        this.add(btn);
    }
}