import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TabContainer } from "../../../components/container/tabcontainer";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { TabHistory } from "./tab_history";
import { TabInventory } from "./tab_inventory";
import { TabPassword } from "./tab_password";
import { TabProfile } from "./tab_profile";
import CommonDef from '../../../def/commondef';
import { translate } from "../../../util/other";

export class MyPageDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.tabContainer = new TabContainer(this.scene, {
            x: this.scene.orientation == "pc" ? -470 : 80,
            y: this.scene.orientation == "pc" ? 140 : 170,
            atlas: this.config.atlas,
            tabs: [
                {
                    x: 0,
                    y: 0,
                    text: translate('profile'),
                    content: new TabProfile(this.scene, {
                        x: 0,
                        y: 0,
                        atlas: this.config.atlas
                    })
                },
                {
                    x: this.scene.orientation == "pc" ? 169 : 137,
                    y: 0,
                    text: translate('my_history'),
                    content: new TabHistory(this.scene, {
                        x: 0,
                        y: 0,
                        atlas: this.config.atlas
                    })
                },
                {
                    x: this.scene.orientation == "pc" ? 339 : 283,
                    y: 0,
                    text: translate('inventory'),
                    content: new TabInventory(this.scene, {
                        x: 0,
                        y: 0,
                        atlas: this.config.atlas
                    })
                },
                {
                    x: this.scene.orientation == "pc" ? 508 : 429,
                    y: 0,
                    text: translate('password'),
                    content: new TabPassword(this.scene, {
                        x: 0,
                        y: 0,
                        atlas: this.config.atlas
                    })
                }
            ],
            tab_line: {
                x: 0,
                y: this.scene.orientation == "pc" ? 49 : 54,
                texture: 'tab_line_' + this.scene.orientation + '.png'
            },
            scale: this.scene.orientation == "pc" ? [1, 1] : [1.3, 0.92],
            tabChanged: this.onChangeTab.bind(this)
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.tabContainer);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 440,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        // let index = CommonDef.Dialog.WaitingRoomScene.findIndex(x => x.key == "mypage")
        // CommonDef.Dialog.WaitingRoomScene.splice(index, 1);
        // this.destroy();
        this.setVisible(false);
    }


    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    setTab(tabIndex) {
        this.tabContainer.setTab(tabIndex);
    }

    onChangeTab(tabIndex) {
        if(tabIndex == 0) { // 프로필 탭
            this.scene.requestBorderList();
        } else if(tabIndex == 1) { // 나의 전적 탭

        } else if(tabIndex == 2) { // 인벤토리 탭
            this.scene.requestInventory();
        } else if(tabIndex == 3) { // 비밀번호 변경 탭

        }
    }

    setHistoryData(type, profile) {
        this.tabContainer.tabs[1].content.setHistoryData(type, profile);
        // else 
    }

    setTournamentList(tournamentList) {
        this.tabContainer.tabs[2].content.setTournamentList(tournamentList);
    }
}