import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TextBox } from "../../../components/input/textbox";
import { TextClass } from "../../../components/text/text";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class ReportDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: "message_box_back.png",
            light: "card_light.png",
            scale: [1.2, this.config.yScale],
            title: {
                text: translate('report'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 200,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
        let warningMsg = {
            profile: translate('profile_report_warning'),
            message: translate('chat_report_warning')
        }
        if(this.config.type == CommonDef.reportType.ProfileReport) {
            this.reason_textarea = new TextBox(this.scene, {
                x: -240,
                y: 110,
                atlas: this.config.atlas,
                input: {
                    x: 0,
                    y: 0,
                    absolute: {
                        x: this.scene.ScreenWidth / 2 - 240,
                        y: this.config.y + 110,
                    },
                    width: 480,
                    height: 200,
                    placeholder: warningMsg.profile,
                    color: "#777777",
                    fontFamily: "GmarketSansLight",
                    fontSize: 24
                }
            });
            this.cardContainer.add(this.reason_textarea);
        }
        if(this.config.type == CommonDef.reportType.MessageReport) {
            this.warningIcon = this.scene.add.sprite(-190, 160, this.config.atlas, "warning.png").setOrigin(0.5, 0.5);
            this.cardContainer.add(this.warningIcon);
            this.warningIcon.setScale(0.8);
            
            this.warningText = TextClass.plainText1(this.scene, {
                x: -120,
                y: 160,
                text: warningMsg.message,
                fontFamily: "GmarketSansLight",
                fontSize: 18,
                color: "#f66d01"
            }).setOrigin(0, 0.5);
            this.warningText.setStyle({
                wordWrap: { width: 350, useAdvancedWrap: true }
            });
            this.warningText.setLineSpacing(10);
            this.cardContainer.add(this.warningText);

            this.sureText = TextClass.plainText1(this.scene, {
                x: 0,
                y: 270,
                text: translate('report_confirm'),
                fontFamily: "GmarketSansLight",
                fontSize: 24,
                color: "#dddddd"
            }).setOrigin(0.5, 0.5);
            this.cardContainer.add(this.sureText);
        }
        this.btn_cancel = new IconButton(this.scene, {
            x: -180,
            y: 330,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png',
            },
            hover: {
                back: 'btn_cancel_back_hover.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png'
            },
            scale: [0.8, 0.8],
            text: {
                x: 58,
                y: 10,
                value: translate('cancel'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_cancel);

        this.btn_ok = new IconButton(this.scene, {
            x: 0,
            y: 330,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_ok_back_normal.png',
            },
            hover: {
                back: 'btn_ok_back_hover.png'
            },
            clicked: {
                back: 'btn_ok_back_clicked.png'
            },
            scale: [0.8, 0.8],
            text: {
                x: 58,
                y: 10,
                value: translate('report'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnOk.bind(this)
        });
        this.cardContainer.add(this.btn_ok);
    }

    close() {
        this.destroy();
    }

    onClickedBtnOk() {
        if(this.config.type == CommonDef.reportType.ProfileReport && !this.reason_textarea.value) {
            this.scene.showToast("failed", translate('report_empty_error'));
            return;
        }
        this.scene.requestReport(this.config.type == CommonDef.reportType.ProfileReport ? this.reason_textarea.value : this.config.message, this.config.userid, this.config.type, this.config.message_index);
    }
}