import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { translate } from "../../../util/other";

export class ShopItemDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.fillRect(0, -10, this.scene.orientation == "pc" ? 1000 : 600, this.scene.orientation == "pc" ? 540 : 1140);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 530 : 1110);
        this.add(this.backGraphic);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.mainContainer = this.scene.add.container(this.scene.orientation == "pc" ? 300 : 0, this.scene.orientation == "pc" ? 32 : 0);
        this.add(this.mainContainer);
        this.border = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.border.lineStyle(1, 0x222222, 1);
        this.border.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 400 : 590, this.scene.orientation == "pc" ? 460 : 475, 10);
        this.mainContainer.add(this.border);
        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 23 : 24,
            text: translate('buy_item'),
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.mainContainer.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 340 : 525,
            y: this.scene.orientation == "pc" ? 21 : 22,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_close);

        this.icon = this.scene.add.sprite(this.scene.orientation == "pc" ? 200 : 295, 154, this.config.atlas, this.config.icon);
        this.icon.setOrigin(0.5, 0.5);
        this.mainContainer.add(this.icon);
        this.icon.setScale(1.2);

        let text_start_posX = this.scene.orientation == "pc" ? 60 : 160
        let askConfirmMsgs = translate('ask_buy_item').split("\n");
        for(let i = 0 ; i < askConfirmMsgs.length ; i ++) {
            let msg = askConfirmMsgs[i];
            let y = i == 0 ? 230 : 265;
            let index1 = msg.indexOf('%1');
            let index2 = msg.indexOf('%2');
            console.log(i, index1, index2)
            if(index1 > -1) {
                this.description1 = TextClass.plainText1(this.scene, {
                    x: text_start_posX,
                    y: y,
                    text: msg.substring(0, index1),
                    fontSize: 20,
                    color: "#777777"
                }).setOrigin(0, 0);
                this.mainContainer.add(this.description1);
                this.description2 = TextClass.plainText1(this.scene, {
                    x: text_start_posX + this.description1.width + (index1 == 0 ? 0 : 8),
                    y: y,
                    text: this.config.title,
                    fontSize: 20,
                    color: "#ffffff"
                }).setOrigin(0, 0);
                this.mainContainer.add(this.description2);
                this.description3 = TextClass.plainText1(this.scene, {
                    x: text_start_posX + this.description1.width + this.description2.width + 10,
                    y: y,
                    text: msg.substring(index1 + 2, index2),
                    fontSize: 20,
                    color: "#777777"
                }).setOrigin(0, 0);
                this.mainContainer.add(this.description3);
                this.description4 = TextClass.plainText1(this.scene, {
                    x: text_start_posX + this.description1.width + this.description2.width + this.description3.width + 18,
                    y: y,
                    text: NumberUtil.convertNumberWithComma(this.config.price) + "php",
                    fontSize: 20,
                    color: "#ffffff"
                }).setOrigin(0, 0);
                this.mainContainer.add(this.description4);
                this.description5 = TextClass.plainText1(this.scene, {
                    x: text_start_posX + this.description1.width + this.description2.width + this.description3.width + this.description4.width + 20,
                    y: y,
                    text: msg.substring(index2 + 2),
                    fontSize: 20,
                    color: "#777777"
                }).setOrigin(0, 0);
                this.mainContainer.add(this.description5);
            } else {
                let description = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 200 : 295,
                    y: y,
                    text: msg,
                    fontSize: 20,
                    color: "#777777"
                }).setOrigin(0.5, 0);
                this.mainContainer.add(description);
            }
        }
        

        let availableText = "";
        let available = this.config.availableCount == 0 ? this.config.availableDays : this.config.availableCount;
        if(available < 0) availableText = `(${translate('available_forever')})`;
        else {
            availableText = `(${translate(this.config.availableCount == 0 ? 'available_days' : 'available_count')})`.replace('%1', this.config.availableCount == 0 ? this.config.availableDays : this.config.availableCount);
        }
        this.countText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 200 : 295,
            y: 300,
            text: availableText,
            fontSize: 20,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.mainContainer.add(this.countText);

        this.btn_buy = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 103 : 196,
            y: this.scene.orientation == "pc" ? 364 : 370,
            atlas: this.config.atlas,
            normal: {
                back: "btn_buy_back_normal.png"
            },
            hover: {
                back: "btn_buy_back_hover.png"
            },
            clicked: {
                back: "btn_buy_back_clicked.png"
            },
            scale: [1.38, 1.52],
            text: {
                x: 70 * 1.38,
                y: 10 * 1.52,
                value: NumberUtil.convertNumberWithComma(this.config.price) + "php",
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: () => {
                this.config.onClickBuy(this.config.id);
            }
        });
        this.mainContainer.add(this.btn_buy);
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        this.scene.tweens.add({
            targets: this.mainContainer,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.mainContainer.x + (this.scene.orientation == "pc" ? 200 : 290),
                to: this.mainContainer.x
            },
            y: {
                from: 60,
                to: this.mainContainer.y
            },
            duration: 150,
            ease: "Back"
        })
    }
}