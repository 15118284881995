var PacketBuffer = require('./net/PacketBuffer')
var ConnectionMgr = require('./ConnectionMgr')
export class ProtocolEncoder {
    constructor(__messageCode, __messageLen) {
        this._messageCode = __messageCode
        this._messageLen = __messageLen
        this._buf = []
        this._pos = 1
        this._metaData = ""
        this._bodyData = []
        this._gameid = 0
        return true
    };
    //SetGameId = function (__gameId) {
    SetGameId (__gameId) {
        this._gameid = __gameId
    };
    //WriteWORD = function (__offset, __word) {
    WriteWORD (__offset, __word) {
        this._metaData = this._metaData + "W"
        this._bodyData.push(__word)
    };
    //WriteString = function (__offset, __string) {
    WriteString (__offset, __string) {
        this._metaData = this._metaData + "S"
        this._bodyData.push(__string)
    };
    //WriteShort = function (__offset, __short) {
    WriteShort (__offset, __short) {
        this._metaData = this._metaData + "s"
        this._bodyData.push(__short)
    };
    //WriteInt = function (__offset, __int) {
    WriteInt (__offset, __int) {
        this._metaData = this._metaData + "r"
        this._bodyData.push(__int)
    };
    //WriteInt64 = function (__offset, __int64) {
    WriteInt64 (__offset, __int64) {
        this._metaData = this._metaData + "l"
        this._bodyData.push(__int64)
    };
    //WriteFloat = function (__offset, __float) {
    WriteFloat (__offset, __float) {
        this._metaData = this._metaData + "f"
        this._bodyData.push(__float)
    };
    //WriteDouble = function (__offset, __double) {
    WriteDouble (__offset, __double) {
        this._metaData = this._metaData + "D"
        this._bodyData.push(__double)
    };
    //WriteBool = function (__bool) {
    WriteBool (__bool) {
        this._metaData = this._metaData + "B"
        this._bodyData.push(__bool)
    };
    //WriteBYTE = function (__byte) {
    WriteBYTE (__byte) {
        this._metaData = this._metaData + "b"
        this._bodyData.push(__byte)
    };
    //getByteArray = function () {
    getByteArray () {
        var __buf = new ByteArray(ByteArray.ENDIAN_LITTLE)
        return __buf
    };
    //SendData = function () {
    SendData () {
        var __buf = PacketBuffer.create().createPacket(this._messageCode, this._metaData,this._messageLen, this._bodyData)
        var arrData = new Uint8Array(__buf.length)
        for (var i = 0; i < __buf.length; ++i) {
            arrData[i] = __buf[i];
        }
        try {
            return ConnectionMgr.ConnectionMgr.create().send(arrData.buffer)
        } catch (error) {
            // console.log("소켓이 연결되지 않았는데 요청을 보내고 있어요", this._messageCode);
            return;
        }
    }
}

ProtocolEncoder.create = function (__messageCode, __messageLen) {
    let encoder = new ProtocolEncoder(__messageCode, __messageLen)
    return encoder
}
// module.exports = ProtocolEncoder
// export default ProtocolEncoder