export const convertShotDateWithDot = (timestamp) => {
    let date = new Date(timestamp);
    let str = "";
    str += date.getFullYear() + ".";
    str += convert2Digits(date.getMonth() + 1) + ".";
    str += convert2Digits(date.getDate());
    return str;
}
export const convertShortDate = (timestamp) => {
    let date = new Date(timestamp);
    let str = "";
    str += date.getFullYear() + "-";
    str += convert2Digits(date.getMonth() + 1) + "-";
    str += convert2Digits(date.getDate());
    return str;
}

export const convertShotTime = (timestamp) => {
    let date = new Date(timestamp);
    let str = "";
    str += convert2Digits(date.getHours()) + ":";
    str += convert2Digits(date.getMinutes());
    return str;
}

export const getHoldemTime = () => {
    let date = new Date();
    let str = "";
    str += date.getFullYear() + "-";
    str += convert2Digits(date.getMonth() + 1) + "-";
    str += convert2Digits(date.getDate()) + " ";
    let hours = date.getHours();
    str += hours >= 12 ? "PM" : "AM";
    if(hours > 12) hours %= 12;
    str += " " + convert2Digits(hours) + ":";
    str += convert2Digits(date.getMinutes()) + ":";
    str += convert2Digits(date.getSeconds());
    return str;
}

export const convertDefaultDateTime = (timestamp) => {
    let date = new Date(timestamp);
    let str = "";
    str += date.getFullYear() + "-";
    str += convert2Digits(date.getMonth() + 1) + "-";
    str += convert2Digits(date.getDate());
    let hours = date.getHours();
    str += " " + convert2Digits(hours) + ":";
    str += convert2Digits(date.getMinutes()) + ":";
    str += convert2Digits(date.getSeconds());
    return str;
}

export const convertToTournamentStartTime = (timestamp) => {
    let date = new Date(timestamp);
    let str = "";
    str += convert2Digits(date.getMonth() + 1) + "/" + convert2Digits(date.getDate()) + " ";
    str += convert2Digits(date.getHours()) + ":" + convert2Digits(date.getMinutes());
    return str;
}

export const convertToLeftTime = (number, hourFlag = true) => {
    if(!number || number < 0) return "";
    let str = "";
    number = Math.floor(number / 1000);
    let hours = Math.floor(number / 3600);
    if(hours < 10) hours = "0" + hours;
    hourFlag && (str += hours + ":");
    number %= 3600;
    let mins = Math.floor(number / 60);
    if(mins < 10) mins = "0" + mins;
    str += mins + ":";
    number %= 60;
    let seconds = number % 60;
    if(seconds < 10) seconds = "0" + seconds;
    str += seconds;
    return str;
}

const convert2Digits = (value) => {
    let str = "00" + value.toString();
    return str.substring(str.length - 2);
}