import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { SliderBar } from "../../../components/slider/sliderbar";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";

export class RaiseDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.range = [500, 90000];
        this.pot = 5600;
        this.type = 0;
        this.amount = 0;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "raise_dlg_back.png").setOrigin(0, 0);
        this.add(this.back);
        this.back.setInteractive();
        this.scene.orientation == "mobile" && this.back.setAlpha(0.7);

        let title = TextClass.plainText1(this.scene, {
            x: 40,
            y: 25,
            text: "RAISE",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 30,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.add(title);

        this.btn_close = new SpriteButton(this.scene, {
            x: 680,
            y: 25,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        let amount_list = ['half', 'full', 'allin'];
        let raise_amount_button_position = {
            x: [34, 264, 494],
            y: 96
        }
        let icon_position = {
            x: [65, 44, 37],
            y: [32, 16, 16]
        }
        for(let i = 0 ; i < amount_list.length ; i ++) {
            let btn = new IconButton(this.scene, {
                x: raise_amount_button_position.x[i],
                y: raise_amount_button_position.y,
                atlas: this.config.atlas,
                normal: {
                    back: 'btn_raise_amount_back_normal.png',
                    icon: 'icon_' + amount_list[i] + '.png',
                    icon_position: {
                        x: icon_position.x[i],
                        y: icon_position.y[i]
                    }
                },
                hover: {
                    back: 'btn_raise_amount_back_hover.png',
                },
                clicked: {
                    back: 'btn_raise_amount_back_clicked.png',
                },
                text: {
                    x: 110,
                    x_pos: true,
                    y: 95,
                    value: amount_list[i].toUpperCase(),
                    fontSize: 30,
                    gradient: ['#d7cfcf', '#ffffff'],
                    origin: [0.5, 0]
                },
                onClicked: () => {
                    this.onClickRaiseAmount(amount_list[i])
                }
            });
            this.add(btn);
        }

        this.btn_minus = new SpriteButton(this.scene, {
            x: 65,
            y: 280,
            atlas: this.config.atlas,
            normal: "minus_normal.png",
            hover: "minus_hover.png",
            clicked: "minus_clicked.png",
            onClicked: this.onClickedMinus.bind(this)
        });
        this.add(this.btn_minus);

        this.btn_plus = new SpriteButton(this.scene, {
            x: 685,
            y: 280,
            atlas: this.config.atlas,
            normal: "plus_normal.png",
            hover: "plus_hover.png",
            clicked: "plus_clicked.png",
            onClicked: this.onClickedPlus.bind(this)
        });
        this.add(this.btn_plus);

        this.raise_amount = TextClass.plainText1(this.scene, {
            x: 380,
            y: 280,
            text: NumberUtil.convertNumberWithComma(0),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 30,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.add(this.raise_amount);

        this.slider = new SliderBar(this.scene, {
            x: 35,
            y: 324,
            absoluteX: this.scene.orientation == "pc" ? 1150 : 40,
            atlas: this.config.atlas,
            width: 675,
            height: 20,
            ruler: true,
            onChange: this.onChangeSlider.bind(this)
        });
        this.add(this.slider);

        this.btn_cancel = new IconButton(this.scene, {
            x: 260,
            y: 423,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png'
            },
            hover: {
                back: 'btn_cancel_back_hover.png',
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png',
            },
            text: {
                x: 110,
                y: 19,
                value: "CANCEL",
                fontSize: 32,
                gradient: ['#d6cece', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_cancel);

        this.btn_ok = new IconButton(this.scene, {
            x: 490,
            y: 423,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_ok_back_normal.png'
            },
            hover: {
                back: 'btn_ok_back_hover.png',
            },
            clicked: {
                back: 'btn_ok_back_clicked.png',
            },
            text: {
                x: 110,
                y: 19,
                value: "OK",
                fontSize: 32,
                gradient: ['#d6cece', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: this.onClickOk.bind(this)
        });
        this.add(this.btn_ok);
    }

    setConfig(data) {
        this.range = data.range;
        this.pot = data.pot;
        this.type = data.type;
        this.amount = this.range[0];
        this.raise_amount.setText(NumberUtil.convertNumberWithComma(this.amount));
        this.slider.setValue(0);
        this.onChangeSlider(0);
    }

    close() {
        this.setVisible(false);
    }

    onClickRaiseAmount(type) {
        let amount = 0;
        switch(type) {
            case "half":
                amount = Math.floor(this.pot / 2) + this.range[0];
                if(amount > this.range[1]) amount = this.range[1];
                break;
            case "full":
                amount = this.pot + this.range[0];
                if(amount > this.range[1]) amount = this.range[1];
                break;
            case "allin":
                amount = this.range[1]
                break;
            default:
                break;
        }
        if(amount > 0) {
            this.raise_amount.setText(NumberUtil.convertNumberWithComma(amount));
            this.slider.setValue((amount - this.range[0]) / (this.range[1] - this.range[0]));
            this.amount = amount;
        }
    }

    onChangeSlider(value) {
        this.amount = Math.floor((this.range[0] + (this.range[1] - this.range[0]) * value))
        this.raise_amount.setText(NumberUtil.convertNumberWithComma(this.amount));
    }

    onClickedMinus() {
        let sliderValue = this.slider.getValue();
        sliderValue -= 0.01;
        if(sliderValue < 0) sliderValue = 0;
        this.slider.setValue(sliderValue);
        this.onChangeSlider(sliderValue);
    }

    onClickedPlus() {
        let sliderValue = this.slider.getValue();
        sliderValue += 0.01;
        if(sliderValue > 1) sliderValue = 1;
        this.slider.setValue(sliderValue);
        this.onChangeSlider(sliderValue);
    }

    onClickOk() {
        if(this.type < 1) {
            return;
        }
        let value = this.slider.getValue();
        this.scene.requestRaise(this.type, this.amount)
    }
}