import { Avatar } from "../../../components/avatar.js/avatar";
import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from '../../../util/number';
import { EditNicknameDialog } from "./edit_nickname";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";
import { AvatarDialog } from "./avatar_dialog";

export class TabProfile extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.uploading = false;
        this.selectedBorderId = -1;
        this.borders = [];
        this.initGraph();
    }

    closeAllChildDialog() {
        this.editNicknameDlg && this.editNicknameDlg.close();
    }

    initGraph() {
        this.avatarContent = this.scene.add.container(this.scene.orientation == "pc" ? 80 : 35, this.scene.orientation == "pc" ? 100 : 102);
        this.add(this.avatarContent);
        
        this.avatar = new Avatar(this.scene, {
            x: 71,
            y: 0,
            atlas: "AvatarAtlas",
            avatar: CommonDef.Me.avatar,
            border: CommonDef.Me.bordericon,
            mask: {
                x: this.scene.orientation == "pc" ? 641 : 185,
                y: this.scene.orientation == "pc" ? 492 : 332
            },
            onClick: this.onClickedPhoto.bind(this)
        });
        this.avatarContent.add(this.avatar);

        this.player_name = TextClass.plainText1(this.scene, {
            x: 72,
            y: this.scene.orientation == "pc" ? 140 : 138,
            text: CommonDef.Me.nickname,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20,
            color: "#d7bba3"
        }).setOrigin(0.5, 0);
        this.avatarContent.add(this.player_name);

        this.btn_edit = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 52 : 47,
            y: 172,
            origin: [0.5, 0],
            atlas: this.config.atlas,
            normal: this.scene.orientation == "pc" ? "btn_edit_normal_pc.png" : "btn_edit_normal_mobile.png",
            hover: this.scene.orientation == "pc" ? "btn_edit_hover_pc.png" : "btn_edit_hover_mobile.png",
            clicked: this.scene.orientation == "pc" ? "btn_edit_clicked_pc.png" : "btn_edit_clicked_mobile.png",
            onClicked: this.onClickedEditNickname.bind(this)
        });
        this.avatarContent.add(this.btn_edit);

        this.btn_photo = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 92 : 97,
            y: 172,
            origin: [0.5, 0],
            atlas: this.config.atlas,
            normal: this.scene.orientation == "pc" ? "btn_photo_normal_pc.png" : "btn_photo_normal_mobile.png",
            hover: this.scene.orientation == "pc" ? "btn_photo_hover_pc.png" : "btn_photo_hover_mobile.png",
            clicked: this.scene.orientation == "pc" ? "btn_photo_clicked_pc.png" : "btn_photo_clicked_mobile.png",
            onClicked: this.onClickedPhoto.bind(this)
        });
        this.avatarContent.add(this.btn_photo);
        
        this.inputTag = document.createElement("input");
        this.inputTag.setAttribute("type", "file");
        this.inputTag.setAttribute("accept", "image/png, image/jpg, image/jpeg");
        this.inputTag.style.display = "none";
        document.body.appendChild(this.inputTag);
        this.inputTag.onchange = _ => {
            this.onChangeAvatar();
        }

        this.textContent = this.scene.add.container(this.scene.orientation == "pc" ? 0 : 232, this.scene.orientation == "pc" ? 342 : 123);
        this.add(this.textContent);

        this.my_money_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: translate('my_money'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.textContent.add(this.my_money_label);

        this.my_money_value = TextClass.plainText1(this.scene, {
            x: 315,
            y: 0,
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.money),
            fontFamily: "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(1, 0);
        this.textContent.add(this.my_money_value);

        this.my_point_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 45,
            text: translate('my_point'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.textContent.add(this.my_point_label);

        this.my_point_value = TextClass.plainText1(this.scene, {
            x: 315,
            y: 45,
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.point),
            fontFamily: "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(1, 0);
        this.textContent.add(this.my_point_value);

        this.divider = this.scene.add.sprite(0, 90, this.config.atlas, "divider.png");
        this.divider.setOrigin(0, 0);
        this.textContent.add(this.divider);

        this.my_today_betting_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 110,
            text: translate('today_betting'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.textContent.add(this.my_today_betting_label);

        this.my_today_betting_value = TextClass.plainText1(this.scene, {
            x: 315,
            y: 110,
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.dailybetting),
            fontFamily: "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(1, 0);
        this.textContent.add(this.my_today_betting_value);

        this.my_total_betting_label = TextClass.plainText1(this.scene, {
            x: 0,
            y: 155,
            text: translate('total_betting'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.textContent.add(this.my_total_betting_label);

        this.my_total_betting_value = TextClass.plainText1(this.scene, {
            x: 315,
            y: 155,
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.totalbetting),
            fontFamily: "GmarketSansMedium",
            fontSize: 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(1, 0);
        this.textContent.add(this.my_total_betting_value);

        this.borderContainer = this.scene.add.container(this.scene.orientation == "pc" ? 380 : 30, this.scene.orientation == "pc" ? 100 : 380);
        this.add(this.borderContainer);
        this.borderContainerBack = this.scene.add.graphics({
            x: 0,
            y: 0
        })
        this.borderContainerBack.fillStyle(0x0e0e0e, 1);
        this.borderContainerBack.lineStyle(1, 0x682e00, 1);
        this.borderContainerBack.fillRoundedRect(0, 0, 534, this.scene.orientation == "pc" ? 345 : 475, 4);
        this.borderContainerBack.strokeRoundedRect(0, 0, 534, this.scene.orientation == "pc" ? 345 : 475, 4);
        this.borderContainer.add(this.borderContainerBack);

        

        this.border_name_back = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 370 : 490, this.config.atlas, "border_name_back.png").setOrigin(0, 0);
        this.borderContainer.add(this.border_name_back);

        this.border_name = TextClass.plainText1(this.scene, {
            x: 182,
            y: this.scene.orientation == "pc" ? 395 : 515,
            text: "",
            fontSize: 26,
            color: "#d7bba3"
        }).setOrigin(0.5, 0.5);
        this.borderContainer.add(this.border_name);

        this.borderList = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: 20,
            width: 510,
            height: this.scene.orientation == "pc" ? 310 : 435,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.borderList,
            mask: {
                x: this.scene.orientation == "pc" ? 890 : 130,
                y: this.scene.orientation == "pc" ? 453 : 573
            },
            action_rect: {
                width: 500,
                height: this.scene.orientation == "pc" ? 310 : 460,
            }
        });
        this.borderContainer.add(this.scrollView);        

        this.btn_ok = new IconButton(this.scene, {
            x: 378,
            y: this.scene.orientation == "pc" ? 370 : 490,
            atlas: this.config.atlas,
            normal: {
                back: "btn_ok_back_normal.png"
            },
            hover: {
                back: "btn_ok_back_hover.png"
            },
            clicked: {
                back: "btn_ok_back_clicked.png"
            },
            text: {
                x: 60,
                y: 12,
                value: translate('ok'),
                fontSize: 26,
                gradient: ['#d7cfcf', '#ffffff']
            },
            onClicked: this.onChangeMyBorder.bind(this)
        });
        this.borderContainer.add(this.btn_ok)
    }

    onClickedEditNickname() {
        this.editNicknameDlg = new EditNicknameDialog(this.scene, {
            x: 0,
            y: 0,
            atlas: this.config.atlas
        });
        this.add(this.editNicknameDlg);
    }

    onClickedPhoto() {
        return;
        // let avatarDlg = new AvatarDialog(this.scene, {
        //     x: 0,
        //     y: 80
        // });
        // this.add(avatarDlg);
        if(this.uploading) return;
        this.inputTag.click();
    }

    onChangeAvatar() {
        let files = Array.from(this.inputTag.files);
        if(!files || files.length < 1) return;
        if(files[0].type.indexOf("image") == -1) {
            this.scene.showToast("failed", translate('change_avatar_error_file'));
            return;
        }
        if(files[0].size > 256 * 1024) {
            this.scene.showToast("failed", translate('change_avatar_error_size'));
            return;
        }
        var reader = new FileReader();
        reader.onload = this.imageIsLoaded.bind(this);
        reader.readAsDataURL(files[0]);
    }

    imageIsLoaded(e) {
        this.uploading = true;
        // if(e.target.result.length > 256 * 1024) {
        //     this.scene.showToast("failed", "이미지 사이즈는 256KB이하여야 합니다.");
        //     return;
        // }
        this.scene.requestUploadAvatar(e.target.result);
    }

    onChangeMyBorder() {
        this.scene.loadingPage.playLoading();
        this.selectedBorderId > -1 && this.scene.requestBorderUse(this.selectedBorderId);
    }

    setBorderList(borders) {
        this.borders = borders;
        this.borderList.removeAll(true);
        let cols = 3;
        let item_height = 160;
        let posX = [10, 170, 330];
        for(let i = 0 ; i < borders.length ; i ++) {
            let item = this.scene.add.container(posX[i % cols], item_height * Math.floor(i / cols));
            let avatar = new Avatar(this.scene, {
                x: 80,
                y: 10,
                atlas: "AvatarAtlas",
                avatar: null,
                border: borders[i].icon,
            });
            item.add(avatar);

            let icon_img = "";
            if(!borders[i].isAvailable) icon_img = "lock.png";
            else if(borders[i].id == CommonDef.Me.borderid) {
                icon_img = "active.png";
                this.onClickedBorder(borders[i]);
            }
            if(icon_img) {
                let status_icon = this.scene.add.sprite(95, 10, "AvatarAtlas", icon_img);
                status_icon.setOrigin(0, 0);
                item.add(status_icon);
            }

            item.width = 130;
            item.height = 130;
            item.onClick = () => {
                this.onClickedBorder(borders[i]);
            }
            this.borderList.add(item);
        }
        this.borderList.height = item_height * Math.ceil(borders.length / cols);
        this.scrollView.updateScroll();
    }

    onClickedBorder(border) {
        this.border_name.setText(border.description);
        this.selectedBorderId = border.id;
    }

    destroy() {
        document.body.removeChild(this.inputTag);
        super.destroy();
    }

    updateMyInfo() {
        this.avatar.updateAvatar(CommonDef.Me.avatar);
        this.avatar.updateBorder(CommonDef.Me.bordericon);
        this.player_name.setText(CommonDef.Me.nickname);
        this.my_money_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.money));
        this.my_point_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.point));
        this.my_today_betting_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.dailybetting));
        this.my_total_betting_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.totalbetting));
        this.uploading = false;

        this.setBorderList(this.borders);
    }
}