export class SliderBar extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.thumb_back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x2b1100,
                alpha: 1
            }
        });
        this.thumb_back.fillRoundedRect(0, 0, this.config.width, this.config.height, this.config.height / 2);
        this.add(this.thumb_back);

        this.thumb_icon = this.scene.add.sprite(0, -5, this.config.atlas, "slider_thumb.png").setOrigin(0, 0);
        this.add(this.thumb_icon);

        if(this.config.ruler) {
            for(let i = 1 ; i < 20 ; i ++) {
                let ruler = this.scene.add.graphics({
                    x: this.config.width / 20 * i,
                    y: 25,
                    fillStyle: {
                        color: 0xaa4200,
                        alpha: 1
                    }
                });
                ruler.fillRect(-1, 0, 2, i % 5 == 0 ? 20 : 10);
                this.add(ruler);
            }
        }

        this.action_rect = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, -this.config.height, this.config.width, this.config.height * 3);
        this.add(this.action_rect);
        this.action_rect.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.thumbSelected = false;
        this.action_rect.on("pointerdown", (e) => {
            this.thumbSelected = true;
            let x = e.x - this.config.absoluteX - this.thumb_icon.width / 2;
            if(x < 0) x = 0;
            if(x > this.config.width - this.thumb_icon.width) x = this.config.width - this.thumb_icon.width;
            this.thumb_icon.x = x;
            this.config.onChange && this.config.onChange(x / (this.config.width - this.thumb_icon.width));
        });
        this.action_rect.on("pointermove", (e) => {
            if(!this.thumbSelected) return;
            let x = e.x - this.config.absoluteX - this.thumb_icon.width / 2;
            if(x < 0) x = 0;
            if(x > this.config.width - this.thumb_icon.width) x = this.config.width - this.thumb_icon.width;
            this.thumb_icon.x = x;
            this.config.onChange && this.config.onChange(x / (this.config.width - this.thumb_icon.width));
        });
        this.action_rect.on("pointerup", () => {
            this.thumbSelected = false;
        });
        this.action_rect.on("pointerout", () => {
            this.thumbSelected = false;
        })
    }

    setValue(value) {
        // value should be between 0 to 1
        if(value < 0 || value > 1) return;
        this.thumb_icon.x = (this.config.width - this.thumb_icon.width) * value;
        // this.config.onChange && this.config.onChange(value);
    }

    getValue() {
        return this.thumb_icon.x / (this.config.width - this.thumb_icon.width);
    }
}