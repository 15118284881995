import { Transmitter } from '../socket/Transmitter';
import { ProtocolEncoder } from '../socket/protocol/ProtocolEncoder';
// let ProtocolEncoder = require('../socket/protocol/ProtocolEncoder');

import CommonDef from '../def/commondef';
import { SceneManager } from '../scenes/scene_manager';
import MessageCode from './message_code';
import { translate } from '../util/other';

export class GameNet {
    constructor() {
    }

    onCommand(messageCode, body) {
        CommonDef.currentScene.stopLoading && CommonDef.currentScene.stopLoading();
        try {
            switch(messageCode) {
                case MessageCode.HEARTBEAT:
                    this.responseHeartBeat(body);
                    break;
                case MessageCode.LOGIN:
                    this.responseLogin(body);
                    break;
                case MessageCode.SIGNUP:
                    this.responseSignUp(body);
                    break;
                case MessageCode.DOUBLELOGIN:
                    this.responseDoubleLogin(body);
                    break;
                case MessageCode.SERVICEINFO:
                    this.responseServiceInfo(body);
                    break;
                case MessageCode.USERINFO:
                    this.responseUserInfo(body);
                    break;
                case MessageCode.NOTE:
                    this.responseNote(body);
                    break;
                case MessageCode.UPLOADAVATAR:
                    this.responseUploadAvatar(body);
                    break;
                case MessageCode.CHANGEPASSWORD:
                    this.responseChangePassword(body);
                    break;
                case MessageCode.CHANGENICKNAME:
                    this.responseChangeNickname(body);
                    break;
                case MessageCode.BORDER:
                    this.responseBorder(body);
                    break;
                case MessageCode.NOTICELIST:
                    this.responseNoticeList(body);
                    break;
                case MessageCode.PAYIN:
                    this.responsePayin(body);
                    break;
                case MessageCode.PAYOUT:
                    this.reponsePayout(body);
                    break;
                case MessageCode.DEPOSIT:
                    this.responseDeposit(body);
                    break;
                case MessageCode.WITHDRAW:
                    this.responseWithdraw(body);
                    break;
                case MessageCode.STOREHISTORY:
                    this.responseStoreHistory(body);
                    break;
                case MessageCode.POINTEXCHANGE:
                    this.responsePointExchange(body);
                    break;
                case MessageCode.PRESENTEVENT:
                    this.responsePresentEvent(body);
                    break;
                case MessageCode.SHOP:
                    this.responseShop(body);
                    break;
                case MessageCode.CUSTOMER:
                    this.responseCustomer(body);
                    break;
                case MessageCode.INVENTORY:
                    this.responseInventory(body);
                    break;
                case MessageCode.TABLETEMA:
                    this.responseTableTema(body);
                    break;
                case MessageCode.CARDTEMA:
                    this.responseCardTema(body);
                    break;
                case MessageCode.GETLOBBYROOMLIST:
                    this.responseLobbyRoomList(body);
                    break;
                case MessageCode.CHECKBUYIN:
                    this.reponseCheckBuyin(body);
                    break;
                case MessageCode.CREATEROOM:
                    this.responseCreateRoom(body);
                    break;
                case MessageCode.JOINROOM:
                    this.responseJoinRoom(body);
                    break;
                case MessageCode.QUICKJOINROOM:
                    this.responseQuickJoinRoom(body);
                    break;
                case MessageCode.GAMEHISTORY:
                    this.responseGameHistory(body);
                    break;
                case MessageCode.USERPROFILEDATA:
                    this.responseUserProfileData(body);
                    break;
                case MessageCode.USERPROFILEREPORT:
                    this.responseUserProfileReport(body);
                    break;
                case MessageCode.DISCONNECT:
                    this.logout();
                    break;


                case MessageCode.HOLDEMROOMINFO:
                    this.responseHoldemRoomInfo(body);
                    break;
                case MessageCode.HOLDEMROOMEXIT:
                    this.responseHoldemRoomExit(body);
                    break;
                case MessageCode.HOLDEMMOVETURN:
                    this.responseHoldemMoveTurn(body);
                    break;
                case MessageCode.HOLDEMSHOWDOWN:
                    this.responseHoldemShowdown();
                    break;
                case MessageCode.HOLDEMGAMEOVER:
                    this.responseHoldemGameover(body);
                    break;
                case MessageCode.HOLDEMMESSAGE:
                    this.responseHoldemMessage(body);
                    break;
                case MessageCode.HOLDEMRABBIT:
                    this.responseHoldemRabbit(body);
                    break;
                case MessageCode.HOLDEMTIMEBANK:
                    this.responseHoldemTimeBank(body);
                    break;
                case MessageCode.HOLDEMEMOTICON:
                    this.responseHoldemEmoticon(body);
                    break;


                case MessageCode.OMAHAROOMINFO:
                    this.responseOmahaRoomInfo(body);
                    break;
                case MessageCode.OMAHAROOMEXIT:
                    this.responseOmahaRoomExit(body);
                    break;
                case MessageCode.OMAHAMOVETURN:
                    this.responseOmahaMoveTurn(body);
                    break;
                case MessageCode.OMAHASHOWDOWN:
                    this.responseOmahaShowdown();
                    break;
                case MessageCode.OMAHAGAMEOVER:
                    this.responseOmahaGameover(body);
                    break;
                case MessageCode.OMAHAMESSAGE:
                    this.responseOmahaMessage(body);
                    break;
                case MessageCode.OMAHARABBIT:
                    this.responseOmahaRabbit(body);
                    break;
                case MessageCode.OMAHATIMEBANK:
                    this.responseOmahaTimeBank(body);
                    break;
                case MessageCode.OMAHAEMOTICON:
                    this.responseOmahaEmoticon(body);
                    break;


                
                case MessageCode.GETTOURNAMENTLIST:
                    this.responseTournamentList(body);
                    break;
                case MessageCode.GETTOURNAMENTDETAIL:
                    this.responseTournamentDetail(body);
                    break;
                case MessageCode.GETTOURNAMENTENTRY:
                    this.responseTournamentEntry(body);
                    break;
                case MessageCode.GETTOURNAMENTTABLE:
                    this.responseTournamentTable(body);
                    break;
                case MessageCode.GETTOURNAMENTPRIZE:
                    this.responseTournamentPrize(body);
                    break;
                case MessageCode.GETTOURNAMENTBLINDS:
                    this.responseTournamentBlinds(body);
                    break;
                case MessageCode.REGISTERTOURNAMENT:
                    this.responseRegisterTournament(body);
                    break;
                case MessageCode.ENTERTOURNAMENT:
                    this.responseEnterTournament(body);
                    break;
                case MessageCode.OBSERVINGTOURNAMENT:
                    this.responseObservingTournament(body);
                    break;
                case MessageCode.UNREGISTERTOURNAMENT:
                    this.responseUnregisterTournament(body);
                    break;
                case MessageCode.TOURNAMENTALARM:
                    this.responseTournamentAlarm(body);
                    break;
                case MessageCode.GETTOURNAMENTBYTICKET:
                    this.responseTournamentListByTicket(body);
                    break;




                case MessageCode.TOURNAMENTTABLEINFO:
                    this.responseTournamentRoomInfo(body);
                    break;
                case MessageCode.TOURNAMENTTABLEEXIT:
                    this.responseTournamentTableExit(body);
                    break;
                case MessageCode.TOURNAMENTTABLEMOVETURN:
                    this.responseHoldemMoveTurn(body);
                    break;
                case MessageCode.TOURNAMENTTABLESHOWDOWN:
                    this.responseHoldemShowdown();
                    break;
                case MessageCode.TOURNAMENTTABLEGAMEOVER:
                    this.responseHoldemGameover(body, true);
                    break;
                case MessageCode.TOURNAMENTTABLEGAMEMESSAGE:
                    this.responseHoldemMessage(body);
                    break;
                case MessageCode.TOURNAMENTTABLERABBIT:
                    this.responseHoldemRabbit(body);
                    break;
                case MessageCode.TOURNAMENTTABLETIMEBANK:
                    this.responseHoldemTimeBank(body);
                    break;
                case MessageCode.TOURNAMENTTABLEEMOTICON:
                    this.responseHoldemEmoticon(body);
                    break;
                case MessageCode.TOURNAMENTHOLDEMADDON:
                    this.responseTournamentHoldemAddon(body);
                    break;
                case MessageCode.TOURNAMENTRESULT:
                    this.responseTournamentResult(body);
                    break;


                case MessageCode.REPORT:
                    this.responseReport(body);
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error("error on GameNet => onCommand", messageCode, body, e);
        }
    }

    requestLogin(data){
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        Transmitter.AdoManager = this;
        Transmitter.init();
        CommonDef.Me.loginid = data.username;
        console.log("$$$", CommonDef.ServerIP);
        Transmitter.connect(1, data.username, data.password, CommonDef.ServerIP, CommonDef.ServerPORT, true);
    }

    responseLogin(data) {
        let loginResult = data.shift();
        if(CommonDef.currentScene.scene.key != "LoginScene") return;
        CommonDef.currentScene.responseLogin();
        if(loginResult == MessageCode.LOGINRESPONSECODE.OK){
            if(this.heartbeatInterval) clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = setInterval(() => {
                Transmitter.tick() // 1초간격으로 허트비트를 보낸다
            }, 1000);
            CommonDef.currentScene.loginSuccessed();
        }
        else {
            loginResult == MessageCode.LOGINRESPONSECODE.ALREADYLOGIN && CommonDef.currentScene.showToast("failed", translate('login_error_already_login'));
            loginResult == MessageCode.LOGINRESPONSECODE.IDPASSWORDMISMATCH && CommonDef.currentScene.showToast("failed", translate('login_error_wrong_account'));
            loginResult == MessageCode.LOGINRESPONSECODE.DISABLED && CommonDef.currentScene.showToast("failed", translate('login_error_blocked_account'));
            loginResult == MessageCode.LOGINRESPONSECODE.CHECKING && CommonDef.currentScene.showToast("failed", translate('maintaining'));
            loginResult == MessageCode.LOGINRESPONSECODE.PENDING && CommonDef.currentScene.showToast("failed", translate('login_error_pending_account'));
        }
    }

    logout() {
        Transmitter.disconnect();
        if(this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }
        CommonDef.Me.loginid = "none";
        if(CommonDef.currentScene.scene.key == "LoginScene") {
            CommonDef.currentScene.responseLogin();
        } else {
            SceneManager.changeScene(CommonDef.currentScene.scene, CommonDef.currentScene.scene.key, "LoginScene");
        }
    }

    responseHeartBeat(data) {
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.updateServerTime && CommonDef.currentScene.updateServerTime();
    }

    requestSignup(signupCode, data) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        Transmitter.AdoManager = this;
        Transmitter.init();
        Transmitter.signup(1, CommonDef.ServerIP, CommonDef.ServerPORT, signupCode, data, true);
    }

    responseSignUp(data) {
        if(CommonDef.currentScene.scene.key != "RegisterScene") return;
        let signupCode = data.shift();
        let signupResult = data.shift();
        console.log(signupResult);
        if(signupResult == 0) {
            signupCode == MessageCode.SIGNUOCODE.IDCHECK && CommonDef.currentScene.idCheckComplete(true);
            signupCode == MessageCode.SIGNUOCODE.NICKNAMECHECK && CommonDef.currentScene.nicknameCheckComplete(true);
            signupCode == MessageCode.SIGNUOCODE.PHONENUMBER && CommonDef.currentScene.phoneNumberAccepted(true);
            signupCode == MessageCode.SIGNUOCODE.VERRIFYCODE && CommonDef.currentScene.phoneNumberVerifyCompleted(true);
            if(signupCode == MessageCode.SIGNUOCODE.FINAL) {
                this.logout();
            }
        } else {
            CommonDef.currentScene.showToast("failed", translate('signup_error')[signupResult - 1]);
        }
        this.heartbeatInterval && clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = setInterval(() => {
            Transmitter.tick() // 1초간격으로 허트비트를 보낸다
        }, 1000);
    }

    responseDoubleLogin(data) {
        let ip = data.shift();
        CommonDef.DoubleLogin = {
            double: true,
            ip: ip
        };
    }

    requestServiceInfo() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.SERVICEINFO, 0)
        protocolEncoder.SendData()
        // CommonDef.currentScene.updateServiceInfo && CommonDef.currentScene.updateServiceInfo([82, 73, 9, 11])
    }

    responseServiceInfo(data) {
        CommonDef.currentScene.updateServiceInfo && CommonDef.currentScene.updateServiceInfo(data)
    }

    requestUserInfo() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.USERINFO, 0)
        protocolEncoder.SendData();
    }

    responseUserInfo(data) {
        CommonDef.Me.loginid = data.shift();
        CommonDef.Me.nickname = data.shift();
        CommonDef.Me.avatar = data.shift();
        CommonDef.Me.money = data.shift();
        CommonDef.Me.storemoney = data.shift() * 1;
        CommonDef.Me.point = data.shift() * 1;
        CommonDef.Me.dailybetting = data.shift() * 1;
        CommonDef.Me.totalbetting = data.shift() * 1;
        CommonDef.Me.borderid = data.shift();
        CommonDef.Me.bordericon = data.shift();
        CommonDef.Me.tabletemaid = data.shift();
        CommonDef.Me.tabletemaicon = data.shift();
        CommonDef.Me.cardtemaid = data.shift();
        CommonDef.Me.cardtemaicon = data.shift();
        CommonDef.Me.bankname = data.shift();
        CommonDef.Me.accountnumber = data.shift();
        CommonDef.Me.ownername = data.shift();
        CommonDef.Me.eventlevel = data.shift();
        CommonDef.Me.viplevel = data.shift();
        CommonDef.Me.vipstarttime = data.shift();
        CommonDef.Me.vipduration = data.shift();
        CommonDef.Me.emoticonCount = data.shift();
        CommonDef.Me.timeBankCount = data.shift();
        CommonDef.Me.rabbitEnable = data.shift();
        CommonDef.Me.isGaming = parseInt(data.shift());
        CommonDef.Me.unreadNoteCount = data.shift();
        CommonDef.currentScene.updateMyInfo && CommonDef.currentScene.updateMyInfo();
    }

    requestNoteList() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.NOTE, 1)
        protocolEncoder.WriteInt(0, MessageCode.NOTECODE.LIST);
        protocolEncoder.SendData();
    }

    readNote(noteid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.NOTE, 2)
        protocolEncoder.WriteInt(0, MessageCode.NOTECODE.READ); // update
        protocolEncoder.WriteInt(1, noteid);
        protocolEncoder.SendData();
    }

    removeNote(noteid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.NOTE, 2)
        protocolEncoder.WriteInt(0, MessageCode.NOTECODE.REMOVE); 
        protocolEncoder.WriteInt(1, noteid);
        protocolEncoder.SendData();
    }

    responseNote(data) {
        let type = data.shift();
        if(type == MessageCode.NOTECODE.LIST) {
            let count = data.shift();
            let noteList = [];
            for(let i = 0 ; i < count ; i ++) {
                noteList.push({
                    id: data.shift(),
                    content: data.shift(),
                    isread: data.shift(),
                    createdat: parseInt(data.shift())
                })
            }
            CommonDef.currentScene.setNoteList && CommonDef.currentScene.setNoteList(noteList);
        } else if(type == MessageCode.NOTECODE.READ) {
            let code = data.shift();
            if(code == 0) {
                let noteid = data.shift();
                CommonDef.currentScene.setNoteRead && CommonDef.currentScene.setNoteRead(noteid);
            }
        } else if(type == MessageCode.NOTECODE.REMOVE) {
            let code = data.shift();
            if(code == 0) {
                let noteid = data.shift();
                CommonDef.currentScene.setNoteRead && CommonDef.currentScene.removeNote(noteid);
            } else {
                CommonDef.currentScene.showToast && CommonDef.currentScene.showToast("failed", "쪽지 삭제가 실패했습니다.");
            }
        }
    }

    requestUploadAvatar(avatar) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.UPLOADAVATAR, 1)
        protocolEncoder.WriteString(0, avatar);
        protocolEncoder.SendData();
    }

    responseUploadAvatar(data) {
        let code = data.shift();
        if(code == 0) {
            CommonDef.currentScene.showToast("success", translate('change_avatar_success'));
        } else {
            CommonDef.currentScene.showToast("failed", translate('change_avatar_error'));
        }
    }

    requestChagnePassword(oldPassword, newPassword, confirmPassword) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CHANGEPASSWORD, 3)
        protocolEncoder.WriteString(0, oldPassword);
        protocolEncoder.WriteString(1, newPassword);
        protocolEncoder.WriteString(2, confirmPassword);
        protocolEncoder.SendData();
    }

    responseChangePassword(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.showToast("success", translate('change_password_success'));
            CommonDef.currentScene.onSuccessChangePassword();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", transalte('change_password_error'));
        }
    }

    requestChangeNickname(nickname) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CHANGENICKNAME, 1);
        protocolEncoder.WriteString(0, nickname);
        protocolEncoder.SendData();
    }

    responseChangeNickname(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.showToast("success", translate('change_nickname_success'));
            CommonDef.currentScene.onSuccessChangeNickname();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('change_nickname_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('change_nickname_error2'));
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('change_nickname_error3'))
        }
    }

    requestBorderList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.BORDER, 1)
        protocolEncoder.WriteInt(0, MessageCode.BORDERCODE.LIST);
        protocolEncoder.SendData();
    }

    requestBorderUse(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.BORDER, 2)
        protocolEncoder.WriteInt(0, MessageCode.BORDERCODE.USE);
        protocolEncoder.WriteInt(1, id);
        protocolEncoder.SendData();
    }

    responseBorder(data) {
        let code = data.shift();
        if(code == MessageCode.BORDERCODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    name: data.shift(),
                    description: data.shift(),
                    icon: data.shift(),
                    isAvailable: data.shift()
                })
            }
            CommonDef.currentScene.setBorderList && CommonDef.currentScene.setBorderList(list);
        } else if(code == MessageCode.BORDERCODE.USE) {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.showToast("success", translate('change_border_success'));
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", translate('change_border_error1'));
            }  else if(result == 2) {
                CommonDef.currentScene.showToast("failed", translate('change_border_error1'));
            }
        }
    }

    requestNoticeList() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.NOTICELIST, 0)
        protocolEncoder.SendData();
    }

    responseNoticeList(data) {
        let count = data.shift();
        let normalNoticeList = [];
        let tournamentNoticeList = [];
        let eventNoticeList = [];
        for(let i = 0 ; i < count ; i ++) {
            let notice = {
                id: data.shift(),
                type: data.shift(),
                title: data.shift(),
                content: data.shift(),
                created_at: data.shift()
            }
            notice.type == 0 && normalNoticeList.push(notice);
            notice.type == 1 && tournamentNoticeList.push(notice);
            notice.type == 2 && eventNoticeList.push(notice);
        }
        CommonDef.currentScene.setNoticeList && CommonDef.currentScene.setNoticeList(normalNoticeList, tournamentNoticeList, eventNoticeList);
    }

    requestPayinList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PAYIN, 1)
        protocolEncoder.WriteInt(0, MessageCode.PAYINCODE.LIST);
        protocolEncoder.SendData();
    }

    requestPayin(ownerName, playerName, amount, bonusType) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PAYIN, 5)
        protocolEncoder.WriteInt(0, MessageCode.PAYINCODE.CREATE);
        protocolEncoder.WriteString(1, ownerName);
        protocolEncoder.WriteString(2, playerName);
        protocolEncoder.WriteString(3, amount.toString());
        protocolEncoder.WriteInt(4, bonusType);
        protocolEncoder.SendData();
    }

    responsePayin(data) {
        let code = data.shift();
        if(code == 0) {
            let bonus_type = data.shift();
            let bonus_percent = data.shift();
            let count = data.shift();
            let history = [];
            for(let i = 0 ; i < count ; i ++) {
                history.push({
                    id: data.shift(),
                    amount: data.shift(),
                    bonus_type: data.shift(),
                    state: data.shift(),
                    created_at: data.shift(),
                    updated_at: data.shift()
                });
            }
            CommonDef.currentScene.setChargeDialogHistory && CommonDef.currentScene.setChargeDialogHistory(history, [{type: bonus_type, percent: bonus_percent}])
        } else if(code == 1) {
            let result = data.shift();
            if(result == 0) {
                // window.open('https://www.google.com','_blank');
                CommonDef.currentScene.showToast("success", "충전요청이 성공했습니다.");
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", "충전요청이 실패했습니다.");
            } else if(result == 2) {
                CommonDef.currentScene.showToast("failed", "현재 대기중인 요청이 있습니다.");
            }
        }
    }

    requestPayoutList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PAYOUT, 1)
        protocolEncoder.WriteInt(0, MessageCode.PAYOUTCODE.LIST);
        protocolEncoder.SendData();
    }

    requestPayout(amount) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PAYOUT, 2)
        protocolEncoder.WriteInt(0, MessageCode.PAYINCODE.CREATE);
        protocolEncoder.WriteString(1, amount.toString());
        protocolEncoder.SendData();
    }

    reponsePayout(data) {
        let code = data.shift();
        if(code == 0) {
            let count = data.shift();
            let history = [];
            for(let i = 0 ; i < count ; i ++) {
                history.push({
                    id: data.shift(),
                    amount: data.shift(),
                    state: data.shift(),
                    created_at: data.shift(),
                    updated_at: data.shift()
                });
            }
            CommonDef.currentScene.setChangeDialogHistory(history)
        } else {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.showToast("success", "환전요청이 성관적으로 완료되었습니다.");
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", "환전요청이 실패했습니다.");
            } else if(result == 2) {
                CommonDef.currentScene.showToast("failed", "환전요청 금액이 보유금액을 초과합니다.");
            } else if(result == 3) {
                CommonDef.currentScene.showToast("failed", "출금조건이 만족하지 않습니다.");
            } else if(result == 4) {
                CommonDef.currentScene.showToast("failed", "현재 대기중인 요청이 있습니다.");
            }
        }
    }

    requestStoreHistory() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.STOREHISTORY, 0)
        protocolEncoder.SendData();
    }

    responseStoreHistory(data) {
        let count = data.shift();
        let history = [];
        for(let i = 0 ; i < count ; i ++) {
            history.push({
                id: data.shift(),
                amount: data.shift(),
                type: data.shift(),
                created_at: data.shift()
            });
        }
        CommonDef.currentScene.setStoreHistory(history);
    }

    requestDeposit(amount) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.DEPOSIT, 1)
        protocolEncoder.WriteString(0, amount.toString());
        protocolEncoder.SendData();
    }

    responseDeposit(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.showToast("success", translate('safe_deposit_success'));
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('safe_deposit_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('safe_deposit_error2'));
        }
    }

    requestWithdraw(amount) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.WITHDRAW, 1)
        protocolEncoder.WriteString(0, amount.toString());
        protocolEncoder.SendData();
    }

    responseWithdraw(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.showToast("success", translate('safe_withdraw_success'));
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('safe_withdraw_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('safe_withdraw_error2'));
        }
    }

    requestPointExchangeList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.POINTEXCHANGE, 1)
        protocolEncoder.WriteInt(0, MessageCode.POINTEXCHANGECODE.LIST);
        protocolEncoder.SendData();
    }

    requestPointExchange(amount) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.POINTEXCHANGE, 2)
        protocolEncoder.WriteInt(0, MessageCode.POINTEXCHANGECODE.CREATE);
        protocolEncoder.WriteString(1, amount.toString());
        protocolEncoder.SendData();
    }

    responsePointExchange(data) {
        let code = data.shift();
        if(code == MessageCode.POINTEXCHANGECODE.LIST) {
            let history = [];
            let count = data.shift();
            for(let i = 0 ; i < count ; i++) {
                history.push({
                    id: data.shift(),
                    amount: data.shift(),
                    created_at: data.shift()
                });
            }
            CommonDef.currentScene.setPointExchangeHistory(history);
        } else if(code == MessageCode.POINTEXCHANGECODE.CREATE) {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.showToast("success", translate('convert_point_success'));
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", translate('convert_point_error1'));
            } else if(result == 2) {
                CommonDef.currentScene.showToast("failed", translate('convert_point_error2'));
            }
        }
    }

    requestPresentEventList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PRESENTEVENT, 1)
        protocolEncoder.WriteInt(0, MessageCode.PRESENTEVENTCODE.LIST);
        protocolEncoder.SendData();
    }

    requestAcceptEventItem(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.PRESENTEVENT, 2)
        protocolEncoder.WriteInt(0, MessageCode.PRESENTEVENTCODE.ACCEPT);
        protocolEncoder.WriteInt(0, id);
        protocolEncoder.SendData();
    }

    responsePresentEvent(data) {
        let code = data.shift();
        if(code == MessageCode.PRESENTEVENTCODE.LIST) {
            let count = data.shift();
            let eventItems = [];
            for(let i = 0 ; i < count ; i ++) {
                eventItems.push({
                    id: data.shift(),
                    name: data.shift(),
                    icon: data.shift(),
                    pass: data.shift(),
                    accepted: data.shift(),
                    eventday: data.shift()
                })
            }
            CommonDef.currentScene.setEventItems(eventItems);
        } else if(code == MessageCode.PRESENTEVENTCODE.ACCEPT) {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.onSuccessEventItemAccept();
            } else {
                CommonDef.currentScene.showToast("failed", translate('event_accept_error'));
            }
        }
    }

    requestShopItems() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.SHOP, 1)
        protocolEncoder.WriteInt(0, MessageCode.SHOPCODE.LIST);
        protocolEncoder.SendData();
    }

    requestBuyShopItem(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.SHOP, 2)
        protocolEncoder.WriteInt(0, MessageCode.SHOPCODE.BUY);
        protocolEncoder.WriteInt(1, id);
        protocolEncoder.SendData();
    }

    responseShop(data) {
        let code = data.shift();
        if(code == MessageCode.SHOPCODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    title: data.shift(),
                    description: data.shift(),
                    icon: data.shift(),
                    price: data.shift(),
                    availableCount: data.shift(),
                    availableDays: data.shift(),
                    type: data.shift(),
                    isBuyed: data.shift()
                });
            }
            CommonDef.currentScene.setShopItemList(list);
        } else if(code == MessageCode.SHOPCODE.BUY) {
            let result = data.shift();
            if(result == 0) { 
                CommonDef.currentScene.showToast("success", translate('buy_shopitem_success'));
                let inventoryId = data.shift();
                let shopItemType = data.shift();
                CommonDef.currentScene.onSuccessBuyShopItem(shopItemType, inventoryId);
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", translate('buy_shopitem_error1'));
            } else if(result == 2) {
                CommonDef.currentScene.showToast("failed", translate('buy_shopitem_error2'));
            } else if(result == 3) {
                CommonDef.currentScene.showToast("failed", translate('buy_shopitem_error3'));
            }
        }
    }

    requestInventoryList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.INVENTORY, 1)
        protocolEncoder.WriteInt(0, MessageCode.INVENTORYCODE.LIST);
        protocolEncoder.SendData();
    }

    requestInventoryUse(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.INVENTORY, 2)
        protocolEncoder.WriteInt(0, MessageCode.INVENTORYCODE.USE);
        protocolEncoder.WriteInt(1, id);
        protocolEncoder.SendData();
    }

    responseInventory(data) {
        let code = data.shift();
        if(code == MessageCode.INVENTORYCODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    name: data.shift(),
                    description: data.shift(),
                    type: data.shift(),
                    icon: data.shift()
                });
            }
            CommonDef.currentScene.setInventoryList && CommonDef.currentScene.setInventoryList(list);
        } else if(code == MessageCode.INVENTORYCODE.USE) {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.showToast("success", translate('use_inventory_success'));
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", translate('use_inventory_error1'));
            } else if(result == 2) {
                CommonDef.currentScene.showToast("failed", translate('use_inventory_error2'));
            }
        }
    }

    requestCustomerList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CUSTOMER, 1)
        protocolEncoder.WriteInt(0, MessageCode.CUSTOMERCODE.LIST);
        protocolEncoder.SendData();
    }

    requestCreateNewQuestion(title, question) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CUSTOMER, 3)
        protocolEncoder.WriteInt(0, MessageCode.CUSTOMERCODE.CREATE);
        protocolEncoder.WriteString(1, title);
        protocolEncoder.WriteString(2, question);
        protocolEncoder.SendData();
    }

    responseCustomer(data) {
        let code = data.shift();
        if(code == MessageCode.CUSTOMERCODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    title: data.shift(),
                    question: data.shift(),
                    status: data.shift(),
                    answer: data.shift(),
                    updated_at: data.shift()
                })
            }
            CommonDef.currentScene.setCustomerList(list);
        } else if(code == MessageCode.CUSTOMERCODE.CREATE) {
            let result = data.shift();
            if(result == 0) {
                CommonDef.currentScene.onSuccessCreateNewCustomerQuestion();
            } else if(result == 1) {
                CommonDef.currentScene.showToast("failed", translate('customer_question_error'));
            }
        }
    }

    requestTableTemaList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TABLETEMA, 1)
        protocolEncoder.WriteInt(0, MessageCode.TABLETEMACODE.LIST);
        protocolEncoder.SendData();
    }

    requestTableTemaUse(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TABLETEMA, 2)
        protocolEncoder.WriteInt(0, MessageCode.TABLETEMACODE.USE);
        protocolEncoder.WriteString(1, id);
        protocolEncoder.SendData();
    }

    responseTableTema(data) {
        let code = data.shift();
        if(code == MessageCode.TABLETEMACODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    name: data.shift(),
                    description: data.shift(),
                    icon: data.shift(),
                    isAvailable: data.shift()
                });
            }
            CommonDef.currentScene.setTableTemaList(list);
        } else if(code == MessageCode.TABLETEMACODE.USE) {
            let result = data.shift();
        }
    }

    requestCardTemaList() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CARDTEMA, 1)
        protocolEncoder.WriteInt(0, MessageCode.CARDTEMACODE.LIST);
        protocolEncoder.SendData();
    }

    requestCardTemaUse(id) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CARDTEMA, 2)
        protocolEncoder.WriteInt(0, MessageCode.CARDTEMACODE.USE);
        protocolEncoder.WriteString(1, id);
        protocolEncoder.SendData();
    }

    responseCardTema(data) {
        let code = data.shift();
        if(code == MessageCode.CARDTEMACODE.LIST) {
            let count = data.shift();
            let list = [];
            for(let i = 0 ; i < count ; i ++) {
                list.push({
                    id: data.shift(),
                    name: data.shift(),
                    description: data.shift(),
                    icon: data.shift(),
                    isAvailable: data.shift()
                });
            }
            CommonDef.currentScene.setCardTemaList(list);
        } else if(code == MessageCode.CARDTEMACODE.USE) {
            let result = data.shift();
        }
    }

    requestLobbyRoomList(gameID) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        // let protocolEncoder = ProtocolEncoder.create(MessageCode.GETLOBBYROOMLIST, 1)
        // protocolEncoder.WriteInt(0, gameID);
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETLOBBYROOMLIST, 0)
        protocolEncoder.SendData();
    }

    responseLobbyRoomList(data) {
        // let gameID = data.shift();
        let gameID = 0;
        let count = data.shift();
        let roomList = [];
        let mode = translate('speed_labels');
        for(let i = 0 ; i < count ; i ++) {
            roomList.push({
                id: data.shift(),
                title: data.shift(),
                security: data.shift(),
                mode: mode[data.shift()],
                blinds: {
                    sb: parseFloat(data.shift()),
                    bb: parseFloat(data.shift())
                },
                ante: parseFloat(data.shift()),
                buyin: {
                    min: parseFloat(data.shift()),
                    max: parseFloat(data.shift())
                },
                seat_count: data.shift(),
                player_count: data.shift()
            });
        }
        CommonDef.currentScene.setLobbyContent && CommonDef.currentScene.setLobbyContent(gameID, roomList);
    }

    requestBuyin(roomid, sb, createFlag, security = 0) { // createFlag => 0: create, 1: 입장, 2: 빠른 입장
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CHECKBUYIN, 4);
        protocolEncoder.WriteString(0, roomid);
        protocolEncoder.WriteInt(1, sb);
        protocolEncoder.WriteInt(2, createFlag);
        protocolEncoder.WriteInt(3, security);
        protocolEncoder.SendData();
    }

    reponseCheckBuyin(data) {
        let roomid = data.shift();
        let createFlag = data.shift();
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.openBuyinDialog(roomid, data.splice(0, 2), createFlag, data.shift());
        } else if(result == 1) { // 실패
            CommonDef.currentScene.showToast("failed", createFlag > 0 ? translate('join_failed') : translate('create_room_failed'));
        } else if(result == 2) { // 보유금액 불충
            CommonDef.currentScene.showToast("failed", translate('not_enough_balance'));
        }
    }

    requestCreateRoom(gameID, roomInfo, buyin) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        // let protocolEncoder = ProtocolEncoder.create(MessageCode.CREATEROOM, 9);
        // protocolEncoder.WriteInt(0, gameID);
        // protocolEncoder.WriteString(1, roomInfo.title);
        // protocolEncoder.WriteString(2, roomInfo.password);
        // protocolEncoder.WriteInt(3, roomInfo.mode);
        // protocolEncoder.WriteInt(4, roomInfo.seat_count);
        // protocolEncoder.WriteInt(5, roomInfo.blinds.sb);
        // protocolEncoder.WriteInt(6, roomInfo.blinds.bb);
        // protocolEncoder.WriteInt(7, roomInfo.ante);
        // protocolEncoder.WriteString(8, buyin.toString());
        // protocolEncoder.SendData();

        let protocolEncoder = ProtocolEncoder.create(MessageCode.CREATEROOM, 8);
        protocolEncoder.WriteString(0, roomInfo.title);
        protocolEncoder.WriteString(1, roomInfo.password);
        protocolEncoder.WriteInt(2, roomInfo.mode);
        protocolEncoder.WriteInt(3, roomInfo.seat_count);
        protocolEncoder.WriteInt(4, roomInfo.blinds.sb);
        protocolEncoder.WriteInt(5, roomInfo.blinds.bb);
        protocolEncoder.WriteInt(6, roomInfo.ante);
        protocolEncoder.WriteString(7, buyin.toString());
        protocolEncoder.SendData();
    }

    responseCreateRoom(data) {
        if(CommonDef.currentScene.scene.key != "LobbyScene") return;
        let result = data.shift();
        if(result == 0) { // 성공
            CommonDef.currentScene.onSuccessJoinRoom();
        } else if(result == 1) { // 실패
            CommonDef.currentScene.showToast("failed", translate('create_room_failed'));
        } else if(result == 2) { // 방제목 없음
            CommonDef.currentScene.showToast("failed", translate('create_room_error_title'));
        } else if(result == 3) { // 보유금액 불충분
            CommonDef.currentScene.showToast("failed", translate('not_enough_balance'));
        } else if(result == 4) { // 바이인 금액 [min, max] 벗어남
            CommonDef.currentScene.showToast("failed", translate('overflow_buyin'));
        }
    }


    requestJoinRoom(gameID, roomid, password, buyin) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.JOINROOM, 3);
        protocolEncoder.WriteString(0, roomid);
        protocolEncoder.WriteString(1, password);
        protocolEncoder.WriteString(2, buyin.toString());
        protocolEncoder.SendData();
    }

    responseJoinRoom(data) {
        if(CommonDef.currentScene.scene.key != "LobbyScene") return;
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.onSuccessJoinRoom && CommonDef.currentScene.onSuccessJoinRoom();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('join_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('join_error2'));  
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('join_error3'));  
        } else if(result == 4) {
            CommonDef.currentScene.showToast("failed", translate('join_error4'));  
        } else if(result == 5) {
            CommonDef.currentScene.showToast("failed", translate('join_error5'));  
        } else if(result == 6) {
            CommonDef.currentScene.showToast("failed", translate('join_error6'));  
        }
    }

    requestQuickJoinRoom(gameID, sb, ante, buyin) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.QUICKJOINROOM, 3);
        protocolEncoder.WriteString(0, sb.toString());
        protocolEncoder.WriteString(1, ante.toString());
        protocolEncoder.WriteString(2, buyin.toString());
        protocolEncoder.SendData();
    }

    responseQuickJoinRoom(data) {
        if(CommonDef.currentScene.scene.key != "LobbyScene") return;
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.onSuccessJoinRoom && CommonDef.currentScene.onSuccessJoinRoom();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('join_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('join_error2'));  
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('join_error3'));  
        } else if(result == 4) {
            CommonDef.currentScene.showToast("failed", translate('no_valid_rooms'));  
        }
    }

    
    // 홀덤

    requestHoldemRoomInfo() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMROOMINFO, 0);
        protocolEncoder.SendData();
    }

    responseHoldemRoomInfo(data) {
        let firstFlag = data.shift();
        let roomid = data.shift();
        let sb = parseFloat(data.shift());
        let bb = parseFloat(data.shift());
        let ante = parseFloat(data.shift());
        let mode = data.shift();
        let seat_count = data.shift();
        let roomState = data.shift();
        let dealer_seat = parseInt(data.shift());
        let turn_seat = parseInt(data.shift());
        let turn_start = data.shift();
        let turn_duration = data.shift(); // this is second
        let player_count = data.shift();
        let players = [];
        let mySeat = -1;
        for(let i = 0 ; i < player_count ; i ++) {
            let player = {
                loginid: "",
                nickname: "",
                chatpossible: true,
                handcards: [],
                coins: 0,
                round_chip: 0,
                state: 0,
                avatar: "",
                border: "",
                seat: -1,
                pattern: ""
            }
            player.loginid = data.shift();
            player.nickname = data.shift();
            player.chatpossible = data.shift();
            let handcard_count = data.shift();
            for(let count = 0 ; count < handcard_count ; count ++) {
                player.handcards.push(data.shift());
            }
            player.coins = parseFloat(data.shift());
            player.round_chip = parseFloat(data.shift());
            player.state = data.shift();
            player.avatar = data.shift();
            player.border = data.shift();
            player.seat = data.shift();
            player.pattern = parseInt(data.shift());
            players.push(player);
            if(player.loginid == CommonDef.Me.loginid) mySeat = player.seat;
        }
        let bettingBookEnable = data.shift();
        
        let bettingBook = {
            enable: bettingBookEnable
        }
        if(bettingBookEnable) {
            bettingBook = {
                enable: bettingBookEnable,
                allin: data.shift(),
                check: data.shift(),
                call: data.shift(),
                fold: data.shift(),
                book: data.shift()
            }
        }
        let pot = parseFloat(data.shift());
        let shared_card_count = data.shift();
        let shared_cards = [];
        for(let i = 0 ; i < shared_card_count ; i ++) {
            shared_cards.push(data.shift());
        }

        let block_user_count = data.shift();
        let block_user_id_list = [];
        for(let i = 0 ; i < block_user_count ; i ++) {
            block_user_id_list.push(data.shift());
        }

        if(mySeat < 0) {
            // console.log("방 정보에 나의 정보가 없어요!, 오류!!\n", players, CommonDef.Me);
            this.responseHoldemRoomExit([0]);
            return;
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.responseHoldemRoomInfo && CommonDef.currentScene.responseHoldemRoomInfo(firstFlag, roomid, sb, bb, ante, mode, seat_count, roomState, dealer_seat, turn_seat, turn_start, turn_duration, players, mySeat, pot, shared_cards, bettingBook, block_user_id_list);
    }
    
    requestHoldemRoomExit() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMROOMEXIT, 0);
        protocolEncoder.SendData();
    }

    responseHoldemRoomExit(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.onSuccessExit && CommonDef.currentScene.onSuccessExit();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('leave_room_error'));
        }
    }

    responseHoldemMoveTurn(data) {
        let user_id = data.shift();
        let seat = data.shift();
        let prompt = {
            call: data.shift(),
            raise: data.shift(),
            raise_min: parseFloat(data.shift()),
            raise_max: parseFloat(data.shift()),
            bet: data.shift(),
            bet_min: parseFloat(data.shift()),
            bet_max: parseFloat(data.shift()),
            fold: data.shift(),
            allin: data.shift(),
            check: data.shift()
        }
        let enable_timebank = data.shift();
        let countdown = data.shift();
        let roomid = data.shift();
        let ts = data.shift();
        CommonDef.currentScene.responseHoldemMoveTurn && CommonDef.currentScene.responseHoldemMoveTurn(seat, prompt, countdown, enable_timebank);
    }

    requestHoldemBetting(action, amount) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMBETTING, 2);
        protocolEncoder.WriteInt(0, action);
        protocolEncoder.WriteString(1, amount.toString());
        protocolEncoder.SendData();
    }

    responseHoldemShowdown() {
        CommonDef.currentScene.playShowdownAnimation && CommonDef.currentScene.playShowdownAnimation();
    }

    responseHoldemGameover(data, isTournament = false) {
        !isTournament && data.shift(); // roomid for not tournament
        let count = data.shift();
        let players = [];
        for(let i = 0 ; i < count ; i ++) {
            let player = {
                user_id: data.shift(),
                seat: data.shift(),
                winner: data.shift(),
                handcards: [],
                chip: 0,
                prize: 0,
                pattern: "",
                patternIndex: -1,
                highcards: []
            }
            let handcard_count = data.shift();
            for(let hi = 0 ; hi < handcard_count ; hi ++) {
                player.handcards.push(data.shift());
            }
            player.chip = parseFloat(data.shift());
            player.prize = parseFloat(data.shift());
            if(player.winner) {
                player.pattern = data.shift();
                player.patternIndex = parseInt(data.shift());
                let highcard_count = data.shift();
                for(let wi = 0 ; wi < highcard_count ; wi ++) {
                    player.highcards.push(data.shift());
                }
            }
            players.push(player);
        }
        CommonDef.currentScene.responseHoldemGameover && CommonDef.currentScene.responseHoldemGameover(players);
    }

    requestHoldemMessage(message) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMMESSAGE, 1);
        protocolEncoder.WriteString(0, message);
        protocolEncoder.SendData();
    }

    responseHoldemMessage(data) {
        let user_id = data.shift();
        let seat = data.shift();
        let avatar = data.shift();
        let border = data.shift();
        let nickname = data.shift();
        let message = data.shift();
        CommonDef.currentScene.responseHoldemMessage && CommonDef.currentScene.responseHoldemMessage(user_id, seat, nickname, avatar, border, message);
    }

    requestHoldemRabbit() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMRABBIT, 0);
        protocolEncoder.SendData();
    }

    responseHoldemRabbit(data) {
        let seat = data.shift();
        let userid = data.shift();
        let count = data.shift();
        let shared_cards = [];
        for(let i = 0 ; i < count ; i ++) {
            shared_cards.push(data.shift());
        }
        CommonDef.currentScene.responseHoldemRabbit(seat, userid, shared_cards);
    }

    requestHoldemTimeBank() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMTIMEBANK, 0);
        protocolEncoder.SendData();
    }

    responseHoldemTimeBank(data) {
        let seat = data.shift();
        let userid = data.shift();
        CommonDef.currentScene.responseHoldemTimeBank(seat, userid);
    }

    requestHoldemEmoticon(userid, key) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMEMOTICON, 2);
        protocolEncoder.WriteString(0, userid);
        protocolEncoder.WriteString(1, key);
        protocolEncoder.SendData();
    }

    responseHoldemEmoticon(data) {
        let from = data.shift();
        let to = data.shift();
        let key = data.shift();
        CommonDef.currentScene.responseHoldemEmoticon && CommonDef.currentScene.responseHoldemEmoticon(from, to, key);
    }

    requestGameHistory(roomid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GAMEHISTORY, 1);
        protocolEncoder.WriteString(0, roomid);
        protocolEncoder.SendData();
    }

    responseGameHistory(data) {
        let count = data.shift();
        let history = [];
        for(let i = 0 ; i < count ; i ++) {
            history.push({
                bet: parseFloat(data.shift()),
                win: parseFloat(data.shift()),
                handcards: data.shift().split(",")
            });
        }
        CommonDef.currentScene.responseGameHistory(history);
    }

    requestUserProfileData(userid, matchType) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.USERPROFILEDATA, 2);
        protocolEncoder.WriteString(0, userid);
        protocolEncoder.WriteInt(1, matchType);
        protocolEncoder.SendData();
    }
    
    responseUserProfileData(data) {
        let type = data.shift();
        let profile = {
            userid: data.shift(),
            avatar: data.shift(),
            border: data.shift(),
            nickname: data.shift(),
            ip: data.shift(),
            totalgame: data.shift(),
            totalhand: data.shift(),
            winnercount: data.shift(),
            vpip: data.shift(),
            pfr: data.shift(),
            wtsd: data.shift(),
            wssd: data.shift(),
            threebet: data.shift(),
            cbet: data.shift(),
            foldtothreebet: data.shift(),
            foldtocbet: data.shift(),
            steal: data.shift(),
            checkraise: data.shift(),
            roi: data.shift()
        }
        CommonDef.currentScene.responseUserProfileData(type, profile);
    }

    requestHoldemBlockUser(blockState, userid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMBLOCKUSER, 2);
        protocolEncoder.WriteInt(0, blockState);
        protocolEncoder.WriteString(1, userid);
        protocolEncoder.SendData();
    }

    requestHoldemPublishMyCard() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.HOLDEMPUBLISHFOLDCARD, 0);
        protocolEncoder.SendData();
    }

    requestUserProfileReport() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.USERPROFILEREPORT, 0);
        protocolEncoder.SendData();
    }

    responseUserProfileReport(data) {

    }




    // 오마하
    requestOmahaRoomInfo() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHAROOMINFO, 0);
        protocolEncoder.SendData();
    }

    responseOmahaRoomInfo(data) {
        let firstFlag = data.shift();
        let roomid = data.shift();
        let sb = parseFloat(data.shift());
        let bb = parseFloat(data.shift());
        let ante = parseFloat(data.shift());
        let mode = data.shift();
        let seat_count = data.shift();
        let roomState = data.shift();
        let dealer_seat = parseInt(data.shift());
        let turn_seat = parseInt(data.shift());
        let turn_start = data.shift();
        let turn_duration = data.shift(); // this is second
        let player_count = data.shift();
        let players = [];
        let mySeat = -1;
        for(let i = 0 ; i < player_count ; i ++) {
            let player = {
                loginid: "",
                nickname: "",
                chatpossible: true,
                handcards: [],
                coins: 0,
                round_chip: 0,
                state: 0,
                avatar: "",
                border: "",
                seat: -1,
                pattern: ""
            }
            player.loginid = data.shift();
            player.nickname = data.shift();
            player.chatpossible = data.shift();
            let handcard_count = data.shift();
            for(let count = 0 ; count < handcard_count ; count ++) {
                player.handcards.push(data.shift());
            }
            player.coins = parseFloat(data.shift());
            player.round_chip = parseFloat(data.shift());
            player.state = data.shift();
            player.avatar = data.shift();
            player.border = data.shift();
            player.seat = data.shift();
            player.pattern = parseInt(data.shift());
            players.push(player);
            if(player.loginid == CommonDef.Me.loginid) mySeat = player.seat;
        }
        let bettingBookEnable = data.shift();
        
        let bettingBook = {
            enable: bettingBookEnable
        }
        if(bettingBookEnable) {
            bettingBook = {
                enable: bettingBookEnable,
                allin: data.shift(),
                check: data.shift(),
                call: data.shift(),
                fold: data.shift(),
                book: data.shift()
            }
        }
        let pot = parseFloat(data.shift());
        let shared_card_count = data.shift();
        let shared_cards = [];
        for(let i = 0 ; i < shared_card_count ; i ++) {
            shared_cards.push(data.shift());
        }

        let block_user_count = data.shift();
        let block_user_id_list = [];
        for(let i = 0 ; i < block_user_count ; i ++) {
            block_user_id_list.push(data.shift());
        }

        if(mySeat < 0) {
            // console.log("방 정보에 나의 정보가 없어요!, 오류!!\n", players, CommonDef.Me);
            this.responseOmahaRoomExit([0]);
            return;
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.responseRoomInfo && CommonDef.currentScene.responseRoomInfo(firstFlag, roomid, sb, bb, ante, mode, seat_count, roomState, dealer_seat, turn_seat, turn_start, turn_duration, players, mySeat, pot, shared_cards, bettingBook, block_user_id_list);
    }

    requestOmahaRoomExit() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHAROOMEXIT, 0);
        protocolEncoder.SendData();
    }

    responseOmahaRoomExit(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.onSuccessExit && CommonDef.currentScene.onSuccessExit();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('leave_room_error'));
        }
    }

    responseOmahaMoveTurn(data) {
        let user_id = data.shift();
        let seat = data.shift();
        let prompt = {
            call: data.shift(),
            raise: data.shift(),
            raise_min: parseFloat(data.shift()),
            raise_max: parseFloat(data.shift()),
            bet: data.shift(),
            bet_min: parseFloat(data.shift()),
            bet_max: parseFloat(data.shift()),
            fold: data.shift(),
            allin: data.shift(),
            check: data.shift()
        }
        let enable_timebank = data.shift();
        let countdown = data.shift();
        let roomid = data.shift();
        let ts = data.shift();
        CommonDef.currentScene.responseOmahaMoveTurn && CommonDef.currentScene.responseOmahaMoveTurn(seat, prompt, countdown, enable_timebank);
    }

    requestOmahaBetting(action, amount) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHABETTING, 2);
        protocolEncoder.WriteInt(0, action);
        protocolEncoder.WriteString(1, amount.toString());
        protocolEncoder.SendData();
    }

    responseOmahaShowdown() {
        CommonDef.currentScene.playShowdownAnimation && CommonDef.currentScene.playShowdownAnimation();
    }

    responseOmahaGameover(data, isTournament = false) {
        !isTournament && data.shift(); // roomid for not tournament
        let count = data.shift();
        let players = [];
        for(let i = 0 ; i < count ; i ++) {
            let player = {
                user_id: data.shift(),
                seat: data.shift(),
                winner: data.shift(),
                handcards: [],
                chip: 0,
                prize: 0,
                pattern: "",
                patternIndex: -1,
                highcards: []
            }
            let handcard_count = data.shift();
            for(let hi = 0 ; hi < handcard_count ; hi ++) {
                player.handcards.push(data.shift());
            }
            player.chip = parseFloat(data.shift());
            player.prize = parseFloat(data.shift());
            if(player.winner) {
                player.pattern = data.shift();
                player.patternIndex = parseInt(data.shift());
                let highcard_count = data.shift();
                for(let wi = 0 ; wi < highcard_count ; wi ++) {
                    player.highcards.push(data.shift());
                }
            }
            players.push(player);
        }
        console.log("omaha game over", players);
        CommonDef.currentScene.responseOmahaGameover && CommonDef.currentScene.responseOmahaGameover(players);
    }

    requestOmahaMessage(message) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHAMESSAGE, 1);
        protocolEncoder.WriteString(0, message);
        protocolEncoder.SendData();
    }

    responseOmahaMessage(data) {
        let user_id = data.shift();
        let seat = data.shift();
        let avatar = data.shift();
        let border = data.shift();
        let nickname = data.shift();
        let message = data.shift();
        CommonDef.currentScene.responseOmahaMessage && CommonDef.currentScene.responseOmahaMessage(user_id, seat, nickname, avatar, border, message);
    }

    requestOmahaRabbit() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHARABBIT, 0);
        protocolEncoder.SendData();
    }

    responseOmahaRabbit(data) {
        let seat = data.shift();
        let userid = data.shift();
        let count = data.shift();
        let shared_cards = [];
        for(let i = 0 ; i < count ; i ++) {
            shared_cards.push(data.shift());
        }
        CommonDef.currentScene.responseOmahaRabbit(seat, userid, shared_cards);
    }

    requestOmahaTimeBank() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHATIMEBANK, 0);
        protocolEncoder.SendData();
    }

    responseOmahaTimeBank(data) {
        let seat = data.shift();
        let userid = data.shift();
        CommonDef.currentScene.responseOmahaTimeBank(seat, userid);
    }

    requestOmahaEmoticon(userid, key) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHAEMOTICON, 2);
        protocolEncoder.WriteString(0, userid);
        protocolEncoder.WriteString(1, key);
        protocolEncoder.SendData();
    }

    responseOmahaEmoticon(data) {
        let from = data.shift();
        let to = data.shift();
        let key = data.shift();
        CommonDef.currentScene.responseOmahaEmoticon && CommonDef.currentScene.responseOmahaEmoticon(from, to, key);
    }

    requestOmahaBlockUser(blockState, userid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHABLOCKUSER, 2);
        protocolEncoder.WriteInt(0, blockState);
        protocolEncoder.WriteString(1, userid);
        protocolEncoder.SendData();
    }

    requestOmahaPublishMyCard() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OMAHAPUBLISHFOLDCARD, 0);
        protocolEncoder.SendData();
    }



    // 토너먼트
    requestTournamentList(filterValue = -1) {
        console.log("request tournamnet list", filterValue);
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTLIST, 1);
        protocolEncoder.WriteString(0, filterValue.toString());
        protocolEncoder.SendData();
    }

    responseTournamentList(data) {
        console.log("responseTournamentList", data);
        let count = data.shift();
        let tournamentList = [];
        for(let i = 0 ; i < count ; i ++) {
            tournamentList.push({
                id: data.shift(),
                title: data.shift(),
                buyin: parseInt(data.shift()),
                fee: parseInt(data.shift()),
                state: data.shift(),
                prize: parseInt(data.shift()),
                player_count: data.shift(),
                total_entry: data.shift(),
                blinds_up_time: data.shift(),
                start_time: data.shift(),
                registered: data.shift()
            })
        }
        console.log(tournamentList);
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentTableContent && CommonDef.currentScene.setTournamentTableContent(tournamentList);
    }

    requestTournamentDetail(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTDETAIL, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseTournamentDetail(data) {
        let detail = {
            id: data.shift(),
            title: data.shift(),
            prize: parseInt(data.shift()),
            total_entry: data.shift(),
            player_count: data.shift(),
            seat_count: data.shift(),
            state: data.shift(),
            starttime: data.shift(),
            blinds_up_time: data.shift(),
            late_reg_level: data.shift(),
            current_level: data.shift(),
            partin: data.shift(),
            buyin: parseInt(data.shift()),
            fee: parseInt(data.shift()),
            rebuy_amount: parseInt(data.shift()),
            start_chip: parseInt(data.shift()),
            rebuy_count: parseInt(data.shift()),
            break_time_cycle: data.shift(),
            break_time: data.shift(),
            min_entry: data.shift(),
            max_entry: data.shift(),
            av_stack: data.shift(),
            addon: parseInt(data.shift()),
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentDetail && CommonDef.currentScene.setTournamentDetail(detail);
    }

    requestTournamentBlinds(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTBLINDS, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseTournamentBlinds(data) {
        let tournamentid = data.shift();
        let count = data.shift();
        let blinds = [];
        for(let i = 0 ; i < count ; i ++) {
            blinds.push({
                level: data.shift(),
                sb: parseInt(data.shift()),
                bb: parseInt(data.shift()),
                ante: parseInt(data.shift())
            })
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentBlinds && CommonDef.currentScene.setTournamentBlinds(blinds);
    }

    requestTournamentEntry(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTENTRY, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseTournamentEntry(data) {
        let tournamentid = data.shift();
        let tournamentstate = data.shift();
        let count = data.shift();
        let entries = [];
        for(let i = 0 ; i < count ; i ++) {
            entries.push({
                userid: data.shift(),
                nickname: data.shift(),
                avatar: data.shift(),
                border: data.shift(),
                balance: data.shift(),
                rank: data.shift(),
                prize: parseInt(data.shift()),
                rebuycount: data.shift(),
                addoncount: data.shift(),
                gameover: data.shift()
            })
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentEntry && CommonDef.currentScene.setTournamentEntry(tournamentstate, entries);
    }

    requestTournamentTable(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTTABLE, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseTournamentTable(data) {
        let tournamentid = data.shift();
        let count = data.shift();
        let tables = [];
        for(let i = 0 ; i < count ; i ++) {
            tables.push({
                index: data.shift(),
                players: data.shift(),
                min: parseInt(data.shift()),
                max: parseInt(data.shift())
            });
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentTable && CommonDef.currentScene.setTournamentTable(tables);
    }

    requestTournamentPrize(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTPRIZE, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseTournamentPrize(data) {
        let tournamentid = data.shift();
        let count = data.shift();
        let prizes = [];
        let amount = 0;
        for(let i = 0 ; i < count ; i ++) {
            let prizeBuf = {
                rank: data.shift(),
                amount: data.shift()
            }
            if(prizeBuf.amount == amount) {
                prizeBuf[prizeBuf.length - 1].endrank++;
            } else {
                prizes.push({
                    startrank: prizeBuf.rank,
                    endrank: prizeBuf.rank,
                    prize: {
                        type: 0,
                        amount: prizeBuf.amount,
                    }
                })
            }
        }
        CommonDef.ServerTime = data.shift();
        CommonDef.currentScene.setTournamentPrize && CommonDef.currentScene.setTournamentPrize(prizes);
    }

    requestRegisterTournament(tournamentid, ticketid = 0) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.REGISTERTOURNAMENT, 2);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.WriteInt(1, ticketid);
        protocolEncoder.SendData();
    }

    responseRegisterTournament(data) {
        let result = data.shift();
        if(result == 0) {
            if(CommonDef.currentScene.scene.key == "TournamentHoldemScene") {
                this.requestTournamentTableInfo();
            }
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error2'));
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error3'));
        } else if(result == 4) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error4'));
        } else if(result == 5) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error5'));
        } else if(result == 6) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error6'));
        } else if(result == 7) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error7'));
        } else if(result == 8) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error8'));
        } else if(result == 9) {
            CommonDef.currentScene.showToast("failed", translate('tournament_register_error9'));
        } 
    }

    requestUnregisterTournament(tournamentid) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.UNREGISTERTOURNAMENT, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseUnregisterTournament(data) {
        let result = data.shift();
        if(result == 0) {

        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('tournament_unregister_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('tournament_unregister_error2'));
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('tournament_unregister_error3'));
        }
    }

    requestCancelTournamentRebuy(tournamentid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.CANCELTOURNAMENTREBUY, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    requestEnterTournament(tournamentid) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.ENTERTOURNAMENT, 1);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.SendData();
    }

    responseEnterTournament(data) {
        let result = data.shift();
        if(result == 0) {
            let gameID = data.shift();
            CommonDef.currentScene.onSuccessJoinTournamentTable(gameID);
        } else {
            CommonDef.currentScene.showToast("failed", translate('join_error1'));
        }
    }

    requestObservingTournament(tournamentid, tableIndex = 0) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.OBSERVINGTOURNAMENT, 2);
        protocolEncoder.WriteInt(0, tournamentid);
        protocolEncoder.WriteInt(1, tableIndex);
        protocolEncoder.SendData();
    }

    responseObservingTournament(data) {
        let result = data.shift();
        if(result == 0) {
            let gameID = data.shift();
            CommonDef.currentScene.onSuccessJoinTournamentTable(gameID);
        } else {
            CommonDef.currentScene.showToast("failed", translate('tournament_observe_error'));
        }
    }

    responseTournamentAlarm(data) { // 토너먼트 알림 & 관리자 알림 메시지
        if(document.hidden) return;
        let message = data.shift();
        CommonDef.AlarmStack.push(message.replace("\n", " "));
        CommonDef.currentScene.showAlert();
    }

    requestTournamentListByTicket(ticketid) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.GETTOURNAMENTBYTICKET, 1);
        protocolEncoder.WriteInt(0, ticketid);
        protocolEncoder.SendData();
    }

    responseTournamentListByTicket(data) {
        let count = data.shift();
        let tournamentList = [];
        for(let i = 0 ; i < count ; i ++) {
            tournamentList.push({
                id: data.shift(),
                title: data.shift(),
                buyin: parseInt(data.shift()),
                fee: parseInt(data.shift()),
                state: data.shift(),
                prize: parseInt(data.shift()),
                player_count: data.shift(),
                total_entry: data.shift(),
                blinds_up_time: data.shift(),
                start_time: data.shift(),
                registered: data.shift()
            })
        }
        CommonDef.currentScene.setTournamentTableContent && CommonDef.currentScene.setTournamentTableContent(tournamentList);
    }





    requestTournamentTableInfo() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLEINFO, 0);
        protocolEncoder.SendData();
    }

    responseTournamentRoomInfo(data) {
        let firstFlag = data.shift(); 
        let tableinfo = {
            tournamentid: data.shift(),
            tableindex: data.shift(),
            starttime: data.shift(),
            av_stack: parseInt(data.shift()),
            total_addon_count: data.shift(),
            curlevel: data.shift(),
            cursb: parseInt(data.shift()),
            curbb: parseInt(data.shift()),
            curante: parseInt(data.shift()),
            nextlevel: data.shift(),
            nextsb: parseInt(data.shift()),
            nextbb: parseInt(data.shift()),
            nextante: parseInt(data.shift()),
            blinduptime: data.shift(),
            curlevelstartedat: data.shift(),
            dealer_seat: data.shift(), 
            turn_seat: data.shift(),
            turn_start: data.shift(),
            turn_duration: data.shift(),
            addon: parseInt(data.shift()),
            myaddon: data.shift(),
            enablebuyin: data.shift(),
            enablerebuyin: data.shift(),
            buyin: parseInt(data.shift()),
            fee: parseInt(data.shift()),
            rebuy: parseInt(data.shift()),
            roomstate: data.shift(),
            tournamentstate: data.shift(),
            isbreaktime: data.shift(),
            iswaiting: data.shift(),
            waitingstart: data.shift(),
            breakstart: data.shift(),
            breaktime: data.shift(),
            totalplayercount: data.shift(),
            prizelastrank: data.shift(),
            players: [],
        }
        // if(tableinfo.isbreaktime == 1 && tableinfo.iswaiting == 0) {
        //     tableinfo.breakstart = data.shift();
        //     tableinfo.breaktime = data.shift();
        // }
        // tableinfo.totalplayercount = data.shift();
        // tableinfo.prizelastrank = data.shift();
        let player_count = data.shift();
        let mySeat = -1;
        for(let i = 0 ; i < player_count ; i ++) {
            let player = {
                loginid: "",
                nickname: "",
                chatpossible: true,
                handcards: [],
                coins: 0,
                round_chip: 0,
                state: 0,
                sittingout: 0,
                avatar: "",
                border: "",
                seat: -1,
                pattern: ""
            }
            player.loginid = data.shift();
            player.nickname = data.shift();
            player.chatpossible = data.shift();
            let handcard_count = data.shift();
            for(let count = 0 ; count < handcard_count ; count ++) {
                player.handcards.push(data.shift());
            }
            player.coins = parseFloat(data.shift());
            player.round_chip = parseFloat(data.shift());
            player.state = data.shift();
            player.sittingout = data.shift();
            player.avatar = data.shift();
            player.border = data.shift();
            player.seat = data.shift();
            player.pattern = parseInt(data.shift());
            tableinfo.players.push(player);
            if(player.loginid == CommonDef.Me.loginid) mySeat = player.seat;
        }
        let bettingBookEnable = data.shift();
        
        let bettingBook = {
            enable: bettingBookEnable
        }
        if(bettingBookEnable) {
            bettingBook = {
                enable: bettingBookEnable,
                allin: data.shift(),
                check: data.shift(),
                call: data.shift(),
                fold: data.shift(),
                book: data.shift()
            }
        }
        let pot = parseFloat(data.shift());
        let shared_card_count = data.shift();
        let shared_cards = [];
        for(let i = 0 ; i < shared_card_count ; i ++) {
            shared_cards.push(data.shift());
        }

        let block_user_count = data.shift();
        let block_user_id_list = [];
        for(let i = 0 ; i < block_user_count ; i ++) {
            block_user_id_list.push(data.shift());
        }

        CommonDef.ServerTime = data.shift();

        if(mySeat < 0) {
            // console.log("방 정보에 나의 정보가 없어요!, 저는 관전자입니다");
            // return;
        }
        CommonDef.currentScene.responseTournamentTableInfo && CommonDef.currentScene.responseTournamentTableInfo(firstFlag, tableinfo, mySeat, pot, shared_cards, bettingBook, block_user_id_list);
    }

    responseTournamentTableExit(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.onSuccessExit && CommonDef.currentScene.onSuccessExit();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('leave_room_error'));
        }
    }

    requestTournamentTableExit() {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLEEXIT, 0);
        protocolEncoder.SendData();
    }
    
    requestTournamentTableBetting(action, amount) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLEBETTING, 2);
        protocolEncoder.WriteInt(0, action);
        protocolEncoder.WriteString(1, amount.toString());
        protocolEncoder.SendData();
    }

    requestTournamentTalbeMessage(message) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLEGAMEMESSAGE, 1);
        protocolEncoder.WriteString(0, message);
        protocolEncoder.SendData();
    }

    requestTournamentTableRabbit() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLERABBIT, 0);
        protocolEncoder.SendData();
    }

    requestTournamentTableTimeBank() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLETIMEBANK, 0);
        protocolEncoder.SendData();
    }

    requestTournamentTableEmoticon(userid, key) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTTABLEEMOTICON, 2);
        protocolEncoder.WriteString(0, userid);
        protocolEncoder.WriteString(1, key);
        protocolEncoder.SendData();
    }

    requestImBack() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.IMBACK, 0);
        protocolEncoder.SendData();
    }

    requestTournamentHoldemAddon(tournamentid, addonFlag) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTHOLDEMADDON, 2);
        protocolEncoder.WriteInt(0, tournamentid)
        protocolEncoder.WriteInt(1, addonFlag)
        protocolEncoder.SendData();
    }

    responseTournamentHoldemAddon(data) {
        let result = data.shift();
        if(result == 0) {
            CommonDef.currentScene.responseAddon();
        } else if(result == 1) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error1'));
        } else if(result == 2) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error2'));
        } else if(result == 3) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error3'));
        } else if(result == 4) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error4'));
        } else if(result == 5) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error5'));
        } else if(result == 6) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error6'));
        } else if(result == 7) {
            CommonDef.currentScene.showToast("failed", translate('tournament_addon_error7'));
        }
    }

    responseTournamentResult(data) {
        let tournamentid = data.shift();
        let tournamentstate = data.shift();
        let myrank = data.shift();
        let myaward = parseInt(data.shift());
        let count = data.shift();
        let players = [];
        for(let i = 0 ; i < count ; i ++) {
            players.push({
                userid: data.shift(),
                nickname: data.shift(),
                avatar: data.shift(),
                border: data.shift(),
                rank: data.shift(),
                award: {
                    type: 0,
                    amount: parseInt(data.shift()),
                    shopid: data.shift(),
                    icon: "",
                    name: ""
                }
            })
        }
        CommonDef.currentScene.showFinalResult && CommonDef.currentScene.showFinalResult(tournamentstate == CommonDef.tournament_state.FINISHED, myrank, myaward, players);
    }

    requestTournamentBlockUser(blockState, userid) {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTBLOCKUSER, 2);
        protocolEncoder.WriteInt(0, blockState);
        protocolEncoder.WriteString(1, userid);
        protocolEncoder.SendData();
    }

    requestTournamentPublishMyCard() {
        let protocolEncoder = ProtocolEncoder.create(MessageCode.TOURNAMENTPUBLISHFOLDCARD, 0);
        protocolEncoder.SendData();
    }

    requestReport(roomid, content, reporter, receiver, type, message_index) {
        CommonDef.currentScene.playLoading && CommonDef.currentScene.playLoading();
        let protocolEncoder = ProtocolEncoder.create(MessageCode.REPORT, 6);
        protocolEncoder.WriteString(0, roomid);
        protocolEncoder.WriteString(1, content);
        protocolEncoder.WriteString(2, reporter);
        protocolEncoder.WriteString(3, receiver);
        protocolEncoder.WriteInt(4, type);
        protocolEncoder.WriteInt(5, message_index);
        protocolEncoder.SendData();
    }
    responseReport(data) {
        let result = data.shift();
        let message_index = 0;
        if(result == 0) {
            message_index = data.shift();
        } else {
            CommonDef.currentScene.showToast("failed", translate('report_error'));
        }
        CommonDef.currentScene.responseReport && CommonDef.currentScene.responseReport(message_index);
    }
}