import { HistoryChart } from "../../../components/chart/historychart";
import { ScrollView } from "../../../components/container/scrollview";
import { UnderlineTabContainer } from "../../../components/container/underline_tabcontainer";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";

export class TabHistoryReport extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.pageName = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 0 : 295,
            y: this.scene.orientation == "pc" ? 193 : 232,
            text: "",
            fontSize: this.scene.orientation == "pc" ? 22 : 24,
            color: "#ffffff" 
        }).setOrigin(this.scene.orientation == "pc" ? 0 : 0.5, 0);
        this.add(this.pageName);

        this.chartContainer = this.scene.add.container(0, this.scene.orientation == "pc" ? 234 : 284);
        this.add(this.chartContainer);

        this.underlineTabContainer = new UnderlineTabContainer(this.scene, {
            x: 0,
            y: 0,
            back: 0x222222,
            width: this.scene.orientation == "pc" ? 450 : 590,
            height: this.scene.orientation == "pc" ? 50 : 66,
            tabs: [{
                name: "일",
                x: 0,
                y: 0,
                width: this.scene.orientation == "pc" ? 150 : 197,
                content: new HistoryChart(this.scene, {
                    type: "day",
                    x: 0,
                    y: this.scene.orientation == "pc" ? 52 : 68,
                    back: [0x222222, 0x333333],
                    width: this.scene.orientation == "pc" ? 450 : 590,
                    height: this.scene.orientation == "pc" ? [177, 58] : [232, 77]
                })
            }, {
                name: "월",
                x: this.scene.orientation == "pc" ? 150 : 197,
                y: 0,
                width: this.scene.orientation == "pc" ? 150 : 197,
                content: new HistoryChart(this.scene, {
                    type: "month",
                    x: 0,
                    y: this.scene.orientation == "pc" ? 52 : 68,
                    back: [0x222222, 0x333333],
                    width: this.scene.orientation == "pc" ? 450 : 590,
                    height: this.scene.orientation == "pc" ? [177, 58] : [232, 77]
                })
            }, {
                name: "최근 7일",
                x: this.scene.orientation == "pc" ? 300 : 394,
                y: 0,
                width: this.scene.orientation == "pc" ? 150 : 196,
                content: new HistoryChart(this.scene, {
                    type: "recent",
                    x: 0,
                    y: this.scene.orientation == "pc" ? 52 : 68,
                    back: [0x222222, 0x333333],
                    width: this.scene.orientation == "pc" ? 450 : 590,
                    height: this.scene.orientation == "pc" ? [177, 58] : [232, 77]
                })
            }],
            atlas: this.config.atlas,
            active_underline: "tab_underline_" + this.scene.orientation + ".png",
            color: {
                inactive: '#777777',
                active: '#ffffff'
            },
            fontSize: this.scene.orientation == "pc" ? 18 : 24
        });
        this.chartContainer.add(this.underlineTabContainer);

        this.underlineTabContainer.tabs[0].content.setData([30, 22, 45, -19, -33, 27, 53, 44, -38, 20, -10, -3, -29, 45, 21]);
        this.underlineTabContainer.tabs[1].content.setData([20, 12, -45, 19, -23, 17, -33, 9, -13, -12, 30, -29]);
        this.underlineTabContainer.tabs[2].content.setData([30, 22, 45, -19, -33, 27, 53]);

        let listContent = this.scene.add.container(0, 0);

        this.scrollViewContainer = new ScrollView(this.scene, {
            x: this.scene.orientation == "pc" ? 490 : 0,
            y: this.scene.orientation == "pc" ? 237 : 723,
            width: this.scene.orientation == "pc" ? 447 : 587,
            height: this.scene.orientation == "pc" ? 284 : 378,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: listContent,
            mask: {
                x: this.scene.orientation == "pc" ? 980 : 80,
                y: this.scene.orientation == "pc" ? 568 : 892
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 410 : 560,
                height: this.scene.orientation == "pc" ? 288 : 378
            }
        });
        this.add(this.scrollViewContainer);
    }

    setData(data) {
        data.type && this.pageName.setText(data.type);
        if(data.list) {
            let item_height = this.scene.orientation == "pc" ? 81 : 108;
            this.scrollViewContainer.content.removeAll(true);
            for(let i = 0 ; i < data.list.length ; i ++) {
                let item = this.scene.add.container(0, i * item_height);
                let divider = this.scene.add.graphics({
                    x: 0,
                    y: 0,
                    fillStyle: {
                        color: 0x222222,
                        alpha: 1
                    }
                });
                divider.fillRect(0, 0, this.scene.orientation == "pc" ? 410 : 537, 1);
                item.add(divider);
                this.scrollViewContainer.content.add(item)

                let date = TextClass.plainText1(this.scene, {
                    x: 0,
                    y: item_height / 2,
                    text: data.list[i].date,
                    color: "#777777",
                    fontSize: this.scene.orientation == "pc" ? 16 : 21
                }).setOrigin(0, 0.5);
                item.add(date);

                let gameProcess = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 161 : 122,
                    y: item_height / 2,
                    text: "게임: " + data.list[i].games + "\n핸드: " + data.list[i].hands,
                    color: "#777777",
                    fontSize: this.scene.orientation == "pc" ? 16 : 21
                }).setOrigin(0, 0.5);
                gameProcess.setLineSpacing(10);
                item.add(gameProcess);

                let total = TextClass.plainText1(this.scene, {
                    x: this.scene.orientation == "pc" ? 410 : 537,
                    y: item_height / 2,
                    text: (data.list[i].total >= 0 ? "+" : "") + NumberUtil.convertNumberWithComma(data.list[i].total) + "php",
                    color: data.list[i].total >= 0 ? "#00e793" : "#d70056",
                    fontSize: this.scene.orientation == "pc" ? 20 : 26
                }).setOrigin(1, 0.5);
                item.add(total);
            }
            this.scrollViewContainer.content.height = data.list.length * item_height + 2;
            this.scrollViewContainer.updateScroll();
        }
        if(data.chart) {
            this.underlineTabContainer.tabs[0].content.setData(data.chart[0]);
            this.underlineTabContainer.tabs[1].content.setData(data.chart[1]);
            this.underlineTabContainer.tabs[2].content.setData(data.chart[2]);
        }
    }
}