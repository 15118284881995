var ByteArray = require('../Utils/ByteArray')
var cc = cc || {}

class PacketBuffer {
    constructor() {
        this._TYPE_CODE = {
            Empty   : 0,
            Object  : 1,
            DBNull  : 2,
            Boolean : 3,
            Char    : 4,
            SByte   : 5,
            Byte    : 6,
            Int16   : 7,
            UInt16  : 8,
            Int32   : 9,
            UInt32  : 10,
            Int64   : 11,
            UInt64  : 12,
            Single  : 13,
            Double  : 14,
            Decimal : 15,
            DateTime: 16,
            String  : 18
        }
        //this._super()
        this.init()
        return true
    };
    _getKeyFromi(i, __keys) {
        if(!__keys){
            return i
        }
        if(__keys[i]){
            return __keys[i]
        }
        return i
    };
    _createBody(__fmt, __body) {
        console.assert(__fmt.length == __body.length, __fmt.length + "!=" + __body.length + " The number of format string must be equivalent to body's!");

        var __buf = this.getBaseBA()
        for(var i = 0; i < __fmt.length; i++){
            var __f = __fmt.substring(i, i + 1)
            if(__f == "R"){
                __buf.writeUInt(__body[i])
            }else if(__f == "S"){
                __buf.writeStringUShort(__body[i])
            }else if(__f == "r"){
                __buf.writeInt(__body[i])
            }else if (__f == "l"){
                __buf.writeInt64(__body[i])
            }else if (__f == "W"){
                __buf.writeUShort(__body[i])
            }else if(__f == "s"){
                __buf.writeShort(__body[i])
            }else if(__f == "f"){
                __buf.writeFloat(__body[i])
            }else if(__f == "D"){
                __buf.writeDouble(__body[i])
            }else if(__f == "B"){
                __buf.writeBool(__body[i])
            }else if(__f == "b"){
                __buf.writeByte(__body[i])
            }
        }
        return __buf
    };
    _parseBody(__buf) {
        var __body = []
        var __bodyByteBuffer = this.getBaseBA()
        //서버로부터 받은 메시지의 본체의 첫부분(인트형 4바이트)데이터 개수
        var __dataCnt = __buf.readInt()
        //해당개수 만큼 읽으면서 첫바이트는 데이터의 타입이고 해당 타입에따라 데이터 자료를 읽는 함수호출
        for (var i = 0; i < __dataCnt; i++){
            var __codetype = (__buf.readByte()) || 0
            var __value = null;
            switch (__codetype) {
                case this._TYPE_CODE.Boolean:
                    __value = __buf.readBool() || 0
                    __bodyByteBuffer.writeBool(__value)
                    break;
                case this._TYPE_CODE.Int16:
                case this._TYPE_CODE.UInt16:
                case this._TYPE_CODE.Int32:
                case this._TYPE_CODE.UInt32:
                    __value = __buf.readInt() || 0
                    __bodyByteBuffer.writeInt()
                    break;
                case this._TYPE_CODE.Int64:
                case this._TYPE_CODE.UInt64:
                    __value = __buf.readInt64 () || 0
                    __bodyByteBuffer.writeInt64()
                    break;
                case this._TYPE_CODE.Char:
                    __value = __buf.readChar() || 0
                    __bodyByteBuffer.writeChar()
                    break;
                case this._TYPE_CODE.Double:
                    __value = __buf.readDouble() || 0
                    __bodyByteBuffer.writeDouble()
                    break;
                    break;
                case this._TYPE_CODE.Byte:
                    __value = __buf.readByte() || 0
                    break;
                case this._TYPE_CODE.String:
                    //__value = (__buf.readStringUShort()) || ""
                    __value = (__buf.readStringUInt()) || ""
                    __bodyByteBuffer.writeStringUShort(__value)
                    break;
            }

            var __key = this._getKeyFromi(i, null)  //__keys
            __body[__key] = __value
        }

        __bodyByteBuffer._pos = 0
        return [__body, __bodyByteBuffer]
    };
    getBaseBA() {
        return new ByteArray(PacketBuffer.ENDIAN)
    };
    createPacket(__msgCode, __msgFmt, __msgCnt, __msgBodyTable) {
        var __buf = this.getBaseBA()
        var __bodyBA = this._createBody(__msgFmt, __msgBodyTable)
        var __bodyLen = __bodyBA.getLen() + 4;

        __buf.rawPack("<bbii",
            [PacketBuffer.MASK0,
                __msgCode,
                __bodyLen,
                __msgCnt])

        __buf.writeBuf(__bodyBA._buf)
        return __buf._buf
    };
    init() {
        this._buf = this.getBaseBA()
    };
    parsePackets(__byteString) {
        var __msgs = []
        var __pos = 0
        this._buf.setPos(this._buf.getLen())
        this._buf.writeBuf(__byteString)
        this._buf.setPos(0)
        var __signature = null
        var __preLen = PacketBuffer.SIGNATURE_LEN + PacketBuffer.MSGCODE_LEN + PacketBuffer.BODY_LEN
        while (this._buf.getAvailable() >= __preLen) {
            __signature = this._buf.readByte()
            if (__signature == PacketBuffer.MASK0) {
                var __msgCode = this._buf.readByte()
                var __bodyLen = this._buf.readInt()

                if (this._buf.getAvailable() < __bodyLen) {
                    this._buf.setPos(this._buf.getPos() - __preLen)
                    break
                }

                if(__bodyLen <= PacketBuffer.PACKET_MAX_LEN){
                    var __msg = []
                    __msg.code= __msgCode
                    var data = this._parseBody(this._buf)
                    __msg.body = data[0]
                    __msg.bodyByteBuffer =data[1]
                    __msgs.push(__msg)
                }
            }
        }

        if(this._buf.getAvailable() <= 0){
            this.init()
        }else {
            var __tmp = this.getBaseBA()
            this._buf.readBytes(__tmp, 1, this._buf.getAvailable())
            this._buf = __tmp
        }
        return __msgs
    };
}
PacketBuffer._instance = null;
PacketBuffer.create = function () {
    if(PacketBuffer._instance == null){
        PacketBuffer._instance = new PacketBuffer()
    }
    return PacketBuffer._instance
}

PacketBuffer.ENDIAN = ByteArray.ENDIAN_LITTLE

//서버와 약속한 파케트의 시그네이쳐
PacketBuffer.MASK0 = 0x80

//PacketBuffer.PACKET_MAX_LEN = 2100000000
//서버와 약속한 최대 파케트길이
PacketBuffer.PACKET_MAX_LEN = 20 * 1024 * 1024
//파케트 머리부 길이
PacketBuffer.SIGNATURE_LEN = 1;
PacketBuffer.MSGCODE_LEN = 1;
PacketBuffer.BODY_LEN = 4;
module.exports = PacketBuffer
