import { IconButton } from "../../../components/button/iconbutton";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import { Lang } from "../../../lang";
import CommonDef from "../../../def/commondef";

export class TabPassword extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.current_password_label = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 258 : 0,
            y: this.scene.orientation == "pc" ? 88 : 105,
            text: Lang[CommonDef.Lang].current_password,
            color: "#f58a00",
            fontFamily: "GmarketSansMedium",
            fontSize: 20
        }).setOrigin(0, 0);
        this.add(this.current_password_label);

        this.current_password_field = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 258 : 0,
            y: this.scene.orientation == "pc" ? 132 : 138,
            displayTextY: 0,
            atlas: this.config.atlas,
            back: "password_field_back_" + this.scene.orientation + ".png",
            input: {
                x: 21,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 750 : 80,
                    y: this.scene.orientation == "pc" ? 464 : 308,
                },
                type: "password",
                width: this.scene.orientation == "pc" ? 420 : 590,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: Lang[CommonDef.Lang].current_password_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.current_password_field);

        this.new_password_label = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 258 : 0,
            y: this.scene.orientation == "pc" ? 220 : 238,
            text: Lang[CommonDef.Lang].new_password,
            color: "#f58a00",
            fontFamily: "GmarketSansMedium",
            fontSize: 20
        }).setOrigin(0, 0);
        this.add(this.new_password_label);

        this.new_password_field = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 258 : 0,
            y: this.scene.orientation == "pc" ? 265 : 270,
            displayTextY: 0,
            atlas: this.config.atlas,
            back: "password_field_back_" + this.scene.orientation + ".png",
            input: {
                x: 21,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 750 : 80,
                    y: this.scene.orientation == "pc" ? 597 : 440,
                },
                type: "password",
                width: this.scene.orientation == "pc" ? 420 : 590,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: Lang[CommonDef.Lang].new_password_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.new_password_field);

        this.new_password_confirm_field = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 258 : 0,
            y: this.scene.orientation == "pc" ? 340 : 360,
            displayTextY: 0,
            atlas: this.config.atlas,
            back: "password_field_back_" + this.scene.orientation + ".png",
            input: {
                x: 21,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 750 : 80,
                    y: this.scene.orientation == "pc" ? 672 : 530,
                },
                type: "password",
                width: this.scene.orientation == "pc" ? 420 : 590,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: Lang[CommonDef.Lang].new_password_confirm_placeholder,
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.new_password_confirm_field);

        this.btn_ok = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 370 : 202,
            y: this.scene.orientation == "pc" ? 435 : 471,
            atlas: this.config.atlas,
            scale: [0.82, 0.95],
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 43,
                y: 17,
                value: Lang[CommonDef.Lang].change_confirm,
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.changePassword.bind(this)
        });
        this.add(this.btn_ok);
    }

    changePassword() {
        let oldPassword = this.current_password_field.value;
        let newPassword = this.new_password_field.value;
        let confirmPassword = this.new_password_confirm_field.value;
        if(newPassword != confirmPassword) {
            this.scene.showToast("failed", Lang[CommonDef.Lang].error_password_not_match)
            return;
        }
        this.scene.requestChagnePassword(oldPassword, newPassword, confirmPassword);
    }

    resetFields() {
        this.current_password_field.setValue("");
        this.new_password_field.setValue("");
        this.new_password_confirm_field.setValue("");
    }
}