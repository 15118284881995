import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import * as DateTimeUtil from "../../../util/datetime";

export class DepositWithdrawItem extends Phaser.GameObjects.Container {
    constructor(scene, config){
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "row_back_" + this.scene.orientation +".png").setOrigin(0, 0);
        this.add(this.back);
        this.dateText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 18 : 365,
            y: this.scene.orientation == "pc" ? 19 : 40,
            text: DateTimeUtil.convertDefaultDateTime(this.config.created_at),
            fontSize: 14,
            color: "#777777"
        }).setOrigin(0, 0.5);
        this.add(this.dateText);
        this.amountText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 18 : 30,
            y: this.scene.orientation == "pc" ? 50 : 40,
            text: (this.config.amount > 0 ? "+" : "") + NumberUtil.convertNumberWithComma(this.config.amount) + "php",
            fontSize: 28,
            color: this.config.amount > 0 ? "#00e793" : "#d70056"
        }).setOrigin(0, 0.5);
        this.add(this.amountText);
    }
}