var ConnectionMgr = require('./protocol/ConnectionMgr')
var LoginResult = require('./protocol/LoginResult')
export class Transmitter {
    static ip = ""
    static port = 0
    static name = ""
    static password = ""
    static encrypt = true
    static schedulerID = null
    static className = "com/wd/jni/JniMgr"

    static MutilNet=null
    static luabridge = null
    static connectionMgr = ConnectionMgr.ConnectionMgr.create()
    static AdoManager = null;
    static t = []
    static gameCommand = null
}



Transmitter.setMutilNet = function(_MutilNet){
    Transmitter.MutilNet = _MutilNet
}
Transmitter.onLoginResponse = function (code){
    // AdoManager.loginCode = code
}
Transmitter.init = function () {
    var t = []
    t[0] = Transmitter.onConnect
    t[1] = Transmitter.onTimeOut
    t[2] = Transmitter.onReconnect
    t[3] = Transmitter.onReady
    t[4] = Transmitter.onDisconnect
    t[5] = Transmitter.onAccessDenied
    t[6] = Transmitter.onOutPlay
    t[7] = Transmitter.onCommand
    t[8] = Transmitter.onNetworkFail
    Transmitter.connectionMgr.initNetworkTable(t)
    //Transmitter.dlg = ModalDlg.create()
    Transmitter.t = t
}
Transmitter.tick = function () {
    Transmitter.connectionMgr.processHeartbeat()
}
Transmitter.connect = function (version, username, password, IP, port, reconnect) {
    Transmitter.connectionMgr.connect(version, username, password, IP, port, reconnect)
    //LobbyData.status = LobbyData.eStatus.loginning
}
Transmitter.signup = function (version, IP, PORT, signupCode, data, reconnect) {
    Transmitter.connectionMgr.signup(version, IP, PORT, signupCode, data, reconnect);
}
Transmitter.disconnect = function(){
    Transmitter.connectionMgr.disconnect();
}
Transmitter.onConnect = function () {
    // console.log("소켓연결 성공")
}
Transmitter.onTimeOut = function () {
    //if(LobbyData.status == LobbyData.eStatus.loginning){
    //    showMsg("Time out에러, please try again!");
    //}
    //showMsg("Time out에러, please try again!");
}
Transmitter.onReconnect = function () {
    //GameScene.scene.onReconnect()
    // console.log("소켓이 연결된상태에서 또 다른 연결을 시도하였다는것같음.");
}
Transmitter.onReady = function () {
    //로그인이 성공하면 준비된상태
    // console.log("로그인이 성공하여 응답메시지를 받았습니다.");
}
Transmitter.onDisconnect = function () {
    // console.log("서버와의 소켓연결이 끊어졌습니다. 다시 시도해주세요.");
    Transmitter.AdoManager.logout();
    //if(!LobbyData.lobby){
    //    LobbyData.reconnectGame = LobbyData.runningGame
    //}
    // toastr.error("연결이 끊어졌습니다. 다시 연결을시도해 주세요!");
}
Transmitter.onAccessDenied = function (loginResult) {
    switch (loginResult) {
        case LoginResult.OK:
            break;
        case LoginResult.ALREADYLOGGEDIN:
            // showMsg("이미 방에 입장하였습니다.")
            // window.history.back();
            break;
        case LoginResult.IDPASSWORDMISMATCH:
            // showMsg("가입정보가 맞지않습니다.")
            // window.history.back();
            break;
        case LoginResult.ACCOUNTLOGINDISABLED:
            // showMsg("가입불가능한 유저입니다.");
            // window.history.back();
            break;
        case LoginResult.BANEDIP:
            // showMsg("제한된 아이피입니다.");
            break;
        case LoginResult.SAMEIP:
            // showMsg("다른 유저가 이미 가입한 아이피 입니다.")
            break
    }
}
Transmitter.onOutPlay = function (id) {
    if(id * 1 == 1 && mgfx != null){
        mgfx.onAckInGameFail()
        return
    }

    if(id * 1 == 1 && ggfx != null && isMutilGame){
        MutilNet.onOutPlay()
        return
    }

    if(id * 1 == 100){
        //director.end()
        return
    }
    LobbyNet.onOutPlayer()
}
Transmitter.onCommand = function (msgCode, body) {
    Transmitter.AdoManager.onCommand(msgCode, body);
}
Transmitter.onNetworkFail = function (type) {
    showMsg("네트웤 Failed, please try later!");
    toastr.error("네트워크 오류입니다. 네트웤상태를 다시 확인해 주세요!");
}