import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { TextClass } from "../../../components/text/text";
import CommonDef from '../../../def/commondef';

export class NoticeDetailDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        this.textContent.setVisible(false);
        this.popupAnimation();
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'notice_detail_back.png',
            light: 'content_back_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 320,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);

        this.title = TextClass.plainText1(this.scene, {
            x: -330,
            y: 178,
            text: this.config.title,
            color: '#d7bba3',
            fontSize: 22,
            fontFamily: "NanumSquareR"
        }).setOrigin(0, 1);
        this.cardContainer.add(this.title)
        this.title.setStyle({
            wordWrap: { width: 550, useAdvancedWrap: true }
        });
        this.title.setLineSpacing(10);
        this.title.y = 178 + this.title.height - 24;

        this.date = TextClass.plainText1(this.scene, {
            x: 310,
            y: 178 + this.title.height - 24,
            text: this.config.date,
            color: '#d7bba3',
            fontSize: 18,
            fontFamily: "NanumSquareR"
        }).setOrigin(1, 1);
        this.cardContainer.add(this.date);
        
        this.contentContainer = this.scene.add.container(0, 0);
        this.textContent = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: this.config.content,
            fontFamily: "NanumSquareR",
            fontSize: 22,
            color: '#999999'
        }).setOrigin(0, 0);
        this.textContent.setStyle({
            wordWrap: { width: 650, useAdvancedWrap: true }
        });
        this.textContent.setLineSpacing(15);
        this.contentContainer.height = this.textContent.height;
        this.contentContainer.add(this.textContent)
        this.content = new ScrollView(this.scene, {
            x: -330,
            y: 178 + this.title.height - 24 + 50, //228,
            width: 682,
            height: 410 - this.title.height + 24,
            atlas: this.config.atlas,
            thumb: 'scroll_thumb.png',
            content: this.contentContainer,
            mask: {
                x: 627,
                y: 418
            },
            action_rect: {
                width: 660,
                height: 410 - this.title.height + 24
            }
        });
        this.cardContainer.add(this.content);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);

        this.title = TextClass.plainText1(this.scene, {
            x: 40,
            y: 192,
            text: this.config.title,
            color: '#d7bba3',
            fontSize: 22,
            fontFamily: "NanumSquareR"
        }).setOrigin(0, 1);
        this.mobileDlg.add(this.title)
        this.title.setStyle({
            wordWrap: { width: 550, useAdvancedWrap: true }
        });
        this.title.setLineSpacing(10);
        this.title.y = 192 + this.title.height - 24;

        this.date = TextClass.plainText1(this.scene, {
            x: 673,
            y: 192 + this.title.height - 24,
            text: this.config.date,
            color: '#d7bba3',
            fontSize: 18,
            fontFamily: "NanumSquareR"
        }).setOrigin(1, 1);
        this.mobileDlg.add(this.date);
        
        this.contentContainer = this.scene.add.container(0, 0);
        this.textContent = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: this.config.content,
            fontFamily: "NanumSquareR",
            fontSize: 22,
            color: '#999999'
        }).setOrigin(0, 0);
        this.textContent.setStyle({
            wordWrap: { width: 630, useAdvancedWrap: true }
        });
        this.textContent.setLineSpacing(15);
        this.contentContainer.add(this.textContent);
        this.contentContainer.height = this.textContent.height;
        this.content = new ScrollView(this.scene, {
            x: 40,
            y: 192 + this.title.height - 24 + 50,
            width: 672,
            height: 1060,
            atlas: this.config.atlas,
            thumb: 'scroll_thumb.png',
            content: this.contentContainer,
            mask: {
                x: 40,
                y: 240
            },
            action_rect: {
                width: 650,
                height: 1060
            }
        });
        this.mobileDlg.add(this.content);
    }

    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.textContent.setVisible(true);
            }
        })
    }

    close() {
        // let index = CommonDef.Dialog.WaitingRoomScene.findIndex(x => x.key == "noticeDetail")
        // CommonDef.Dialog.WaitingRoomScene.splice(index, 1);
        this.destroy();
    }
}