export class HoldemCard extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.setOpen(false, false);
    }

    initGraph() {
        this.value = this.config.value || 0;
        this.cardImg = this.scene.add.sprite(0, 0, this.config.atlas, this.value + ".png").setOrigin(0.5, 0);
        this.add(this.cardImg);

        this.disableMask = this.scene.add.graphics({
            x: -this.cardImg.width / 2,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.5
            }
        });
        this.disableMask.fillRoundedRect(0, 0, this.cardImg.width, this.cardImg.height, 5);
        this.add(this.disableMask);
        this.setDisableMask(false);
    }

    setValue(value) {
        this.value = value;
    }

    setOpen(flag = true, animFlag = true) {
        animFlag = animFlag && !document.hidden;
        this.opened = flag;
        let scaleX = this.scaleX;
        if(animFlag) {
            try {
                this.scene.tweens.add({
                    targets: this,
                    scaleX: 0,
                    duration: 200,
                    ease: "Power2",
                    onComplete: () => {
                        try {
                            this.cardImg.setTexture(this.config.atlas, flag ? (this.value + ".png") : "0.png");
                            this.scene.tweens.add({
                                targets: this,
                                scaleX: scaleX,
                                duration: 200,
                                ease: "Power2"
                            });
                        } catch (e) {
                            console.error("카드 오픈 애님 2");
                        }
                    }
                })
            } catch (e) {
                console.error("카드 오픈 애님 1");
            }
        } else {
            this.cardImg.setTexture(this.config.atlas, flag ? (this.value + ".png") : "0.png");
        }
    }

    setDisableMask(flag) {
        this.disableMask.setVisible(flag);
    }
}