import CommonDef from '../def/commondef.js';
import LobbyDef from '../def/lobbydef.js';
import { SpriteButton } from '../components/button/spritebutton.js';
import { ContentCard } from '../components/card/contentcard.js';
import { TabContainer } from '../components/container/tabcontainer.js';
import { TextClass } from '../components/text/text.js';
import { HoldemContent } from './pages/lobby/tab_holdem_content.js'
import { IconButton } from '../components/button/iconbutton.js';
import { Preparing } from './pages/lobby/preparing.js';
import { Avatar } from '../components/avatar.js/avatar.js';
import * as NumberUtil from '../util/number';
import { CouponDialog } from './pages/lobby/coupon.js';
import { DepositWithdrawDialog } from './pages/lobby/deposit_withdarw.js';
import { PointDialog } from './pages/lobby/point_dialog.js';
import { CustomerDialog } from './pages/lobby/customer.js';
import { SettingDialog } from './pages/lobby/setting/setting_dialog.js';
import { BuyinDialog } from './pages/lobby/game/buyin_dialog.js';
import { CreateRoomDialog } from './pages/lobby/game/create_room.js';
import { QuickJoinDialog } from './pages/lobby/game/quick_join.js';
import { ChargeDialog } from './pages/lobby/charge_dialog.js';
import { TabTournamentPage } from './pages/lobby/tab_tournament.js';
import { TournamentDialog } from './pages/lobby/tournament/tournament_dialog.js';
import { EventPage } from './pages/lobby/event/event_page.js';
import { SceneManager } from './scene_manager.js';
import { Toast } from '../components/dialog/toast.js';
import { GameSound } from '../sound/game_sound.js';
import { TournamentHoldemRegisterDialog } from './pages/tournament_holdem/register_dialog.js';
import { ChangeDialog } from './pages/lobby/change_dialog.js';
import { LoadingPage } from './pages/loadingPage.js';
import { translate } from '../util/other.js';
import { NoteDialog } from './pages/note/note_dialog.js';
import { OmahaContent } from './pages/lobby/tab_omaha_content.js';

export class LobbyScene extends Phaser.Scene {
    constructor() {
        super({
            key: "LobbyScene"
        })
    }

    preload() {

    }

    create() {
        if (this.backContainer) {
            this.backContainer.removeAll(true);
        } else {
            this.backContainer = this.add.container(0, 0);
            this.backContainer.setDepth(0);
        }

        if(this.headerContainer) {
            this.headerContainer.removeAll(true);
        } else {
            this.headerContainer = this.add.container(0, 0);
            this.headerContainer.setDepth(0);
        }

        if(this.bodyContainer) {
            this.bodyContainer.removeAll(true);
        } else {
            this.bodyContainer = this.add.container(0, 0);
            this.bodyContainer.setDepth(1);
        }

        if(this.bottomContainer) {
            this.bottomContainer.removeAll(true);
        } else {
            this.bottomContainer = this.add.container(0, 0);
            this.bottomContainer.setDepth(0);
        }

        this.createRoomBuf = null;
        this.quickJoinRoomBuf = null;
        this.tournamentFilterValue = -1;

        this.initSound();
        this.initGraph();
        this.initLoading();
        CommonDef.currentScene = this;
        this.requestLobbyList(0);
    }

    initSound() {
        this.audio = new GameSound(this);
        this.audio.stopEffect(null, ["NormalBGM"]);
        this.audio.playBGM("NormalBGM", true);
    }

    initLoading() {
        this.loadingPage = new LoadingPage(this, {
            atlas: 'LoadingAtlas'
        });
        this.loadingPage.setDepth(1000);
    }

    playLoading() {
        // this.loadingPage && this.loadingPage.playLoading();
    }

    stopLoading() {
        this.loadingPage && this.loadingPage.stopLoading();
    }

    initGraph() {
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.LobbyAtlasName = 'LobbyAtlas_' + this.orientation + "_" + this.tema;
        this.LobbyDialogAtlasName = "LobbyDialogAtlas_" + this.tema;
        this.MyPageAtlasName = 'MyPageAtlas_' + this.tema;

        this.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.tournamentDialog = new TournamentDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 98 : 0,
            atlas: this.LobbyAtlasName,
            from: "lobby"
        });
        this.bodyContainer.add(this.tournamentDialog);
        this.tournamentDialog.setVisible(false);

        // init alert
        this.alertContainer = this.add.container(0, 0);
        this.alertContainer.setDepth(10);
        let alert_back = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.5
            }
        });
        alert_back.fillRect(0, 0, this.ScreenWidth, 40);
        this.alert_message = TextClass.plainText1(this, {
            x: this.ScreenWidth,
            y: 20,
            text: "",
            fontSize: 24,
            color: "#eeeeee"
        }).setOrigin(0, 0.5);
        this.alertContainer.add(alert_back);
        this.alertContainer.add(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();
    }

    initGraphForPC() {
        this.background = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, 'LobbyBackground_pc');
        this.background.setOrigin(0.5, 0.5);
        this.backContainer.add(this.background);

        this.profileAvatar = new Avatar(this, {
            x: LobbyDef.profile.avatar[this.orientation].x,
            y: LobbyDef.profile.avatar[this.orientation].y,
            atlas: "AvatarAtlas",
            avatar: CommonDef.Me.avatar,
            border: CommonDef.Me.bordericon
        });
        this.headerContainer.add(this.profileAvatar);

        this.my_money_label = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: translate('my_money'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_money_label);
 
        this.my_money_value = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + 15,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.money),
            fontFamily: "GmarketSansLight",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_money_value);

        this.divider_label = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + 35,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: '|',
            fontFamily: "GmarketSansLight",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.divider_label);

        this.my_point_label = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + 60,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: translate('my_point'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_point_label);

        this.my_point_value = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + this.my_point_label.width + 75,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.point),
            fontFamily: "GmarketSansLight",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_point_value);

        // this.btn_coupon = new SpriteButton(this, {
        //     x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + this.my_point_label.width + this.my_point_value.width + 75 + 95,
        //     y: LobbyDef.profile.info[this.orientation].btn_coupon.y,
        //     atlas: this.LobbyAtlasName,
        //     normal: 'btn_coupon_normal.png',
        //     hover: "btn_coupon_hover.png",
        //     clicked: "btn_coupon_clicked.png",
        //     onClicked: this.onClickedCoupon.bind(this)
        // });
        // this.headerContainer.add(this.btn_coupon);

        this.my_name = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x,
            y: LobbyDef.profile.info[this.orientation].y[1],
            text: CommonDef.Me.nickname,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20,
            color: '#d7bba3'
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_name);

        this.btn_back = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[0],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_back_normal.png',
            hover: 'icon_back_hover.png',
            clicked: 'icon_back_clicked.png',
            onClicked: this.onClickedBack.bind(this)
        });
        this.headerContainer.add(this.btn_back);

        this.btn_inbox = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[1],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_inbox_normal.png',
            hover: 'icon_inbox_hover.png',
            clicked: 'icon_inbox_clicked.png',
            onClicked: this.showNoteDialog.bind(this)
        });
        this.headerContainer.add(this.btn_inbox);

        this.btn_setting = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[2],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_setting_normal.png',
            hover: 'icon_setting_hover.png',
            clicked: 'icon_setting_clicked.png',
            onClicked: this.onClickedSetting.bind(this)
        });
        this.headerContainer.add(this.btn_setting);

        this.btn_close = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[3],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_close_normal.png',
            hover: 'icon_close_hover.png',
            clicked: 'icon_close_clicked.png',
            onClicked: this.onClickedClose.bind(this)
        });
        this.headerContainer.add(this.btn_close);

        this.mainContent = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: LobbyDef.main_content[this.orientation].y,
            atlas: this.LobbyAtlasName,
            back: 'card_back.png',
            light: 'card_back_light.png'
        });
        this.bodyContainer.add(this.mainContent);

        this.tabContainer = new TabContainer(this, {
            x: this.ScreenWidth / 2,
            y: LobbyDef.tabContainer[this.orientation].y,
            atlas: this.LobbyAtlasName,
            tabs: [
                {
                    x: LobbyDef.tabContainer[this.orientation].tabs.x[0],
                    y: LobbyDef.tabContainer[this.orientation].tabs.y,
                    text: translate('tab_holdem'),
                    content: new HoldemContent(this, {
                        x: LobbyDef.holdem_content[this.orientation].x,
                        y: 0,
                        atlas: this.LobbyAtlasName,
                        content: []
                    })
                },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[1],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     text: translate('tab_omaha'),
                //     content: new OmahaContent(this, {
                //         x: LobbyDef.holdem_content[this.orientation].x,
                //         y: 0,
                //         atlas: this.LobbyAtlasName,
                //         content: []
                //     })
                // },
                {
                    x: LobbyDef.tabContainer[this.orientation].tabs.x[1],
                    y: LobbyDef.tabContainer[this.orientation].tabs.y,
                    text: translate('tab_tournament'),
                    content: new TabTournamentPage(this, {
                        x: LobbyDef.tournament_content[this.orientation].x,
                        y: 0,
                        atlas: this.LobbyAtlasName
                    })
                },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[2],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     active: 'tab_slot_active.png',
                //     inactive: 'tab_slot_inactive.png',
                //     content: new TabSlot(this, {
                //         x: LobbyDef.holdem_content[this.orientation].x,
                //         y: 0,
                //     })
                // },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[4],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     active: 'tab_bakara_active.png',
                //     inactive: 'tab_bakara_inactive.png',
                //     content: new Preparing(this, {
                //         x: LobbyDef.preparing[this.orientation].x,
                //         y: LobbyDef.preparing[this.orientation].y,
                //         atlas: this.LobbyAtlasName,
                //         logo: {
                //             x: LobbyDef.preparing[this.orientation].logo.x,
                //             y: LobbyDef.preparing[this.orientation].logo.y,
                //             texture: "logo.png"
                //         },
                //         text: {
                //             x: LobbyDef.preparing[this.orientation].text.x,
                //             y: LobbyDef.preparing[this.orientation].text.y,
                //             text: "준비중 입니다",
                //             gradient: ['#ffe0cf', '#ffffff'],
                //             fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                //             fontSize: 40
                //         }
                //     })
                // }
            ],
            tab_line: {
                x: LobbyDef.tabContainer[this.orientation].tab_line.x,
                y: LobbyDef.tabContainer[this.orientation].tab_line.y,
                texture: 'tab_line.png'
            },
            tabChanged: this.requestLobbyList.bind(this)
        });
        this.bodyContainer.add(this.tabContainer)

        this.btn_gold = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[0],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_gold_normal.png',
            hover: 'icon_gold_hover.png',
            clicked: 'icon_gold_clicked.png',
            onClicked: this.onClickedGold.bind(this)
        });
        this.bottomContainer.add(this.btn_gold);

        this.btn_gold_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[1],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            text: translate('safe'),
            color: "#d7bba3",
            fontSize: 26,
        }).setOrigin(0, 0.5);
        this.bottomContainer.add(this.btn_gold_label);

        this.btn_point = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[2],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_point_normal.png',
            hover: 'icon_point_hover.png',
            clicked: 'icon_point_clicked.png',
            onClicked: this.onClickedPoint.bind(this)
        });
        this.bottomContainer.add(this.btn_point);

        this.btn_point_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[3],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            text: translate('point'),
            color: "#d7bba3",
            fontSize: 26,
        }).setOrigin(0, 0.5);
        this.bottomContainer.add(this.btn_point_label);

        this.btn_customer = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[4],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_customer_normal.png',
            hover: 'icon_customer_hover.png',
            clicked: 'icon_customer_clicked.png',
            onClicked: this.onClickedCustomer.bind(this)
        });
        this.bottomContainer.add(this.btn_customer);

        this.btn_customer_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[5],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            text: translate('customer'),
            // fontFamily: "SpoqaHanSansBold",
            color: "#d7bba3",
            fontSize: 26,
        }).setOrigin(0, 0.5);
        this.bottomContainer.add(this.btn_customer_label);

        this.btn_event = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[6],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_event_normal.png',
            hover: 'icon_event_hover.png',
            clicked: 'icon_event_clicked.png',
            onClicked: this.onClickedEvent.bind(this)
        });
        this.bottomContainer.add(this.btn_event);

        this.btn_event_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[7],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            text: translate('event'),
            // fontFamily: "SpoqaHanSansBold",
            color: "#d7bba3",
            fontSize: 26,
        }).setOrigin(0, 0.5);
        this.bottomContainer.add(this.btn_event_label);

        if(CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0) {
            this.btn_charge = new IconButton(this, {
                x: LobbyDef.bottom_button[this.orientation].x[8],
                y: LobbyDef.bottom_button[this.orientation].y[1],
                atlas: this.LobbyAtlasName,
                normal: {
                    back: 'btn_deposit_back_normal.png',
                    icon: 'btn_deposit_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                hover: {
                    back: 'btn_deposit_back_hover.png'
                },
                clicked: {
                    back: 'btn_deposit_back_clicked.png'
                },
                text: {
                    x: 115,
                    y: 23,
                    value: translate('payin'),
                    fontSize: 34,
                    gradient: ['#ffe0cf', '#ffffff']
                },
                onClicked: this.onClickedBtnCharge.bind(this)
            });
            this.bottomContainer.add(this.btn_charge);

            this.btn_change = new IconButton(this, {
                x: LobbyDef.bottom_button[this.orientation].x[9],
                y: LobbyDef.bottom_button[this.orientation].y[1],
                atlas: this.LobbyAtlasName,
                normal: {
                    back: 'btn_withdraw_back_normal.png',
                    icon: 'btn_withdraw_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                hover: {
                    back: 'btn_withdraw_back_hover.png',
                },
                clicked: {
                    back: 'btn_withdraw_back_clicked.png',
                },
                text: {
                    x: 115,
                    y: 23,
                    value: translate('payout'),
                    fontSize: 34,
                    gradient: ['#d7cfcf', '#ffffff']
                },
                onClicked: this.onClickedBtnChange.bind(this)
            });
            this.bottomContainer.add(this.btn_change);     
        }
    }

    initGraphForMobile() {
        this.background = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, this.ScreenWidth, this.ScreenHeight);
        this.background.fillRectShape(rect);
        this.backContainer.add(this.background);

        this.profileAvatar = new Avatar(this, {
            x: LobbyDef.profile.avatar[this.orientation].x,
            y: LobbyDef.profile.avatar[this.orientation].y,
            atlas: "AvatarAtlas",
            avatar: CommonDef.Me.avatar,
            border: CommonDef.Me.bordericon
        });
        this.headerContainer.add(this.profileAvatar);

        this.my_name = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x,
            y: LobbyDef.profile.info[this.orientation].y[0],
            text: CommonDef.Me.nickname,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20,
            color: '#d7bba3'
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_name);

        this.my_money_label = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x,
            y: LobbyDef.profile.info[this.orientation].y[1],
            text: translate('my_money'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_money_label);

        this.my_money_value = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + 15,
            y: LobbyDef.profile.info[this.orientation].y[1],
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.money),
            fontFamily: "GmarketSansLight",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_money_value);

        this.my_point_label = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x,
            y: LobbyDef.profile.info[this.orientation].y[2],
            text: translate('my_point'),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_point_label);

        this.my_point_value = TextClass.plainText1(this, {
            x: LobbyDef.profile.info[this.orientation].x + this.my_point_label.width + 15,
            y: LobbyDef.profile.info[this.orientation].y[2],
            text: NumberUtil.convertNumberWithComma(CommonDef.Me.point),
            fontFamily: "GmarketSansLight",
            fontSize: 24,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0.5);
        this.headerContainer.add(this.my_point_value);

        // this.btn_coupon = new SpriteButton(this, {
        //     x: LobbyDef.profile.info[this.orientation].btn_coupon.x,
        //     y: LobbyDef.profile.info[this.orientation].btn_coupon.y,
        //     atlas: this.LobbyAtlasName,
        //     normal: 'btn_coupon_normal.png',
        //     hover: 'btn_coupon_hover.png',
        //     clicked: 'btn_coupon_clicked.png',
        //     onClicked: this.onClickedCoupon.bind(this)
        // })
        // this.headerContainer.add(this.btn_coupon)

        this.btn_back = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[0],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_back_normal.png',
            hover: 'icon_back_hover.png',
            clicked: 'icon_back_clicked.png',
            onClicked: this.onClickedBack.bind(this)
        });
        this.headerContainer.add(this.btn_back);

        this.btn_inbox = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[1],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_inbox_normal.png',
            hover: 'icon_inbox_hover.png',
            clicked: 'icon_inbox_clicked.png',
            onClicked: this.showNoteDialog.bind(this)
        });
        this.headerContainer.add(this.btn_inbox);

        this.btn_setting = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[2],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_setting_normal.png',
            hover: 'icon_setting_hover.png',
            clicked: 'icon_setting_clicked.png',
            onClicked: this.onClickedSetting.bind(this)
        });
        this.headerContainer.add(this.btn_setting);

        this.btn_close = new SpriteButton(this, {
            x: LobbyDef.header_btn_position[this.orientation].x[3],
            y: LobbyDef.header_btn_position[this.orientation].y,
            atlas: this.LobbyAtlasName,
            normal: 'icon_close_normal.png',
            hover: 'icon_close_hover.png',
            clicked: 'icon_close_clicked.png',
            onClicked: this.onClickedClose.bind(this)
        });
        this.headerContainer.add(this.btn_close);

        this.mainContent = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: LobbyDef.main_content[this.orientation].y,
            atlas: this.LobbyAtlasName,
            back: 'card_back.png',
            light: 'card_back_light.png'
        });
        this.bodyContainer.add(this.mainContent);


        this.tabContainer = new TabContainer(this, {
            x: this.ScreenWidth / 2,
            y: LobbyDef.tabContainer[this.orientation].y,
            atlas: this.LobbyAtlasName,
            tabs: [
                {
                    x: LobbyDef.tabContainer[this.orientation].tabs.x[0],
                    y: LobbyDef.tabContainer[this.orientation].tabs.y,
                    text: translate('tab_holdem'),
                    content: new HoldemContent(this, {
                        x: LobbyDef.holdem_content[this.orientation].x,
                        y: 0,
                        atlas: this.LobbyAtlasName,
                        content: []
                    })
                },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[1],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     text: translate('tab_omaha'),
                //     content: new HoldemContent(this, {
                //         x: LobbyDef.holdem_content[this.orientation].x,
                //         y: 0,
                //         atlas: this.LobbyAtlasName,
                //         content: []
                //     })
                // },
                {
                    x: LobbyDef.tabContainer[this.orientation].tabs.x[1],
                    y: LobbyDef.tabContainer[this.orientation].tabs.y,
                    text: translate('tab_tournament'),
                    content: new TabTournamentPage(this, {
                        x: LobbyDef.tournament_content[this.orientation].x,
                        y: 0,
                        atlas: this.LobbyAtlasName
                    })
                },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[2],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     active: 'tab_slot_active.png',
                //     inactive: 'tab_slot_inactive.png',
                //     content: new TabSlot(this, {
                //         x: LobbyDef.holdem_content[this.orientation].x,
                //         y: 0,
                //     })
                // },
                // {
                //     x: LobbyDef.tabContainer[this.orientation].tabs.x[4],
                //     y: LobbyDef.tabContainer[this.orientation].tabs.y,
                //     active: 'tab_bakara_active.png',
                //     inactive: 'tab_bakara_inactive.png',
                //     content: new Preparing(this, {
                //         x: LobbyDef.preparing[this.orientation].x,
                //         y: LobbyDef.preparing[this.orientation].y,
                //         atlas: this.LobbyAtlasName,
                //         logo: {
                //             x: LobbyDef.preparing[this.orientation].logo.x,
                //             y: LobbyDef.preparing[this.orientation].logo.y,
                //             texture: "logo.png"
                //         },
                //         text: {
                //             x: LobbyDef.preparing[this.orientation].text.x,
                //             y: LobbyDef.preparing[this.orientation].text.y,
                //             text: "준비중 입니다",
                //             gradient: ['#ffe0cf', '#ffffff'],
                //             fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                //             fontSize: 40
                //         }
                //     })
                // }
            ],
            tab_line: {
                x: LobbyDef.tabContainer[this.orientation].tab_line.x,
                y: LobbyDef.tabContainer[this.orientation].tab_line.y,
                texture: 'tab_line.png'
            },
            scale: [1.5, 1],
            tabChanged: this.requestLobbyList.bind(this)
        });
        this.bodyContainer.add(this.tabContainer)







        this.btn_gold = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[0],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_gold_normal.png',
            hover: 'icon_gold_hover.png',
            clicked: 'icon_gold_clicked.png',
            onClicked: this.onClickedGold.bind(this)
        });
        this.bottomContainer.add(this.btn_gold);

        this.btn_gold_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[0],
            y: LobbyDef.bottom_button[this.orientation].y[1],
            text: translate('safe'),
            color: "#d7bba3",
            fontSize: 18,
        }).setOrigin(0.5, 0.5);
        this.bottomContainer.add(this.btn_gold_label);

        this.btn_point = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[1],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_point_normal.png',
            hover: 'icon_point_hover.png',
            clicked: 'icon_point_clicked.png',
            onClicked: this.onClickedPoint.bind(this)
        });
        this.bottomContainer.add(this.btn_point);

        this.btn_point_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[1],
            y: LobbyDef.bottom_button[this.orientation].y[1],
            text: translate('point'),
            color: "#d7bba3",
            fontSize: 18,
        }).setOrigin(0.5, 0.5);
        this.bottomContainer.add(this.btn_point_label);

        this.btn_customer = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[2],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_customer_normal.png',
            hover: 'icon_customer_hover.png',
            clicked: 'icon_customer_clicked.png',
            onClicked: this.onClickedCustomer.bind(this)
        });
        this.bottomContainer.add(this.btn_customer);

        this.btn_customer_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[2],
            y: LobbyDef.bottom_button[this.orientation].y[1],
            text: translate('customer'),
            color: "#d7bba3",
            fontSize: 18,
        }).setOrigin(0.5, 0.5);
        this.bottomContainer.add(this.btn_customer_label);

        this.btn_event = new SpriteButton(this, {
            x: LobbyDef.bottom_button[this.orientation].x[3],
            y: LobbyDef.bottom_button[this.orientation].y[0],
            atlas: this.LobbyAtlasName,
            normal: 'icon_event_normal.png',
            hover: 'icon_event_hover.png',
            clicked: 'icon_event_clicked.png',
            onClicked: this.onClickedEvent.bind(this)
        });
        this.bottomContainer.add(this.btn_event);

        this.btn_event_label = TextClass.plainText1(this, {
            x: LobbyDef.bottom_button[this.orientation].x[3],
            y: LobbyDef.bottom_button[this.orientation].y[1],
            text: translate('event'),
            color: "#d7bba3",
            fontSize: 18,
        }).setOrigin(0.5, 0.5);
        this.bottomContainer.add(this.btn_event_label);

        
        if(CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0) {
            this.btn_charge = new IconButton(this, {
                x: LobbyDef.bottom_button[this.orientation].x[4],
                y: LobbyDef.bottom_button[this.orientation].y[2],
                atlas: this.LobbyAtlasName,
                normal: {
                    back: 'btn_deposit_back_normal.png',
                    icon: 'btn_deposit_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                hover: {
                    back: 'btn_deposit_back_hover.png',
                    icon: 'btn_deposit_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                clicked: {
                    back: 'btn_deposit_back_clicked.png',
                    icon: 'btn_deposit_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                onClicked: this.onClickedBtnCharge.bind(this)
            });
            this.bottomContainer.add(this.btn_charge);

            let btn_charge_label = TextClass.plainText1(this, {
                x: LobbyDef.bottom_button[this.orientation].x[4] + this.btn_charge.back.width / 2,
                y: LobbyDef.bottom_button[this.orientation].y[1],
                text: translate('payin'),
                fontSize: 18,
                gradient: ['#d1b7a9', '#ffffff']
            }).setOrigin(0.5, 0.5);
            this.bottomContainer.add(btn_charge_label);

            this.btn_change = new IconButton(this, {
                x: LobbyDef.bottom_button[this.orientation].x[5],
                y: LobbyDef.bottom_button[this.orientation].y[2],
                atlas: this.LobbyAtlasName,
                normal: {
                    back: 'btn_withdraw_back_normal.png',
                    icon: 'btn_withdraw_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                hover: {
                    back: 'btn_withdraw_back_hover.png',
                    icon: 'btn_withdraw_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                clicked: {
                    back: 'btn_withdraw_back_clicked.png',
                    icon: 'btn_withdraw_icon.png',
                    icon_position: {
                        x: 20,
                        y: 9
                    }
                },
                onClicked: this.onClickedBtnChange.bind(this)
            });
            this.bottomContainer.add(this.btn_change);

            let btn_change_label = TextClass.plainText1(this, {
                x: LobbyDef.bottom_button[this.orientation].x[5] + this.btn_change.back.width / 2,
                y: LobbyDef.bottom_button[this.orientation].y[1],
                text: translate('payout'),
                fontSize: 18,
                gradient: ['#d1b7a9', '#ffffff']
            }).setOrigin(0.5, 0.5);
            this.bottomContainer.add(btn_change_label);
        }
    }

    initTab() {
        this.tabContainer.setTab(0);
        this.tournamentDialog.close();
        // this.requestLobbyList(undefined, undefined);
    }

    onClickedBack() {
        SceneManager.changeScene(this.scene, "LobbyScene", "WaitingRoomScene");
    }

    onClickedSetting() {
        this.settingDialog = new SettingDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('setting')
        });
        this.bodyContainer.add(this.settingDialog);
        CommonDef.gameNet.requestTableTemaList();
        CommonDef.gameNet.requestCardTemaList();
    }

    setTableTemaList(list) {
        this.settingDialog.setTableTemaList && this.settingDialog.setTableTemaList(list);
    }

    setCardTemaList(list) {
        this.settingDialog.setCardTemaList && this.settingDialog.setCardTemaList(list);
    }

    onClickedInbox() {

    }

    onClickedClose() {
        CommonDef.gameNet.logout();
    }

    onClickedGold() {
        this.depositWithdrawDialog = new DepositWithdrawDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('safe')
        });
        this.bodyContainer.add(this.depositWithdrawDialog);
        CommonDef.gameNet.requestStoreHistory();
    }

    setStoreHistory(history) {
        if(this.depositWithdrawDialog && this.depositWithdrawDialog.active && this.depositWithdrawDialog.list && this.depositWithdrawDialog.list.length > 0) this.depositWithdrawDialog.setHistoryContent(history);
    }

    requestDeposit(amount) {
        CommonDef.gameNet.requestDeposit(amount);
    }

    requestWithdraw(amount) {
        CommonDef.gameNet.requestWithdraw(amount);
    }

    onClickedPoint() {
        this.pointDialog = new PointDialog(this, {
            x: this.ScreenWidth / 2, 
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('point')
        });
        this.bodyContainer.add(this.pointDialog);
        CommonDef.gameNet.requestPointExchangeList();
    }

    setPointExchangeHistory(history) {
        if(this.pointDialog && this.pointDialog.active && this.pointDialog.list && this.pointDialog.list.length > 0) this.pointDialog.setHistoryContent(history);
    }

    requestPointExchange(amount) {
        CommonDef.gameNet.requestPointExchange(amount);
    }

    onClickedCustomer() {
        this.customerDialog = new CustomerDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 190 : 0,
            width: 1100,
            height: 700,
            name: translate('customer'),
            atlas: this.LobbyDialogAtlasName
        });
        this.bodyContainer.add(this.customerDialog);
        CommonDef.gameNet.requestCustomerList();
    }

    setCustomerList(list) {
        if(this.customerDialog && this.customerDialog.active && this.customerDialog.list && this.customerDialog.list.length) this.customerDialog.setContent(list);
    }

    requestCreateNewQuestion(title, question) {
        CommonDef.gameNet.requestCreateNewQuestion(title, question);
    }

    onSuccessCreateNewCustomerQuestion() {
        if(this.customerDialog && this.customerDialog.active && this.customerDialog.list && this.customerDialog.list.length) this.customerDialog.onSuccessCreateNewCustomerQuestion();
    }

    onClickedBtnCharge() {
        this.chargeDialog = new ChargeDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 140 : 0,
            atlas: this.LobbyDialogAtlasName
        });
        this.bodyContainer.add(this.chargeDialog);
        CommonDef.gameNet.requestPayinList();
    }

    setChargeDialogHistory(history, bonusItems) {
        if(this.chargeDialog && this.chargeDialog.active && this.chargeDialog.list && this.chargeDialog.list.length > 0) this.chargeDialog.setHistoryContent(history, bonusItems);
    }

    requestPayin(ownerName, playerName, amount, bonusType) {
        CommonDef.gameNet.requestPayin(ownerName, playerName, amount, bonusType);
    }

    onClickedBtnChange() {
        this.changeDialog = new ChangeDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName
        });
        this.bodyContainer.add(this.changeDialog);
        CommonDef.gameNet.requestPayoutList();
    }

    setChangeDialogHistory(history) {
        if(this.changeDialog && this.changeDialog.active && this.changeDialog.list && this.changeDialog.list.length > 0) this.changeDialog.setHistoryContent(history);
    }

    requestPayout(amount) {
        CommonDef.gameNet.requestPayout(amount);
    }

    onClickedCoupon() {
        let couponDialog = new CouponDialog(this, {
            x: this.ScreenWidth / 2, 
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: "쿠폰"
        });
        this.bodyContainer.add(couponDialog);
    }

    requestLobbyList(tabIndex, filterValue) {
        console.log("request lobby list", tabIndex, filterValue);
        if(tabIndex == undefined) tabIndex = this.tabContainer.indexValue;
        else if(tabIndex > 0 && (!this.tabContainer || tabIndex != this.tabContainer.indexValue)) return;
        if(filterValue !== undefined) this.tournamentFilterValue = filterValue;
        if(tabIndex == 0) CommonDef.gameNet.requestLobbyRoomList(CommonDef.Games.Holdem);
        // else if(tabIndex == 1) CommonDef.gameNet.requestLobbyRoomList(CommonDef.Games.Omaha);
        else if(tabIndex == 1) CommonDef.gameNet.requestTournamentList(this.tournamentFilterValue);
    }

    setLobbyContent(gameID, content) {
        if(gameID != this.tabContainer.indexValue) return;
        this.tabContainer.tabs[this.tabContainer.indexValue].content.setContent(content);
    }

    setTournamentTableContent(tournaments) {
        this.tabContainer.tabs[1].content.setContent(tournaments);
        if(tournaments.findIndex(x => x.id == this.tournamentDialog.tournamentid) < 0) {
            this.tournamentDialog.visible && this.tournamentDialog.close();
        }
    }

    onClickedJoinBtn(room_id, blinds, security) {
        CommonDef.gameNet.requestBuyin(room_id, blinds.sb, 1, security);
    }

    openBuyinDialog(roomid, buyin, createFlag, security) {
        let buyinDialog = new BuyinDialog(this, {
            x: this.ScreenWidth / 2, 
            y: this.orientation == "pc" ? 320 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('buyin_amount'),
            buyin: {
                min: parseInt(buyin[1]),
                max: parseInt(buyin[0])
            },
            roomid: roomid,
            createFlag: createFlag,
            security: security
        });
        this.bodyContainer.add(buyinDialog);
    }

    joinRoom(roomid, password, createFlag, buyin) {
        if(createFlag == 0) {
            CommonDef.gameNet.requestCreateRoom(this.tabContainer.indexValue, this.createRoomBuf, buyin);
        } else if(createFlag == 1) {
            CommonDef.gameNet.requestJoinRoom(this.tabContainer.indexValue, roomid, password, buyin);
        } else if(createFlag == 2) {
            CommonDef.gameNet.requestQuickJoinRoom(this.tabContainer.indexValue, this.quickJoinRoomBuf.sb, this.quickJoinRoomBuf.ante, buyin);
        }
    }

    onSuccessJoinRoom() {
        if(this.tabContainer.indexValue == CommonDef.Games.Holdem) {
            SceneManager.changeScene(this.scene, "LobbyScene", "HoldemScene");
        } else if(this.tabContainer.indexValue == CommonDef.Games.Omaha) {
            SceneManager.changeScene(this.scene, "LobbyScene", "OmahaScene");
        }
    }

    onSuccessJoinTournamentTable(gameID) {
        if(gameID == CommonDef.Games.Holdem) {
            SceneManager.changeScene(this.scene, "LobbyScene", "TournamentHoldemScene");
        } else if(gameID == CommonDef.Games.Omaha) {
            SceneManager.changeScene(this.scene, "LobbyScene", "TournamentOmahaScene");
        }
    }

    onClickedBtnCreateRoom() {
        let createRoomDialog = new CreateRoomDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 210 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('create_room'),
        });
        this.bodyContainer.add(createRoomDialog);
    }

    requestCreateRoom(roomInfo) {
        this.createRoomBuf = roomInfo;
        CommonDef.gameNet.requestBuyin("", roomInfo.blinds.sb, 0)
    }

    onClickedBtnQuickJoin() {
        let createRoomDialog = new QuickJoinDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 315 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('quick_join'),
        });
        this.bodyContainer.add(createRoomDialog);
    }

    requestQuickJoin(sb, ante) {
        this.quickJoinRoomBuf = {
            sb: sb,
            ante: ante
        };
        CommonDef.gameNet.requestBuyin("", sb, 2);
    }

    onClickTournamentItem(tournament_id) {
        this.tournamentDialog.setTournamentID(tournament_id);
        this.tournamentDialog.initTab();
        this.tournamentDialog.show();
        this.requestTournamentDetail();
    }

    requestTournamentDetail() {
        CommonDef.gameNet.requestTournamentDetail(this.tournamentDialog.tournamentid);
    }

    setTournamentDetail(detail) {
        this.tournamentDialog.setDetail(detail);
    }

    requestTournamentBlinds() {
        CommonDef.gameNet.requestTournamentBlinds(this.tournamentDialog.tournamentid);
    }

    setTournamentBlinds(blinds) {
        this.tournamentDialog.setBlinds(blinds);
    }

    requestTournamentEntry() {
        CommonDef.gameNet.requestTournamentEntry(this.tournamentDialog.tournamentid);
    }

    setTournamentEntry(tournamentstate, entries) {
        this.tournamentDialog.setEntry(tournamentstate, entries);
    }

    requestTournamentTable() {
        CommonDef.gameNet.requestTournamentTable(this.tournamentDialog.tournamentid);
    }

    setTournamentTable(tables) {
        this.tournamentDialog.setTable(tables);
    }

    requestTournamentPrize() {
        CommonDef.gameNet.requestTournamentPrize(this.tournamentDialog.tournamentid);
    }

    setTournamentPrize(prize) {
        this.tournamentDialog.setPrize(prize);
    }

    onClickedEvent() {
        this.showEventPage();
    }

    showEventPage() {
        this.eventPage = new EventPage(this, {
            atlas: this.LobbyAtlasName
        });
        this.bodyContainer.add(this.eventPage);
        CommonDef.gameNet.requestPresentEventList();
    }

    setEventItems(eventItems) {
        if(this.eventPage && this.eventPage.active && this.eventPage.list && this.eventPage.list.length > 0) this.eventPage.setEventItems(eventItems);
    }

    requestAcceptEventItem(eventItemId) {
        CommonDef.gameNet.requestAcceptEventItem(eventItemId);
    }

    onSuccessEventItemAccept() {
        if(this.eventPage && this.eventPage.active && this.eventPage.list && this.eventPage.list.length > 0) this.eventPage.onEventItemArrived();
    }

    updateMyInfo() {
        if(CommonDef.Me.unreadNoteCount > 0) {
            this.showNoteDialog();
        }
        this.my_money_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.money));
        this.my_point_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.point));
        if(this.orientation == "pc") {
            this.divider_label.x = LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + 35;
            this.my_point_label.x = LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + 60;
            this.my_point_value.x = LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + this.my_point_label.width + 75;
            // this.btn_coupon.x = LobbyDef.profile.info[this.orientation].x + this.my_money_label.width + this.my_money_value.width + this.my_point_label.width + this.my_point_value.width + 75 + 95;
        }
        if(this.changeDialog && this.changeDialog.active) {
            this.changeDialog.myMoneyValue.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.money));
        }
        if(this.depositWithdrawDialog && this.depositWithdrawDialog.active) {
            this.depositWithdrawDialog.updateMyMoney();
        }
        if(this.pointDialog && this.pointDialog.active) {
            this.pointDialog.updateMyPoint();
        }
    }

    showToast(type, text) {
        if(this.toast) this.toast.close();
        this.toast = new Toast(this, {
            type: type,
            text: text
        });
        this.bodyContainer.add(this.toast);
        this.toast.setDepth(10);
        this.bodyContainer.sort("depth");
        setTimeout(() => {
            this.toast && this.toast.close();
        }, 2000);
    }

    showAlert() {
        if(CommonDef.AlarmStack.length < 1 || this.alertContainer.visible) return;
        let timeout = 15000;
        let message = CommonDef.AlarmStack.shift();
        this.alert_message.setText(message);
        this.alert_message.x = this.ScreenWidth;
        this.alertContainer.setVisible(true);
        this.tweens.add({
            targets: this.alert_message,
            x: -this.alert_message.width,
            duration: timeout,
            power: "Linear",
            onComplete: () => {
                this.hideAlert();
            }
        })
    }

    hideAlert() {
        this.tweens.killTweensOf(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();
    }

    updateServerTime() {
        this.tournamentDialog.visible && this.tournamentDialog.updateServerTime();
    }

    openRegisterDialog(tournamentid, buyin, fee, rebuy, starttime, rebuyFlag) {
        let registerDlg = new TournamentHoldemRegisterDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 350 : 400,
            atlas: this.LobbyDialogAtlasName,
            card_back: "card_back.png",
            card_light: "card_light.png",
            btn_ok: "btn_confirm_back",
            btn_cancel: "btn_cancel_back",
            btn_scale: [0.9, 0.9],
            scale: [0.4, 0.5],
            rebuyFlag: rebuyFlag,
            tournamentid: tournamentid,
            buyin: buyin,
            fee: fee,
            rebuy: rebuy,
            starttime: starttime,
            countdown: false
        });
        this.bodyContainer.add(registerDlg);
        registerDlg.setDepth(10);
        this.bodyContainer.sort("depth");
    }

    requestRegisterTournament(tournamentid) {
        CommonDef.gameNet.requestRegisterTournament(tournamentid);
    }

    requestUnregisterTournament() {
        CommonDef.gameNet.requestUnregisterTournament(this.tournamentDialog.tournamentid);
    }

    requestEnterTournament() {
        CommonDef.gameNet.requestEnterTournament(this.tournamentDialog.tournamentid);
    }

    requestObservingTournament(tableIndex = 0) {
        CommonDef.gameNet.requestObservingTournament(this.tournamentDialog.tournamentid, tableIndex);
    }

    showNoteDialog() {
        this.noteDlg = new NoteDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 200 : 0,
            atlas: this.MyPageAtlasName
        });
        this.bodyContainer.add(this.noteDlg);
        CommonDef.gameNet.requestNoteList();
    }

    setNoteList(noteList) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.setContent(noteList);
    }

    setNoteRead(noteid) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.setNoteRead(noteid);
    }

    removeNote(noteid) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.removeNote(noteid);
    }
}