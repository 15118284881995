import { FilterButton } from "../../../../components/button/filterbutton";
import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { SliderBar } from "../../../../components/slider/sliderbar";
import { TextClass } from "../../../../components/text/text";
import { SceneManager } from "../../../scene_manager";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class SettingSystem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.add(this.back);
        this.back.fillRect(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 480 : 580);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 480 : 580);
        this.back.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.backgroundMusicVolumeText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 0,
            y: this.scene.orientation == "pc" ? 82 : 48,
            text: translate('background_volume'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(this.scene.orientation == "pc" ? 1 : 0, 0.5);
        this.add(this.backgroundMusicVolumeText);

        this.btn_background_sound_off = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 194 : 0,
            y: this.scene.orientation == "pc" ? 82 : 87,
            atlas: this.config.atlas,
            normal: "icon_sound_off_normal.png",
            hover: "icon_sound_off_hover.png",
            clicked: "icon_sound_off_clicked.png",
            origin: [0, this.scene.orientation == "pc" ? 0.5 : 0],
            onClicked: this.onClickedBtnBackgroundSoundOff.bind(this)
        });
        this.add(this.btn_background_sound_off);

        this.backgroundMusicVolumeSlider = new SliderBar(this.scene, {
            x: this.scene.orientation == "pc" ? 260 : 67,
            y: this.scene.orientation == "pc" ? 73 : 97,
            absoluteX: this.scene.orientation == "pc" ? 750 : 146,
            atlas: this.config.atlas,
            width: this.scene.orientation == "pc" ? 530 : 455,
            height: 20,
            ruler: true,
            onChange: this.onChangeBackgroundMusicVolumeSlider.bind(this)
        });
        this.add(this.backgroundMusicVolumeSlider);
        this.backgroundMusicVolumeSlider.setValue(localStorage.getItem('vol_back') || 1);

        this.btn_background_sound_on = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 814 : 546,
            y: this.scene.orientation == "pc" ? 82 : 87,
            atlas: this.config.atlas,
            normal: "icon_sound_on_normal.png",
            hover: "icon_sound_on_hover.png",
            clicked: "icon_sound_on_clicked.png",
            origin: [0, this.scene.orientation == "pc" ? 0.5 : 0],
            onClicked: this.onClickedBtnBackgroundSoundOn.bind(this)
        });
        this.add(this.btn_background_sound_on);

        this.effectVolumeText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 0,
            y: this.scene.orientation == "pc" ? 198 : 179,
            text: translate('effect_volume'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(this.scene.orientation == "pc" ? 1 : 0, 0.5);
        this.add(this.effectVolumeText);

        this.btn_effect_sound_off = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 194 : 0,
            y: this.scene.orientation == "pc" ? 198 : 218,
            atlas: this.config.atlas,
            normal: "icon_sound_off_normal.png",
            hover: "icon_sound_off_hover.png",
            clicked: "icon_sound_off_clicked.png",
            origin: [0, this.scene.orientation == "pc" ? 0.5 : 0],
            onClicked: this.onClickedBtnEffectSoundOff.bind(this)
        });
        this.add(this.btn_effect_sound_off);

        this.effectVolumeSlider = new SliderBar(this.scene, {
            x: this.scene.orientation == "pc" ? 260 : 67,
            y: this.scene.orientation == "pc" ? 189 : 218,
            absoluteX: this.scene.orientation == "pc" ? 750 : 146,
            atlas: this.config.atlas,
            width: this.scene.orientation == "pc" ? 530 : 455,
            height: 20,
            ruler: true,
            onChange: this.onChangeEffectVolumeSlider.bind(this)
        });
        this.add(this.effectVolumeSlider);
        this.effectVolumeSlider.setValue(localStorage.getItem('vol_effect') || 1);

        this.btn_effect_sound_on = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 814 : 546,
            y: this.scene.orientation == "pc" ? 198 : 218,
            atlas: this.config.atlas,
            normal: "icon_sound_on_normal.png",
            hover: "icon_sound_on_hover.png",
            clicked: "icon_sound_on_clicked.png",
            origin: [0, this.scene.orientation == "pc" ? 0.5 : 0],
            onClicked: this.onClickedBtnEffectSoundOn.bind(this)
        });
        this.add(this.btn_effect_sound_on);

        this.screenModeText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 165 : 0,
            y: this.scene.orientation == "pc" ? 314 : 310,
            text: translate('screen_mode'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(this.scene.orientation == "pc" ? 1 : 0, 0.5);
        this.add(this.screenModeText);

        this.screenModeFilterButton = new FilterButton(this.scene, {
            x: this.scene.orientation == "pc" ? 177 : -17,
            y: this.scene.orientation == "pc" ? 314 : 367,
            atlas: this.config.atlas,
            // back: "screen_mode_filter_back.png",
            back: "voice_filter_back.png",
            button_back: "setting_filter_btn_back.png",
            list: [{
                text: translate('portrait'),
                x: 69,
                y: 0,
                value: "portrait"
            }, /*{
                text: translate('auto'),
                x: 174,
                y: 0,
                value: "auto"
            },*/ {
                text: translate('landscape'),
                x: 174, // 279,
                y: 0,
                value: "landscape"
            }],
            color: {
                active: "#ffffff",
                inactive: "#662600"
            },
            fontSize: 18,
            disabled: window.isMobile == 0
        });
        this.add(this.screenModeFilterButton);
        let screen_mode = localStorage.getItem('screen_mode') || 'portrait';
        if(window.isMobile == 0) screen_mode = 'landscape';
        this.screenModeFilterButton.setValue(screen_mode);

        this.voiceText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 620 : 377,
            y: this.scene.orientation == "pc" ? 314 : 310,
            text: translate('voice'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(this.scene.orientation == "pc" ? 1 : 0, 0.5);
        this.add(this.voiceText);

        this.voiceFilterButton = new FilterButton(this.scene, {
            x: this.scene.orientation == "pc" ? 630 : 360,
            y: this.scene.orientation == "pc" ? 314 : 367,
            atlas: this.config.atlas,
            back: "voice_filter_back.png",
            button_back: "setting_filter_btn_back.png",
            list: [{
                text: translate('male'),
                x: 69,
                y: 0,
                value: "male"
            }, {
                text: translate('female'),
                x: 174,
                y: 0,
                value: "female"
            }],
            color: {
                active: "#ffffff",
                inactive: "#662600"
            },
            fontSize: 20
        });
        this.add(this.voiceFilterButton);
        this.voiceFilterButton.setValue(localStorage.getItem('voice') || 'male');

        this.btn_cancel = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 277 : 80,
            y: this.scene.orientation == "pc" ? 403 : 488,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png',
            },
            hover: {
                back: 'btn_cancel_back_hover.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png'
            },
            text: {
                x: 70,
                y: 15,
                value: translate('cancel'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_cancel);

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 480 : 310,
            y: this.scene.orientation == "pc" ? 403 : 488,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 45,
                y: 15,
                value: translate('save'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.onClickedBtnConfirm.bind(this)
        });
        this.add(this.btn_confirm);
    }

    close() {
        this.config.close && this.config.close();
    }

    onClickedBtnBackgroundSoundOff() {
        let value = this.backgroundMusicVolumeSlider.getValue();
        value -= 0.05;
        if(value < 0) value = 0;
        this.backgroundMusicVolumeSlider.setValue(value);
    }

    onClickedBtnBackgroundSoundOn() {
        let value = this.backgroundMusicVolumeSlider.getValue();
        value += 0.05;
        if(value > 1) value = 1;
        this.backgroundMusicVolumeSlider.setValue(value);
    }

    onChangeBackgroundMusicVolumeSlider(value) {
        this.scene.audio.changeVolume("BGM", value);
    }

    onClickedBtnEffectSoundOff() {
        let value = this.effectVolumeSlider.getValue();
        value -= 0.05;
        if(value < 0) value = 0;
        this.effectVolumeSlider.setValue(value);
    }

    onClickedBtnEffectSoundOn() {
        let value = this.effectVolumeSlider.getValue();
        value += 0.05;
        if(value > 1) value = 1;
        this.effectVolumeSlider.setValue(value);
    }

    onChangeEffectVolumeSlider(value) {
        this.scene.audio.changeVolume("effect", value);
    }

    onClickedBtnConfirm() {
        localStorage.setItem('vol_back', this.backgroundMusicVolumeSlider.getValue());
        localStorage.setItem('vol_effect', this.effectVolumeSlider.getValue());
        localStorage.setItem('voice', this.voiceFilterButton.value);
        let screen_mode = this.screenModeFilterButton.value;
        if(localStorage.getItem("screen_mode") != screen_mode) {
            CommonDef.ScreenModeChanged = true;
            localStorage.setItem("screen_mode", screen_mode);
            screen.orientation.lock(screen_mode).then(function success() {
                console.log("Successfully locked the orientation");
            },function error(errMsg) {
                console.log("Error locking the orientation :: " + errMsg);
            });
        }
        this.close();
    }
}