import { IconButton } from "../../../../components/button/iconbutton";
import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import * as DateTimeUtil from "../../../../util/datetime";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class TabDetail extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.detail = {}
        this.interval = null;
        this.initGraph();
    }

    initGraph() {
        this.titleBorder = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.titleBorder.lineStyle(1, 0x222222, 1);
        this.titleBorder.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 530 : 590, this.scene.orientation == "pc" ? 50 : 56, this.scene.orientation == "pc" ? 25 : 28);
        this.add(this.titleBorder);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 265 : 295,
            y: this.scene.orientation == "pc" ? 25 : 28,
            text: "",
            color: "#ffc97c",
            fontSize: this.scene.orientation == "pc"? 24 : 27
        }).setOrigin(0.5, 0.5);
        this.add(this.title);

        // this.player_count = TextClass.plainText1(this.scene, {
        //     x: this.scene.orientation == "pc" ? 510 : 570,
        //     y: this.scene.orientation == "pc" ? 25 : 28,
        //     text: "",
        //     color: "#ffffff",
        //     fontSize: this.scene.orientation == "pc" ? 16 : 18
        // }).setOrigin(1, 0.5);
        // this.add(this.player_count);

        // this.icon_user = this.scene.add.sprite((this.scene.orientation == "pc" ? 510 : 570) - this.player_count.width - 5, this.scene.orientation == "pc" ? 25 : 28, this.config.atlas, 'icon_user.png').setOrigin(1, 0.5);
        // this.add(this.icon_user);

        this.light_top = this.scene.add.sprite(this.scene.orientation == "pc" ? 265 : 295, this.scene.orientation == "pc" ? 138 : 154, this.config.atlas, "tournament_detail_light_top.png").setOrigin(0.5, 1);
        this.add(this.light_top);
        this.light_bottom = this.scene.add.sprite(this.scene.orientation == "pc" ? 265 : 295, this.scene.orientation == "pc" ? 138 : 154, this.config.atlas, "tournament_detail_light_bottom.png").setOrigin(0.5, 0);
        this.add(this.light_bottom);
        
        this.timeText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 265 : 295,
            y: this.scene.orientation == "pc" ? 128 : 144,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 42 : 46,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0.5, 1);
        this.add(this.timeText);

        this.stateText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 387 : 430,
            y: this.scene.orientation == "pc" ? 123 : 140,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 16 : 18,
            color: "#9e2700"
        }).setOrigin(0, 1);
        this.add(this.stateText);

        this.timeDescriptionText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 265 : 295,
            y: this.scene.orientation == "pc" ? 153 : 169,
            text: "",
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 20 : 22,
            color: "#f58a00"
        }).setOrigin(0.5, 0);
        this.add(this.timeDescriptionText);

        let summaryPosition = {
            pc: {
                x: [88, 177, 265, 353, 442],
                y: [238, 242, 265]
            },
            mobile: {
                x: [98, 197, 295, 393, 492],
                y: [263, 270, 295]
            }
        }

        this.blind_up_time = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[0],
            y: summaryPosition[this.scene.orientation].y[0],
            text: "",
            color: "#ffffff",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 26 : 28
        }).setOrigin(0.5, 1);
        this.add(this.blind_up_time);

        this.blind_up_time_text = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[0],
            y: summaryPosition[this.scene.orientation].y[2],
            text: translate('blind_up'),
            color: "#777777",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 16 : 18
        }).setOrigin(0.5, 1);
        this.add(this.blind_up_time_text);

        let divider0 = this.scene.add.sprite(summaryPosition[this.scene.orientation].x[1], summaryPosition[this.scene.orientation].y[1], this.config.atlas, "tournament_detail_divider.png").setOrigin(0.5, 0.5);
        this.add(divider0);
        divider0.setScale(0.8)

        this.late_reg_level = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[2],
            y: summaryPosition[this.scene.orientation].y[0],
            text: "",
            color: "#ffffff",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 26 : 28
        }).setOrigin(0.5, 1);
        this.add(this.late_reg_level);

        this.late_reg_level_text = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[2],
            y: summaryPosition[this.scene.orientation].y[2],
            text: translate('last_reg'),
            color: "#777777",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 16 : 18
        }).setOrigin(0.5, 1);
        this.add(this.late_reg_level_text);

        let divider1 = this.scene.add.sprite(summaryPosition[this.scene.orientation].x[3], summaryPosition[this.scene.orientation].y[1], this.config.atlas, "tournament_detail_divider.png").setOrigin(0.5, 0.5);
        this.add(divider1);
        divider1.setScale(0.8)

        this.current_level = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[4],
            y: summaryPosition[this.scene.orientation].y[0],
            text: "",
            color: "#ffffff",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 26 : 28
        }).setOrigin(0.5, 1);
        this.add(this.current_level);

        this.current_level_text = TextClass.plainText1(this.scene, {
            x: summaryPosition[this.scene.orientation].x[4],
            y: summaryPosition[this.scene.orientation].y[2],
            text: translate('cur_level'),
            color: "#777777",
            fontFamily: "NanumSquareR",
            fontSize: this.scene.orientation == "pc" ? 16 : 18
        }).setOrigin(0.5, 1);
        this.add(this.current_level_text);

        this.splitGraphic = this.scene.add.graphics({
            x: 0,
            y: this.scene.orientation == "pc" ? 296 : 330,
            fillStyle: {
                color: 0x222222,
                alpha: 1
            }
        });
        this.splitGraphic.fillRect(0, 0, this.scene.orientation == "pc" ? 530 : 590, 1);
        this.add(this.splitGraphic);

        let detailPosition = {
            pc: {
                x: [0, 324],
                y: [337, 377, 417, 457, 497, 537]
            },
            mobile: {
                x: [0, 360],
                y: [375, 420, 465, 510, 555, 600]
            }
        }

        let detailText = [
            translate('total_prize'), 
            translate('buyin'),
            translate('entry'), 
            translate('entry_range'), 
            translate('start_chip'), 
            translate('av_stack'), 
            translate('rebuy'), 
            translate('addon'), 
            translate('break')
        ];
        this.detailValue = [];
        for(let i = 0 ; i < 9 ; i ++) {
            let text0 = TextClass.plainText1(this.scene, {
                x: detailPosition[this.scene.orientation].x[i < 2 ? 0 : i % 2],
                y: detailPosition[this.scene.orientation].y[i < 2 ? i : Math.floor(i / 2) + 1],
                text: "■",
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 12 : 13,
                color: "#9e2700"
            }).setOrigin(0, 0.5);
            this.add(text0);

            let text1 = TextClass.plainText1(this.scene, {
                x: detailPosition[this.scene.orientation].x[i < 2 ? 0 : i % 2] + 20,
                y: detailPosition[this.scene.orientation].y[i < 2 ? i : Math.floor(i / 2) + 1],
                text: detailText[i] + " : ",
                fontSize: this.scene.orientation == "pc" ? 20 : 22,
                color: "#777777"
            }).setOrigin(0, 0.5);
            this.add(text1);

            this.detailValue[i] = TextClass.plainText1(this.scene, {
                x: detailPosition[this.scene.orientation].x[i < 2 ? 0 : i % 2] + 20 + text1.width,
                y: detailPosition[this.scene.orientation].y[i < 2 ? i : Math.floor(i / 2) + 1],
                text: "",
                fontSize: this.scene.orientation == "pc" ? 20 : 22,
                color: "#ffffff"
            }).setOrigin(0, 0.5);
            this.add(this.detailValue[i]);
        }

        this.btn_blind = new IconButton(this.scene, {
            x: detailPosition[this.scene.orientation].x[1] - 7,
            y: detailPosition[this.scene.orientation].y[0] - (this.scene.orientation == "pc" ? 20 : 22),
            atlas: this.config.atlas,
            normal: {
                back: "btn_blind_back_normal.png",
                icon: 'icon_help.png',
                icon_position: {
                    x: 15,
                    y: 7
                }
            },
            hover: {
                back: "btn_blind_back_hover.png"
            },
            clicked: {
                back: "btn_blind_back_clicked.png"
            },
            text: {
                x: this.scene.orientation == "pc" ? 50 : 55,
                y: 10,
                value: translate('blinds_tree'),
                fontSize: this.scene.orientation == "pc" ? 20 : 22,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.onClickedBtnBlind.bind(this)
        });
        this.add(this.btn_blind);

        
    }

    onClickedBtnBlind() {
        this.config.openBlindsTable();
    }

    getStateString(state) {
        if(state < CommonDef.tournament_state.WAITING || state > CommonDef.tournament_state.FINISHED) return "";
        if(state == CommonDef.tournament_state.WAITING) return translate('tournament_state_waiting');
        if(state == CommonDef.tournament_state.STARTING) return translate('tournament_state_starting');
        if(state > CommonDef.tournament_state.STARTING && state < CommonDef.tournament_state.FINISHED) return translate('tournament_state_playing');
        if(state == CommonDef.tournament_state.FINISHED) return translate('tournament_state_finished');
    }

    initDetail() {
        this.title.setText("");
        this.stateText.setText("");
        this.timeDescriptionText.setText("")
        this.blind_up_time.setText("");
        this.late_reg_level.setText("");
        this.current_level.setText("")
        for(let i = 0 ; i < 9 ; i ++) {
            this.detailValue[i].setText("");
        }
        if(this.interval) clearInterval(this.interval);
        this.detail = {};
    }

    setDetail(detail) {
        if(detail == null) {
            this.initDetail();
            return;
        }
        this.detail = detail;
        this.title.setText(detail.title);
        this.stateText.setText(this.getStateString(detail.state));
        this.timeDescriptionText.setText(`${translate('tournament_start_time')}: ${DateTimeUtil.convertDefaultDateTime(detail.starttime)}`)
        this.blind_up_time.setText(detail.blinds_up_time + " min");
        this.late_reg_level.setText(`${translate('level')} ${detail.late_reg_level}`);
        this.current_level.setText(detail.current_level)
        for(let i = 0 ; i < 9 ; i ++) {
            let value = "";
            // if(i == 0) value = detail.buyin > 0 ? "-" : NumberUtil.convertNumberWithComma(detail.prize);
            if(i == 0) value = NumberUtil.convertNumberWithComma(detail.prize);
            else if(i == 1) value = detail.buyin > 0 ? NumberUtil.convertNumbersWithUnit(detail.buyin) + "+" +   NumberUtil.convertNumbersWithUnit(detail.fee) : translate('free');
            else if(i == 2) value = detail.player_count + (detail.state > CommonDef.tournament_state.STARTING ? "/" + detail.total_entry : "");
            else if(i == 3) value = detail.min_entry + "~" + detail.max_entry;
            else if(i == 4) value = NumberUtil.convertNumberWithComma(detail.start_chip);
            else if(i == 5) value = detail.state > CommonDef.tournament_state.STARTING ? NumberUtil.convertNumberWithComma(detail.av_stack) : "-";
            else if(i == 6) {
                if(detail.rebuy_count < 0) value = translate('tournament_rebuy_no_limit');
                else if(detail.rebuy_count == 0) value = translate('tournament_rebuy_not_allow');
                else value = detail.rebuy_count + translate('tournament_rebuy_count_unit')
            }
            else if(i == 7) value = detail.addon == 0 ? translate('tournament_addon_no') : NumberUtil.convertNumbersWithUnit(detail.addon);
            else if(i == 8) {
                if(detail.break_time_cycle > 0) {
                    value = translate('tournament_break').replace('%1', detail.break_time_cycle).replace('%2', detail.break_time);
                } else {
                    value = translate('tournament_break_no');
                }
            }
            this.detailValue[i].setText(value);
        }

        this.startInterval();
    } 

    startInterval() {
        if(this.interval) clearInterval(this.interval);
        if(this.detail.state >= CommonDef.tournament_state.FINISHED) return;
        let deltaTime = 0;
        if(this.detail.state < CommonDef.tournament_state.REBUY) deltaTime = this.detail.starttime - CommonDef.ServerTime;
        else deltaTime = CommonDef.ServerTime - this.detail.starttime;
        this.timeText.setText(DateTimeUtil.convertToLeftTime(deltaTime));
        this.interval = setInterval(() => {
            if(this.detail.state < CommonDef.tournament_state.REBUY) deltaTime -= 1000;
            else deltaTime += 1000;
            if(deltaTime < 0) deltaTime = 0;
            if(this.scene) this.timeText.setText(DateTimeUtil.convertToLeftTime(deltaTime));
            else clearInterval(this.interval);
        }, 1000);
    }
}