import { IconButton } from "../../../components/button/iconbutton";
import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import LobbyDef from '../../../def/lobbydef.js'
import { translate } from "../../../util/other";
import { TabHoldemScrollViewContent } from "./tab_holdem_scroll_view_content";
export class OmahaContent extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
    }

    initGraphForPC() {
        this.btn_refresh = new IconButton(this.scene, {
            x: 0,
            y: LobbyDef.holdem_content[this.scene.orientation].button.y[0],
            atlas: this.config.atlas,
            normal: {
                back: 'btn_refresh_back_normal.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            hover: {
                back: 'btn_refresh_back_hover.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            clicked: {
                back: 'btn_refresh_back_clicked.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            text: {
                x: 70,
                y: 18,
                value: translate('refresh'),
                fontSize: 22,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: () => {
                this.scene.requestLobbyList(1)
            }
        });
        this.add(this.btn_refresh);

        this.btn_create_room = new IconButton(this.scene, {
            x: 0,
            y: LobbyDef.holdem_content[this.scene.orientation].button.y[1],
            atlas: this.config.atlas,
            normal: {
                back: 'btn_refresh_back_normal.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            hover: {
                back: 'btn_refresh_back_hover.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            clicked: {
                back: 'btn_refresh_back_clicked.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            text: {
                x: 70,
                y: 18,
                value: translate('create_room'),
                fontSize: 22,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: () => {
                this.scene.onClickedBtnCreateRoom();
            }
        });
        this.add(this.btn_create_room);

        this.btn_join_lobby = new IconButton(this.scene, {
            x: 0,
            y: LobbyDef.holdem_content[this.scene.orientation].button.y[2],
            atlas: this.config.atlas,
            normal: {
                back: 'btn_join_lobby_back_normal.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            hover: {
                back: 'btn_join_lobby_back_hover.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            clicked: {
                back: 'btn_join_lobby_back_clicked.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 12
                }
            },
            text: {
                x: 70,
                y: 18,
                value: translate('quick_join'),
                fontSize: 22,
                gradient: ['#d7cfcf', '#ffffff']
            },
            onClicked: () => {
                this.scene.onClickedBtnQuickJoin();
            }
        });
        this.add(this.btn_join_lobby);

        let tableHeaderContainer = this.scene.add.container(0, 0);
        for(let i = 0 ; i < LobbyDef.tabHoldemTable.header.text.length ; i ++) {
            let label = TextClass.plainText1(this.scene, {
                x: LobbyDef.tabHoldemTable.header.pc.x[i],
                y: LobbyDef.tabHoldemTable.header.pc.y,
                text: LobbyDef.tabHoldemTable.header.text[i],
                color: "#444444",
                fontSize: 20,
            }).setOrigin(0.5, 0.5)
            tableHeaderContainer.add(label);
        }
        this.add(tableHeaderContainer)

        this.mainContent = new ScrollView(this.scene, {
            x: LobbyDef.tabHoldemTable.content[this.scene.orientation].x,
            y: LobbyDef.tabHoldemTable.content[this.scene.orientation].y,
            width: LobbyDef.tabContainer[this.scene.orientation].width,
            height: LobbyDef.tabContainer[this.scene.orientation].height,
            atlas: this.config.atlas,
            thumb: 'scroll_thumb.png',
            content: new TabHoldemScrollViewContent(this.scene, {
                x: 0,
                y: 0,
                atlas: this.config.atlas,
                content: this.config.content
            }),
            mask: {
                x: 533,
                y: 380
            },
            action_rect: {
                width: LobbyDef.tabContainer[this.scene.orientation].width - 30,
                height: LobbyDef.tabContainer[this.scene.orientation].height
            }
        });
        this.add(this.mainContent);
    }

    initGraphForMobile() {
        this.btn_join_lobby = new IconButton(this.scene, {
            x: LobbyDef.holdem_content[this.scene.orientation].button.x[0],
            y: LobbyDef.holdem_content[this.scene.orientation].button.y,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_join_lobby_back_normal.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            hover: {
                back: 'btn_join_lobby_back_hover.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            clicked: {
                back: 'btn_join_lobby_back_clicked.png',
                icon: 'btn_join_lobby_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            text: {
                x: 70,
                y: 16,
                value: translate('quick_join'),
                fontSize: 22,
                gradient: ['#d7cfcf', '#ffffff']
            },
            onClicked: () => {
                this.scene.onClickedBtnQuickJoin();
            }
        });
        this.add(this.btn_join_lobby);

        this.btn_create_room = new IconButton(this.scene, {
            x: LobbyDef.holdem_content[this.scene.orientation].button.x[1],
            y: LobbyDef.holdem_content[this.scene.orientation].button.y,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_refresh_back_normal.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            hover: {
                back: 'btn_refresh_back_hover.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            clicked: {
                back: 'btn_refresh_back_clicked.png',
                icon: 'btn_create_room_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            text: {
                x: 70,
                y: 16,
                value: translate('create_room'),
                fontSize: 22,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: () => {
                this.scene.onClickedBtnCreateRoom();
            }
        });
        this.add(this.btn_create_room);

        this.btn_refresh = new IconButton(this.scene, {
            x: LobbyDef.holdem_content[this.scene.orientation].button.x[2],
            y: LobbyDef.holdem_content[this.scene.orientation].button.y,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_refresh_back_normal.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            hover: {
                back: 'btn_refresh_back_hover.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            clicked: {
                back: 'btn_refresh_back_clicked.png',
                icon: 'btn_refresh_icon.png',
                icon_position: {
                    x: 19,
                    y: 10
                }
            },
            text: {
                x: 70,
                y: 16,
                value: translate('refresh'),
                fontSize: 22,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: () => {
                this.scene.requestLobbyList(0)
            }
        });
        this.add(this.btn_refresh);

        this.mainContent = new ScrollView(this.scene, {
            x: LobbyDef.tabHoldemTable.content[this.scene.orientation].x,
            y: LobbyDef.tabHoldemTable.content[this.scene.orientation].y,
            width: LobbyDef.tabContainer[this.scene.orientation].width,
            height: LobbyDef.tabContainer[this.scene.orientation].height,
            atlas: this.config.atlas,
            thumb: 'scroll_thumb.png',
            content: new TabHoldemScrollViewContent(this.scene, {
                x: -10,
                y: 0,
                atlas: this.config.atlas,
                content: this.config.content
            }),
            mask: {
                x: 78,
                y: 506
            },
            action_rect: {
                width: LobbyDef.tabContainer[this.scene.orientation].width - 30,
                height: LobbyDef.tabContainer[this.scene.orientation].height
            }
        });
        this.add(this.mainContent);
    }

    setContent(content) {
        this.mainContent.content.setContent(content);
        this.mainContent.updateScroll();
    }
}