import { TextClass } from "../text/text";
export class BettingButton extends Phaser.GameObjects.Container {
    constructor(scene, config){
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.disabled = false;
        this.bookingState = false;
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.normal.back);
        this.back.setOrigin(0, 0);
        if(this.config.origin) {
            this.back.setOrigin(this.config.origin[0], this.config.origin[1]);
        }
        if(this.config.scale) {
            this.back.setScale(this.config.scale[0], this.config.scale[1]);
        }
        this.add(this.back);
        if(this.config.normal.icon) {
            this.icon = this.scene.add.sprite(this.config.normal.icon_position.x, this.config.normal.icon_position.y, this.config.atlas, this.config.normal.icon);
            this.icon.setOrigin(0, 0);
            this.add(this.icon);
            this.config.normal.icon_scale && this.icon.setScale(this.config.normal.icon_scale);
        }

        this.checkBack = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 60 : 52,
            y: this.scene.orientation == "pc" ? 40 : 45,
            fillStyle: {
                color: 0xffffff,
                alpha: 0.6
            }
        });
        this.checkBack.fillCircle(0, 0, 20);
        this.checkBack.setVisible(false);
        this.add(this.checkBack);

        this.checkIcon = this.scene.add.sprite(this.scene.orientation == "pc" ? 60 : 52, this.scene.orientation == "pc" ? 40 : 45, this.config.atlas, "book_check.png").setOrigin(0.5, 0.5);
        this.checkIcon.setVisible(false);
        this.add(this.checkIcon);

        if(this.config.text) {
            this.text = TextClass.plainText1(this.scene, {
                x: this.config.text.x,
                y: this.config.text.y,
                text: this.config.text.value,
                fontSize: this.config.text.fontSize,
                color: this.config.text.color,
                gradient: this.config.text.gradient
            });
            if(this.config.text.origin) {
                this.text.setOrigin(this.config.text.origin[0], this.config.text.origin[1]);
            }
            this.add(this.text);
        }

        
        this.config.interactive !== false && this.back.setInteractive();
        this.back.on('pointerup', () => {
            this.setHover();
            if(!this.disabled && this.isDown) {
                this.scene.audio.playEffect("BtnClick");
                if(this.bookingState) {
                    this.toggleBooking();
                } else {
                    this.config.onClicked && this.config.onClicked();
                }
            }
            this.isDown = false;
        })

        if(this.config.clicked) {
            this.back.on('pointerdown', () => {
                this.isDown = true;
                this.setClicked();
            })
        }

        if(this.config.hover) {
            this.back.on("pointerover", () => {
                this.setHover();
            })
        }

        this.back.on("pointerout", () => {
            this.setRelease();
        })
    }

    setDisable(flag) {
        this.disabled = flag;
        if(flag && this.config.disabled) {
            this.back.setTexture(this.config.atlas, this.config.disabled.back);
        }
        if(!flag) {
            this.setRelease();
        }
    }

    setHover() {
        if(!this.disabled && this.config.hover) {
            this.back.setTexture(this.config.atlas, this.config.hover.back)
        }
    }

    setClicked() {
        if(!this.disabled && this.config.clicked) {
            this.back.setTexture(this.config.atlas, this.config.clicked.back)
        }
    }

    setRelease() {
        if(!this.disabled) {
            this.back.setTexture(this.config.atlas, this.config.normal.back);
        }
    }

    convertBooking(flag) {
        if(this.bookingState == flag) return;
        this.bookingState = flag;
        if(flag) {
            this.icon.setVisible(false);
            this.checkBack.setVisible(true);
            this.checkIcon.setVisible(false);
        } else {
            this.icon.setVisible(true);
            this.checkBack.setVisible(false);
        }
    }

    clearBooking() {
        this.checkIcon.setVisible(false);
    }

    toggleBooking() {
        if(!this.bookingState) return;
        this.scene.clearBettingBook();
        this.scene.requestBettingBook(this.config.action);
    }
}