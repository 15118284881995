export default {
    top_text_position: {
        pc: {
            x: [755, 862, 939, 983, 1040, 1086, 1132, 1188],
            y: 28
        },
        mobile: {
            x: [187, 285, 356, 396, 447, 490, 532, 584],
            y: 27
        }
    },
    top_button: {
        pc: {
            x: [97, 150, 237, 1826],
            y: 80
        },
        mobile: {
            x: [65, 115, 193, 690],
            y: 80
        }
    },
    bottom_button: {
        pc: {
            x: [105, 195, 1110, 1360, 1610],
            y: [970, 930]
        },
        mobile: {
            x: [70, 157, 380, 500, 620],
            y: [1267, 1211, 1310]
        }
    },
    time_bank: {
        pc: {
            x: 100,
            y: 880
        },
        mobile: {
            x: 70,
            y: 1200
        }
    },
    double_rabbie: {
        pc: {
            x: 200,
            y: 880
        },
        mobile: {
            x: 155,
            y: 1200
        }
    },
    table: {
        pc: {
            y: 197
        },
        mobile: {
            y: 144
        }
    },
    notice: {
        pc: {
            x: 960,
            y: 680
        },
        mobile: {
            x: 375,
            y: 880
        }
    },
    detail: {
        pc: {
            x: 960,
            y: 645
        },
        mobile: {
            x: 375,
            y: 680
        }
    },
    pot: {
        pc: {
            icon: {
                x: 730,
                y: 348
            },
            back: {
                x: [740, 860, 1045],
                y: 415
            }
        },
        mobile: {
            icon: {
                x: 222,
                y: 440
            },
            back: {
                x: [213, 303, 425],
                y: 479
            }
        }
    },
    players: {
        pc: {
            6: {
                x: [848, 421, 437, 848, 1487, 1487],
                y: [730, 622, 199, 99, 199, 622]
            },
            9: {
                x: [848, 520, 318, 427, 772, 1152, 1498, 1605, 1405],
                y: [730, 700, 448, 170, 100, 100, 170, 448, 700]
            }
        },
        mobile: {
            6: {
                x: [282, 102, 102, 375, 652, 652],
                y: [990, 738, 364, 168, 364, 738]
            },
            9: {
                x: [282, 102, 102, 102, 102, 652, 652, 652, 652],
                y: [990, 858, 646, 436, 224, 224, 436, 646, 858]
            }
        }
    },
    shared_cards: {
        pc: {
            x: [730, 844, 960, 1076, 1196],
            y: 485
        },
        mobile: {
            x: [226, 301, 376, 451, 526],
            y: 540
        }
    },
    messageBox: {
        pc: {
            x: 40,
            y: 650
        },
        mobile: {
            x: 0,
            y: 970
        }
    },
    imback: {
        pc: {
            x: 1600,
            y: 940
        },
        mobile: {
            x: 500,
            y: 1230
        }
    },
    animations: {
        other: {
            Banana: { atlas: "EmoticonAtlas2", prefix: "Bannan_", last: 30, repeat: 1, speed: 0.05, zeroPad: 5, scale: 0.7, width: 100 },
            Beer: { atlas: "EmoticonAtlas2", prefix: "Beer_", last: 1, repeat: 2, speed: 0.35, zeroPad: 2, scale: 0.35, width: 200 },
            Donkey: { atlas: "EmoticonAtlas2", prefix: "Donkey_", last: 1, repeat: 1, speed: 0.3, zeroPad: 2, scale: 0.18, width: 90 },
            Excrement: { atlas: "EmoticonAtlas2", prefix: "Excrement_", last: 19, repeat: 1, speed: 0.03, zeroPad: 5, scale: 0.3, width: 100 },
            // Bomb: { atlas: "EmoticonAtlas1", prefix: "Bomb_", last: 49, repeat: 0, speed: 0.1, zeroPad: 5, scale: 0.33, width: 100},
            Fish: { atlas: "EmoticonAtlas2", prefix: "Fish_", last: 9, repeat: 2, speed: 0.05, zeroPad: 5, scale: 0.45, width: 120 },
            Kiss: { atlas: "EmoticonAtlas2", prefix: "Kiss_", last: 1, repeat: 0, speed: 0.3, zeroPad: 2, scale: 0.18, width: 110 },
            Like: { atlas: "EmoticonAtlas2", prefix: "Like_", last: 1, repeat: 2, speed: 0.5, zeroPad: 2, scale: 0.25, width: 80 }
        },
        self: {
            Angry: { atlas: "EmoticonAtlas3", prefix: "Angry_", last: 19, repeat: 1, speed: 0.05, zeroPad: 5, scale: 0.28, width: 100 },
            Crying: { atlas: "EmoticonAtlas3", prefix: "Crying_", last: 9, repeat: 3, speed: 0.1, zeroPad: 5, scale: 0.27, width: 100 },
            Laught: { atlas: "EmoticonAtlas3", prefix: "Laught_", last: 9, repeat: 2, speed: 0.08, zeroPad: 5, scale: 0.6, width: 100 },
            Nose: { atlas: "EmoticonAtlas3", prefix: "Nose_", last: 20, repeat: 1, speed: 0.05, zeroPad: 5, scale: 0.25, width: 100 },
            Funny: { atlas: "EmoticonAtlas4", prefix: "Funny_", last: 23, repeat: 1, speed: 0.05, zeroPad: 5, scale: 0.25, width: 100 },
            Prying: { atlas: "EmoticonAtlas4", prefix: "Prying_", last: 19, repeat: 2, speed: 0.12, zeroPad: 5, scale: 0.25, width: 100 },
            Shooting: { atlas: "EmoticonAtlas4", prefix: "Shooting_", last: 11, repeat: 2, speed: 0.1, zeroPad: 5, scale: 0.27, width: 100 },
        }
    },


    // Global variables:
    MySeat: -1,
    BlockUsers: [],
    // Consts:
    roomState: {
        PREPARING: -1,
        WAITING: 0,
        START: 1,
        BLIND: 2,
        PREFLOP: 3,
        FLOP: 4,
        TURN: 5,
        RIVER: 6,
        GAMEOVER: 7
    },
    userState: {
        PREPARING: -1,
        WAITING: 0,
    },
    userPositionState: {
        NONE: 0,
        DEALER: 1,
        SB: 2,
        BB: 3
    },
    userBettingState: {
        NONE: 0,
        CHECK: 1,
        ALLIN: 2,
        CALL: 3,
        FOLD: 4,
        RAISE: 5,
        BET: 6,
        DEALER: 7,
        SB: 8,
        BB: 9
    },

    patternList: [
        "Royal Straight Flush",
        "Back Straight Flush",
        "Straight Flush",
        "Four Card",
        "Full House",
        "Flush",
        "Mountin",
        "Back Straight",
        "Straight",
        "Three of a kind",
        "Two Pairs",
        "One Pair",
        "High Card"
    ],

    Sounds: [
        ///// 배경음악
        {
            key: "GameBGM",
            file: "sounds/game_BGM.mp3",
            type: "BGM"
        },
        //// 이모티콘 Other
        {
            key: "Banana",
            file: "sounds/banana.mp3",
            type: "emoticon"
        },
        {
            key: "Beer",
            file: "sounds/beer.mp3",
            type: "emoticon"
        },
        {
            key: "Donkey",
            file: "sounds/donkey.mp3",
            type: "emoticon"
        },
        {
            key: "Excrement",
            file: "sounds/excrement.mp3",
            type: "emoticon"
        },
        {
            key: "Fish",
            file: "sounds/fish.mp3",
            type: "emoticon"
        },
        {
            key: "Kiss",
            file: "sounds/kiss.mp3",
            type: "emoticon"
        },
        {
            key: "Like",
            file: "sounds/like.mp3",
            type: "emoticon"
        },
        ///// 이모티콘 Self
        {
            key: "Angry",
            file: "sounds/angry.mp3",
            type: "emoticon"
        },
        {
            key: "Crying",
            file: "sounds/crying.mp3",
            type: "emoticon"
        },
        {
            key: "Laught",
            file: "sounds/laught.mp3",
            type: "emoticon"
        },
        {
            key: "Nose",
            file: "sounds/nose.mp3",
            type: "emoticon"
        },
        {
            key: "Funny",
            file: "sounds/funny.mp3",
            type: "emoticon"
        },
        {
            key: "Prying",
            file: "sounds/prying.mp3",
            type: "emoticon"
        },
        {
            key: "Shooting",
            file: "sounds/shooting.mp3",
            type: "emoticon"
        },
        {
            key: "Bomb",
            file: "sounds/bomb.mp3",
            type: "emoticon"
        },
        ////// 베팅 보이스
        {
            key: "raise_male",
            file: "sounds/raise_m.mp3",
            type: "voice"
        },
        {
            key: "raise_female",
            file: "sounds/raise_f.mp3",
            type: "voice"
        },
        {
            key: "call_male",
            file: "sounds/call_m.mp3",
            type: "voice"
        },
        {
            key: "call_female",
            file: "sounds/call_f.mp3",
            type: "voice"
        },
        {
            key: "allin_male",
            file: "sounds/allin_m.mp3",
            type: "voice"
        },
        {
            key: "allin_female",
            file: "sounds/allin_f.mp3",
            type: "voice"
        },
        {
            key: "check_male",
            file: "sounds/check_m.mp3",
            type: "voice"
        },
        {
            key: "check_female",
            file: "sounds/check_f.mp3",
            type: "voice"
        },
        {
            key: "fold_male",
            file: "sounds/fold_m.mp3",
            type: "voice"
        },
        {
            key: "fold_female",
            file: "sounds/fold_f.mp3",
            type: "voice"
        },
        {
            key: "bet_male",
            file: "sounds/bet_m.mp3",
            type: "voice"
        },
        {
            key: "bet_female",
            file: "sounds/bet_f.mp3",
            type: "voice"
        },
        ////// Effect
        {
            key: "Showdown",
            file: "sounds/showdown.mp3",
            type: "effect"
        },
        {
            key: "EnterRoom",
            file: "sounds/enter_room.mp3",
            type: "effect"
        },
        {
            key: "LeaveRoom",
            file: "sounds/leave_room.mp3",
            type: "effect"
        },
        {
            key: "Win",
            file: "sounds/win.mp3",
            type: "effect"
        },
        {
            key: "CardOpen",
            file: "sounds/card_open.mp3",
            type: "effect"
        },
        {
            key: "PullChip",
            file: "sounds/pull_chip.mp3",
            type: "effect"
        },
        {
            key: "CountDown",
            file: "sounds/countdown.mp3",
            type: "effect"
        },
        {
            key: "SetTurn",
            file: "sounds/setturn.mp3",
            type: "effect"
        },
        {
            key: "PushChip",
            file: "sounds/push_chip.mp3",
            type: "effect"
        },
        //////
        {
            key: "Pattern_0_male",
            file: "sounds/pattern_0_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_0_female",
            file: "sounds/pattern_0_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_2_male",
            file: "sounds/pattern_2_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_2_female",
            file: "sounds/pattern_2_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_3_male",
            file: "sounds/pattern_3_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_3_female",
            file: "sounds/pattern_3_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_4_male",
            file: "sounds/pattern_4_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_4_female",
            file: "sounds/pattern_4_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_5_male",
            file: "sounds/pattern_5_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_5_female",
            file: "sounds/pattern_5_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_8_male",
            file: "sounds/pattern_8_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_8_female",
            file: "sounds/pattern_8_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_9_male",
            file: "sounds/pattern_9_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_9_female",
            file: "sounds/pattern_9_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_10_male",
            file: "sounds/pattern_10_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_10_female",
            file: "sounds/pattern_10_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_11_male",
            file: "sounds/pattern_11_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_11_female",
            file: "sounds/pattern_11_f.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_12_male",
            file: "sounds/pattern_12_m.mp3",
            type: "pattern"
        },
        {
            key: "Pattern_12_female",
            file: "sounds/pattern_12_f.mp3",
            type: "pattern"
        }
    ]
}