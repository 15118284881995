import { FilterButton } from "../../../components/button/filterbutton";
import { ScrollView } from "../../../components/container/scrollview";
import { translate } from "../../../util/other";
import { TournamentItem } from "./tournament/tournament_item";

export class TabTournamentPage extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.btn_filter = new FilterButton(this.scene, {
            x: this.scene.orientation == "pc" ? 316 : -10,
            y: this.scene.orientation == "pc" ? 110 : 130,
            atlas: this.config.atlas,
            back: "tournament_filter_back.png",
            button_back: "tournament_filter_btn.png",
            list: [{
                text: translate('filter_all'),
                x: 72,
                y: 0,
                value: -1
            }, {
                text: translate('free'),
                x: 217,
                y: 0,
                value: 0
            }, {
                text: translate('h_100k'),
                x: 362,
                y: 0,
                value: 100000
            }, {
                text: translate('h_1m'),
                x: 507,
                y: 0,
                value: 1000000
            }],
            color: {
                active: "#ffffff",
                inactive: "#662600"
            },
            fontSize: 20,
            onChange: this.onChnageFilter.bind(this)
        });
        this.add(this.btn_filter);

        this.tournamentListContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: -7,
            y: this.scene.orientation == "pc" ? 170 : 200,
            width: this.scene.orientation == "pc" ? 1240 : 590,
            height: this.scene.orientation == "pc" ? 430 : 620,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.tournamentListContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 330 : 80,
                y: this.scene.orientation == "pc" ? 410 : 507
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 1210 : 560,
                height: this.scene.orientation == "pc" ? 430 : 620,
            }
        });
        this.add(this.scrollView);
    }

    setContent(tournaments) {
        this.tournamentListContainer.removeAll(true);
        let cols = this.scene.orientation == "pc" ? 3 : 1;
        for(let i = 0 ; i < tournaments.length ; i ++) {
            this.tournamentListContainer.add(new TournamentItem(this.scene, {
                x: (i % cols) * 410,
                y: Math.floor(i / cols) * (this.scene.orientation == "pc" ? 330 : 185),
                atlas: this.config.atlas,
                ...tournaments[i],
                onClick: this.onClickTournamentItem.bind(this)
            }));
        }
        this.tournamentListContainer.height = (this.scene.orientation == "pc" ? 330 : 185) * Math.ceil(tournaments.length / cols);
        this.scrollView.updateScroll();
    }

    onChnageFilter(value) {
        this.scene.requestLobbyList(2, value);
    }

    onClickTournamentItem(tournament_id) {
        this.scene.onClickTournamentItem(tournament_id);
    }
}