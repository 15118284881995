import { TextClass } from "../../../components/text/text";
import * as DateTimeUtil from "../../../util/datetime";

export class NoticeItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.id = this.config.id;
        this.title = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.config.height / 2,
            text: this.config.title.length > this.config.letter_count_limit ? this.config.title.substring(0, this.config.letter_count_limit - 2) + "..." : this.config.title,//this.config.title,
            fontSize: 20,
            color: "#d7bba3",
            fontFamily: "NanumSquareR"
        }).setOrigin(0, 0.5);
        this.add(this.title);

        this.date = TextClass.plainText1(this.scene, {
            x: this.config.width,
            y: this.config.height / 2,
            text: DateTimeUtil.convertShortDate(this.config.date),
            fontSize: 20,
            color: "#d7bba3",
            fontFamily: "NanumSquareR"
        }).setOrigin(1, 0.5);
        this.add(this.date);

        this.width = this.config.width;
        this.height = this.config.height;
    }

    onClick() {
        let detail = {
            id: this.id,
            type: this.config.type,
            title: this.config.title,
            date: DateTimeUtil.convertShortDate(this.config.date),
            content: this.config.content,
        }
        this.scene.showNoticeDetail(detail)
    }
}