import { TextClass } from "../text/text";

export class FilterButton extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }
    
    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.back);
        this.back.setOrigin(0, 0.5);
        this.add(this.back);

        this.btn_filter = this.scene.add.sprite(this.config.list[0].x, this.config.list[0].y, this.config.atlas, this.config.button_back);
        this.btn_filter.setOrigin(0.5, 0.5);
        this.add(this.btn_filter);
        this.btn_filter.setVisible(false);

        this.value = null;

        this.radioList = [];
        for(let i = 0 ; i < this.config.list.length ; i ++) {
            let container = this.scene.add.container(this.config.list[i].x, this.config.list[i].y);
            let text = TextClass.plainText1(this.scene, {
                x: 0,
                y: 0,
                text: this.config.list[i].text,
                color: this.config.color.inactive,
                fontFamily: this.config.fontFamily,
                fontSize: this.config.fontSize
            }).setOrigin(0.5, 0.5);
            container.add(text);
            this.add(container);
            container.setDepth(1);
            this.radioList.push(container)

            let action_area = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: 0xff0000,
                    alpha: 0.6
                }
            });
            let rect = new Phaser.Geom.Rectangle(-this.btn_filter.width / 2, -this.btn_filter.height / 2, this.btn_filter.width, this.btn_filter.height);
            container.add(action_area);
            action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
            action_area.on("pointerdown", () => {
                this.config.disabled !== true && this.setValue(this.config.list[i].value);
            })
        }

        

        this.sort("depth");

        this.setValue(this.config.list[0].value, false);
    }

    setValue(value, callback = true) {
        if(value == null) return;
        let index = this.config.list.findIndex(x => x.value == value);
        if(index < 0) return;
        if(this.value == this.config.list[index].value) return;
        this.value = this.config.list[index].value;
        this.btn_filter.setVisible(true);
        this.scene.tweens.add({
            targets: this.btn_filter,
            x: this.radioList[index].x,
            y: this.radioList[index].y,
            duration: 300,
            ease: "Power2"
        });
        for(let i = 0 ; i < this.radioList.length ; i ++) {
            this.radioList[i].list[0].setColor(i == index ? this.config.color.active : this.config.color.inactive)
        }
        this.config.onChange && this.config.onChange(value);
    }
}