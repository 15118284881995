import { SpriteButton } from "../../../components/button/spritebutton";
import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import { TournamentItem } from "./tournament_item";
import CommonDef from "../../../def/commondef";

export class TournamentTicketRegister extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.fillRect(-10, 0, this.scene.orientation == "pc" ? 960 : 610, this.scene.orientation == "pc" ? 530 : 1150);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 530 : 1110);
        this.add(this.backGraphic);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.mainContainer = this.scene.add.container(0, 0);
        this.add(this.mainContainer);
        this.backBorder = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.backBorder.lineStyle(1, 0x222222, 1)
        this.backBorder.strokeRoundedRect(0, this.scene.orientation == "pc" ? 99 : 105, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 420 : 1025);
        this.mainContainer.add(this.backBorder);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 122 : 128,
            text: "티켓등록",
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', "#ffffff"]
        });
        this.mainContainer.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 870 : 525,
            y: this.scene.orientation == "pc" ? 120 : 127,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_close);
        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 195 : 223,
            width: this.scene.orientation == "pc" ? 875 : 515,
            height: this.scene.orientation == "pc" ? 282 : 862,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 520 : 114,
                y: this.scene.orientation == "pc" ? 525 : 392
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 840 : 480,
                height: this.scene.orientation == "pc" ? 282 : 862,
            }
        });
        this.mainContainer.add(this.scrollView);
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        this.scene.tweens.add({
            targets: this.mainContainer,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.mainContainer.x + (this.scene.orientation == "pc" ? 200 : 290),
                to: this.mainContainer.x
            },
            y: {
                from: 60,
                to: this.mainContainer.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    setContent(tournaments) {
        this.listContainer.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 129 : 169;
        for(let i = 0 ; i < tournaments.length ; i++) {
            let item = new TournamentItem(this.scene, {
                x: 0,
                y: item_height * i,
                atlas: this.config.atlas,
                ... tournaments[i],
                onClick: this.onClickTournamentItem.bind(this)
            })
            this.listContainer.add(item);
        }
        this.listContainer.height = item_height * tournaments.length;
        this.scrollView.updateScroll();
        
        if(tournamentList.findIndex(x => x.id == this.scene.tournamentDialog.tournamentid) < 0) {
            this.scene.tournamentDialog.close();
            CommonDef.gameNet.requestTournamentListByTicket(this.config.ticketid);
        }
    }

    onClickTournamentItem(tournament_id) {
        this.scene.onClickTournamentItem(tournament_id, this.config.ticketid);
    }
}