import { SpriteButton } from "../../../../components/button/spritebutton";
import { TextClass } from "../../../../components/text/text";
import { translate } from "../../../../util/other";
import { EventItemAcceptDialog } from "./event_accept";
import { EventItem } from "./event_item";

export class EventPage extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.back = this.scene.add.sprite(this.scene.ScreenWidth / 2, this.scene.ScreenHeight / 2, "EventBack_" + this.scene.orientation + "_" + this.scene.tema).setOrigin(0.5, 0.5);
        this.add(this.back);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 1810 : 670,
            y: this.scene.orientation == "pc" ? 75 : 50,
            origin: [0, 0],
            atlas: this.config.atlas,
            normal: 'icon_close_normal.png',
            hover: 'icon_close_hover.png',
            clicked: 'icon_close_clicked.png',
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 960 : 375,
            y: this.scene.orientation == "pc" ? 390 : 350,
            text: translate('attendance_event'),
            gradient: ['#ffe1d0', '#ffffff'],
            fontSize: 50,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
        }).setOrigin(0.5, 0);
        this.add(this.title);

        this.itemContainer = this.scene.add.container(0, 0);
        this.add(this.itemContainer);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    setEventItems(eventItems) {
        this.itemContainer.removeAll(true);
        eventItems.sort((a, b) => a.eventday - b.eventday);
        for(let i = 0 ; i < eventItems.length ; i ++) {
            let eventItem = new EventItem(this.scene, {
                x: this.scene.orientation == "pc" ? (600 + (i % 4) * 239 + Math.floor(i / 4) * 110) : (145 + (i % 3) * 229 + Math.floor(i / 6) * 229),
                y: this.scene.orientation == "pc" ? (479 + Math.floor(i / 4) * 239) : (459 + Math.floor(i / 3) * 269),
                atlas: this.config.atlas,
                id: eventItems[i].id,
                day: i + 1,
                name: eventItems[i].name,
                icon: eventItems[i].icon,
                scale: (i == 5 || i == 6) ? 0.9 : 1,
                pass: eventItems[i].pass,
                accepted: eventItems[i].accepted,
                onAccept: this.onAcceptEventItem.bind(this)
            });
            this.itemContainer.add(eventItem);
        }
    }

    onAcceptEventItem(eventItem) {
        this.scene.requestAcceptEventItem(eventItem.id);
    }
    
    onEventItemArrived() {
        let eventAcceptDlg = new EventItemAcceptDialog(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: this.scene.orientation == "pc" ? 320 : 0,
            atlas: this.config.atlas
        });
        this.add(eventAcceptDlg);
    }
}