import { TextClass } from "../../../../components/text/text";

export class RoomTitleItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.blindText = TextClass.plainText1(this.scene, {
            x: 10,
            y: 0,
            text: this.config.title,
            color: "#ffffff",
            fontSize: 20
        }).setOrigin(0, 0);
        this.add(this.blindText);
        this.height = 40
    }

    onClick() {
        this.config.onClicked && this.config.onClicked(this.config.title);
    }
}