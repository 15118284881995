// import { GameNet } from '../reference/game_net.js';
import CommonDef from '../def/commondef.js';
import LoginDef from '../def/logindef.js';
import { InputField } from '../components/input/input.js';
import { IconButton } from '../components/button/iconbutton.js';
import { Toast } from '../components/dialog/toast.js';
import { LoadingScene } from './loading.js';
import { SceneManager } from './scene_manager.js';
import { GameSound } from '../sound/game_sound.js';
import { TextClass } from '../components/text/text.js';
import { ServerMaintainDialog } from './pages/login/maintain_dialog.js';
import { LoadingPage } from './pages/loadingPage.js';
import { translate } from '../util/other.js';

export class LoginScene extends Phaser.Scene {
    constructor(){
        super({
            key:"LoginScene"
        })
    }
    preload(){     
    }

    create(){
        if(this.backContainer) {
            this.backContainer.removeAll(true);
        } else {
            this.backContainer = this.add.container(0,0);
        }

        this.username = '';
        this.password = '';
        this.tweens.killAll();
        this.initSound();  
        this.initGraph();
        this.initLoading();
        this.playAnimation();
        this.responseLogin();
        CommonDef.currentScene = this;
        if(CommonDef.DoubleLogin.double == true) {
            this.showToast("failed", translate('double_login').replace('%1', CommonDef.DoubleLogin.ip))
        }
    }

    initSound() {
        this.audio = new GameSound(this);
        this.audio.stopEffect();
        this.audio.playBGM("LoginBGM", true);
    }

    initGraph(){
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.LoginAtlasName = 'LoginAtlas_' + this.tema;

        this.background = this.add.sprite(this.ScreenWidth/2, this.ScreenHeight/2, 'LoginBackground_' + this.orientation);
        this.background.setOrigin(0.5, 0.5)
        this.backContainer.add(this.background);
        this.light = this.add.sprite(LoginDef.light[this.orientation].x, LoginDef.light[this.orientation].y, this.LoginAtlasName, "light.png").setOrigin(0.5, 0.5);
        this.backContainer.add(this.light);

        this.card_left = this.add.sprite(this.ScreenWidth/2 + LoginDef.card.x[0], LoginDef.logo_position_y[this.orientation] + LoginDef.card.y[0], this.LoginAtlasName, "card_left.png").setOrigin(0, 0);
        this.backContainer.add(this.card_left);
        this.card_right = this.add.sprite(this.ScreenWidth/2 + LoginDef.card.x[1], LoginDef.logo_position_y[this.orientation] + LoginDef.card.y[1], this.LoginAtlasName, "card_right.png").setOrigin(1, 0);
        this.backContainer.add(this.card_right);
        this.card_light = [];
        for(let i = 0 ; i < 4 ; i ++) {
            let light = this.add.sprite(this.ScreenWidth/2 + LoginDef.card_light.x[i], LoginDef.logo_position_y[this.orientation] + LoginDef.card_light.y[i], this.LoginAtlasName, "card_light.png").setOrigin(0.5, 0.5);
            this.backContainer.add(light);
            this.card_light.push(light);
            light.setAlpha(0);
        }
        
        !window.isMobile && this.light.setTint(Phaser.Display.Color.GetColor(0, 0, 0));
        window.isMobile && this.light.setAlpha(0);

        this.loginFormContainer = this.add.container(0, 0);
        this.backContainer.add(this.loginFormContainer);
        this.loginFormBack = this.add.sprite(this.ScreenWidth/2, LoginDef.loginForm_position_y[this.orientation], this.LoginAtlasName, 'login_form_back.png');
        this.loginFormBack.setOrigin(0.5, 0.5);
        this.loginFormContainer.add(this.loginFormBack);

        this.logo = this.add.sprite(this.ScreenWidth/2, LoginDef.logo_position_y[this.orientation], this.LoginAtlasName, 'logo.png');
        this.logo.setOrigin(0.5, 0.5);
        this.logo.setScale(LoginDef.logo_scale[this.orientation]);
        this.backContainer.add(this.logo);
        // this.logo.setAlpha(0.2);
        this.logo.setInteractive( { useHandCursor: true  } )

        this.username_field = new InputField(this, {
            x: LoginDef.input_field[this.orientation].x,
            y: LoginDef.input_field[this.orientation].y[0],
            displayTextY: 35,
            atlas: this.LoginAtlasName,
            back: "input_username.png",
            input: {
                x: 80,
                y: 0,
                absolute: {
                    x: LoginDef.input_field[this.orientation].x,
                    y: LoginDef.input_field[this.orientation].y[0] - 1,
                },
                width: LoginDef.input_field[this.orientation].width,
                height: LoginDef.input_field[this.orientation].height,
                placeholder: translate('login_id'),
                value: parseInt(localStorage.getItem("rememberme") || '0') == 1 ? (localStorage.getItem("username") || ""): "",
                color: "#d7bba3",
                fontSize: 26
            }
        });
        this.loginFormContainer.add(this.username_field);

        this.password_field = new InputField(this, {
            x: LoginDef.input_field[this.orientation].x,
            y: LoginDef.input_field[this.orientation].y[1],
            displayTextY: 35,
            atlas: this.LoginAtlasName,
            back: "input_password.png",
            input: {
                x: 80,
                y: 0,
                absolute: {
                    x: LoginDef.input_field[this.orientation].x,
                    y: LoginDef.input_field[this.orientation].y[1],
                },
                type: "password",
                width: LoginDef.input_field[this.orientation].width,
                height: LoginDef.input_field[this.orientation].height,
                placeholder: translate('password'),
                value: parseInt(localStorage.getItem("rememberme") || '0') == 1 ? (localStorage.getItem("password") || ""): "",
                color: "#d7bba3",
                fontSize: 26
            }
        });
        this.loginFormContainer.add(this.password_field);

        this.checkbox = this.add.graphics({
            x: LoginDef.input_field[this.orientation].x,
            y: LoginDef.input_field[this.orientation].y[2],
            fillStyle: {
                color: 0xcccccc,
                alpha: 1
            }
        });
        this.checkbox.lineStyle(2, 0xcccccc);
        this.checkbox.strokeRoundedRect(0, -12, 24, 24, 3);
        this.loginFormContainer.add(this.checkbox);

        this.remember_check = this.add.sprite(LoginDef.input_field[this.orientation].x, LoginDef.input_field[this.orientation].y[2]-3, this.LoginAtlasName, "check.png").setOrigin(0, 0.5);
        this.loginFormContainer.add(this.remember_check);
        this.remember_check.setVisible(parseInt(localStorage.getItem("rememberme") || '0') == 1);

        let checkbox_label = TextClass.plainText1(this, {
            x: LoginDef.input_field[this.orientation].x + 40,
            y: LoginDef.input_field[this.orientation].y[2],
            text: "Remember me",
            fontSize: 24,
            color: "#cccccc"
        }).setOrigin(0, 0.5);
        this.loginFormContainer.add(checkbox_label);

        this.checkbox_action_rect = new Phaser.Geom.Rectangle(0, -12, 200, 24);
        this.checkbox.setInteractive(this.checkbox_action_rect, Phaser.Geom.Rectangle.Contains);
        this.checkbox.on("pointerdown", () => {
            let rememberme = localStorage.getItem("rememberme") || '0';
            rememberme = (parseInt(rememberme) + 1) % 2;
            localStorage.setItem("rememberme", rememberme);
            this.remember_check.setVisible(rememberme == 1);
        })


        this.btn_register = new IconButton(this, {
            x: this.ScreenWidth/2 - LoginDef.button_position.delta_x[0],
            y: LoginDef.loginForm_position_y[this.orientation] + LoginDef.button_position.delta_y,
            atlas: this.LoginAtlasName,
            normal: {
                back: 'btn_register_back_normal.png'
            },
            hover: {
                back: 'btn_register_back_hover.png'
            },
            clicked: {
                back: 'btn_register_back_clicked.png'
            },
            text: {
                x: 60,
                y: 18,
                value: translate('register'),
                fontSize: 30,
                gradient: ['#d7cfcf', '#ffffff']
            },
            onClicked: this.register.bind(this)
        })
        this.loginFormContainer.add(this.btn_register);

        this.btn_login = new IconButton(this, {
            x: this.ScreenWidth/2 + LoginDef.button_position.delta_x[1],
            y: LoginDef.loginForm_position_y[this.orientation] + LoginDef.button_position.delta_y,
            atlas: this.LoginAtlasName,
            normal: {
                back: 'btn_login_back_normal.png'
            },
            hover: {
                back: 'btn_login_back_hover.png'
            },
            clicked: {
                back: 'btn_login_back_clicked.png'
            },
            text: {
                x: 75,
                y: 18,
                value: translate('login'),
                fontSize: 30,
                gradient: ['#ffe2d1', '#ffffff']
            },
            onClicked: this.login.bind(this)
        })
        this.loginFormContainer.add(this.btn_login);
        
        var keyObj = this.input.keyboard.addKey('Enter');
        keyObj.on('down', (event) => { 
            this.username_field.htmlBodyClickEvent();
            this.password_field.htmlBodyClickEvent();
            this.login();
        });

        this.serverMaintainDlg = new ServerMaintainDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 300 : 400,
            atlas: this.LoginAtlasName
        });
        this.backContainer.add(this.serverMaintainDlg);
        this.serverMaintainDlg.setVisible(CommonDef.ServerMaintain);
    }

    initLoading() {
        this.loadingPage = new LoadingPage(this, {
            atlas: 'LoadingAtlas'
        });
        this.loadingPage.setDepth(1000);
    }

    playLoading() {
        this.loadingPage && this.loadingPage.playLoading();
    }

    stopLoading() {
        this.loadingPage && this.loadingPage.stopLoading();
    }

    playAnimation() {
        this.tweens.preUpdate();
        this.loginFormContainer.y = -300;
        this.tweens.add({
            targets: this.logo,
            scaleX: {
                from: 2.5 * LoginDef.logo_scale[this.orientation],
                to: LoginDef.logo_scale[this.orientation]
            },
            scaleY: {
                from: 3 * LoginDef.logo_scale[this.orientation],
                to: LoginDef.logo_scale[this.orientation]
            },
            angle: {
                from: 90,
                to: 0
            },
            duration: 1000,
            ease: "Power2",
            onComplete: () => {
                this.tweens.addCounter({
                    from: 0,
                    to: 255,
                    duration: 500,
                    onUpdate: (tween) => {
                        const value = Math.floor(tween.getValue());
                        !window.isMobile && this.light.setTint(Phaser.Display.Color.GetColor(value, value, value));
                        window.isMobile && this.light.setAlpha(value / 255);
                        this.loginFormContainer.y = 300 * (value - 255) / 255;
                        for(let i = 0 ; i < 4 ; i ++) {
                            this.card_light[i].setAlpha(value / 255);
                        }
                    }
                });
            }
        });
    }
    
    login(){
        if(CommonDef.ServerMaintain) {
            this.serverMaintainDlg.setVisible(true);
            return;
        }
        if(!this.username_field.value) {
            this.showToast("failed", translate('require_login_id'));
            return;
        }
        if(!this.password_field.value) {
            this.showToast("failed", translate('require_password'));
            return;
        }
        this.btn_login.setDisable(true);
        CommonDef.gameNet.requestLogin({
            username: this.username_field.value,
            password: this.password_field.value
        });
    }

    responseLogin() {
        this.btn_login.setDisable(false);
    }

    loginSuccessed() {
        if(parseInt(localStorage.getItem("rememberme") || '0') == 1) {
            localStorage.setItem("username", this.username_field.value);
            localStorage.setItem("password", this.password_field.value);
        } else {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
        }
        CommonDef.DoubleLogin = {};
        SceneManager.changeScene(this.scene, "LoginScene", "WaitingRoomScene");
    }

    register() {
        if(CommonDef.ServerMaintain) {
            this.serverMaintainDlg.setVisible(true);
            return;
        }
        SceneManager.changeScene(this.scene, "LoginScene", "RegisterScene")
    }

    destroyScene() {
        this.backContainer.destroy();
    }

    showToast(type, text, destroy = true) {
        if(this.toast) this.toast.close();
        this.toast = new Toast(this, {
            type: type,
            text: text
        });
        this.backContainer.add(this.toast);
        if(destroy) {
            setTimeout(() => {
                this.toast && this.toast.close();
            }, 2000);
        }
    }
}
