import { ScrollView } from "../../../../components/container/scrollview";
import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import { translate } from "../../../../util/other";

export class TabPrize extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.tableHeader = this.scene.add.container(0, 0);
        this.add(this.tableHeader);
        this.posX = {
            pc: [66, 333],
            mobile: [73, 370]
        }
        let rankingText = TextClass.plainText1(this.scene, {
            x: this.posX[this.scene.orientation][0],
            y: 0,
            text: translate('ranking'),
            fontSize: this.scene.orientation == "pc" ? 18 : 20,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.tableHeader.add(rankingText);

        let prizeText = TextClass.plainText1(this.scene, {
            x: this.posX[this.scene.orientation][1],
            y: 0,
            text: translate('prize'),
            fontSize: this.scene.orientation == "pc" ? 18 : 20,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.tableHeader.add(prizeText);

        this.tableBody = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 40 : 50,
            width: this.scene.orientation == "pc" ? 525 : 585,
            height: this.scene.orientation == "pc" ? 514 : 570,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.tableBody,
            mask: {
                x: this.scene.orientation == "pc" ? 690 : 80,
                y: this.scene.orientation == "pc" ? 350 : 335
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 500 : 560,
                height: this.scene.orientation == "pc" ? 514 : 570,
            }
        });
        this.add(this.scrollView);
    }

    setData(prizes) {
        this.tableBody.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 32 : 36;
        for(let i = 0 ; i < prizes.length ; i++) {
            let item = this.scene.add.container(0, i * item_height);
            let back = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: i % 2 ? 0x000000 : 0x111111,
                    alpha: 1
                }
            });
            back.fillRect(0, 0, this.scene.orientation == "pc" ? 490 : 530, item_height);
            item.add(back);
            this.tableBody.add(item);

            let rank = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][0],
                y: item_height / 2,
                text: prizes[i].startrank == prizes[i].endrank ? prizes[i].startrank : prizes[i].startrank + " ~ " + prizes[i].endrank,
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 16 : 18,
                color: "#ffffff"
            }).setOrigin(0.5, 0.5);
            item.add(rank);

            let prize = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][1],
                y: item_height / 2,
                text: NumberUtil.convertNumberWithComma(prizes[i].prize.amount),
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 16 : 18,
                color: "#ffffff"
            }).setOrigin(0.5, 0.5);
            item.add(prize);
        }
        this.tableBody.height = prizes.length * item_height;
        this.scrollView.updateScroll();
    }
}