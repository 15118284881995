export default {
    btn_close_position: {
        pc: {
            x: 1850,
            y: 99
        },
        mobile: {
            x: 650,
            y: 80
        }
    },
    notice_content: {
        x: 340,
        y: 140,
        title: {
            y: 50
        },
        content: {
            x: -250,
            y: 110,
            width: 510,
            height: 215
        }
    },
    tournament_content: {
        x: 960,
        y: 140,
        title: {
            y: 50
        },
        content: {
            x: -250,
            y: 110,
            width: 510,
            height: 215
        }
    },
    event_content: {
        x: 1580,
        y: 140,
        title: {
            y: 50
        },
        content: {
            x: -250,
            y: 110,
            width: 510,
            height: 215
        }
    },
    logo_position: {
        y: 775
    },
    profile_content: {
        pc: {
            x: 340,
            y: 580,
            avatar: {
                x: -186,
                y: 44,
                name: {
                    x: -185,
                    y: 190
                },
                mask: {
                    x: 153,
                    y: 685
                }
            },
            text_position: {
                x: [-70, 245],
                y: [87, 137, 187]
            }
        },
        mobile: {
            y: 630,
            avatar: {
                x: -186,
                y: 44,
                name: {
                    x: -185,
                    y: 190
                },
                mask: {
                    x: 189,
                    y: 735
                }
            },
            text_position: {
                x: [-65, 250],
                y: [87, 137, 187]
            }
        },
        btn_my_page: {
            x: -250,
            y: 250
        },
        btn_shop: {
            x: 10,
            y: 250
        }
    },
    service_content: {
        pc: {
            x: 1580,
            y: 580,
        },
        mobile: {
            y: 980
        },
        login_user_count: {
            x: -200,
            y: [95, 150, 180]
        },
        playing_user_count: {
            x: -65,
            y: [95, 150, 180]
        },
        waiting_user_count: {
            x: 67,
            y: [95, 150, 180]
        },
        room_count: {
            x: 200,
            y: [95, 150, 180]
        },
        devider: {
            x: [-133, 1, 137],
            y: 135
        },
        btn_joinLobby:{
            x: -130,
            y: 250
        }
    },
    tabContainer: {
        card_container: {
            y: 120
        },
        x: -295,
        y: 66,
        tabs: {
            x: [0, 190, 380],
        },
        tab_line: {
            x: 0,
            y: 59
        },
        content: {
            x: 0,
            y: 105,
            width: 590,
            height: 280
        }
    },
    notice_detail: {
        pc: {
            card: {
                x: 560,
                y: 192
            },
            title: {
                y: 60
            },
            close: {
                x: -93,
                y: 64
            }
        },
        mobile: {
        }
    },
    
    Sounds: [
        {
            key: "NormalBGM",
            file: "sounds/normal_BGM.mp3",
            type: "BGM"
        }
    ]
}