import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { ContentCard } from "../../../../components/card/contentcard";
import { MobileDialog } from "../../../../components/dialog/mobile_dialog";
import { InputField } from "../../../../components/input/input";
import { SliderBar } from "../../../../components/slider/sliderbar";
import { TextClass } from "../../../../components/text/text";
import { translate } from "../../../../util/other";

export class BuyinDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        let paddingTop = 0;
        if(this.config.security) {
            paddingTop = 140;
            this.passwordContainer = this.scene.add.container(this.scene.orientation == "pc" ? -335 : 40, this.scene.orientation == "pc" ? 146 : 240);
            (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.passwordContainer);

            let password_label = TextClass.plainText1(this.scene, {
                x: 0,
                y: 0,
                text: translate('password'),
                fontSize: 24,
                color: "#ffffff",
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            }).setOrigin(0, 0);
            this.passwordContainer.add(password_label);

            this.password_field = new InputField(this.scene, {
                x: 0,
                y: 40,
                atlas: this.config.atlas,
                back: "input_back_" + this.scene.orientation + ".png",
                scale: [this.scene.orientation == "pc" ? 1.05 : 1, 1],
                input: {
                    x: 20,
                    y: 0,
                    type: "password",
                    absolute: {
                        x: this.scene.orientation == "pc" ? 630 : 45,
                        y: this.scene.orientation == "pc" ? 505 : 280
                    },
                    width: this.scene.orientation == "pc" ? 640 : 670,
                    height: this.scene.orientation == "pc" ? 60 : 70,
                    placeholder: "",
                    color: "#ffffff",
                    fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                    fontSize: 30
                },
                // displayTextY: this.scene.orientation == "pc" ? 30 : 35
            });
            this.passwordContainer.add(this.password_field);
        }

        this.mainContainer = this.scene.add.container(this.scene.orientation == "pc" ? -335 : 40, (this.scene.orientation == "pc" ? 146 : 240) + paddingTop);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.mainContainer);

        this.btn_min = new SpriteButton(this.scene, {
            x: 0,
            y: 25,
            origin: [0, 0.5],
            atlas: this.config.atlas,
            normal: "min_normal.png",
            hover: "min_hover.png",
            clicked: "min_clicked.png",
            onClicked: this.onClickedBtnMin.bind(this)
        });
        this.mainContainer.add(this.btn_min);

        this.btn_minus = new SpriteButton(this.scene, {
            x: 103,
            y: 25,
            origin: [0, 0.5],
            atlas: this.config.atlas,
            normal: "minus_normal.png",
            hover: "minus_hover.png",
            clicked: "minus_clicked.png",
            onClicked: this.onClickedBtnMinus.bind(this)
        });
        this.mainContainer.add(this.btn_minus);

        this.moneyField = new InputField(this.scene, {
            x: 175,
            y: 0,
            align: "center",
            atlas: this.config.atlas,
            back: "buyin_input_back.png",
            input: {
                x: 0,
                y: 0,
                type: "number",
                absolute: {
                    x: this.scene.orientation == "pc" ? 800 : 215,
                    y: (this.scene.orientation == "pc" ? 465 : 240) + paddingTop
                },
                width: 310,
                height: 50,
                placeholder: "",
                color: "#ffffff",
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 30
            },
            onChange: this.onChangeMoney.bind(this)
        });
        this.mainContainer.add(this.moneyField);
        this.moneyField.setValue(this.config.buyin.min);

        this.btn_plus = new SpriteButton(this.scene, {
            x: 523,
            y: 25,
            origin: [0, 0.5],
            atlas: this.config.atlas,
            normal: "plus_normal.png",
            hover: "plus_hover.png",
            clicked: "plus_clicked.png",
            onClicked: this.onClickedBtnPlus.bind(this)
        });
        this.mainContainer.add(this.btn_plus);

        this.btn_max = new SpriteButton(this.scene, {
            x: 670,
            y: 25,
            origin: [1, 0.5],
            atlas: this.config.atlas,
            normal: "max_normal.png",
            hover: "max_hover.png",
            clicked: "max_clicked.png",
            onClicked: this.onClickedBtnMax.bind(this)
        });
        this.mainContainer.add(this.btn_max);

        this.slider = new SliderBar(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 85 : 115,
            absoluteX: this.scene.orientation == "pc" ? 625 : 40,
            atlas: this.config.atlas,
            width: 670,
            height: 20,
            ruler: true,
            onChange: this.onChangeSlider.bind(this)
        });
        this.mainContainer.add(this.slider)

        this.btn_cancel = new IconButton(this.scene, {
            x: 140,
            y: this.scene.orientation == "pc" ? 163 : 213,
            atlas: this.config.atlas,
            normal: {
                back: "btn_cancel_back_normal.png"
            },
            hover: {
                back: "btn_cancel_back_hover.png"
            },
            clicked: {
                back: "btn_cancel_back_clicked.png"
            },
            text: {
                x: 70,
                y: 15,
                value: translate('cancel'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_cancel);

        this.btn_confirm = new IconButton(this.scene, {
            x: 340,
            y: this.scene.orientation == "pc" ? 163 : 213,
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 45,
                y: 15,
                value: translate('join'),
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.joinRoom.bind(this)
        });
        this.mainContainer.add(this.btn_confirm);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            scale: [0.67, this.config.security ? 0.8 : 0.6],
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 300,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.destroy();
    }

    onClickedBtnMin() {
        this.slider.setValue(0);
        this.moneyField.setValue(this.config.buyin.min);
    }

    onClickedBtnMax() {
        this.slider.setValue(1);
        this.moneyField.setValue(this.config.buyin.max);
    }

    onClickedBtnMinus() {
        let sliderValue = this.slider.getValue();
        sliderValue -= 0.05;
        if(sliderValue < 0) sliderValue = 0;
        this.slider.setValue(sliderValue);
        this.onChangeSlider(sliderValue);
    }

    onClickedBtnPlus() {
        let sliderValue = this.slider.getValue();
        sliderValue += 0.05;
        if(sliderValue > 1) sliderValue = 1;
        this.slider.setValue(sliderValue);
        this.onChangeSlider(sliderValue);
    }

    onChangeMoney(value) {
        /* if(value < this.config.buyin.min) {
            value = this.config.buyin.min;
            this.moneyField.setValue(this.config.buyin.min);
            this.slider.setValue(0);
        } else */if(value > this.config.buyin.max) {
            value = Math.floor(value / 10);
            this.moneyField.setValue(value);
        } else {
            this.slider.setValue((value - this.config.buyin.min) / (this.config.buyin.max - this.config.buyin.min));
        }
    }

    onChangeSlider(value) {
        this.moneyField.setValue(this.config.buyin.min + Math.floor((this.config.buyin.max - this.config.buyin.min) * value));
    }

    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    joinRoom() {
        this.btn_confirm.setDisable(true);
        this.scene.joinRoom(this.config.roomid, this.config.security ? this.password_field.value : "", this.config.createFlag, this.moneyField.value);       
    }
}