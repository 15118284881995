import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import * as DateTimeUtil from "../../../../util/datetime";
import { translate } from "../../../../util/other";

export class TournamentItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "tournament_item_back.png");
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.buyinImg = this.scene.add.sprite(this.scene.orientation == "pc" ? 195 : 155, 85, this.config.atlas, "tournament_table_icon.png");
        this.buyinImg.setOrigin(0.5, 0.5);
        this.add(this.buyinImg);

        this.buyinText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 155,
            y: 70,
            text: `${translate('buyin')} : ${this.config.buyin > 0 ? NumberUtil.convertNumbersWithUnit(this.config.buyin) : translate('free')}`,
            color: "#ffffff",
            fontSize: 24,
            fontFamily: "NanumSquareL"
        }).setOrigin(0.5, 0.5);
        this.add(this.buyinText);

        if(this.config.buyin == 0) {
            this.prizeText = TextClass.plainText1(this.scene, {
                x: 0,
                y: 105,
                text: NumberUtil.convertNumberWithComma(this.config.prize),
                color: "#ffc97c",
                fontSize: 24
            }).setOrigin(0, 0.5);
            this.add(this.prizeText);
    
            this.prizeIcon = this.scene.add.sprite(0, 105, this.config.atlas, 'tournament_prize_icon.png').setOrigin(0, 0.5);
            this.add(this.prizeIcon);
            this.prizeIcon.setScale(0.8);

            this.prizeIcon.x = (this.scene.orientation == "pc" ? 195 : 155) - (this.prizeText.width + this.prizeIcon.width) / 2;
            this.prizeText.x = this.prizeIcon.x + this.prizeIcon.width;
        } else {
            this.feeText = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 195 : 155,
                y: 105,
                text: `${translate('tournament_fee')} : ${NumberUtil.convertNumbersWithUnit(this.config.fee)}`,
                color: "#ffffff",
                fontSize: 24,
                fontFamily: "NanumSquareL"
            }).setOrigin(0.5, 0.5);
            this.add(this.feeText);
        }

        let limitLength = this.scene.orientation == "pc" ? 15 : 12;
        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 415,
            y: this.scene.orientation == "pc" ? 180 : 50,
            text: this.config.title.length > limitLength ? this.config.title.substring(0, limitLength - 1) + "..." : this.config.title,
            // fontFamily: ""
            fontSize: 24,
            color: "#eeeeee"
        }).setOrigin(0.5, 0.5);
        this.add(this.title);

        this.userIcon = this.scene.add.sprite(this.scene.orientation == "pc" ? 98 : 317, this.scene.orientation == "pc" ? 225 : 93, this.config.atlas, "icon_user.png").setOrigin(0, 0.5);
        this.add(this.userIcon);

        this.userCountText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 155 : 375,
            y: this.scene.orientation == "pc" ? 225 : 93,
            text: this.config.player_count + (this.config.state > 1 ? "/" + this.config.total_entry : ""),
            color: "#ffffff",
            fontSize: 16
        }).setOrigin(0.5, 0.5);
        this.add(this.userCountText);

        this.clockIcon = this.scene.add.sprite(this.scene.orientation == "pc" ? 211 : 429, this.scene.orientation == "pc" ? 225 : 93, this.config.atlas, "icon_clock.png").setOrigin(0, 0.5);
        this.add(this.clockIcon);

        this.blindsUpText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 267 : 485,
            y: this.scene.orientation == "pc" ? 225 : 93,
            text: this.config.blinds_up_time + "min",
            color: "#ffffff",
            fontSize: 16
        }).setOrigin(0.5, 0.5);
        this.add(this.blindsUpText);

        let text = "";
        if(this.config.state < 2) text = `${translate('tournament_start_time')}: ` +  DateTimeUtil.convertDefaultDateTime(this.config.start_time)
        this.leftTimeText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 415,
            y: this.scene.orientation == "pc" ? 253 : 123,
            text: text,
            color: "#b84700",
            fontFamily: "NanumSquareR",
            fontSize: 16
        }).setOrigin(0.5, 0);
        this.add(this.leftTimeText);

        this.width = this.back.width;
        this.height = this.back.height;
    }

    onClick() {
        this.config.onClick && this.config.onClick(this.config.id);
    }
}