export default {
    logo_position_y: {
        pc: 360,
        mobile: 510
    },
    light: {
        pc: {
            x: 915,
            y: 345
        },
        mobile: {
            x: 330,
            y: 500
        }
    },
    card: {
        x: [-380, 385],
        y: [-215, -215]
    },
    card_light: {
        x: [-257, -270, 230, 280],
        y: [-125, 40, -117, 55]
    },
    logo_scale: {
        pc: 0.46,
        mobile: 0.46
    },
    loginForm_position_y: {
        pc: 730,
        mobile: 870
    },
    input_field: {
        pc: {
            x: 730,
            y: [605, 690, 787],
            width: 385,
            height: 70
        },
        mobile: {
            x: 140,
            y: [750, 835, 932],
            width: 385,
            height: 70
        }
    },
    button_position: {
        delta_x: [240, 0],
        delta_y: 90
    },

    Sounds: [
        {
            key: "LoginBGM",
            file: "sounds/Login_BGM.mp3",
            type: "BGM"
        }
    ]
}