import CommonDef from '../def/commondef.js';
import WaitingRoomDef from '../def/waitingroomdef.js';
import { ContentCard } from '../components/card/contentcard.js';
import { TabContainer } from '../components/container/tabcontainer.js';
import { ScrollView } from "../components/container/scrollview.js";
import { TextClass } from '../components/text/text.js';
import { TabNoticeContent } from './pages/waiting_room/tab_notice_content.js';
import { TabTournamentContent } from './pages/waiting_room/tab_tournament_content.js';
import { TabEventContent } from './pages/waiting_room/tab_event_content.js';
import { Avatar } from '../components/avatar.js/avatar.js';
import * as NumberUtil from '../util/number';
import { IconButton } from '../components/button/iconbutton.js';
import { NoticeDetailDialog } from './pages/waiting_room/notice_detail_dialog.js';
import { MyPageDialog } from './pages/my_page/my_page_dialog.js';
import { ShopDialog } from './pages/shop/shop_dialog.js';
import { SceneManager } from './scene_manager.js';
import { Toast } from '../components/dialog/toast.js';
import { SpriteButton } from '../components/button/spritebutton.js';
import { GameSound } from '../sound/game_sound.js';
import { VIPCardUseConfirm } from './pages/shop/vip_card_use_confirm.js';
import { TournamentDialog } from './pages/lobby/tournament/tournament_dialog.js';
import { TournamentHoldemRegisterDialog } from './pages/tournament_holdem/register_dialog.js';
import { LoadingPage } from './pages/loadingPage.js';
import { translate } from '../util/other.js';
import { NoteDialog } from './pages/note/note_dialog.js';


export class WaitingRoomScene extends Phaser.Scene {
    constructor(){
        super({
            key:"WaitingRoomScene",
        })
    }

    preload(){
    }

    create(){
        if(this.backContainer) {
            this.backContainer.removeAll(true);
        } else {
            this.backContainer = this.add.container(0, 0);
        }
        this.initSound();
        this.initGraph();
        this.initLoading();
        CommonDef.currentScene = this;
        CommonDef.gameNet.requestServiceInfo();
        CommonDef.gameNet.requestUserInfo();
        CommonDef.gameNet.requestNoticeList();
    }

    initSound() {
        this.audio = new GameSound(this);
        this.audio.stopEffect();
        this.audio.playBGM("NormalBGM", true);
    }

    initLoading() {
        this.loadingPage = new LoadingPage(this, {
            atlas: 'LoadingAtlas'
        });
        this.loadingPage.setDepth(1000);
    }

    playLoading() {
        this.loadingPage && this.loadingPage.playLoading();
    }

    stopLoading() {
        this.loadingPage && this.loadingPage.stopLoading();
    }

    initGraph() {
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.WaitingRoomAtlasName = 'WaitingRoomAtlas_' + this.orientation + "_" + this.tema;
        this.MyPageAtlasName = 'MyPageAtlas_' + this.tema;
        this.ShopAtlasName = 'ShopAtlas_' + this.tema;
        this.LobbyAtlasName = 'LobbyAtlas_' + this.orientation + "_" + this.tema;
        this.LobbyDialogAtlasName = "LobbyDialogAtlas_" + this.tema;
        this.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        // init alert
        this.alertContainer = this.add.container(0, 0);
        this.alertContainer.setDepth(10);
        let alert_back = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.5
            }
        });
        alert_back.fillRect(0, 0, this.ScreenWidth, 40);
        this.alert_message = TextClass.plainText1(this, {
            x: this.ScreenWidth,
            y: 20,
            text: "",
            fontSize: 24,
            color: "#eeeeee"
        }).setOrigin(0, 0.5);
        this.alertContainer.add(alert_back);
        this.alertContainer.add(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();

        this.tournamentDialog = new TournamentDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 98 : 0,
            atlas: this.LobbyAtlasName,
            from: "waitingroom"
        });
        this.backContainer.add(this.tournamentDialog);
        this.tournamentDialog.setDepth(1);
        this.backContainer.sort("depth");
        this.tournamentDialog.setVisible(false);
    }

    initGraphForPC() {
        this.background = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, 'WaitingRoomBackground_pc_' + this.tema);
        this.background.setOrigin(0.5, 0.5);
        this.backContainer.add(this.background);

        this.btn_close = new SpriteButton(this, {
            x: WaitingRoomDef.btn_close_position[this.orientation].x,
            y: WaitingRoomDef.btn_close_position[this.orientation].y,
            atlas: this.WaitingRoomAtlasName,
            normal: 'icon_close_normal.png',
            hover: 'icon_close_hover.png',
            clicked: 'icon_close_clicked.png',
            onClicked: this.onClickedClose.bind(this)
        });
        this.backContainer.add(this.btn_close);

        this.noticeContentCard = new ContentCard(this, {
            x: WaitingRoomDef.notice_content.x,
            y: WaitingRoomDef.notice_content.y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png',
            light: 'content_back_light.png',
            // title: {
            //     texture: 'label_notice.png',
            //     y: WaitingRoomDef.notice_content.title.y
            // }
            title: {
                y: WaitingRoomDef.notice_content.title.y,
                text: translate('notification'),
                fontFamily: "GmarketSansBold",
                fontSize: 36,
                gradient: ['#ffe1cf', '#ffffff'],
                origin: [0.5, 0.5]
            }
        });
        this.backContainer.add(this.noticeContentCard);
        
        this.noticeContent = new ScrollView(this, {
            x: WaitingRoomDef.notice_content.content.x,
            y: WaitingRoomDef.notice_content.content.y,
            width: WaitingRoomDef.notice_content.content.width,
            height: WaitingRoomDef.notice_content.content.height,
            atlas: this.WaitingRoomAtlasName,
            thumb: 'scroll_thumb.png',
            content: new TabNoticeContent(this, {
                x: 0,
                y: 0,
                data: [],
                width: 485
            }),
            mask: {
                x: 85,
                y: 250
            },
            action_rect: {
                width: WaitingRoomDef.notice_content.content.width - 20,
                height: WaitingRoomDef.notice_content.content.height
            }
        });
        this.noticeContentCard.add(this.noticeContent);

        this.tournamentContentCard = new ContentCard(this, {
            x: WaitingRoomDef.tournament_content.x,
            y: WaitingRoomDef.tournament_content.y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png',
            light: 'content_back_light.png',
            // title: {
            //     texture: 'label_tournament_notice.png',
            //     y: WaitingRoomDef.tournament_content.title.y
            // }
            title: {
                y: WaitingRoomDef.tournament_content.title.y,
                text: translate('tournament_notification'),
                fontFamily: "GmarketSansBold",
                fontSize: 36,
                gradient: ['#ffe1cf', '#ffffff'],
                origin: [0.5, 0.5]
            }
        });
        this.backContainer.add(this.tournamentContentCard);

        this.tournamentContent = new ScrollView(this, {
            x: WaitingRoomDef.tournament_content.content.x,
            y: WaitingRoomDef.tournament_content.content.y,
            width: WaitingRoomDef.tournament_content.content.width,
            height: WaitingRoomDef.tournament_content.content.height,
            atlas: this.WaitingRoomAtlasName,
            thumb: 'scroll_thumb.png',
            content: new TabTournamentContent(this, {
                x: 0,
                y: 0,
                data: []
            }),
            mask: {
                x: 708,
                y: 250
            },
            action_rect: {
                width: WaitingRoomDef.tournament_content.content.width - 20,
                height: WaitingRoomDef.tournament_content.content.height
            }
        });
        this.tournamentContentCard.add(this.tournamentContent);

        this.eventContentCard = new ContentCard(this, {
            x: WaitingRoomDef.event_content.x,
            y: WaitingRoomDef.event_content.y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png',
            light: 'content_back_light.png',
            // title: {
            //     texture: 'label_event.png',
            //     y: WaitingRoomDef.event_content.title.y
            // }
            title: {
                y: WaitingRoomDef.event_content.title.y,
                text: translate('event_notification'),
                fontFamily: "GmarketSansBold",
                fontSize: 36,
                gradient: ['#ffe1cf', '#ffffff'],
                origin: [0.5, 0.5]
            }
        });
        this.backContainer.add(this.eventContentCard);

        this.eventContent = new ScrollView(this, {
            x: WaitingRoomDef.event_content.content.x,
            y: WaitingRoomDef.event_content.content.y,
            width: WaitingRoomDef.event_content.content.width,
            height: WaitingRoomDef.event_content.content.height,
            atlas: this.WaitingRoomAtlasName,
            thumb: 'scroll_thumb.png',
            content: new TabEventContent(this, {
                x: 0,
                y: 0,
                data: []
            }),
            mask: {
                x: 1330,
                y: 250
            },
            action_rect: {
                width: WaitingRoomDef.event_content.content.width - 20,
                height: WaitingRoomDef.event_content.content.height
            }
        });
        this.eventContentCard.add(this.eventContent);

        this.logo = this.add.sprite(this.ScreenWidth / 2, WaitingRoomDef.logo_position.y, this.WaitingRoomAtlasName, 'logo.png');
        this.logo.setOrigin(0.5, 0.5);
        this.backContainer.add(this.logo);
        this.logo.setScale(0.46)

        this.profileContent = new ContentCard(this, {
            x: WaitingRoomDef.profile_content[this.orientation].x,
            y: WaitingRoomDef.profile_content[this.orientation].y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png'
        });

        this.profileAvatar = new Avatar(this, {
            x: WaitingRoomDef.profile_content[this.orientation].avatar.x,
            y: WaitingRoomDef.profile_content[this.orientation].avatar.y,
            atlas: "AvatarAtlas",
            mask: {
                x: WaitingRoomDef.profile_content[this.orientation].avatar.mask.x,
                y: WaitingRoomDef.profile_content[this.orientation].avatar.mask.y
            }
        });
        this.profileContent.add(this.profileAvatar);

        this.my_name = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].avatar.name.x,
            y: WaitingRoomDef.profile_content[this.orientation].avatar.name.y,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20,
            color: '#d7bba3'
        }).setOrigin(0.5, 0.5);
        this.profileContent.add(this.my_name);

        let my_money_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[0],
            text: translate('my_money'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_money_label)

        this.my_money_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[0],
            text: "",//NumberUtil.convertNumberWithComma(9200000),
            fontFamily: "GmarketSansLight",
            fontSize: 28,
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_money_value)

        let my_point_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[1],
            text: translate('my_point'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_point_label)

        this.my_point_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[1],
            text: "",//NumberUtil.convertNumberWithComma(700000),
            fontSize: 28,
            fontFamily: "GmarketSansLight",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_point_value)

        let my_today_betting_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[2],
            text: translate('today_betting'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_today_betting_label)

        this.my_today_betting_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[2],
            text: "",//NumberUtil.convertNumberWithComma(5000000),
            fontSize: 28,
            fontFamily: "GmarketSansLight",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_today_betting_value)

        this.btn_my_page = new IconButton(this, {
            x: WaitingRoomDef.profile_content.btn_my_page.x + (CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0 ? 0 : 130),
            y: WaitingRoomDef.profile_content.btn_my_page.y,
            atlas: this.WaitingRoomAtlasName,
            normal: {
                back: 'btn_my_page_back_normal.png'
            },
            hover: {
                back: 'btn_my_page_back_hover.png'
            },
            clicked: {
                back: 'btn_my_page_back_clicked.png'
            },
            text: {
                x: 55,
                y: 19,
                value: translate('my_page'),
                fontSize: 30,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.goToMyPage.bind(this)
        })
        this.profileContent.add(this.btn_my_page);
        if(CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0) {
            this.btn_shop = new IconButton(this, {
                x: WaitingRoomDef.profile_content.btn_shop.x,
                y: WaitingRoomDef.profile_content.btn_shop.y,
                atlas: this.WaitingRoomAtlasName,
                normal: {
                    back: 'btn_my_page_back_normal.png'
                },
                hover: {
                    back: 'btn_my_page_back_hover.png'
                },
                clicked: {
                    back: 'btn_my_page_back_clicked.png'
                },
                text: {
                    x: 38,
                    y: 19,
                    value: translate('go_to_shop'),
                    fontSize: 30,
                    gradient: ['#d6cece', '#ffffff']
                },
                onClicked: this.goToShop.bind(this)
            })
            this.profileContent.add(this.btn_shop);
        }

        this.backContainer.add(this.profileContent);

        this.serviceContent = new ContentCard(this, {
            x: WaitingRoomDef.service_content[this.orientation].x,
            y: WaitingRoomDef.service_content[this.orientation].y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png'
        });

        let service_login_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.login_user_count.x, WaitingRoomDef.service_content.login_user_count.y[0], this.WaitingRoomAtlasName, 'service_login_user_count_icon.png');
        service_login_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_login_user_count_icon);
        this.service_login_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.login_user_count.x,
            y: WaitingRoomDef.service_content.login_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('online_player_count'),
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_login_user_count_label);
        this.service_login_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.login_user_count.x,
            y: WaitingRoomDef.service_content.login_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_login_user_count_value);

        let service_playing_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.playing_user_count.x, WaitingRoomDef.service_content.playing_user_count.y[0], this.WaitingRoomAtlasName, 'service_playing_user_count_icon.png');
        service_playing_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_playing_user_count_icon);
        this.service_playing_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.playing_user_count.x,
            y: WaitingRoomDef.service_content.playing_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('gaming_player_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_playing_user_count_label);
        this.service_playing_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.playing_user_count.x,
            y: WaitingRoomDef.service_content.playing_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_playing_user_count_value);

        let service_waiting_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.waiting_user_count.x, WaitingRoomDef.service_content.waiting_user_count.y[0], this.WaitingRoomAtlasName, 'service_waiting_user_count_icon.png');
        service_waiting_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_waiting_user_count_icon);
        this.service_waiting_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.waiting_user_count.x,
            y: WaitingRoomDef.service_content.waiting_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('waiting_player_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_waiting_user_count_label);
        this.service_waiting_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.waiting_user_count.x,
            y: WaitingRoomDef.service_content.waiting_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_waiting_user_count_value);

        let service_room_count_icon = this.add.sprite(WaitingRoomDef.service_content.room_count.x, WaitingRoomDef.service_content.room_count.y[0], this.WaitingRoomAtlasName, 'service_room_count_icon.png');
        service_room_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_room_count_icon);
        this.service_room_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.room_count.x,
            y: WaitingRoomDef.service_content.room_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('room_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_room_count_label);
        this.service_room_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.room_count.x,
            y: WaitingRoomDef.service_content.room_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_room_count_value);

        for(let i = 0 ; i < 3 ; i++) {
            let divider = this.add.sprite(WaitingRoomDef.service_content.devider.x[i], WaitingRoomDef.service_content.devider.y, this.WaitingRoomAtlasName, 'service_divider.png');
            divider.setOrigin(0.5, 0.5);
            this.serviceContent.add(divider);
        }

        this.btn_joinLobby = new IconButton(this, {
            x: WaitingRoomDef.service_content.btn_joinLobby.x,
            y: WaitingRoomDef.service_content.btn_joinLobby.y,
            atlas: this.WaitingRoomAtlasName,
            normal: {
                back: 'btn_join_lobby_back_normal.png'
            },
            hover: {
                back: 'btn_join_lobby_back_hover.png'
            },
            clicked: {
                back: 'btn_join_lobby_back_clicked.png'
            },
            text: {
                x: 50,
                y: 19,
                value: translate('join_lobby'),
                fontSize: 30,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.joinLobby.bind(this)
        })
        this.serviceContent.add(this.btn_joinLobby);


        this.backContainer.add(this.serviceContent);
    }

    initGraphForMobile() {
        this.background = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, this.WaitingRoomAtlasName, 'background.png');
        this.background.setOrigin(0.5, 0.5);
        this.backContainer.add(this.background);


        this.btn_close = new SpriteButton(this, {
            x: WaitingRoomDef.btn_close_position[this.orientation].x,
            y: WaitingRoomDef.btn_close_position[this.orientation].y,
            atlas: this.WaitingRoomAtlasName,
            normal: 'icon_close_normal.png',
            hover: 'icon_close_hover.png',
            clicked: 'icon_close_clicked.png',
            onClicked: this.onClickedClose.bind(this)
        });
        this.backContainer.add(this.btn_close);

        this.contentCard = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: WaitingRoomDef.tabContainer.card_container.y,
            atlas: this.WaitingRoomAtlasName,
            back: 'tab_content_back.png',
        });
        this.backContainer.add(this.contentCard);

        

        this.tabContainer = new TabContainer(this, {
            x: WaitingRoomDef.tabContainer.x,
            y: WaitingRoomDef.tabContainer.y,
            atlas: this.WaitingRoomAtlasName,
            tabs: [
                {
                    x: WaitingRoomDef.tabContainer.tabs.x[0],
                    y: 0,
                    text: translate('notification'),
                    content: new ScrollView(this, {
                        x: WaitingRoomDef.tabContainer.content.x,
                        y: WaitingRoomDef.tabContainer.content.y,
                        width: WaitingRoomDef.tabContainer.content.width,
                        height: WaitingRoomDef.tabContainer.content.height,
                        atlas: this.WaitingRoomAtlasName,
                        thumb: 'scroll_thumb.png',
                        content: new TabNoticeContent(this, {
                            x: 0,
                            y: 0,
                            data: [],
                            width: 550
                        }),
                        mask: {
                            x: 78,
                            y: 290
                        },
                        action_rect: {
                            width: WaitingRoomDef.tabContainer.content.width - 20,
                            height: WaitingRoomDef.tabContainer.content.height
                        }
                    })
                },
                {
                    x: WaitingRoomDef.tabContainer.tabs.x[1],
                    y: 0,
                    text: translate('tournament'),
                    content: new ScrollView(this, {
                        x: WaitingRoomDef.tabContainer.content.x,
                        y: WaitingRoomDef.tabContainer.content.y,
                        width: WaitingRoomDef.tabContainer.content.width,
                        height: WaitingRoomDef.tabContainer.content.height,
                        atlas: this.WaitingRoomAtlasName,
                        thumb: 'scroll_thumb.png',
                        content: new TabTournamentContent(this, {
                            x: 0,
                            y: 0
                        }),
                        mask: {
                            x: 78,
                            y: 290
                        },
                        action_rect: {
                            width: WaitingRoomDef.tabContainer.content.width - 20,
                            height: WaitingRoomDef.tabContainer.content.height
                        }
                    })
                },
                {
                    x: WaitingRoomDef.tabContainer.tabs.x[2],
                    y: 0,
                    text: translate('event'),
                    content: new ScrollView(this, {
                        x: WaitingRoomDef.tabContainer.content.x,
                        y: WaitingRoomDef.tabContainer.content.y,
                        width: WaitingRoomDef.tabContainer.content.width,
                        height: WaitingRoomDef.tabContainer.content.height,
                        atlas: this.WaitingRoomAtlasName,
                        thumb: 'scroll_thumb.png',
                        content: new TabEventContent(this, {
                            x: 0,
                            y: 0
                        }),
                        mask: {
                            x: 78,
                            y: 290
                        },
                        action_rect: {
                            width: WaitingRoomDef.tabContainer.content.width - 20,
                            height: WaitingRoomDef.tabContainer.content.height
                        }
                    })
                }
            ],
            tab_line: {
                x: WaitingRoomDef.tabContainer.tab_line.x,
                y: WaitingRoomDef.tabContainer.tab_line.y,
                texture: 'tab_line.png'
            },
            scale: [1.7, 1]
        });
        this.contentCard.add(this.tabContainer);

        this.profileContent = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: WaitingRoomDef.profile_content[this.orientation].y,
            atlas: this.WaitingRoomAtlasName,
            back: 'content_back.png'
        });

        this.profileAvatar = new Avatar(this, {
            x: WaitingRoomDef.profile_content[this.orientation].avatar.x,
            y:  WaitingRoomDef.profile_content[this.orientation].avatar.y,
            atlas: "AvatarAtlas",
            mask: {
                x: WaitingRoomDef.profile_content[this.orientation].avatar.mask.x,
                y: WaitingRoomDef.profile_content[this.orientation].avatar.mask.y
            }
        });
        this.profileContent.add(this.profileAvatar);

        this.my_name = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].avatar.name.x,
            y: WaitingRoomDef.profile_content[this.orientation].avatar.name.y,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: 20,
            color: '#d7bba3'
        }).setOrigin(0.5, 0.5);
        this.profileContent.add(this.my_name);

        let my_money_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[0],
            text: translate('my_money'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_money_label)

        this.my_money_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[0],
            text: "",
            fontSize: 28,
            fontFamily: "GmarketSansLight",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_money_value)

        let my_point_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[1],
            text: translate('my_point'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_point_label)

        this.my_point_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[1],
            text: "",
            fontSize: 28,
            fontFamily: "GmarketSansLight",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_point_value)

        let my_today_betting_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[0],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[2],
            text: translate('today_betting'),
            fontSize: 28,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(0, 0.5);
        this.profileContent.add(my_today_betting_label)

        this.my_today_betting_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.profile_content[this.orientation].text_position.x[1],
            y: WaitingRoomDef.profile_content[this.orientation].text_position.y[2],
            text: "",
            fontSize: 28,
            fontFamily: "GmarketSansLight",
            gradient: ['#ffe1d0', '#fffefe']
        }).setOrigin(1, 0.5);
        this.profileContent.add(this.my_today_betting_value)

        this.btn_my_page = new IconButton(this, {
            x: WaitingRoomDef.profile_content.btn_my_page.x + (CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0 ? 0 : 130),
            y: WaitingRoomDef.profile_content.btn_my_page.y,
            atlas: this.WaitingRoomAtlasName,
            normal: {
                back: 'btn_my_page_back_normal.png'
            },
            hover: {
                back: 'btn_my_page_back_hover.png'
            },
            clicked: {
                back: 'btn_my_page_back_clicked.png'
            },
            text: {
                x: 55,
                y: 19,
                value: translate('my_page'),
                fontSize: 30,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.goToMyPage.bind(this)
        })
        this.profileContent.add(this.btn_my_page);

        if(CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0) {
            this.btn_shop = new IconButton(this, {
                x: WaitingRoomDef.profile_content.btn_shop.x,
                y: WaitingRoomDef.profile_content.btn_my_page.y,
                atlas: this.WaitingRoomAtlasName,
                normal: {
                    back: 'btn_my_page_back_normal.png'
                },
                hover: {
                    back: 'btn_my_page_back_hover.png'
                },
                clicked: {
                    back: 'btn_my_page_back_clicked.png'
                },
                text: {
                    x: 38,
                    y: 19,
                    value: translate('go_to_shop'),
                    fontSize: 30,
                    gradient: ['#d6cece', '#ffffff']
                },
                onClicked: this.goToShop.bind(this)
            })
            this.profileContent.add(this.btn_shop);
        }

        this.backContainer.add(this.profileContent);

        this.serviceContent = new ContentCard(this, {
            x: this.ScreenWidth / 2,
            y: WaitingRoomDef.service_content[this.orientation].y,
            atlas: this.WaitingRoomAtlasName
        });

        let service_login_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.login_user_count.x, WaitingRoomDef.service_content.login_user_count.y[0], this.WaitingRoomAtlasName, 'service_login_user_count_icon.png');
        service_login_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_login_user_count_icon);
        this.service_login_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.login_user_count.x,
            y: WaitingRoomDef.service_content.login_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('online_player_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_login_user_count_label);
        this.service_login_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.login_user_count.x,
            y: WaitingRoomDef.service_content.login_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_login_user_count_value);

        let service_playing_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.playing_user_count.x, WaitingRoomDef.service_content.playing_user_count.y[0], this.WaitingRoomAtlasName, 'service_playing_user_count_icon.png');
        service_playing_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_playing_user_count_icon);
        this.service_playing_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.playing_user_count.x,
            y: WaitingRoomDef.service_content.playing_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('gaming_player_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_playing_user_count_label);
        this.service_playing_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.playing_user_count.x,
            y: WaitingRoomDef.service_content.playing_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_playing_user_count_value);

        let service_waiting_user_count_icon = this.add.sprite(WaitingRoomDef.service_content.waiting_user_count.x, WaitingRoomDef.service_content.waiting_user_count.y[0], this.WaitingRoomAtlasName, 'service_waiting_user_count_icon.png');
        service_waiting_user_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_waiting_user_count_icon);
        this.service_waiting_user_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.waiting_user_count.x,
            y: WaitingRoomDef.service_content.waiting_user_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('waiting_player_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_waiting_user_count_label);
        this.service_waiting_user_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.waiting_user_count.x,
            y: WaitingRoomDef.service_content.waiting_user_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_waiting_user_count_value);

        let service_room_count_icon = this.add.sprite(WaitingRoomDef.service_content.room_count.x, WaitingRoomDef.service_content.room_count.y[0], this.WaitingRoomAtlasName, 'service_room_count_icon.png');
        service_room_count_icon.setOrigin(0.5, 0.5);
        this.serviceContent.add(service_room_count_icon);
        this.service_room_count_label = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.room_count.x,
            y: WaitingRoomDef.service_content.room_count.y[1],
            fontFamily: "NanumSquareB",
            fontSize: 20,
            color: "#d7bba3",
            text: translate('room_count')
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_room_count_label);
        this.service_room_count_value = TextClass.plainText1(this, {
            x: WaitingRoomDef.service_content.room_count.x,
            y: WaitingRoomDef.service_content.room_count.y[2],
            fontFamily: "GmarketSansMedium",
            fontSize: 24,
            color: "#ffffff",
            text: ""
        }).setOrigin(0.5, 0.5);
        this.serviceContent.add(this.service_room_count_value);

        for(let i = 0 ; i < 3 ; i++) {
            let divider = this.add.sprite(WaitingRoomDef.service_content.devider.x[i], WaitingRoomDef.service_content.devider.y, this.WaitingRoomAtlasName, 'service_divider.png');
            divider.setOrigin(0.5, 0.5);
            this.serviceContent.add(divider);
        }

        this.btn_joinLobby = new IconButton(this, {
            x: WaitingRoomDef.service_content.btn_joinLobby.x,
            y: WaitingRoomDef.service_content.btn_joinLobby.y,
            atlas: this.WaitingRoomAtlasName,
            normal: {
                back: 'btn_join_lobby_back_normal.png'
            },
            hover: {
                back: 'btn_join_lobby_back_hover.png'
            },
            clicked: {
                back: 'btn_join_lobby_back_clicked.png'
            },
            text: {
                x: 50,
                y: 19,
                value: translate('join_lobby'),
                fontSize: 30,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.joinLobby.bind(this)
        });
        this.serviceContent.add(this.btn_joinLobby);


        this.backContainer.add(this.serviceContent);
    }

    joinLobby() {
        SceneManager.changeScene(this.scene, "WaitingRoomScene", "LobbyScene");
    }

    goToMyPage() {
        if(this.myPageDialog) this.myPageDialog.setVisible(true);
        else {
            this.myPageDialog = new MyPageDialog(this, {
                x: this.ScreenWidth / 2,
                y: this.orientation == "pc" ? 192 : 0,
                atlas: this.MyPageAtlasName,
                name: translate('my_page')
            });
            this.backContainer.add(this.myPageDialog);
            this.backContainer.sort("depth");
        }
        this.requestBorderList();
    }

    requestUserProfileData(matchType) {
        CommonDef.gameNet.requestUserProfileData(CommonDef.Me.loginid, matchType);
    }

    responseUserProfileData(type, profile) {
        this.myPageDialog && this.myPageDialog.setHistoryData(type, profile);
    }

    requestBorderList() {
        CommonDef.gameNet.requestBorderList();
    }

    setBorderList(list) {
        if(this.myPageDialog && this.myPageDialog.active && this.myPageDialog.list && this.myPageDialog.list.length > 0) this.myPageDialog.tabContainer.tabs[0].content.setBorderList(list);
    }

    requestBorderUse(id) {
        CommonDef.gameNet.requestBorderUse(id);
    }

    requestUploadAvatar(avatar) {
        CommonDef.gameNet.requestUploadAvatar(avatar);
    }

    onClickTournamentItem(tournament_id, ticket_id) {
        this.tournamentDialog.setTournamentID(tournament_id);
        this.tournamentDialog.setTicketID(ticket_id);
        this.tournamentDialog.initTab();
        this.tournamentDialog.show();
        this.requestTournamentDetail();
    }

    requestTournamentDetail() {
        CommonDef.gameNet.requestTournamentDetail(this.tournamentDialog.tournamentid);
    }

    setTournamentDetail(detail) {
        this.tournamentDialog.setDetail(detail);
    }

    requestTournamentBlinds() {
        CommonDef.gameNet.requestTournamentBlinds(this.tournamentDialog.tournamentid);
    }

    setTournamentBlinds(blinds) {
        this.tournamentDialog.setBlinds(blinds);
    }

    requestTournamentEntry() {
        CommonDef.gameNet.requestTournamentEntry(this.tournamentDialog.tournamentid);
    }

    setTournamentEntry(tournamentstate, entries) {
        this.tournamentDialog.setEntry(tournamentstate, entries);
    }

    requestTournamentTable() {
        CommonDef.gameNet.requestTournamentTable(this.tournamentDialog.tournamentid);
    }

    setTournamentTable(tables) {
        this.tournamentDialog.setTable(tables);
    }

    requestTournamentPrize() {
        CommonDef.gameNet.requestTournamentPrize(this.tournamentDialog.tournamentid);
    }

    setTournamentPrize(prize) {
        this.tournamentDialog.setPrize(prize);
    }

    requestChagnePassword(oldPassword, newPassword, confirmPassword) {
        CommonDef.gameNet.requestChagnePassword(oldPassword, newPassword, confirmPassword);
    }

    onSuccessChangePassword() {
        if(this.myPageDialog && this.myPageDialog.active && this.myPageDialog.list && this.myPageDialog.list.length > 0) this.myPageDialog.tabContainer.tabs[3].content.resetFields()
    }

    requestChangeNickname(nickname) {
        CommonDef.gameNet.requestChangeNickname(nickname);
    }

    onSuccessChangeNickname() {
        if(this.myPageDialog && this.myPageDialog.active && this.myPageDialog.list && this.myPageDialog.list.length > 0) this.myPageDialog.tabContainer.tabs[0].content.editNicknameDlg.close()
    }

    requestInventory() {
        CommonDef.gameNet.requestInventoryList();
    }

    setInventoryList(list) {
        if(this.myPageDialog && this.myPageDialog.active && this.myPageDialog.list && this.myPageDialog.list.length > 0) this.myPageDialog.tabContainer.tabs[2].content.setContent(list);
    }

    requestInventoryUse(id) {
        CommonDef.gameNet.requestInventoryUse(id);
    }

    goToShop() {
        if(this.shopDialog) this.shopDialog.setVisible(true);
        else {
            this.shopDialog = new ShopDialog(this, {
                x: this.ScreenWidth / 2,
                y: this.orientation == "pc" ? 192 : 0,
                atlas: this.ShopAtlasName,
                name: translate('shinepoker_shop')
            });
            this.backContainer.add(this.shopDialog);
        }
        CommonDef.gameNet.requestShopItems();
    }

    setShopItemList(list) {
        if(this.shopDialog && this.shopDialog.active && this.shopDialog.list && this.shopDialog.list.length > 0) this.shopDialog.setShopItemList(list);
    }

    requestBuyShopItem(id) {
        CommonDef.gameNet.requestBuyShopItem(id);
    }

    onSuccessBuyShopItem(shopItemType, inventoryId) {
        if(this.shopDialog && this.shopDialog.active && this.shopDialog.list && this.shopDialog.list.length > 0) this.shopDialog.onSuccessBuyShopItem();
        if(shopItemType > 0 && shopItemType < 4) {
            this.showVIPCardUseConfirm(inventoryId);
        }
    }

    showVIPCardUseConfirm(inventoryId) {
        let vipCardUseConfirm = new VIPCardUseConfirm(this, {
            x: this.ScreenWidth / 2,
            y: 300,
            name: translate('use_vip_card'),
            atlas: "LoginAtlas_" + this.tema,
            inventoryId: inventoryId
        });
        this.backContainer.add(vipCardUseConfirm);
    }

    setTabNoticeContent(content) {
        if(this.orientation == "pc") {
            this.noticeContent.content.setContent(content);
            this.noticeContent.updateScroll();
        } else {
            this.tabContainer.tabs[0].content.content.setContent(content);
            this.tabContainer.tabs[0].content.updateScroll();
        }
    }

    setTabTournamentContent(content) {
        if(this.orientation == "pc") {
            this.tournamentContent.content.setContent(content);
            this.tournamentContent.updateScroll();
        } else {
            this.tabContainer.tabs[1].content.content.setContent(content);
            this.tabContainer.tabs[1].content.updateScroll();
        }
    }

    setTabEventContent(content) {
        if(this.orientation == "pc") {
            this.eventContent.content.setContent(content);
            this.eventContent.updateScroll();
        } else {
            this.tabContainer.tabs[2].content.content.setContent(content);
            this.tabContainer.tabs[2].content.updateScroll();
        }
    }

    showNoticeDetail(detail, store = true) {
        let title = [translate('notification'), translate('tournament_notification'), translate('event_notification')];
        let noticeDetailDialog = new NoticeDetailDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 192 : 0,
            atlas: this.WaitingRoomAtlasName,
            name: title[detail.type],
            title: detail.title,
            content: detail.content,
            date: detail.date 
        });
        this.backContainer.add(noticeDetailDialog);
        // store && CommonDef.Dialog.WaitingRoomScene.push({
        //     key: "noticeDetail",
        //     func: this.showNoticeDetail.bind(this),
        //     params: [detail]
        // })
    }

    updateMyInfo() {
        if(CommonDef.Me.unreadNoteCount > 0) {
            this.showNoteDialog();
        // } else if(CommonDef.Me.isGaming > -1) {
        //     if(CommonDef.Me.isGaming == CommonDef.Games.Holdem) {
        //         SceneManager.changeScene(this.scene, "WaitingRoomScene", "HoldemScene");
        //     } else if(CommonDef.Me.isGaming == CommonDef.Games.Omaha) {
        //         SceneManager.changeScene(this.scene, "WaitingRoomScene", "OmahaScene");
        //     }
        //     return;
        // }
        } else if(CommonDef.Me.isGaming > 0) {
            if(CommonDef.Me.isGaming == CommonDef.Games.Holdem) {
                SceneManager.changeScene(this.scene, "WaitingRoomScene", "HoldemScene");
            }
            return;
        }
        this.profileAvatar.updateAvatar(CommonDef.Me.avatar);
        this.profileAvatar.updateBorder(CommonDef.Me.bordericon);
        this.my_name.setText(CommonDef.Me.nickname);
        this.my_money_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.money));
        this.my_point_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.point))
        this.my_today_betting_value.setText(NumberUtil.convertNumberWithComma(CommonDef.Me.dailybetting));
        if(this.myPageDialog && this.myPageDialog.active && this.myPageDialog.list && this.myPageDialog.list.length > 0) {
            // this.myPageDialog.tabContainer.tabs[0].content.updateAvatar(CommonDef.Me.avatar);
            this.myPageDialog.tabContainer.tabs[0].content.updateMyInfo();
        }
    }

    updateServiceInfo(serviceInfo) {
        this.service_login_user_count_value.setText(serviceInfo[0] + translate('player_count_unit'));
        this.service_playing_user_count_value.setText(serviceInfo[1] + translate('player_count_unit'));
        this.service_waiting_user_count_value.setText(serviceInfo[2] + translate('player_count_unit'));
        this.service_room_count_value.setText(serviceInfo[3] + translate('room_count_unit'));
    }

    setNoticeList(normalNoticeList, tournamentNoticeList, eventNoticeList) {
        this.setTabNoticeContent(normalNoticeList);
        this.setTabTournamentContent(tournamentNoticeList);
        this.setTabEventContent(eventNoticeList);
    }

    setTournamentTableContent(tournamentList) {
        this.myPageDialog && this.myPageDialog.setTournamentList(tournamentList);
    }

    onClickedClose() {
        CommonDef.gameNet.logout();
    }

    showToast(type, text) {
        if(this.toast) this.toast.close();
        this.toast = new Toast(this, {
            type: type,
            text: text
        });
        this.backContainer.add(this.toast);
        setTimeout(() => {
            this.toast && this.toast.close();
        }, 2000);
    }

    showAlert() {
        if(CommonDef.AlarmStack.length < 1 || this.alertContainer.visible) return;
        let timeout = 15000;
        let message = CommonDef.AlarmStack.shift();
        this.alert_message.setText(message);
        this.alert_message.x = this.ScreenWidth;
        this.alertContainer.setVisible(true);
        this.tweens.add({
            targets: this.alert_message,
            x: -this.alert_message.width,
            duration: timeout,
            power: "Linear",
            onComplete: () => {
                this.hideAlert();
            }
        })
    }

    hideAlert() {
        this.tweens.killTweensOf(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();
    }


    updateServerTime() {
        this.tournamentDialog.visible && this.tournamentDialog.updateServerTime();
    }

    openRegisterDialog(tournamentid, buyin, fee, rebuy, starttime, rebuyFlag) {
        let registerDlg = new TournamentHoldemRegisterDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 350 : 400,
            atlas: this.LobbyDialogAtlasName,
            card_back: "card_back.png",
            card_light: "card_light.png",
            btn_ok: "btn_confirm_back",
            btn_cancel: "btn_cancel_back",
            btn_scale: [0.9, 0.9],
            scale: [0.4, 0.5],
            rebuyFlag: rebuyFlag,
            tournamentid: tournamentid,
            buyin: buyin,
            fee: fee,
            rebuy: rebuy,
            starttime: starttime,
            countdown: false
        });
        this.backContainer.add(registerDlg);
        registerDlg.setDepth(10);
        this.backContainer.sort("depth");
    }

    requestRegisterTournament(tournamentid) {
        CommonDef.gameNet.requestRegisterTournament(tournamentid, this.tournamentDialog.ticketid);
    }

    requestUnregisterTournament() {
        CommonDef.gameNet.requestUnregisterTournament(this.tournamentDialog.tournamentid);
    }

    requestEnterTournament() {
        CommonDef.gameNet.requestEnterTournament(this.tournamentDialog.tournamentid);
    }

    requestObservingTournament(tableIndex = 0) {
        CommonDef.gameNet.requestObservingTournament(this.tournamentDialog.tournamentid, tableIndex);
    }

    showNoteDialog() {
        this.noteDlg = new NoteDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 200 : 0,
            atlas: this.MyPageAtlasName
        });
        this.backContainer.add(this.noteDlg);
        CommonDef.gameNet.requestNoteList();
    }

    setNoteList(noteList) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.setContent(noteList);
    }

    setNoteRead(noteid) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.setNoteRead(noteid);
    }

    removeNote(noteid) {
        if(this.noteDlg && this.noteDlg.active && this.noteDlg.setContent) this.noteDlg.removeNote(noteid);
    }
}
