import { TextClass } from "../../../components/text/text";

export class Preparing extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.logo = this.scene.add.sprite(this.config.logo.x, this.config.logo.y, this.config.atlas, this.config.logo.texture);
        this.logo.setOrigin(0.5, 0.5);
        this.add(this.logo);
        this.label = TextClass.plainText1(this.scene, this.config.text).setOrigin(0.5, 0.5);
        this.add(this.label);
    }
}