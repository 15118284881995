import { SpriteButton } from '../components/button/spritebutton.js';
import { Toast } from '../components/dialog/toast.js';
import { BettingButton } from '../components/holdem/bettingbutton.js';
import { HoldemCard } from '../components/holdem/card.js';
import { HoldemChip } from '../components/holdem/chip.js';
import { TextClass } from '../components/text/text.js';
import CommonDef from '../def/commondef';
import OmahaDef from '../def/omahadef';
import { GameSound } from '../sound/game_sound.js';
import * as DateTimeUtil from '../util/datetime';
import * as NumberUtil from '../util/number';
import { GameHistoryDialog } from './pages/holdem/history_dialog.js';
import { MessageBox } from './pages/holdem/message_box.js';
import { PlayerProfile } from './pages/holdem/player_profile.js';
import { RaiseDialog } from './pages/holdem/raise_dialog.js';
import { SettingDialog } from './pages/lobby/setting/setting_dialog.js';
import { ShopDialog } from './pages/shop/shop_dialog.js';
import { VIPCardUseConfirm } from './pages/shop/vip_card_use_confirm.js';
import { SceneManager } from './scene_manager.js';
import { Omaha } from 'poker-odds-calc';
import { Lang } from '../lang/index.js';
import { ReportDialog } from './pages/holdem/report_dialog.js';
import { LoadingPage } from './pages/loadingPage.js';
import { OmahaPlayer } from '../components/player/omaha_player.js';
import { translate } from '../util/other.js';


export class OmahaScene extends Phaser.Scene {
    constructor(){
        super({
            key:"OmahaScene",
        })
    }

    preload(){
        
    }

    create(){
        this.tweens.killAll();
        if (this.backContainer) {
            this.backContainer.removeAll(true);
            this.currentTimeInterval && clearInterval(this.currentTimeInterval);
        } else {
            this.backContainer = this.add.container(0, 0);
            this.backContainer.setDepth(0);
        }

        if(this.emoticonContainer) {
            this.emoticonContainer.removeAll(true);
        } else {
            this.emoticonContainer = this.add.container(0, 0);
            this.emoticonContainer.setDepth(1)
        }

        if(this.showdownContainer) {
            this.showdownContainer.removeAll(true);
        } else {
            this.showdownContainer = this.add.container(0, 0);
            this.showdownContainer.setDepth(2);
        }

        if(this.dialogContainer) {
            this.dialogContainer.removeAll(true);
        } else {
            this.dialogContainer = this.add.container(0, 0);
            this.dialogContainer.setDepth(3);
        }

        this.roomid = "";
        this.pot = 0;
        this.isShowdown = 0; // 0: 아님, 1: 쇼우다운 응답 받았음, 2: 승률 계산 완료
        this.messageHistory = [];
        this.initSound();
        this.initGraph();
        this.initLoading();
        this.initShowdown();
        this.chipList = [];
        CommonDef.currentScene = this;

        this.showTimeBank(false);
        this.showRabbitHunting(false);
        this.showDoubleRabbit(false);
        this.hideBettingButton();
        this.roomState = OmahaDef.roomState.WAITING;
        this.requestRoomInfo();
    }
    initSound() {
        this.audio = new GameSound(this);
        this.audio.stopEffect();
        this.audio.playBGM("GameBGM", true);
    }

    initLoading() {
        this.loadingPage = new LoadingPage(this, {
            atlas: 'LoadingAtlas'
        });
        this.loadingPage.setDepth(1000);
    }

    playLoading() {
        this.loadingPage && this.loadingPage.playLoading();
    }

    stopLoading() {
        this.loadingPage && this.loadingPage.stopLoading();
    }

    initGraph() {
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "mobile" : "pc";
        this.ScreenWidth = CommonDef.ScreenSize[this.orientation].width;
        this.ScreenHeight = CommonDef.ScreenSize[this.orientation].height;
        this.tema = localStorage.getItem('tema') || "red";
        this.HoldemAtlasName = 'HoldemAtlas_' + this.orientation + "_" + this.tema;
        this.CardAtlasName = "HoldemDefaultCardAtlas_" + this.tema;
        this.ShopAtlasName = 'ShopAtlas_' + this.tema;
        this.LobbyDialogAtlasName = "LobbyDialogAtlas_" + this.tema;

        this.background = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "HoldemBackground_" + this.orientation + "_" + this.tema);
        this.background.setOrigin(0.5, 0.5);
        this.backContainer.add(this.background);

        this.topBack = this.add.sprite(this.ScreenWidth / 2, 0, this.HoldemAtlasName, "top_background.png").setOrigin(0.5, 0);
        this.backContainer.add(this.topBack);

        let blindLabel = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[0],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: Lang[CommonDef.Lang].blind,
            fontFamily: "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            color: "#a64708"
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(blindLabel);

        this.blindValue = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[1],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            gradient: ["#ffe1d0", "#ffffff"]
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(this.blindValue);

        let spliter1 = this.add.sprite(OmahaDef.top_text_position[this.orientation].x[2], OmahaDef.top_text_position[this.orientation].y, this.HoldemAtlasName, "top_spliter.png").setOrigin(0.5, 0.5);
        this.backContainer.add(spliter1);

        let anteLabel = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[3],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: Lang[CommonDef.Lang].ante,
            fontFamily: "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            color: "#a64708"
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(anteLabel);

        this.anteValue = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[4],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: "0",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            gradient: ["#ffe1d0", "#ffffff"]
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(this.anteValue);

        let spliter2 = this.add.sprite(OmahaDef.top_text_position[this.orientation].x[5], OmahaDef.top_text_position[this.orientation].y, this.HoldemAtlasName, "top_spliter.png").setOrigin(0.5, 0.5);
        this.backContainer.add(spliter2);

        let modeLabel = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[6],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: Lang[CommonDef.Lang].speed_mode,
            fontFamily: "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            color: "#a64708"
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(modeLabel);

        this.modeValue = TextClass.plainText1(this, {
            x: OmahaDef.top_text_position[this.orientation].x[7],
            y: OmahaDef.top_text_position[this.orientation].y,
            text: "",
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 20,
            gradient: ["#ffe1d0", "#ffffff"]
        }).setOrigin(0.5, 0.5);
        this.backContainer.add(this.modeValue);

        this.btn_out = new SpriteButton(this, {
            x: OmahaDef.top_button[this.orientation].x[0],
            y: OmahaDef.top_button[this.orientation].y,
            atlas: this.HoldemAtlasName,
            normal: "icon_back_normal.png",
            hover: "icon_back_hover.png",
            clicked: "icon_back_clicked.png",
            origin: [0.5, 0.5],
            onClicked: this.onClickedBtnOut.bind(this)
        });
        this.backContainer.add(this.btn_out);

        if(CommonDef.AppleUserIDList.indexOf(CommonDef.Me.loginid) < 0) {
            let spliter3 = this.add.sprite(OmahaDef.top_button[this.orientation].x[1], OmahaDef.top_button[this.orientation].y + 3, this.HoldemAtlasName, "icon_spliter.png").setOrigin(0.5, 0.5);
            this.backContainer.add(spliter3);
            this.btn_shop = new SpriteButton(this, {
                x: OmahaDef.top_button[this.orientation].x[2],
                y: OmahaDef.top_button[this.orientation].y,
                atlas: this.HoldemAtlasName,
                normal: "icon_shop_normal.png",
                hover: "icon_shop_hover.png",
                clicked: "icon_shop_clicked.png",
                origin: [0.5, 0.5],
                onClicked: this.onClickedBtnShop.bind(this)
            });
            this.backContainer.add(this.btn_shop);
        }

        this.btn_setting = new SpriteButton(this, {
            x: OmahaDef.top_button[this.orientation].x[3],
            y: OmahaDef.top_button[this.orientation].y,
            atlas: this.HoldemAtlasName,
            normal: "icon_setting_normal.png",
            hover: "icon_setting_hover.png",
            clicked: "icon_setting_clicked.png",
            origin: [0.5, 0.5],
            onClicked: this.onClickedBtnSetting.bind(this)
        });
        this.backContainer.add(this.btn_setting);

        let timezone = -new Date().getTimezoneOffset() / 60;
        let timeZoneText = TextClass.plainText1(this, {
            x: OmahaDef.top_button[this.orientation].x[3] - (this.orientation == "pc" ? 40 : 32),
            y: OmahaDef.top_button[this.orientation].y,
            text: "(GMT " + (timezone >= 0 ? "+" : "") + timezone + ")",
            fontFamily: "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 18,
            gradient: ["#ffe1d0", "#ffffff"]
        }).setOrigin(1, 0.5);
        this.backContainer.add(timeZoneText);

        this.timeText = TextClass.plainText1(this, {
            x: OmahaDef.top_button[this.orientation].x[3] - timeZoneText.width - (this.orientation == "pc" ? 335 : 275),
            y: OmahaDef.top_button[this.orientation].y,
            text: DateTimeUtil.getHoldemTime(),
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 22 : 18,
            gradient: ["#ffe1d0", "#ffffff"]
        }).setOrigin(0, 0.5);
        this.backContainer.add(this.timeText);
        this.updateCurrentTime();

        this.btn_timebank = new SpriteButton(this, {
            x: OmahaDef.time_bank[this.orientation].x,
            y: OmahaDef.time_bank[this.orientation].y,
            atlas: this.ShopAtlasName,
            normal: "clock.png",
            // hover: "icon_history_hover.png",
            // clicked: "icon_history_clicked.png",
            origin: [0.5, 1],
            onClicked: this.onClickedBtnTimeBank.bind(this)
        });
        this.backContainer.add(this.btn_timebank);

        this.timebank_count = TextClass.plainText1(this, {
            x: OmahaDef.time_bank[this.orientation].x + 45,
            y: OmahaDef.time_bank[this.orientation].y,
            text: 0,
            color: "#ffffff",
            fontSize: 24,
        }).setOrigin(0, 1);
        this.backContainer.add(this.timebank_count);

        this.btn_rabbit = new SpriteButton(this, {
            x: OmahaDef.time_bank[this.orientation].x,
            y: OmahaDef.time_bank[this.orientation].y,
            atlas: this.ShopAtlasName,
            normal: "rabbit_hunting.png",
            // hover: "icon_history_hover.png",
            // clicked: "icon_history_clicked.png",
            origin: [0.5, 1],
            onClicked: this.onClickedBtnRabbit.bind(this)
        });
        this.backContainer.add(this.btn_rabbit);

        this.btn_double_rabbit = new SpriteButton(this, {
            x: OmahaDef.double_rabbie[this.orientation].x,
            y: OmahaDef.double_rabbie[this.orientation].y,
            atlas: this.HoldemAtlasName,
            normal: "double_rabbit.png",
            // hover: "icon_history_hover.png",
            // clicked: "icon_history_clicked.png",
            origin: [0.5, 1],
            onClicked: this.onClickedBtnDoubleRabbit.bind(this)
        });
        this.backContainer.add(this.btn_double_rabbit);
        
        this.btn_history = new SpriteButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[0],
            y: OmahaDef.bottom_button[this.orientation].y[0],
            atlas: this.HoldemAtlasName,
            normal: "icon_history_normal.png",
            hover: "icon_history_hover.png",
            clicked: "icon_history_clicked.png",
            origin: [0.5, 0.5],
            onClicked: this.onClickedBtnHistory.bind(this)
        });
        this.backContainer.add(this.btn_history);

        this.btn_message = new SpriteButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[1],
            y: OmahaDef.bottom_button[this.orientation].y[0],
            atlas: this.HoldemAtlasName,
            normal: "icon_message_normal.png",
            hover: "icon_message_hover.png",
            clicked: "icon_message_clicked.png",
            origin: [0.5, 0.5],
            onClicked: this.onClickedBtnMessage.bind(this)
        });
        this.backContainer.add(this.btn_message);

        this.table = this.add.sprite(this.ScreenWidth / 2, OmahaDef.table[this.orientation].y, this.HoldemAtlasName, "table.png").setOrigin(0.5, 0);
        this.backContainer.add(this.table);
        this.table.setScale(2);
        if(this.orientation == "pc") {
            this.table_logo = this.add.sprite(OmahaDef.table_logo[this.orientation].x, OmahaDef.table_logo[this.orientation].y, this.HoldemAtlasName, "table_logo.png").setOrigin(0.5, 0);
            this.backContainer.add(this.table_logo);
        }

        let potBack = this.add.sprite(OmahaDef.pot[this.orientation].back.x[0], OmahaDef.pot[this.orientation].back.y, this.HoldemAtlasName, "pot_back.png").setOrigin(0, 0.5);
        this.backContainer.add(potBack);
        // let potIcon = this.add.sprite(OmahaDef.pot[this.orientation].icon.x, OmahaDef.pot[this.orientation].icon.y, this.HoldemAtlasName, "icon_pot.png").setOrigin(0, 0);
        // this.backContainer.add(potIcon);
        let potLabel = this.add.text(OmahaDef.pot[this.orientation].back.x[1], OmahaDef.pot[this.orientation].back.y, "POT", {
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 26 : 18,
            shadow: {
                offsetX: 2,
                offsetY: 6,
                color: '#000000',
                blur: 5,
                stroke: true,
                fill: true
            }
        }).setOrigin(0, 0.5);
        let gradient1 = potLabel.context.createLinearGradient(0, 0, 0, potLabel.height);
        gradient1.addColorStop(0, '#fec760');
        gradient1.addColorStop(1, '#a46633');
        potLabel.setFill(gradient1);
        this.backContainer.add(potLabel);

        this.potValue = this.add.text(OmahaDef.pot[this.orientation].back.x[2], OmahaDef.pot[this.orientation].back.y, 0, {
            fontFamily: "GmarketSansMedium",
            fontSize: this.orientation == "pc" ? 32 : 22,
            shadow: {
                offsetX: 2,
                offsetY: 6,
                color: '#000000',
                blur: 5,
                stroke: true,
                fill: true
            }
        }).setOrigin(0.5, 0.5);
        let gradient2 = this.potValue.context.createLinearGradient(0, 0, 0, this.potValue.height);
        gradient2.addColorStop(0, '#d7cfcf');
        gradient2.addColorStop(1, '#ffffff');
        this.potValue.setFill(gradient2);
        this.backContainer.add(this.potValue);
        this.potValue.setVisible(false);

        this.messageBoxDlg = new MessageBox(this, {
            x: OmahaDef.messageBox[this.orientation].x,
            y: OmahaDef.messageBox[this.orientation].y,
            atlas: this.HoldemAtlasName
        });
        this.dialogContainer.add(this.messageBoxDlg);
        // this.messageBoxDlg.setDepth(2);
        this.messageBoxDlg.setVisible(false);

        this.gameHistoryDlg = new GameHistoryDialog(this, {
            x: OmahaDef.messageBox[this.orientation].x,
            y: OmahaDef.messageBox[this.orientation].y,
            atlas: this.HoldemAtlasName
        });
        this.dialogContainer.add(this.gameHistoryDlg);
        // this.gameHistoryDlg.setDepth(2);
        this.gameHistoryDlg.setVisible(false);

        this.raiseDialog = new RaiseDialog(this, {
            x: this.orientation == "pc" ? 1107 : 0,
            y: this.orientation == "pc" ? 490 : 810,
            atlas: this.HoldemAtlasName
        });
        // this.raiseDialog.setDepth(2);
        this.dialogContainer.add(this.raiseDialog);
        this.raiseDialog.setVisible(false);

        for(let i = 0 ; i < Object.keys(OmahaDef.animations).length ; i ++) {
            let type = Object.keys(OmahaDef.animations)[i];
            for(let j = 0 ; j < Object.keys(OmahaDef.animations[type]).length ; j ++) {
                let key = Object.keys(OmahaDef.animations[type])[j];
                let config = OmahaDef.animations[type][key];
                this.anims.create({
                    key: key,
                    frames: this.anims.generateFrameNames(config.atlas, {
                        start: 0,
                        end: config.last,
                        zeroPad: config.zeroPad,
                        prefix: config.prefix,
                        suffix: '.png',
                    }),
                    frameRate: 1 / config.speed,
                    repeat: config.repeat,
                    hideOnComplete: true
                });
            }
        }
        

        this.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.player_count = 0;

        this.sharedCardList = [];

        this.backContainer.sort("depth");

        // init alert
        this.alertContainer = this.add.container(0, 0);
        this.alertContainer.setDepth(10);
        let alert_back = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.5
            }
        });
        alert_back.fillRect(0, 0, this.ScreenWidth, 40);
        this.alert_message = TextClass.plainText1(this, {
            x: this.ScreenWidth,
            y: 20,
            text: "",
            fontSize: 24,
            color: "#eeeeee"
        }).setOrigin(0, 0.5);
        this.alertContainer.add(alert_back);
        this.alertContainer.add(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();

        // 관리자 메시지
        this.adminMessageContainer = this.add.container(this.orientation == "pc" ? 635 : 100, 0);
        this.backContainer.add(this.adminMessageContainer);
        this.adminMessageBack = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.8
            }
        });
        this.adminMessageBack.fillRect(0, 0, this.topBack.width, 70);
        this.adminMessageContainer.add(this.adminMessageBack);
        this.adminMessageText = TextClass.plainText1(this, {
            x: this.topBack.width / 2,
            y: 35,
            text: "",
            color: "#ffff00",
            fontSize: 18,
            fontFamily: "GmarketSansLight"
        }).setOrigin(0.5, 0.5);
        this.adminMessageText.setStyle({
            wordWrap: { width: this.topBack.width - 60, useAdvancedWrap: true }
        });
        this.adminMessageText.setLineSpacing(10);
        this.adminMessageContainer.add(this.adminMessageText);
        this.adminMessageContainer.setVisible(false);
    }

    initShowdown() {
        this.showdownContainer.setVisible(false);
        this.showdown_back_graphic = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.9
            }
        });
        let showdown_rect = new Phaser.Geom.Rectangle(0, 0, this.ScreenWidth, this.ScreenHeight)
        this.showdown_back_graphic.fillRect(0, 0, this.ScreenWidth, this.ScreenHeight);
        this.showdownContainer.add(this.showdown_back_graphic);
        this.showdown_back_graphic.setInteractive(showdown_rect, Phaser.Geom.Rectangle.Contains);

        this.showdown_back = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "HoldemShowDownBack_" + this.orientation + "_" + this.tema);
        this.showdownContainer.add(this.showdown_back);
        this.showdown_back.setOrigin(0.5, 0.5);
        this.showdown_back.setAlpha(0.65);

        this.showdown_light_line_top = this.add.sprite(this.ScreenWidth / 2, this.orientation == "pc" ? 480 : 630, this.HoldemAtlasName, "showdown_light_line.png");
        this.showdownContainer.add(this.showdown_light_line_top);
        this.showdown_light_line_top.setOrigin(0.5, 0.5);
        this.showdown_light_line_top.setScale(this.orientation == "pc" ? 1.3 : 1.2, 0.8);
        this.showdown_light_line_bottom = this.add.sprite(this.ScreenWidth / 2, this.orientation == "pc" ? 620 : 750, this.HoldemAtlasName, "showdown_light_line.png");
        this.showdownContainer.add(this.showdown_light_line_bottom);
        this.showdown_light_line_bottom.setOrigin(0.5, 0.5);
        this.showdown_light_line_bottom.setScale(this.orientation == "pc" ? 1.3 : 1.2, 0.8);
        this.showdown_light_line_bottom.angle = 180;

        this.showdown_light_top = this.add.sprite(this.ScreenWidth / 2, this.orientation == "pc" ? 485 : 635, this.HoldemAtlasName, "showdown_light.png");
        this.showdown_light_top.setOrigin(0.5, 0.5);
        this.showdown_light_top.setScale(0.4);
        this.showdownContainer.add(this.showdown_light_top);
        this.showdown_light_bottom = this.add.sprite(this.ScreenWidth / 2, this.orientation == "pc" ? 610 : 740, this.HoldemAtlasName, "showdown_light.png");
        this.showdown_light_bottom.setOrigin(0.5, 0.5);
        this.showdown_light_bottom.setScale(0.4);
        this.showdownContainer.add(this.showdown_light_bottom);

        this.showdown_text = this.add.sprite(this.ScreenWidth / 2, this.orientation == "pc" ? 550 : 690, this.HoldemAtlasName, "showdown_text.png");
        this.showdown_text.setOrigin(0.5, 0.5);
        this.showdown_text.setScale(this.orientation == "pc" ? 1 : 0.9);
        this.showdownContainer.add(this.showdown_text);
    }

    initGraphForPC() {
        this.btn_raise = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_raise.png',
                icon_position: {
                    x: 25,
                    y: 8
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 105,
                y: 23,
                value: "RAISE",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.RAISE,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.RAISE);
            }
        });
        this.backContainer.add(this.btn_raise);

        this.btn_bet = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_raise.png',
                icon_position: {
                    x: 25,
                    y: 8
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 120,
                y: 23,
                value: "BET",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.BET,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.BET);
            }
        });
        this.backContainer.add(this.btn_bet);

        this.btn_allin = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_allin.png',
                icon_position: {
                    x: 20,
                    y: 18
                },
                icon_scale: 0.6
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 110,
                y: 23,
                value: "ALLIN",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.ALLIN,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.ALLIN)
            }
        });
        this.backContainer.add(this.btn_allin);


        this.btn_call = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[3],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_call_back_normal.png',
                icon: 'icon_call.png',
                icon_position: {
                    x: 25,
                    y: 15
                }
            },
            hover: {
                back: 'btn_call_back_hover.png'
            },
            clicked: {
                back: 'btn_call_back_clicked.png'
            },
            text: {
                x: 110,
                y: 23,
                value: "CALL",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.CALL,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.CALL)
            }
        });
        this.backContainer.add(this.btn_call);

        this.btn_check = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[3],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_call_back_normal.png',
                icon: 'icon_check.png',
                icon_position: {
                    x: 25,
                    y: 12
                }
            },
            hover: {
                back: 'btn_call_back_hover.png'
            },
            clicked: {
                back: 'btn_call_back_clicked.png'
            },
            text: {
                x: 95,
                y: 23,
                value: "CHECK",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.CHECK,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.CHECK);
            }
        });
        this.backContainer.add(this.btn_check);

        this.btn_fold = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[4],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_fold.png',
                icon_position: {
                    x: 25,
                    y: 15
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 110,
                y: 23,
                value: "FOLD",
                fontSize: 34,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.FOLD,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.FOLD);
            }
        });
        this.backContainer.add(this.btn_fold);
    }

    initGraphForMobile() {
        this.btn_raise = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_raise.png',
                icon_position: {
                    x: 20,
                    y: 15
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 18,
                y: 100,
                value: "RAISE",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.RAISE,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.RAISE);
            }
        });
        this.backContainer.add(this.btn_raise);

        this.btn_bet = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_raise.png',
                icon_position: {
                    x: 20,
                    y: 15
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 30,
                y: 100,
                value: "BET",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.BET,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.BET);
            }
        });
        this.backContainer.add(this.btn_bet);

        this.btn_allin = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[2],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_raise.png',
                icon_position: {
                    x: 20,
                    y: 15
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 18,
                y: 100,
                value: "ALLIN",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.ALLIN,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.ALLIN)
            }
        });
        this.backContainer.add(this.btn_allin);

        this.btn_call = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[3],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_call_back_normal.png',
                icon: 'icon_call.png',
                icon_position: {
                    x: 20,
                    y: 20
                }
            },
            hover: {
                back: 'btn_call_back_hover.png'
            },
            clicked: {
                back: 'btn_call_back_clicked.png'
            },
            text: {
                x: 25,
                y: 100,
                value: "CALL",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.CALL,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.CALL)
            }
        });
        this.backContainer.add(this.btn_call);

        this.btn_check = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[3],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_call_back_normal.png',
                icon: 'icon_check.png',
                icon_position: {
                    x: 20,
                    y: 15
                }
            },
            hover: {
                back: 'btn_call_back_hover.png'
            },
            clicked: {
                back: 'btn_call_back_clicked.png'
            },
            text: {
                x: 12,
                y: 100,
                value: "CHECK",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.CHECK,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.CHECK)
            }
        });
        this.backContainer.add(this.btn_check);

        this.btn_fold = new BettingButton(this, {
            x: OmahaDef.bottom_button[this.orientation].x[4],
            y: OmahaDef.bottom_button[this.orientation].y[1],
            atlas: this.HoldemAtlasName,
            normal: {
                back: 'btn_raise_back_normal.png',
                icon: 'icon_fold.png',
                icon_position: {
                    x: 23,
                    y: 20
                }
            },
            hover: {
                back: 'btn_raise_back_hover.png'
            },
            clicked: {
                back: 'btn_raise_back_clicked.png'
            },
            text: {
                x: 25,
                y: 100,
                value: "FOLD",
                fontSize: 24,
                gradient: ['#d7cfcf', '#ffffff']
            },
            action: OmahaDef.userBettingState.FOLD,
            onClicked: () => {
                this.onClickedBtnBetting(OmahaDef.userBettingState.FOLD)
            }
        });
        this.backContainer.add(this.btn_fold);
    }

    initPlayers() {
        this.playerList = [];
        for(let i = 0 ; i < this.player_count ; i ++) {
            let type = 0;
            if(i > 0 && i <= this.player_count / 2) type = 1;
            else if(i > this.player_count / 2) type = 2;
            let player = new OmahaPlayer(this, {
                x: OmahaDef.players[this.orientation][this.player_count].x[i],
                y: OmahaDef.players[this.orientation][this.player_count].y[i],
                id: (i + 1),
                type: type, // 카드 보여 줄 위치 다름
                atlas: this.HoldemAtlasName,
                avatar: {
                    url: "",
                    scale: (this.orientation == "pc" || i == 0) ? 1 : 0.8
                },
                seat: -1,
                name: "",
                coins: 0
            });
            player.setDepth(1);
            player.setActive(false);
            this.backContainer.add(player);
            this.playerList.push(player);
        }
    }

    requestRoomInfo() {
        this.hideAlert();
        this.emoticonContainer.removeAll(true);
        CommonDef.gameNet.requestOmahaRoomInfo();
    }

    startGame() {
        let tweens = this.tweens.getAllTweens();
        let exceptContainers = [
            this.emoticonContainer,
            this.shopDialog,
            this.settingDialog,
            this.alertContainer
        ]
        for(let i = 0 ; i < tweens.length ; i ++) {
            let target = tweens[i].targets[0];
            if(target.value || exceptContainers.findIndex(x => x == target.parentContainer) > -1) {
                continue;
            }
            this.tweens.remove(tweens[i]);
        }

        // this.tweens.killAll();
        // this.showdownContainer.setVisible(false);
        try {
            for(let i = this.sharedCardList.length - 1 ; i >= 0 ; i --) {
                this.sharedCardList[i].destroy();
            }
            this.sharedCardList = [];
            for(let i = 0 ; i < this.playerList.length ; i ++) {
                this.playerList[i].initialize();
            }
            this.clearPotChip();
            this.chipList = [];
            this.hideBettingButton();
            this.showRabbitHunting(false);
            this.showDoubleRabbit(false);
            this.isShowdown = 0;
            this.hidePlayerOdds();
        } catch (error) {
            
        }
    }

    updateCurrentTime() {
        this.currentTimeInterval = setInterval(() => {
            try {
                this.timeText.setText(DateTimeUtil.getHoldemTime());
            } catch (err) {
                clearInterval(this.currentTimeInterval);
            }
        }, 1000);
    }

    dealCard(destSeat, cardValue) {
        let card = new HoldemCard(this, {
            x: this.ScreenWidth / 2 - this.playerList[(destSeat - OmahaDef.MySeat + this.player_count) % this.player_count].config.x,
            y: this.ScreenHeight / 2 - this.playerList[(destSeat - OmahaDef.MySeat + this.player_count) % this.player_count].config.y,
            atlas: this.CardAtlasName,
            value: cardValue
        });
        this.playerList[(destSeat - OmahaDef.MySeat + this.player_count) % this.player_count].addHandCard(card, this.roomState < OmahaDef.roomState.FLOP)
        this.audio.playEffect("CardOpen");
    }

    openSharedCard(cardValue, animFlag = true) {
        let card = new HoldemCard(this, {
            x: OmahaDef.shared_cards[this.orientation].x[this.sharedCardList.length],
            y: OmahaDef.shared_cards[this.orientation].y,
            atlas: this.CardAtlasName,
            value: cardValue
        });
        this.sharedCardList.push(card);
        this.backContainer.add(card);
        this.backContainer.sort("depth");
        if(this.orientation == "mobile") card.setScale(67 / 87);
        animFlag = animFlag && !document.hidden;
        if(animFlag) {
            try {
                this.audio.playEffect("CardOpen");
                this.tweens.add({
                    targets: card,
                    x: {
                        from: card.x - 50,
                        to: card.x
                    },
                    alpha: {
                        from: 0,
                        to: 1
                    },
                    duration: 100,
                    ease: "Power2",
                    onComplete: () => {
                        card.setOpen(true);
                    }
                });
            } catch (e) {
                
            }
        }
    }

    onClickedBtnOut() {
        CommonDef.gameNet.requestOmahaRoomExit();
    }

    onSuccessExit() {
        this.clearTimerEvents();
        SceneManager.changeScene(this.scene, "OmahaScene", "LobbyScene");
    }

    clearTimerEvents() {
        this.currentTimeInterval && clearInterval(this.currentTimeInterval);
    }

    onClickedBtnShop() {
        this.shopDialog = new ShopDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 192 : 0,
            atlas: this.ShopAtlasName,
            name: translate('shinepoker_shopt')
        });
        // this.shopDialog.setDepth(5);
        this.dialogContainer.add(this.shopDialog);
        // this.backContainer.sort("depth");
        CommonDef.gameNet.requestShopItems();
    }

    setShopItemList(list) {
        if(this.shopDialog && this.shopDialog.list && this.shopDialog.list.length > 0) this.shopDialog.setShopItemList(list);
    }

    requestBuyShopItem(id) {
        CommonDef.gameNet.requestBuyShopItem(id);
    }

    onSuccessBuyShopItem() {
        if(this.shopDialog && this.shopDialog.list && this.shopDialog.list.length > 0) this.shopDialog.onSuccessBuyShopItem();
    }

    onSuccessBuyShopItem(shopItemType, inventoryId) {
        if(this.shopDialog && this.shopDialog.list && this.shopDialog.list.length > 0) this.shopDialog.onSuccessBuyShopItem();
        if(shopItemType > 0 && shopItemType < 4) {
            this.showVIPCardUseConfirm(inventoryId);
        }
    }

    showVIPCardUseConfirm(inventoryId) {
        let vipCardUseConfirm = new VIPCardUseConfirm(this, {
            x: this.ScreenWidth / 2,
            y: 300,
            name: translate('use_vip_card'),
            atlas: "LoginAtlas_" + this.tema,
            inventoryId: inventoryId
        });
        this.dialogContainer.add(vipCardUseConfirm);
    }

    requestInventoryUse(id) {
        CommonDef.gameNet.requestInventoryUse(id);
    }

    onClickedBtnSetting() {
        this.settingDialog && this.settingDialog.close();
        this.settingDialog = new SettingDialog(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 190 : 0,
            atlas: this.LobbyDialogAtlasName,
            name: translate('setting')
        });
        this.dialogContainer.add(this.settingDialog);
        CommonDef.gameNet.requestTableTemaList();
        CommonDef.gameNet.requestCardTemaList();
    }

    setTableTemaList(list) {
        this.settingDialog.setTableTemaList && this.settingDialog.setTableTemaList(list);
    }

    setCardTemaList(list) {
        this.settingDialog.setCardTemaList && this.settingDialog.setCardTemaList(list);
    }

    onClickedBtnHistory() {
        CommonDef.gameNet.requestGameHistory(this.roomid);
        this.gameHistoryDlg.setVisible(true);
    }

    responseGameHistory(history) {
        this.gameHistoryDlg.setContent(history);
    }

    onClickedBtnMessage() {
        this.messageBoxDlg.setVisible(true);
    }

    requestMessage(message) {
        CommonDef.gameNet.requestOmahaMessage(message);
    }

    responseOmahaMessage(user_id, seat, nickname, avatar, border, message) {
        if(!!user_id) {
            let player = this.getPlayerBySeat(seat);
            if(!player) return;
            player.showMessage(message.length < 15 ? message : message.substring(0, 13) + "...");
        } else {
            this.showAdminMessage(message);
        }
        let index = this.messageHistory.length > 0 ? this.messageHistory[this.messageHistory.length - 1].index + 1 : 1;
        this.messageHistory.push({
            index: index,
            user_id: user_id,
            user_nickname: nickname,
            seat: seat,
            avatar: avatar,
            border: border,
            message: message,
            isreport: false,
            createdat: new Date().getTime()
        });
        if(this.messageHistory.length > 50) this.messageHistory.shift();
        this.messageBoxDlg.setContent(this.messageHistory);
    }

    showAdminMessage(message) {
        this.adminMessageContainer.setVisible(true);
        this.adminMessageText.setText(message.length < 64 ? message : message.substring(0, 63) + "...");
        setTimeout(() => {
            try {
                this.adminMessageContainer.setVisible(false);
            } catch {
                console.log("you are out of the room");
            }
        }, 5000)
    }


    requestPlayerProfile(user_id) {
        CommonDef.gameNet.requestUserProfileData(user_id, 0);
    }

    openPlayerProfile(profile) {
        this.profileDlg = new PlayerProfile(this, {
            x: this.ScreenWidth / 2,
            y: this.orientation == "pc" ? 140 : 0,
            atlas: this.HoldemAtlasName,
            name: translate('player_profile'),
            profile: {
                avatar: profile.avatar,
                border: profile.border,
                nickname: profile.nickname,
                id: profile.userid,
                ip: profile.ip,
                VPIP: profile.vpip,
                PFR: profile.pfr,
                Steal: profile.steal,
                Check_Raise: profile.checkraise,
                Three_Bet: profile.threebet,
                Fold_To_Three_Bet: profile.foldtothreebet,
                C_Bet: profile.cbet,
                Fold_To_C_Bet: profile.foldtocbet,
                Total_Hand: profile.totalhand,
                Winner: profile.winnercount,
                WTSD: profile.wtsd,
                WSSD: profile.wssd
            },
            isMe: CommonDef.Me.loginid == profile.userid,
            observing: false
        });
        this.dialogContainer.add(this.profileDlg);
        this.profileDlg.setBlockState(OmahaDef.BlockUsers.indexOf(profile.userid) > -1)
    }

    playShowdownAnimation() {
        if(document.hidden) return;
        this.audio.playEffect("Showdown");
        this.showdownContainer.setVisible(true);
        this.showdown_back.setAlpha(0);
        this.showdown_light_line_top.setAlpha(0);
        this.showdown_light_line_bottom.setAlpha(0);
        this.showdown_light_top.setAlpha(0);
        this.showdown_light_bottom.setAlpha(0);
        this.showdown_text.setAlpha(0);
        this.tweens.addCounter({
            from: 0,
            to: 1500,
            duration: 1500,
            onUpdate: (tween) => {
                let past = tween.getValue();
                if(past < 150) {
                    this.showdown_back.setAlpha(past / 230);
                    this.showdown_back_graphic.setAlpha(past / 165);
                } else if(past < 500) {
                    this.showdown_light_line_top.setAlpha((past - 150) / 350);
                    this.showdown_light_line_bottom.setAlpha((past - 150) / 350);
                    this.showdown_light_line_top.x = this.ScreenWidth / 2 - (500 - past) / 2 + 20;
                    this.showdown_light_line_bottom.x = this.ScreenWidth / 2 + (500 - past) / 2 - 20;
                    this.showdown_text.setAlpha((past - 150) / 350);
                    this.showdown_text.y = (this.orientation == "pc" ? 550 : 690) + (500 - past) / 7;
                } else if(past < 1150) {
                    this.showdown_light_top.setAlpha(1);
                    this.showdown_light_bottom.setAlpha(1);
                    this.showdown_light_top.x = this.ScreenWidth / 2 + (825 - past) * 0.4 + 20;
                    this.showdown_light_bottom.x = this.ScreenWidth / 2 - (825 - past) * 0.4 - 20;
                    this.showdown_light_top.setScale((1 - Math.abs(825 - past) / 325) * 0.4);
                    this.showdown_light_bottom.setScale((1 - Math.abs(825 - past) / 325) * 0.4);
                } else {
                    this.showdown_light_line_top.setAlpha((1500 - past) / 350);
                    this.showdown_light_line_bottom.setAlpha((1500 - past) / 350);
                    this.showdown_light_line_top.x = this.ScreenWidth / 2 - (past - 1150) / 2 + 20;
                    this.showdown_light_top.x = this.ScreenWidth / 2 - 110 - (past - 1150) / 2;
                    this.showdown_light_top.setAlpha((1500 - past) / 350);
                    this.showdown_light_line_bottom.x = this.ScreenWidth / 2 + (past - 1150) / 2 - 20;
                    this.showdown_light_bottom.x = this.ScreenWidth / 2 + 110 + (past - 1150) / 2;
                    this.showdown_light_bottom.setAlpha((1500 - past) / 350);
                    this.showdown_text.setAlpha((1500 - past) / 350);
                    this.showdown_text.y = (this.orientation == "pc" ? 550 : 690) + (past - 1150) / 7;
                    this.showdown_back.setAlpha((1500 - past) / 350 * 0.65);
                    this.showdown_back_graphic.setAlpha((1500 - past) / 350 * 0.9);
                }
            },
            onComplete: () => {
                this.showdownContainer.setVisible(false);
            }
        });

        this.isShowdown = 1;
    }


    responseRoomInfo(firstFlag, roomid, sb, bb, ante, mode, seat_count, roomState, dealer_seat, turn_seat, turn_start, turn_duration, players, mySeat, pot, shared_cards, bettingBook, block_user_id_list) {
        this.roomid = roomid;
        this.roomState = roomState;
        this.blindValue.setText(NumberUtil.convertNumbersWithUnit(sb, "en") + "/" + NumberUtil.convertNumbersWithUnit(bb, "en"));
        this.anteValue.setText(ante);
        let modeLabel = Lang[CommonDef.Lang].speed_labels;
        this.modeValue.setText(modeLabel[mode]);
        if(this.player_count == 0) {
            this.player_count = seat_count;
            this.initPlayers();
        }
        OmahaDef.MySeat = mySeat;
        OmahaDef.BlockUsers = block_user_id_list;
        if(this.profileDlg && this.profileDlg.active && this.profileDlg.config.profile.id) {
            this.profileDlg.setBlockState(block_user_id_list.indexOf(this.profileDlg.config.profile.id) > -1 ? 1 : 0);
        }
        for(let i = 0 ; i < players.length ; i ++) {
            this.playerList[(players[i].seat - mySeat + this.player_count) % this.player_count].setActive(true);
            this.playerList[(players[i].seat - mySeat + this.player_count) % this.player_count].setPlayerInfo(players[i], !firstFlag);
            if(players[i].loginid == CommonDef.Me.loginid){
                if(this.messageBoxDlg && this.messageBoxDlg.active){
                    if(CommonDef.Me.chatpossible != players[i].chatpossible) {
                        CommonDef.Me.chatpossible = players[i].chatpossible;
                        this.messageBoxDlg.setChatPossible(players[i].chatpossible);
                    }
                }
            }
        }
        // 나간 유저는 setActive(false)로 설정해주어야 한다.
        for(let i = 0 ; i < this.playerList.length ; i ++) {
            if(!this.playerList[i].active) continue;
            let index = players.findIndex(x => x.seat == this.playerList[i].config.seat);
            if(index < 0) this.playerList[i].setActive(false);
        }
        if(roomState > OmahaDef.roomState.START) {
            this.potValue.setText(NumberUtil.convertNumberWithComma(pot));
            this.potValue.setVisible(true);
            this.pot = pot;
        } else {
            this.potValue.setVisible(false);
        }

        if(bettingBook.enable) this.showBettingBookButton(bettingBook);
        else if(!this.isPlayerTurn(OmahaDef.MySeat)) this.hideBettingButton();

        if(firstFlag) {
            // 처음으로 받은 방 정보 응답 => 팟 크기만큼 칩을 쌓는다.
            this.clearPotChip();
            this.pushChip(-1, this.pot, false);

            let turnPlayer = this.getPlayerBySeat(turn_seat);
            turnPlayer && turnPlayer.setTurn(turn_duration * 1000, CommonDef.ServerTime - turn_start);
        }

        if(this.isShowdown == 1 && CommonDef.Me.viplevel > 0) {
            // 쇼우다운인 경우 승률 계산
            this.isShowdown = 2;
            const Table = new Omaha();
            let showdownPlayerSeats = [];
            for(let i = 0 ; i < players.length ; i ++) {
                let action = Math.floor(players[i].state / 10);
                if(action < OmahaDef.userBettingState.DEALER && action != OmahaDef.userBettingState.FOLD) {
                    Table.addPlayer(this.playerList[(players[i].seat - mySeat + this.player_count) % this.player_count].handCardList.map(x => x.value))
                    showdownPlayerSeats.push(players[i].seat);
                }
            }
            this.sharedCardList.length > 0 && Table.setBoard(this.sharedCardList.map(x => x.value));
            const ResultPlayers = Table.calculate().getPlayers();

            for(let i = 0 ; i < ResultPlayers.length ; i ++) {
                this.getPlayerBySeat(showdownPlayerSeats[i]).showOdds(ResultPlayers[i].getWinsPercentage(), ResultPlayers[i].getTiesPercentage())
            }
        }

        for(let i = 0 ; i < shared_cards.length ; i ++) {
            let s_index = this.sharedCardList.findIndex(x => x.value == shared_cards[i]);
            if(s_index < 0) {
                this.openSharedCard(shared_cards[i]);
            }
        }

        // 딜러 표시
        let dealer = this.getPlayerBySeat(dealer_seat);
        dealer && dealer.setMark("dealer");

        switch(roomState) {
            case OmahaDef.roomState.PREPARING:
                this.startGame();
                break;
            case OmahaDef.roomState.WAITING:
                this.startGame();
                break;
            case OmahaDef.roomState.START:
                this.startGame();
                break;
            case OmahaDef.roomState.BLIND:
                break;
            case OmahaDef.roomState.PREFLOP:
                break;
            case OmahaDef.roomState.FLOP:
                break;
            case OmahaDef.roomState.TURN:
                break;
            case OmahaDef.roomState.RIVER:
                break;
            case OmahaDef.roomState.GAMEOVER:
                this.hidePlayerOdds();
                break;
        }
    }

    hidePlayerOdds() {
        for(let i = 0 ; i < this.playerList.length ; i ++) {
            this.playerList[i].hideOdds();
        }
    }

    getPlayerBySeat(seat) {
        if(seat < 0) return null;
        let index = this.playerList.findIndex(x => x.config.seat == seat);
        if(index < 0) return null;
        return this.playerList[index];
    }

    isPlayerTurn(seat) {
        let player = this.getPlayerBySeat(seat);
        if(!player) return false;
        return player.isTurn;
    }

    responseOmahaMoveTurn(seat, prompt, countdown, enable_timebank) {
        let turnPlayer = this.getPlayerBySeat(seat);
        if(!turnPlayer) {
            return
        }
        if(countdown > 0) {
            turnPlayer.setTurn(countdown * 1000, 0);
        }
        if(seat == OmahaDef.MySeat) {
            this.showBettingButton(prompt);
            if(CommonDef.Me.timeBankCount > 0) {
                this.showTimeBank(enable_timebank > 0 ? true : false, CommonDef.Me.timeBankCount);
            }
        } else {
            this.showTimeBank(false);
        }
    }

    responseOmahaGameover(players) {
        this.audio.playEffect("Win");
        for(let i = 0 ; i < players.length ; i ++) {
            let player = this.getPlayerBySeat(players[i].seat);
            if(!player) continue;
            player.setHandCards(players[i].handcards);
            if(players[i].winner) {
                player.setWinner(players[i].pattern, players[i].prize);
                players[i].patternIndex > -1 && this.audio.playEffect("Pattern_" + players[i].patternIndex + "_" + (localStorage.getItem("voice") || "male"));
                for(let ci = 0 ; ci < player.handCardList.length ; ci ++) {
                    let index = players[i].highcards.findIndex(x => x == player.handCardList[ci].value);
                    player.handCardList[ci].setDisableMask(index < 0);
                }
            } else {
                for(let ci = 0 ; ci < player.handCardList.length ; ci ++) {
                    player.handCardList[ci].setDisableMask(true);
                }
            }
            this.pullChip(players[i].seat, players[i].prize);
        }
        let winner = players.find(x => x.winner == 1);
        for(let ci = 0 ; ci < this.sharedCardList.length ; ci ++) {
            let index = winner.highcards.findIndex(x => x == this.sharedCardList[ci].value);
            this.sharedCardList[ci].setDisableMask(index < 0);
        }
        this.clearPotChip();
        this.showTimeBank(false);
        this.hideBettingButton();
        
        let me = this.getPlayerBySeat(OmahaDef.MySeat);
        if(!me) return;
        if(me.state == 0 || me.state == 100) return;
        if(this.sharedCardList.length < 5 && CommonDef.Me.rabbitEnable) {
            this.showRabbitHunting(true);
        }
        if(me.handCardList.length == 0 || (me.user_id == winner.userid && winner.patternIndex < 1)) {
            this.showDoubleRabbit(true);
        }
    }

    pushChip(seat, amount, animFlag = true) {
        animFlag = animFlag && !document.hidden;
        if(amount <= 0) return;
        let player = null;
        if(seat < 0) {
            animFlag = false;
        } else {
            player = this.getPlayerBySeat(seat);
            if(!player) return;
        }
        try {
            let chipUnit = [5000000, 1000000, 500000, 100000, 50000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];        
            let unitIndex = 0;
            
            while(amount > 0) {
                if(unitIndex >= chipUnit.length) break;
                if(amount < chipUnit[unitIndex]) {
                    unitIndex ++;
                    continue;
                }
                amount -= chipUnit[unitIndex];
                let x = OmahaDef.pot[this.orientation].back.x[0] + 20;
                let y = OmahaDef.pot[this.orientation].back.y;
                let deltaX = this.orientation == "pc" ? 100 : 60;
                let deltaY = this.orientation == "pc" ? 50 : 60;
                x += Math.floor(Math.pow(Math.random(), 2) * deltaX) * (Math.random() > 0.5 ? 1 : -1);
                y += Math.floor(Math.pow(Math.random(), 2) * deltaY) * (Math.random() > 0.5 ? 1 : -1);
                let chip = new HoldemChip(this, {
                    x: player ? player.config.x : x,
                    y: player ? (player.config.y + 60 * player.config.avatar.scale) : y,
                    value: chipUnit[unitIndex],
                    atlas: this.HoldemAtlasName,
                    icon: "chip_" + chipUnit[unitIndex] + ".png",
                    scale: 0.3
                });
                this.backContainer.add(chip);
                this.chipList.push(chip);
                this.backContainer.sort("depth");
                
                let duration = 350 + Math.floor(Math.random() * 150);
                if(animFlag) {
                    this.audio.playEffect("PushChip");
                    this.tweens.add({
                        targets: chip,
                        alpha: {
                            from: 0,
                            to: 1
                        },
                        x: x, 
                        y: y,
                        duration: duration,
                        ease: "Cubic.Out"
                    });
                } else {
                    chip.x = x;
                    chip.y = y;
                }
            }
        } catch (e) {
            // CommonDef.gameNet.reportError("Holdem => pushChip", [seat, player ? player.userid : "INIT-POT", amount, animFlag], e);
        }
    }

    pullChip(seat, amount, allFlag = false) {
        seat == OmahaDef.MySeat && this.audio.playEffect("PullChip");
        if(amount <= 0) return;
        if(this.chipList.length < 1) return;
        let player = this.getPlayerBySeat(seat);
        if(!player) return;
        try {
            let pullChipList = [];
            if(allFlag == true) {
                pullChipList = this.chipList;
            } else {
                let chipUnit = [5000000, 1000000, 500000, 100000, 50000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];   
                let unitIndex = 0;
                this.chipList.sort((a, b) => b.value - a.value);
                while(chipUnit[unitIndex] > this.chipList[0].value) {
                    unitIndex ++;
                }
                while(amount > 0) {
                    if(unitIndex >= chipUnit.length || this.chipList.length < 1) break;
                    if(amount < chipUnit[unitIndex]) {
                        unitIndex ++;
                        continue;
                    }
                    let index = this.chipList.findIndex(x => x.value == chipUnit[unitIndex]);
                    if(index < 0) {
                        unitIndex ++;
                    }
                    let chip = this.chipList.splice(index, 1)[0];
                    pullChipList.push(chip);
                    amount -= chip.value;
                }
            }
            if(document.hidden) {
                for(let i = 0 ; i < pullChipList.length ; i ++) {
                    pullChipList[i].destroy();
                }
            } else {
                this.tweens.add({
                    targets: pullChipList,
                    x: player.config.x,
                    y: player.config.y + 60 * player.config.avatar.scale,
                    alpha: 0,
                    ease: "Cubic.In",
                    duration: 500,
                    onComplete: () => {
                        for(let i = 0 ; i < pullChipList.length ; i ++) {
                            pullChipList[i].destroy();
                        }
                    }
                })
            }
        } catch(e) {
            // CommonDef.gameNet.reportError("Holdem => pullChip", [seat, player.userid, amount, allFlag], e);
        }
    }

    clearPotChip() {
        for(let i = 0 ; i < this.chipList.length; i ++) {
            this.chipList[i].destroy();
        }
    }

    updateMyInfo() {
        this.timebank_count.setText(CommonDef.Me.timeBankCount);
    }

    onClickedBtnTimeBank() {
        CommonDef.gameNet.requestOmahaTimeBank();
    }

    responseOmahaTimeBank(seat, userid) {
        let player = this.getPlayerBySeat(seat);
        if(!player) return;
        player.setTimeBank(true);
    }

    onClickedBtnRabbit() {
        CommonDef.gameNet.requestOmahaRabbit();
        this.showRabbitHunting(false);
    }

    responseOmahaRabbit(seat, userid, shared_cards) {
        if(this.roomState != OmahaDef.roomState.GAMEOVER) return;
        if(seat == OmahaDef.MySeat && userid == CommonDef.Me.loginid) {
            for(let i = 0 ; i < shared_cards.length ; i ++) {
                let s_index = this.sharedCardList.findIndex(x => x.value == shared_cards[i]);
                if(s_index < 0) {
                    this.openSharedCard(shared_cards[i]);
                }
            }
        }
        let player = this.getPlayerBySeat(seat);
        if(!player) return;
        player.setRabbit(true);
    }

    onClickedBtnDoubleRabbit() {
        CommonDef.gameNet.requestOmahaPublishMyCard();
        this.showDoubleRabbit(false);
    }

    showTimeBank(flag, count = 0) {
        this.btn_timebank.setVisible(flag);
        this.timebank_count.setVisible(flag);
        this.timebank_count.setText(count);
    }

    showRabbitHunting(flag) {
        if(flag && this.sharedCardList.length < 1) flag = false;
        this.btn_rabbit.setVisible(flag);
    }

    showDoubleRabbit(flag) {
        this.btn_double_rabbit.setVisible(flag);
    }

    showBettingButton(config) {
        this.btn_raise.setVisible(config.raise);
        this.btn_bet.setVisible(config.bet);
        this.btn_allin.setVisible(config.allin);
        config.allin && this.btn_allin.convertBooking(false);
        this.btn_call.setVisible(config.call);
        config.call && this.btn_call.convertBooking(false);
        this.btn_check.setVisible(config.check);
        config.check && this.btn_check.convertBooking(false);
        this.btn_fold.setVisible(config.fold);
        config.fold && this.btn_fold.convertBooking(false);
        this.raiseDialog.setConfig({
            range: config.bet ? [config.bet_min, config.bet_max] : [config.raise_min, config.raise_max],
            pot: this.pot,
            type: config.bet ? OmahaDef.userBettingState.BET : OmahaDef.userBettingState.RAISE
        });
    }

    hideBettingButton() {
        this.btn_raise.setVisible(false);
        this.btn_bet.setVisible(false);
        this.btn_allin.setVisible(false);
        this.btn_call.setVisible(false);
        this.btn_check.setVisible(false);
        this.btn_fold.setVisible(false);
        this.raiseDialog.setVisible(false);
    }

    showBettingBookButton(config) {
        this.btn_raise.setVisible(false);
        this.btn_bet.setVisible(false);

        this.btn_allin.clearBooking();
        this.btn_call.clearBooking();
        this.btn_check.clearBooking();
        this.btn_fold.clearBooking();

        this.btn_allin.setVisible(config.allin);
        config.allin && this.btn_allin.convertBooking(true);
        this.btn_call.setVisible(config.call);
        config.call && this.btn_call.convertBooking(true);
        this.btn_check.setVisible(config.check);
        config.check && this.btn_check.convertBooking(true);
        this.btn_fold.setVisible(config.fold);
        config.fold && this.btn_fold.convertBooking(true);
        
        let bookBtn = null;
        if(config.book == OmahaDef.userBettingState.ALLIN && config.allin) bookBtn = this.btn_allin;
        if(config.book == OmahaDef.userBettingState.CALL && config.call) bookBtn = this.btn_call;
        if(config.book == OmahaDef.userBettingState.CHECK && config.check) bookBtn = this.btn_check;
        if(config.book == OmahaDef.userBettingState.FOLD && config.fold) bookBtn = this.btn_fold;
        bookBtn && bookBtn.checkIcon.setVisible(true);
    }

    requestBettingBook(action) {
        CommonDef.gameNet.requestOmahaBetting(action, 0);
    }

    clearBettingBook() {
        this.btn_allin.clearBooking();
        this.btn_call.clearBooking();
        this.btn_check.clearBooking();
        this.btn_fold.clearBooking();
    }

    onClickedBtnBetting(action) {
        if(action > OmahaDef.userBettingState.NONE && action < OmahaDef.userBettingState.RAISE) {
            CommonDef.gameNet.requestOmahaBetting(action, 0);
            this.hideBettingButton();
            this.showTimeBank(false);
        } else if(action == OmahaDef.userBettingState.RAISE || action == OmahaDef.userBettingState.BET) {
            this.raiseDialog.setVisible(true);
        }
    }

    requestRaise(action, amount) {
        let player_me = this.getPlayerBySeat(OmahaDef.MySeat);
        if(amount == player_me.balance) { // allin
            CommonDef.gameNet.requestOmahaBetting(OmahaDef.userBettingState.ALLIN, 0);
        } else {
            CommonDef.gameNet.requestOmahaBetting(action, amount);
        }
        this.raiseDialog.setVisible(false);
        this.hideBettingButton();
        this.showTimeBank(false);
    }

    requestSendEmoticon(userid, key) {
        CommonDef.gameNet.requestOmahaEmoticon(userid, key);
    }

    responseOmahaEmoticon(from, to, key) {
        if(from == OmahaDef.MySeat) {
            this.profileDlg && this.profileDlg.close();
        }
        if(document.hidden) return;
        let config = OmahaDef.animations.other[key];
        if(!config) config = OmahaDef.animations.self[key];
        if(!config) {
            return;
        }
        let fromUser = this.getPlayerBySeat(from);
        let toUser = this.getPlayerBySeat(to);

        let emoticon_icon_name = config.prefix;
        for(let i = 0 ; i < config.zeroPad ; i ++) {
            emoticon_icon_name += "0";
        }
        emoticon_icon_name += ".png";
        let emoticonSprite = this.add.sprite(0, 0, config.atlas, emoticon_icon_name);
        this.emoticonContainer.add(emoticonSprite);
        emoticonSprite.x = fromUser.config.x;
        emoticonSprite.y = fromUser.config.y + 60 * ((this.orientation == "pc" || toUser.userid == CommonDef.Me.loginid) ? 1 : 0.8);
        emoticonSprite.setScale(config.scale * ((this.orientation == "pc" || toUser.userid == CommonDef.Me.loginid) ? 1 : 0.8) * 1.2);
        this.tweens.add({
            targets: emoticonSprite,
            x: toUser.x,
            y: toUser.y + 60 * ((this.orientation == "pc" || toUser.userid == CommonDef.Me.loginid) ? 1 : 0.8),
            duration: fromUser.userid == toUser.userid ? 0 : 500,
            ease: "Power2",
            onComplete: () => {
                // this.playEmoticon(emoticonSprite, toUser.x, toUser.y + 60 * ((this.orientation == "pc" || toUser.userid == CommonDef.Me.loginid) ? 1 : 0.8), key);
                emoticonSprite.anims.play(key);
                this.audio.playEffect(key);
                emoticonSprite.on('animationcomplete', () => {
                    emoticonSprite.destroy();
                });
            }
        });
    }

    // playEmoticon(emoticonSprite, x, y, key) {
    //     emoticonSprite.x = x;
    //     emoticonSprite.y = y;
    //     emoticonSprite.anims.play(key);
    //     this.audio.playEffect(key);
    //     emoticonSprite.on('animationcomplete', () => {
    //         emoticonSprite.destroy();
    //     });
    // }

    responseUserProfileData(type, profile) {
        this.openPlayerProfile(profile);
    }

    requestBlockUser(userid) {
        CommonDef.gameNet.requestOmahaBlockUser(OmahaDef.BlockUsers.indexOf(userid) > -1 ? 1 : 0, userid);
    }

    reportUser(userid, type, message="", message_index = 0) {
        this.reportDlg = new ReportDialog(this, {
            x: this.ScreenWidth / 2,
            y: 300,
            yScale: 1.2,
            atlas: this.HoldemAtlasName,
            userid: userid,
            type: type,
            message: message,
            message_index: message_index
        });
        this.dialogContainer.add(this.reportDlg);
    }

    requestReport(content, userid, type, message_index) {
        CommonDef.gameNet.requestReport(this.roomid, content, CommonDef.Me.loginid, userid, type, message_index);
    }

    responseReport(message_index) {
        if(this.reportDlg && this.reportDlg.active) this.reportDlg.close();
        if(message_index > 0) {
            let index = this.messageHistory.findIndex(x => x.index == message_index);
            if(index > -1) {
                this.messageHistory[index].isreport = true;
                this.messageBoxDlg.setContent(this.messageHistory);
            }
        }
    }

    showToast(type, text) {
        if(this.toast) this.toast.close();
        this.toast = new Toast(this, {
            type: type,
            text: text
        });
        this.dialogContainer.add(this.toast);
        setTimeout(() => {
            this.toast && this.toast.close();
        }, 2000);
    }

    showAlert() {
        if(CommonDef.AlarmStack.length < 1 || this.alertContainer.visible) return;
        let timeout = 15000;
        let message = CommonDef.AlarmStack.shift();
        this.alert_message.setText(message);
        this.alert_message.x = this.ScreenWidth;
        this.alertContainer.setVisible(true);
        this.tweens.add({
            targets: this.alert_message,
            x: -this.alert_message.width,
            duration: timeout,
            power: "Linear",
            onComplete: () => {
                this.hideAlert();
            }
        })
    }

    hideAlert() {
        this.tweens.killTweensOf(this.alert_message);
        this.alertContainer.setVisible(false);
        this.showAlert();
    }
}