import { TextClass } from "../text/text";
import * as NumberUtil from "../../util/number";

export class HistoryChart extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.type = this.config.type;
        this.padding = {
            left: 30,
            top: 30
        }
        this.xLength = {
            day: 15,
            month: 12,
            recent: 7
        };
        
        this.initGraph();
    }

    initGraph() {
        this.topBack = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: this.config.back[0],
                alpha: 1
            }
        });
        this.topBack.fillRect(0, 0, this.config.width, this.config.height[0]);
        this.topBack.fillStyle(0x444444, 1);
        this.topBack.fillRect(0, this.config.height[0] / 2, this.config.width, 1);
        this.add(this.topBack);
        

        if(this.config.back[1]) {
            this.bottomBack = this.scene.add.graphics({
                x: 0,
                y: this.config.height[0],
                fillStyle: {
                    color: this.config.back[1],
                    alpha: 1
                }
            });
            this.bottomBack.fillRect(0, 0, this.config.width, this.config.height[1]);
            this.add(this.bottomBack);
        }

        this.xPointerText = TextClass.plainText1(this.scene, {
            x: this.config.width / 2,
            y: this.config.height[0] + this.config.height[1] - (this.scene.orientation == "pc" ? 8 : 12),
            text: "2021/12/27",
            color: "#ffffff",
            fontSize: this.scene.orientation == "pc" ? 16 : 20
        }).setOrigin(0.5, 1);
        this.add(this.xPointerText);
        
        this.valueDurationText = TextClass.plainText1(this.scene, {
            x: this.config.width - this.padding.left + 10,
            y: this.config.height[0] + this.config.height[1] - (this.scene.orientation == "pc" ? 8 : 12),
            text: "월간",
            color: "#777777",
            fontSize: this.scene.orientation == "pc" ? 16 : 20
        }).setOrigin(1, 1);
        this.add(this.valueDurationText);

        this.valueText = TextClass.plainText1(this.scene, {
            x: this.config.width - this.padding.left - this.valueDurationText.width + 10,
            y: this.config.height[0] + this.config.height[1] - (this.scene.orientation == "pc" ? 8 : 12),
            text: "+300G",
            color: "#777777",
            fontSize: this.scene.orientation == "pc" ? 16 : 20
        }).setOrigin(1, 1);
        this.add(this.valueText);


        this.chartContainer = this.scene.add.container(0, 0);
        this.add(this.chartContainer);
    }

    setData(data) {
        let xLength = this.xLength[this.type];
        if(xLength == 0 || xLength == undefined) return;
        this.chartContainer.removeAll(true);
        let chartWidth = 16;
        let yMax = Math.max(...data);
        for(let i = 0 ; i < xLength ; i++) {
            let xValue = TextClass.plainText1(this.scene, {
                x: this.config.width - this.padding.left - i * (this.config.width - 2 * this.padding.left) / (xLength - 1),
                y: this.config.height[0] + 8,
                text: this.getXValue(i),
                color: "#ffffff",
                fontSize: this.scene.orientation == "pc" ? 14 : 18
            }).setOrigin(0.5, 0);
            this.chartContainer.add(xValue);
            
            let yValue = data[i];
            if(yValue == undefined) continue;
            let candle = this.scene.add.graphics({
                x: this.config.width - this.padding.left - i * (this.config.width - 2 * this.padding.left) / (xLength - 1),
                y: this.config.height[0] / 2,
                fillStyle: {
                    color: yValue < 0 ? 0xd70056 : 0x00e793,
                    alpha: 1
                }
            });
            let yStart = 0;
            let yHeight = -(this.config.height[0] / 2 - this.padding.top) * yValue / yMax;
            if(yHeight < 0) {
                yStart = yHeight;
                yHeight *= -1;
            }
            let rect = new Phaser.Geom.Rectangle(-chartWidth / 2, yStart, chartWidth, yHeight);
            candle.fillRectShape(rect);
            this.chartContainer.add(candle);
            candle.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
            candle.on("pointerdown", () => {
                this.onClickedCandle(i, yValue);
            });
        }
        this.xPointer = this.scene.add.triangle(0, this.config.height[0], - chartWidth / 2, -3, chartWidth / 2, -3, 0, -chartWidth, 0xd70056);
        this.xPointer.setOrigin(0, 0);
        this.chartContainer.add(this.xPointer);
        this.onClickedCandle(0, data[0]);
    }

    getXValue(past) {
        let today = new Date();
        let value = 0;
        if(this.type == "month") {
            today.setMonth(today.getMonth() - past);
            value = today.getMonth() + 1;
        } else {
            today.setDate(today.getDate() - past);
            value = today.getDate();
        }
        return ("00" + value).substring(value.toString().length);
    }

    onClickedCandle(past, value) {
        let xLength = this.xLength[this.type];
        if(this.xPointer) {
            this.xPointer.setFillStyle(value < 0 ? 0xd70056 : 0x00e793)
            this.xPointer.x = this.config.width - this.padding.left - past * (this.config.width - 2 * this.padding.left) / (xLength - 1);
        }
        let dateText = "";
        let date = new Date();
        if(this.type == "month") {
            date.setMonth(date.getMonth() - past);
            dateText = date.getFullYear() + "/" + (date.getMonth() + 1);
        } else {
            date.setDate(date.getDate() - past);
            dateText = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
        }
        this.xPointerText.setText(dateText)
        this.valueText.setText((value < 0 ? "" : "+") + NumberUtil.convertNumberWithComma(value) + "php");
        this.valueText.setColor(value < 0 ? "#d70056" : "#00e793");
        let durationText = "";
        if(this.type == "month") durationText = "월간";
        else durationText = "하루";
        this.valueDurationText.setText(durationText);
    }
}