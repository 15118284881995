export default {
    HEARTBEAT                   : 0,
    LOGIN                       : 1,
    LOGINRESPONSECODE: {
        OK                      : 1,
        ALREADYLOGIN            : 2,
        IDPASSWORDMISMATCH      : 3,
        DISABLED                : 4,
        CHECKING                : 5,
        PENDING                 : 6
    },
    SIGNUP                      : 2,
    SIGNUOCODE: {
        IDCHECK                 : 0,
        NICKNAMECHECK           : 1,
        PHONENUMBER             : 2,
        VERRIFYCODE             : 3,
        FINAL                   : 4
    },
    SIGNUPRESPONSECODE: {
        SUCCESS                 : 0,
        FAIL                    : 1,
        FAIL_CHECK_USERID       : 2,        //중복되는 아이디 있음
        INVALID_USERID          : 3,        //아이디에 한글또는 특수문자가 포함되어잇음, 빈문자열
        FAIL_CHECK_NICKNAME     : 4,        //닉네임 중복
        INVALID_NICKNAME        : 5,        //닉네임은 한글만 가능
        FAIL_CHECK_PHONE        : 6,        //폰번호 중복됨
        INVALID_PHONE           : 7,        //폰번호 형식이 맞지 않음
        FAIL_CHECK_CODE         : 8,        // 인증코드가 맞지 않음
        INVALID_PASSWORD        : 9,
    },
    SERVICEINFO                 : 3,        // 서비스정보
    USERINFO                    : 4,        // 유저정보
    UPLOADAVATAR                : 5,        // 프로필 이미지 업로드
    CHANGEPASSWORD              : 6,        // 비밀번호 변경
    CHANGENICKNAME              : 7,        // 닉네임변경
    BORDER                      : 8,        // 보더
    BORDERCODE: {
        LIST                    : 0,        // 리스트
        USE                     : 1         // 사용
    },
    DOUBLELOGIN                 : 9,        // 중복로그인
    NOTICELIST                  : 10,       // 공지사항
    PAYIN                       : 11,       // 충전
    PAYINCODE: {
        LIST                    : 0,        // 충전내역
        CREATE                  : 1         // 충전요청
    },
    PAYOUT                      : 12,       // 환전
    PAYOUTCODE: {
        LIST                    : 0,        // 환전내역
        CREATE                  : 1         // 환전요청
    },
    DEPOSIT                     : 13,       // 입금,
    WITHDRAW                    : 14,       // 출금
    POINTEXCHANGE               : 15,       // 포인트전환
    POINTEXCHANGECODE: {
        LIST                    : 0,        // 포인트전환내역
        CREATE                  : 1         // 포인트전환요청
    },
    PRESENTEVENT                : 16,       // 출석이벤트
    PRESENTEVENTCODE: {
        LIST                    : 0,        // 출석이벤트리스트
        ACCEPT                  : 1         // 출석이벤트 수령
    },
    STOREHISTORY                : 17,       // 금고내역
    SHOP                        : 18,       // 상점아이템
    SHOPCODE: {
        LIST                    : 0,        // 리스트
        BUY                     : 1         // 구매
    },
    CUSTOMER                    : 19,       // 고객센터
    CUSTOMERCODE: {
        LIST                    : 0,        // 리스트
        CREATE                  : 1         // 질문 작성
    },
    INVENTORY                   : 20,       // 인벤토리
    INVENTORYCODE: {
        LIST                    : 0,        // 리스트
        USE                     : 1         // 사용
    },
    TABLETEMA                   : 21,       // 테이블테마
    TABLETEMACODE: {
        LIST                    : 0,
        USE                     : 1
    },
    CARDTEMA                    : 22,       // 카드테마
    CARDTEMACODE: {
        LIST                    : 0,
        USE                     : 1
    },

    GETLOBBYROOMLIST            : 23,       // 로비 리스트
    CHECKBUYIN                  : 24,       // 바이인 dlg에 필요한 자료
    GETTOURNAMENTLIST           : 25,       // 토너먼트 리스트
    GETTOURNAMENTDETAIL         : 26,       // 토너먼트 정보
    CREATEROOM                  : 30,       // 방창조
    JOINROOM                    : 31,       // 입장
    QUICKJOINROOM               : 32,       // 빠른입장
    GAMEHISTORY                 : 33,       // 게임 이력
    USERPROFILEDATA             : 34,       // 유저 프로필(전적)
    USERPROFILEREPORT           : 35,       // 나의 전적 리포트

    TOURNAMENTALARM             : 36,       // 토너먼트 알림메시지
    GETTOURNAMENTBYTICKET       : 37,       // 토너먼트 티켓으로 등록 가능한 토너 리스트
    TOURNAMENTRESULT            : 39,       // 토너먼트 결과

    GETTOURNAMENTENTRY          : 40,       // 토너먼트 엔트리
    GETTOURNAMENTTABLE          : 41,       // 토너먼트 테이블
    GETTOURNAMENTPRIZE          : 42,       // 토너먼트 상금
    GETTOURNAMENTBLINDS         : 43,       // 토너먼트 블라인드 구조
    CANCELTOURNAMENTREBUY       : 44,       // 토너먼트 리바이 취소
    REGISTERTOURNAMENT          : 45,       // 토너먼트 등록
    ENTERTOURNAMENT             : 46,       // 토너먼트 입장
    OBSERVINGTOURNAMENT         : 47,       // 토너먼트 관전
    UNREGISTERTOURNAMENT        : 48,       // 토너먼트 등록취소
    TOURNAMENTHOLDEMADDON       : 49,       // 토너먼트 애드온

    DISCONNECT                  : 50,       // 강제로그아웃
    
    REPORT                      : 60,       // 신고
    NOTE                        : 61,       // 쪽지
    NOTECODE: {
        LIST                    : 0,
        READ                    : 2,
        REMOVE                  : 3
    },

    // 홀덤 게임장

    HOLDEMROOMINFO              : 100,
    HOLDEMROOMEXIT              : 101,
    HOLDEMMOVETURN              : 102,
    HOLDEMBETTING               : 103,
    HOLDEMSHOWDOWN              : 104,
    HOLDEMGAMEOVER              : 106,
    HOLDEMMESSAGE               : 107,
    HOLDEMRABBIT                : 108,
    HOLDEMTIMEBANK              : 109,
    HOLDEMEMOTICON              : 110,
    HOLDEMBLOCKUSER             : 111,
    HOLDEMPUBLISHFOLDCARD       : 112,

    OMAHAROOMINFO               : 120,
    OMAHAROOMEXIT               : 121,
    OMAHAMOVETURN               : 122,
    OMAHABETTING                : 123,
    OMAHASHOWDOWN               : 124,
    OMAHAGAMEOVER               : 126,
    OMAHAMESSAGE                : 127,
    OMAHARABBIT                 : 128,
    OMAHATIMEBANK               : 129,
    OMAHAEMOTICON               : 130,
    OMAHABLOCKUSER              : 131,
    OMAHAPUBLISHFOLDCARD        : 132,

    TOURNAMENTTABLEINFO         : 200,
    TOURNAMENTTABLEEXIT         : 201,
    TOURNAMENTTABLEMOVETURN     : 202,
    TOURNAMENTTABLEBETTING      : 203,
    TOURNAMENTTABLESHOWDOWN     : 204,
    TOURNAMENTTABLERESERVEBET   : 205,
    TOURNAMENTTABLEGAMEOVER     : 206, // 라운드 게임오버
    TOURNAMENTTABLEGAMEMESSAGE  : 207,
    TOURNAMENTTABLERABBIT       : 208,
    TOURNAMENTTABLETIMEBANK     : 209,
    TOURNAMENTTABLEEMOTICON     : 210,
    IMBACK                      : 211,
    TOURNAMENTBLOCKUSER         : 212,
    TOURNAMENTPUBLISHFOLDCARD   : 213,

}