import { TextClass } from "../text/text";

export class ContentCard extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        if(this.config.back) {
            this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.back);
            this.back.setOrigin(0.5, 0);
            this.add(this.back);
            if(this.config.scale) this.back.setScale(this.config.scale[0], this.config.scale[1]);
        }

        if(this.config.light) {
            this.light = this.scene.add.sprite(0, 0, this.config.atlas, this.config.light);
            this.light.setOrigin(0.5, 0.5);
            this.add(this.light);
        }

        if(this.config.title) {
            if(this.config.title.texture) {
                this.title = this.scene.add.sprite(0, this.config.title.y, this.config.atlas, this.config.title.texture);
                this.title.setOrigin(0.5, 0.5);
                this.add(this.title);
            }
            if(this.config.title.text) {
                this.title = TextClass.plainText1(this.scene, {
                    x: 0,
                    y: this.config.title.y,
                    text: this.config.title.text,
                    fontFamily: this.config.title.fontFamily,
                    fontSize: this.config.title.fontSize,
                    color: this.config.title.color,
                    gradient: this.config.title.gradient
                });
                if(this.config.title.origin) {
                    this.title.setOrigin(0.5, 0.5);
                } else {
                    this.title.setOrigin(0.5, 0);
                }
                this.add(this.title);
            }
        }
    }
}