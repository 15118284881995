var BufferPack = require('./BufferPack')
class ByteArray {
    //bufferPack = null;
    constructor(__endian) {
        this._endian = __endian
        this._buf = []
        this._pos = 0
        this.bufferPack = new BufferPack()
    };
    //toString = function (self, __radix, __separator) {
    toString(self, __radix, __separator) {
        __radix = __radix || 16
        __radix = ByteArray.radix[__radix] || "%02X"
        __separator = __separator || " "
        var __fmt = __radix + __separator
        var __format = function (__s) {
            return formatStr(__fmt, __s)
        }.bind(this)
        var bytesToHex = function (bytes) {
            for (var hex = [], i = 0; i < bytes.length; i++) {
                hex.push((bytes[i] >>> 4).toString(16));
                hex.push((bytes[i] & 0xF).toString(16));
            }
            return hex.join("");
        }.bind(this)
        if (typeof self == "String") {
            return self.replace("(.)", __format)
        }


        // var __bytes = []
        // for(var i = 0; i < this._buf.length; i++){
        //     __bytes[i] = __format(this._buf[i])
        // }
        //var __bytes = bytesToHex(this._buf)

        return ByteArray.arrayContact(this._buf)
        //return this._buf
    };
    getLen() {
        return this._buf.length
    };
    //getAvailable = function () {
    getAvailable () {
        return this._buf.length - this._pos
    };
    //getPos = function () {
    getPos () {
        return this._pos
    };
    //setPos = function (__pos) {
    setPos (__pos) {
        this._pos = __pos
        return this
    };
    //getEndian = function () {
    getEndian () {
        return this._endian
    };
    //getBytes = function (__offset, __length) {
    getBytes (__offset, __length) {
        __offset = __offset || 0
        __length = __length || this._buf.length
        return ByteArray.arrayContact(this._buf, "", __offset, __length)
    };
    //getPack = function (__offset, __length) {
    getPack (__offset, __length) {
        __offset = __offset || 0
        __length = __length || this._buf.length
        var __t = []
        for (var i = __offset; i < __length; i++) {
            //__t.push(this._buf[i].charCodeAt(0))
            __t.push(this._buf[i])
        }

        var __fmt = this._getLC("b" + __t.length)

        var __s = this.bufferPack.pack(__fmt, __t)
    };
    //rawPack = function (__fmt, values) {
    rawPack (__fmt, values) {
        var __s = this.bufferPack.pack(__fmt, values, "header")//updated by bismarck
        this.writeBuf(__s)
        return this
    };
    //rawUnPack = function (__fmt) {
    rawUnPack (__fmt) {
        var __s = this.getBytes(this._pos)
        var __val = thiws.bufferPack.unpack(__fmt, __s)
        this._pos = this._pos + __s.length
        return [__val, __s.length]
    };
    //readBool = function () {
    readBool () {
        return this.readChar() != 0
    };
    //writeBool = function (__bool) {
    writeBool (__bool) {
        if (__bool) {
            this.writeByte(1)
        } else {
            this.writeByte(0)
        }
        return this
    };
    //readDouble = function () {
    readDouble () {
        var __val = this.bufferPack.unpack(this._getLC("d"), this.readBuf(8))
        return __val[0]
    };
    //writeDouble = function (__double) {
    writeDouble (__double) {
        var __s = this.bufferPack.pack(this._getLC("d"), [__double])
        this.writeBuf(__s)
        return this
    };
    //readFloat = function () {
    readFloat () {
        var __val = this.bufferPack.unpack(this._getLC("f"), this.readBuf(4))
        return __val[0]
    };
    //writeFloat = function (__float) {
    writeFloat (__float) {
        var __s = this.bufferPack.pack(this._getLC("f"), [__float])
        this.writeBuf(__s)
        return this
    };
    //readInt = function () {
    readInt () {
        var __val = this.bufferPack.unpack(this._getLC("i"), this.readBuf(4))
        return __val[0]
    };
    //writeInt = function (__int) {
    writeInt (__int) {
        var __s = this.bufferPack.pack(this._getLC("i"), [__int])
        this.writeBuf(__s)
        return this
    };
    //readInt64 = function () {
    readInt64 () {
        var __val = this.bufferPack.unpack(this._getLC("l"), this.readBuf(8))
        return __val[0]
    };
    //writeInt64 = function (__int64) {
    writeInt64 (__int64) {
        var __s = this.bufferPack.pack(this._getLC("l"), [__int64])
        this.writeBuf(__s)
        return this
    };
    //readUInt = function () {
    readUInt () {
        var __val = this.bufferPack.unpack(this._getLC("I"), this.readBuf(4))
        return __val[0]
    };
    //writeUInt = function (__uint) {
    writeUInt (__uint) {
        var __s = this.bufferPack.pack(this._getLC("I"), [__uint])
        this.writeBuf(__s)
        return this
    };
    //readShort = function () {
    readShort () {
        var __val = this.bufferPack.unpack(this._getLC("h"), this.readBuf(2))
        return __val[0]
    };
    //writeShort = function (__short) {
    writeShort (__short) {
        var __s = this.bufferPack.pack(this._getLC("h"), [__short])
        this.writeBuf(__s)
        return this
    };
    //readUShort = function () {
    readUShort () {
        var __val = this.bufferPack.unpack(this._getLC("H"), this.readBuf(2))
        return __val[0]
    };
    //writeUShort = function (__ushort) {
    writeUShort (__ushort) {
        var __s = this.bufferPack.pack(this._getLC("H"), [__ushort])
        this.writeBuf(__s)
        return this
    };
    //readUByte = function () {
    readUByte () {
        var __val = this.bufferPack.unpack("b", this.readRawByte())
        return __val[0]
    };
    //writeUByte = function (__ubyte) {
    writeUByte (__ubyte) {
        var __s = this.bufferPack.pack("b", [__ubyte])
        this.writeBuf(__s)
        return this
    };
    //readLuaNumber = function (__number) {
    readLuaNumber (__number) {
        var __val = this.bufferPack.unpack(this._getLC("n"), this.readBuf(8))
        return __val[0]
    };
    //writeLuaNumber = function (__number) {
    writeLuaNumber (__number) {
        var __s = this.bufferPack.pack(this._getLC("n"), [__number])
        this.writeBuf(__s)
        return this
    };
    //readStringBytes = function (__len) {
    readStringBytes (__len) {
        if (__len == 0) {
            return ""
        }
        this._checkAvailable()
        // var flagIndex = ""
        // for(var i = 0 ; i < __len; i++){
        //     flagIndex = flagIndex + "A"
        // }
        var __val = this.bufferPack.unpack(this._getLC("S"), this.readBuf(__len))
        return __val[0]
    };
    //utf8_from = function (t) {
    utf8_from (t) {
        var bytearr = []
        for (var i in t) {
            var utf8byte = t[i] < 0 && (0xff + t[i] + 1) || t[i]

            bytearr.push(utf8byte.charCodeAt(0))
        }
        return ByteArray.arrayContact(bytearr)
    };
    //writeStringBytes = function (__string) {
    writeStringBytes (__string) {
        var __s = this.bufferPack.pack(this._getLC("A", [__string]))
        this.writeBuf(__s)
        return this
    };
    //readString = function (__len) {
    readString (__len) {
        if (__len == 0) {
            return ""
        }
        this._checkAvailable()
        return this.readBuf(__len)
    };
    //writeString = function (__string) {
    writeString (__string) {
        this.writeBuf(__string)
        return this
    };
    //readStringUInt = function () {
    readStringUInt () {
        this._checkAvailable()
        var __len = this.readUInt()
        return this.readStringBytes(__len)
    };
    //writeStringUInt = function (__string) {
    writeStringUInt (__string) {
        this.writeUInt(__string.length)
        this.writeStringBytes(__string)
        return this
    };
    //readStringUShort = function () {
    readStringUShort () {
        this._checkAvailable()
        var __len = this.readUShort()
        return this.readStringBytes(__len)
    };
    //writeStringUShort = function (__string) {
    writeStringUShort (__string) {
        var __s = this.bufferPack.pack(this._getLC("S"), ["" + __string])
        this.writeBuf(__s)
        return this
    };
    //readBytes = function (__bytes, __offset, __length) {
    readBytes (__bytes, __offset, __length) {
        var __selfLen = this._buf.length
        var __availblenLen = __selfLen - this._pos
        __offset = __offset || 0
        if (__offset > __selfLen - 1) {
            __offset = 0
        }
        __length = __length || 0
        if (__length == 0 || __length > __availblenLen) {
            __length = __availblenLen
        }
        __bytes.setPos(__offset)
        for (var i = __offset; i < __offset + __length; i++) {
            __bytes.writeRawByte(this.readRawByte())
        }
    };
    //writeBytes = function (__bytes, __offset, __length) {
    writeBytes (__bytes, __offset, __length) {
        var __bytesLen = __bytes.getLen()
        if (__bytesLen == 0) {
            return
        }
        __offset = __offset || 0
        if (__offset > __bytesLen - 1) {
            __offset = 0
        }
        var __availableLen = __bytesLen - __offset
        __length = __length || __availableLen
        if (__length == 0 || __length > __availableLen) {
            __length = __availableLen
        }

        var __oldPos = __bytes.getPos()
        __bytes.setPos(__offset)
        for (var i = __offset; i < __offset + __length; i++) {
            this.writeRawByte(__bytes.readRawByte())
        }

        __bytes.setPos(__oldPos)
        return this
    };
    //readChar = function () {
    readChar () {
        var __val = this.bufferPack.unpack("c", [this.readRawByte()])
        return __val[0]
    };
    //writeChar = function (__char) {
    writeChar (__char) {
        this.writeRawByte(this.bufferPack.pack("c", [__char]))
        return this
    };
    //readByte = function () {
    readByte () {
        return this.readRawByte()
    };
    //writeByte = function (__byte) {
    writeByte (__byte) {
        this.writeRawByte(__byte)
        return this
    };
    //readRawByte = function () {
    readRawByte () {
        this._checkAvailable()
        var __byte = this._buf[this._pos]
        this._pos = this._pos + 1
        return __byte
    };
    //writeRawByte = function (__rawByte) {
    writeRawByte (__rawByte) {
        if (this._pos > this._buf.length) {
            for (var i = this._buf.length; i >= this._pos - 1; i++) {
                this._buf[i] = "0".charCodeAt(0)
            }
        }

        //this._buf[this._pos] = __rawByte.substr(0, 1)
        if ("string" == typeof __rawByte) {
            this._buf[this._pos] = __rawByte.substr(0, 1)
        } else {
            this._buf[this._pos] = __rawByte
        }
        this._pos = this._pos + 1
        return this
    };
    //readBuf = function (__len) {
    readBuf (__len) {
        var __ba = this.getBytes(this._pos, this._pos + __len)
        this._pos = this._pos + __len
        return __ba
    };
    //writeBuf = function (__s) {
    writeBuf (__s) {
        for (var i = 0; i < __s.length; i++) {
            //this.writeRawByte(__s.substring(i, i+1))
            this.writeRawByte(__s[i])
        }
        return this
    };
    //_checkAvailable = function () {
    _checkAvailable () {
        //cc.assert(this._buf >= this._pos, cc.formatStr("End of file was encountered. pos = %d, len = %d.", this._pos, this._buf.length))
    };
    //_getLC = function (__fmt) {
    _getLC (__fmt) {
        __fmt = __fmt || ""
        if (this._endian == ByteArray.ENDIAN_LITTLE) {
            return "<" + __fmt
        } else if (this._endian == ByteArray.ENDIAN_BIG) {
            return ">" + __fmt
        }
        return "=" + __fmt
    }
}

ByteArray.ENDIAN_LITTLE = "ENDIAN_LITTLE"
ByteArray.ENDIAN_BIG = "ENDIAN_BIG"
ByteArray.radix = {10 :"%03u",8 :"%03o",16 :"%02X"}


ByteArray.arrayContact = function (__array, __template, __offset, __length) {
    if(__offset == null){
        __offset = 0
    }
    if(__length == null){
        __length = __array.length
    }
    arr = []
    for(var i = __offset; i < __length; i++){
        arr.push(__array[i])
    }
    return arr
}

ByteArray.pack = function(bytes) {
    var str = "";
// You could make it faster by reading bytes.length once.
    for(var i = 0; i < bytes.length; i += 2) {
// If you're using signed bytes, you probably need to mask here.
        var char = bytes[i] << 8;
// (undefined | 0) === 0 so you can save a test here by doing
//     var char = (bytes[i] << 8) | (bytes[i + 1] & 0xff);
        if (bytes[i + 1])
            char |= bytes[i + 1];
// Instead of using string += you could push char onto an array
// and take advantage of the fact that String.fromCharCode can
// take any number of arguments to do
//     String.fromCharCode.apply(null, chars);
        str += String.fromCharCode(char);
    }
    return str;
}

ByteArray.unpack = function(str) {
    var bytes = [];
    for(var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
// You can combine both these calls into one,
//    bytes.push(char >>> 8, char & 0xff);
        bytes.push(char >>> 8);
        bytes.push(char & 0xFF);
    }
    return bytes;
}
module.exports = ByteArray