import { TextClass } from "../../../components/text/text";
import CommonDef from "../../../def/commondef";
import * as DateTimeUtil from '../../../util/datetime';

export class NoteItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.collapsed = true;
        this.atlas = "DialogAtlas";
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.atlas, "row_title_back_" + this.scene.orientation + ".png");
        this.back.setOrigin(0, 0);
        this.add(this.back);
        let summaryText = this.config.content;
        summaryText = summaryText.replaceAll('\n', ' ');
        let limit = this.scene.orientation == "pc" ? 24 : 18;
        if(summaryText.length > limit) summaryText = summaryText.substring(0, limit - 1) + "...";
        this.summary = TextClass.plainText1(this.scene, {
            x: 36,
            y: this.scene.orientation == "pc" ? 28 : 40,
            text: summaryText,
            fontSize: this.scene.orientation == "pc" ? 24 : 22,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0);
        this.add(this.summary);

        this.collapsed_height = 28 * 2 + this.summary.height;
        this.back.setScale(1, this.collapsed_height / this.back.height);
        this.height = this.collapsed_height;

        this.date = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 580 : 36,
            y: this.scene.orientation == "pc" ? 32 : 15,
            text: DateTimeUtil.convertShotDateWithDot(this.config.createdat),
            fontSize: this.scene.orientation == "pc" ? 20 : 16,
            color: "#777777"
        }).setOrigin(0, 0);
        this.add(this.date);

        this.status = this.scene.add.sprite(this.scene.orientation == "pc" ? 710 : 378, this.scene.orientation == "pc" ? 21 : 27, this.atlas, (this.config.isread ? "read" : "unread") + "_" + this.scene.tema + ".png");
        this.status.setOrigin(0, 0);
        this.add(this.status);
        if(this.config.isread) {
            this.btn_remove = this.scene.add.sprite(this.scene.orientation == "pc" ? 810 : 478, this.scene.orientation == "pc" ? 21 : 27, this.atlas, "remove_" + this.scene.tema + ".png");
            this.btn_remove.setOrigin(0, 0);
            this.add(this.btn_remove);
        }

        this.arrow_icon = this.scene.add.sprite(this.scene.orientation == "pc" ? 922 : 588, this.scene.orientation == "pc" ? 34 : 34, this.atlas, "arrow_down.png");
        this.arrow_icon.setOrigin(0, 0);
        this.add(this.arrow_icon);

        this.content = TextClass.plainText1(this.scene, {
            x: 36,
            y: this.summary.y + this.summary.height + (this.scene.orientation == "pc" ? 30 : 40),
            text: this.config.content,
            fontSize: 20,
            color: "#777777"
        }).setOrigin(0, 0);
        this.content.setStyle({
            wordWrap: { width: this.scene.orientation == "pc" ? 860 : 540, useAdvancedWrap: true }
        });
        this.content.setLineSpacing(12);
        this.add(this.content);
        this.content.setVisible(false);

        this.expand_height = this.content.y + this.content.height + (this.scene.orientation == "pc" ? 20 : 30); 
    }

    onClick(x, y) {
        if(this.config.isread && !!this.btn_remove) {
            if(x > this.btn_remove.x && x < this.btn_remove.x + this.btn_remove.width &&
               y > this.btn_remove.y && y < this.btn_remove.y + this.btn_remove.height) {
                CommonDef.gameNet.removeNote(this.config.id);
                return;
            }
        }
        if(this.collapsed && !this.config.isread) {
            setTimeout(() => {
                try {
                    CommonDef.gameNet.readNote(this.config.id);
                } catch (e) {

                }
            }, 3000);
        }
        this.collapsed = !this.collapsed;
        this.arrow_icon.setTexture(this.atlas, "arrow_" + (this.collapsed ? "down" : "up") +".png");
        this.height = this.collapsed ? this.collapsed_height : this.expand_height;
        this.back.setTexture(this.atlas, "row_" + (this.collapsed ? "title" : "content") + "_back_" + this.scene.orientation + ".png")
        this.back.setScale(1, (this.collapsed ? this.collapsed_height : this.expand_height) / this.back.height);
        this.config.arrangeContent();
        this.content.setVisible(!this.collapsed)
    }

    setNoteRead() {
        this.config.isread = true;
        this.status.setTexture(this.atlas, "read_" + this.scene.tema + ".png");
        if(!!this.btn_remove) return;
        this.btn_remove = this.scene.add.sprite(this.scene.orientation == "pc" ? 810 : 478, this.scene.orientation == "pc" ? 21 : 27, this.atlas, "remove_" + this.scene.tema + ".png");
        this.btn_remove.setOrigin(0, 0);
        this.add(this.btn_remove);
    }
}