import { LoadingScene } from '../scenes/loading.js';
import { PreloadScene } from '../scenes/preload.js';

let preloadScene = new PreloadScene();
let loadingScene = new LoadingScene();

export default {
    pc: {
        type: Phaser.CANVAS,  //Phaser will decide how to render our game (WebGL or Canvas)
        width: 1920,
        height: 1080,
        parent : 'main',
        // dom: { createContainer: true },
        scene:[preloadScene, loadingScene/*, loginScene, registerScene, waitingRoomScene, lobbyScene, holdemScene*/],
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            orientation: Phaser.Scale.Orientation.LANDSCAPE
        },
        fps: 60
    },
    mobile: {
        type: Phaser.CANVAS,  //Phaser will decide how to render our game (WebGL or Canvas)
        parent : 'main',
        // dom: { createContainer: true },
        scene:[preloadScene, loadingScene/*, loginScene, registerScene, waitingRoomScene, lobbyScene, holdemScene*/],
        scale: {
            mode: Phaser.Scale.NONE,
            // autoCenter: Phaser.Scale.CENTER_BOTH,
            orientation: Phaser.Scale.Orientation.PORTRAIT
        },
        fps: 60
    }
};