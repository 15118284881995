import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class VIPCardUseConfirm extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'login_form_back.png',
            title: {
                text: this.config.name,
                y: 60,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.description = TextClass.plainText1(this.scene, {
            x: 0,
            y: 160,
            text: translate('use_vip_card_directly'),
            fontSize: 28,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.description.setLineSpacing(15);
        this.cardContainer.add(this.description);

        this.btn_cancel = new IconButton(this.scene, {
            x: -242,
            y: 260,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_register_back_normal.png'
            },
            hover: {
                back: 'btn_register_back_hover.png'
            },
            clicked: {
                back: 'btn_register_back_clicked.png'
            },
            text: {
                x: 85,
                y: 16,
                value: translate('cancel'),
                fontSize: 32,
                gradient: ['#ffe2d1', '#ffffff']
            },
            onClicked: this.close.bind(this)
        })
        this.cardContainer.add(this.btn_cancel);

        this.btn_use = new IconButton(this.scene, {
            x: 10,
            y: 260,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_login_back_normal.png'
            },
            hover: {
                back: 'btn_login_back_hover.png'
            },
            clicked: {
                back: 'btn_login_back_clicked.png'
            },
            text: {
                x: 55,
                y: 16,
                value: translate('use_directly'),
                fontSize: 32,
                gradient: ['#ffe2d1', '#ffffff']
            },
            onClicked: this.onClickBtnUse.bind(this)
        })
        this.cardContainer.add(this.btn_use);
    }

    close() {
        this.destroy();
    }

    onClickBtnUse() {
        this.scene.requestInventoryUse(this.config.inventoryId);
        this.close();
    }
}