import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { TextClass } from "../../../components/text/text";

export class VIPConfirmDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.fillRect(-10, -10, this.scene.orientation == "pc" ? 950 : 600, this.scene.orientation == "pc" ? 540 : 1140);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 530 : 1110);
        this.add(this.backGraphic);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.mainContainer = this.scene.add.container(this.scene.orientation == "pc" ? 300 : 0, this.scene.orientation == "pc" ? 32 : 0);
        this.add(this.mainContainer);
        this.border = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.border.lineStyle(1, 0x222222, 1);
        this.border.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 400 : 590, this.scene.orientation == "pc" ? 460 : 475, 10);
        this.mainContainer.add(this.border);
        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 23 : 24,
            text: "VIP카드 사용",
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.mainContainer.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 340 : 525,
            y: this.scene.orientation == "pc" ? 21 : 22,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_close);

        this.icon = this.scene.add.sprite(this.scene.orientation == "pc" ? 200 : 295, 154, this.config.atlas, this.config.icon);
        this.icon.setOrigin(0.5, 0.5);
        this.mainContainer.add(this.icon);
        this.icon.setScale(1.5);

        this.description1 = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 200 : 295,
            y: 235,
            text: "현재 사용중인 VIP카드를 무효화하고",
            fontSize: 20,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.mainContainer.add(this.description1);

        this.description2 = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 200 : 295,
            y: 270,
            text: "새 VIP카드를 사용하시겠습니까?",
            fontSize: 20,
            color: "#777777"
        }).setOrigin(0.5, 0);
        this.mainContainer.add(this.description2);

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 103 : 196,
            y: this.scene.orientation == "pc" ? 350 : 350,
            atlas: this.config.atlas,
            normal: {
                back: "btn_buy_back_normal.png"
            },
            hover: {
                back: "btn_buy_back_hover.png"
            },
            clicked: {
                back: "btn_buy_back_clicked.png"
            },
            scale: [1.38, 1.52],
            text: {
                x: 70 * 1.38,
                y: 10 * 1.52,
                value: "확인",
                fontSize: 28,
                gradient: ['#ffe2d2', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: () => {
                this.config.onConfirm();
                this.close();
            }
        });
        this.mainContainer.add(this.btn_confirm);
    }

    close() {
        this.destroy();
    }
}