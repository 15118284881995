export default {
    ScreenSize: {
        pc: {
            width: 1920,
            height: 1080
        },
        mobile: {
            width: 750,
            height: 1350
        }
    },

    ClientSize: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
    },
    
    DeviceType: {
        WindowsDesktop: 0,
        AndroidPhone: 1,
        iPhone: 2
    },
    Games: {
        Holdem: 0,
        Omaha: 1,
    },

    // ServerIP: "gfsdfsdfasfasfasf44323.xyz",
    // APIServer: "http://fawqyrfh13fhfshfosjmc.xyz",
    // LandingUrl: "https://shine-22.com/",
    // ServerPORT: "2083",
    // Env: "production",

    ServerIP: "server.shinepoker.online",
    APIServer: "https://api.shinepoker.online",
    LandingUrl: "https://landing.shinepoker.online",
    // ServerPORT: "2083",
    Env: "production",

    // ServerIP: "192.168.88.32",
    // APIServer: "http://localhost:4544",
    // LandingUrl: "https://shine-22.com/",
    // ServerPORT: "2083",
    // Env: "development",

    ServerTime: 0,
    Version: 1.30,
    SignupCode: "",
    Lang: "en",
    ServerMaintain: false,
    AppleUserIDList: [],
    AlarmStack: [],
    DoubleLogin: {},

    LoadingScene: "",

    orientation: "",
    ScreenModeChanged: false,
    currentScene: null,
    gameNet: null,
    
    toast: {
        pc: {
            x: 645,
            y: 20,
        },
        mobile: {
            x: 60,
            y: 20
        },
        icon: {
            x: 34,
            y: 22
        },
        text: {
            x: 105,
            y: 34
        },
        close: {
            x: 545,
            y: 32
        }
    },


    Dialog: {
        WaitingRoomScene: [],
        LobbyScene: [],
        tabIndex: 0
    },
    
    blinds: [{
        sb: 5,
        bb: 10
    }, {
        sb: 10,
        bb: 20
    }, {
        sb: 15,
        bb: 30
    }, {
        sb: 25,
        bb: 50
    }, {
        sb: 50,
        bb: 100
    }, {
        sb: 100,
        bb: 200
    }, {
        sb: 200,
        bb: 400
    }, {
        sb: 300,
        bb: 600
    }, {
        sb: 500,
        bb: 1000
    }, {
        sb: 1000,
        bb: 2000
    }, {
        sb: 2000,
        bb: 4000
    }, {
        sb: 5000,
        bb: 10000
    }, {
        sb: 10000,
        bb: 20000
    }],

    tournament_state: {
        WAITING: 0,
        STARTING: 1,
        REBUY: 2,
        ADDON: 3,
        MIDDLE: 4,
        BUBBLE: 5,
        FINAL: 6,
        FINISHED: 7
    },

    turnStartTime: 0,

    reportType: {
        ProfileReport: 0,
        MessageReport: 1
    },

    Me: {
        loggedin: false,
        loginid: "me",
        nickname: "",
        avatar: "",
        money: 0,
        storemoney: 0,
        point: 0,
        dailybetting: 0,
        totalbetting: 0,
        borderid: 0,
        bordericon: "",
        tabletemaid: 0,
        tabletemaicon: "",
        cardtemaid: 0,
        cardtemaicon: "",
        bankname: "",
        accountnumber: "",
        ownername: "",
        eventlevel: 0,
        viplevel: 0,
        vipstarttime: 0,
        vipduration: 0,
        emoticonCount: 0,
        timeBankCount: 0,
        rabbitEnable: 0,
        isGaming: -1,
        unreadNoteCount: 0,
        chatpossible: true
    },

    soundStack: [],

    Sounds: [
        {
            key: "BtnClick",
            file: "sounds/btn_click.mp3",
            type: "effect"
        }
    ],
    animations: {
        loading: { atlas: "LoadingAtlas", prefix: "Pre-comp 1_", last: 50, repeat: -1, speed: 0.02, zeroPad: 5 },
    }
}