export class PreloadScene extends Phaser.Scene {
    constructor() {
        super({
            key: "PreloadScene"
        });
    }

    preload() {
        this.load.atlas('LoginAtlas_red', 'images/login_atlas_red.png', 'images/login_atlas_red.json');
        this.load.image('LoginBackground_pc', 'images/login_background_pc.png');
        this.load.image('LoginBackground_mobile', 'images/login_background_mobile.png');
        this.load.on("progress", (percent) => {
        });
    }

    create() {
        this.scene.start("LoadingScene");
    }
}