import { TextClass } from "../../../components/text/text";

export class BankItem extends Phaser.GameObjects.Container {
    constructor(scene, config){
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.id = this.config.id;
        this.title = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.config.height / 2,
            text: this.config.name,
            fontSize: 24,
            color: "#aaaaaa",
            fontFamily: "NanumSquareR"
        }).setOrigin(0, 0.5);
        this.add(this.title);

        this.height = this.config.height;
    }

    onClick() {
        this.config.onClicked(this.id);
    }
}