import { IconButton } from "../button/iconbutton";
import { TextClass } from "../text/text";
import * as NumberUtil from "../../util/number";

export class ShopItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let scale = this.config.scale || 1;
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.type < 4 ? "back_light.png" : "back.png");
        this.back.setOrigin(0, 0);
        this.back.setScale(scale);
        this.add(this.back);

        this.top_back = this.scene.add.sprite(this.back.width * scale / 2, this.scene.orientation == "pc" ? 16 : 21, this.config.atlas, "top_back.png");
        this.top_back.setOrigin(0.5, 0);
        this.add(this.top_back);
        this.top_back.setScale(scale);

        this.title_back = this.scene.add.sprite(this.back.width * scale / 2, 0, this.config.atlas, "title_back.png");
        this.title_back.setOrigin(0.5, 0);
        this.add(this.title_back);
        this.title_back.setScale(scale);

        this.title = TextClass.plainText1(this.scene, {
            x: this.title_back.width * scale / 2,
            y: this.scene.orientation == "pc" ? 20 : 25,
            text: this.config.title,
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 18 : 22,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0.5, 0.5);
        this.add(this.title);

        this.icon = this.scene.add.sprite(this.back.width * scale / 2, (this.back.height - 10) * scale / 2, this.config.atlas, this.config.icon);
        this.icon.setOrigin(0.5, 0.5);
        this.add(this.icon);
        this.icon.setScale(scale);

        this.description = TextClass.plainText1(this.scene, {
            x: this.back.width * scale / 2,
            y: this.scene.orientation == "pc" ? 200 : 240,
            text: this.config.description,
            fontSize: this.scene.orientation == "pc" ? 20 : 24,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.add(this.description);

        if(this.config.isBuyed) {
            let graphic = this.scene.add.graphics({
                x: this.scene.orientation == "pc" ? 40 : 48,
                y: this.scene.orientation == "pc" ? 230 : 276,
                fillStyle: {
                    color: 0x222222,
                    alpha: 1
                }
            });
            graphic.fillRoundedRect(0, 0, 142 * scale, 40 * scale, 20 * scale);
            this.add(graphic);
            let text = TextClass.plainText1(this.scene, {
                x: (this.scene.orientation == "pc" ? 40 : 48) + 70 * scale,
                y: (this.scene.orientation == "pc" ? 230 : 276) + 10 * scale,
                text: "구매함",
                fontSize: this.scene.orientation == "pc" ? 20 : 24,
                gradient: ['#ffe2d2', '#ffffff'],
            }).setOrigin(0.5, 0);
            this.add(text);
        } else {
            this.btn_buy = new IconButton(this.scene, {
                x: this.scene.orientation == "pc" ? 40 : 48,
                y: this.scene.orientation == "pc" ? 230 : 276,
                atlas: this.config.atlas,
                normal: {
                    back: "btn_buy_back_normal.png"
                },
                hover: {
                    back: "btn_buy_back_hover.png"
                },
                clicked: {
                    back: "btn_buy_back_clicked.png"
                },
                scale: [scale, scale],
                text: {
                    x: 70 * scale,
                    y: 10 * scale,
                    value: NumberUtil.convertNumberWithComma(this.config.price) + "php",
                    fontSize: this.scene.orientation == "pc" ? 20 : 24,
                    gradient: ['#ffe2d2', '#ffffff'],
                    origin: [0.5, 0]
                }
            });
            this.add(this.btn_buy);
        }
        this.height = this.back.height * scale;
        this.width = this.back.width * scale;
        if(!this.config.isBuyed) {
            this.click_area = {
                x: this.scene.orientation == "pc" ? 40 : 48,
                y: this.scene.orientation == "pc" ? 230 : 276,
                width: this.scene.orientation == "pc" ? 130 : 156,
                height: this.scene.orientation == "pc" ? 40 : 49
            }
        }
    }

    onClick() {
        this.config.onClicked && this.config.onClicked(this.config);
    }

    onHover() {
        this.btn_buy && this.btn_buy.setHover();
    }

    onRelease() {
        this.btn_buy && this.btn_buy.setRelease();
    }

    clickedEffect() {
        this.btn_buy && this.btn_buy.setClicked();
    }
}