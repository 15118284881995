import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { ShopItem } from "../../../components/container/shopitem";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { ShopCardDialog } from "./shop_card";
import { ShopItemDialog } from "./shop_item";
import CommonDef from '../../../def/commondef';

export class ShopDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }


    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        this.mainContainer = this.scene.add.container(0, 0);

        this.scrollViewContainer = new ScrollView(this.scene, {
            x: this.scene.orientation == "pc" ? -485 : 80,
            y: this.scene.orientation == "pc" ? 140 : 170,
            width: this.scene.orientation == "pc" ? 957 : 588,
            height: this.scene.orientation == "pc" ? 516 : 1130,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.mainContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 460 : 80,
                y: this.scene.orientation == "pc" ? 333 : 170
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 920 : 560,
                height: this.scene.orientation == "pc" ? 516 : 1130
            }
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.scrollViewContainer);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 450,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        // let index = CommonDef.Dialog.WaitingRoomScene.findIndex(x => x.key == "shop")
        // CommonDef.Dialog.WaitingRoomScene.splice(index, 1);
        // this.destroy();
        this.setVisible(false);
    }

    onClickBtnBuy(item) {
        if(item.isBuyed) return;
        if(item.type < 4) {
            this.shopItemDialog = new ShopCardDialog(this.scene, {
                x: this.scene.orientation == "pc" ? -500 : 80,
                y: this.scene.orientation == "pc" ? 138 : 170,
                atlas: this.config.atlas,
                id: item.id,
                card_type: item.type,
                price: item.price,
                availableDays: item.availableDays,
                onClickBuy: this.requestBuyShopItem.bind(this)
            });
            (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.shopItemDialog);
        } else {
            this.shopItemDialog = new ShopItemDialog(this.scene, {
                x: this.scene.orientation == "pc" ? -500 : 80,
                y: this.scene.orientation == "pc" ? 138 : 170,
                atlas: this.config.atlas,
                id: item.id,
                icon: item.icon,
                price: item.price,
                availableCount: item.availableCount,
                availableDays: item.availableDays,
                title: item.title,
                onClickBuy: this.requestBuyShopItem.bind(this)
            });
            (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.shopItemDialog);
        }
    }

    requestBuyShopItem(id) {
        this.scene.requestBuyShopItem(id);
    }

    onSuccessBuyShopItem() {
        this.shopItemDialog && this.shopItemDialog.close();
    }

    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    setShopItemList(list) {
        this.mainContainer.removeAll(true);
        let cols = this.scene.orientation == "pc" ? 4 : 2;
        let scale = this.scene.orientation == "pc" ? 1 : 1.2;
        let padding = {
            pc: {
                x: 235,
                y: 310
            },
            mobile: {
                x: 292,
                y: 378
            }
        }

        for(let i = 0 ; i < list.length ; i ++) {
            let shopItem = new ShopItem(this.scene, {
                x: padding[this.scene.orientation].x * (i % cols),
                y: padding[this.scene.orientation].y * Math.floor(i / cols),
                atlas: this.config.atlas,
                scale: scale,
                ...list[i],
                onClicked: this.onClickBtnBuy.bind(this)
            });
            this.mainContainer.add(shopItem);
        }
        this.mainContainer.height = padding[this.scene.orientation].y * Math.ceil(list.length / cols);
        this.scrollViewContainer.updateScroll();
    }
}