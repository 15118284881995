export default {
    profile: {
        avatar: {
            pc: {
                x: 130,
                y: 39
            },
            mobile: {
                x: 91,
                y: 50
            }
        },
        info: {
            pc: {
                x: 220,
                y: [80, 120],
                btn_coupon: {
                    y: 87
                }
            },
            mobile: {
                x: 190,
                y: [73, 113, 155],
                btn_coupon: {
                    x: 640,
                    y: 162
                }
            }
        }
    },
    header_btn_position: {
        pc: {
            x: [1590, 1670, 1750, 1830],
            y: 99
        }, 
        mobile: {
            x: [480, 551, 622, 693],
            y: 80
        }
    },
    main_content: {
        pc: {
            y: 192
        },
        mobile: {
            y: 242
        }
    },
    tabHoldemTable: {
        header: {
            text: ["secure", "room_title", "speed_mode", "blinds", "buyin", "ante", "room_player_count", ""],
            pc: {
                x: [232, 390, 555, 677, 830, 940, 1025, 1037],
                y: 110
            }
        },
        body: {
            pc: {
                x: [35, 193, 358, 480, 633, 743, 828, 880]
            },
            mobile: {
                x: [50, 92, 419],
                y: [39],
                bottom: {
                    x: [57, 190, 360, 500],
                    y: [90, 117],
                    divider: {
                        x: [103, 277, 444],
                        y: 108
                    }
                }
            }
        },
        content: {
            pc: {
                x: 197,
                y: 140,
                row_height: 70
            },
            mobile: {
                x: 0,
                y:200,
                row_height: 167
            }
        }
    },
    tabContainer: {
        pc: {
            y: 240,
            width: 1048,
            height: 460,
            tabs: {
                x: [-620, -450, -280, -110, 60],
                y: 0
            },
            tab_line: {
                x: -620,
                y: 49
            },
            content: {
                x: 1067,
                y: 610,
            }
        },
        mobile: {
            y: 307,
            width: 585,
            height: 620,
            tabs: {
                // x: [-295, -180, -65, 50, 165],
                x: [-295, -123, -65, 50, 165],
                y: 0
            },
            tab_line: {
                x: -295,
                y: 59
            },
            content: {
                x: 1067,
                y: 610,
            }
        }
    },
    bottom_button: {
        pc: {
            x: [330, 385, 510, 565, 710, 765, 930, 985, 1140, 1390],
            y: [978, 930]
        },
        mobile: {
            x: [70, 158, 246, 334, 498, 615],
            y: [1270, 1320, 1210]
        }
    },
    holdem_content: {
        pc: {
            x: -627,
            button: {
                x: 0,
                y: [140, 210, 280],
            }
        },
        mobile: {
            x: -290,
            button: {
                x: [-10, 175, 423],
                y: 105
            }
        }
    },
    tournament_content: {
        pc: {
            x: -615,
        },
        mobile: {
            x: -290
        }
    },
    preparing: {
        pc: {
            x: 0,
            y: 355,
            logo: {
                x: -142,
                y: 0
            },
            text: {
                x: 123,
                y: -20
            }
        },
        mobile: {
            x: 0,
            y: 410,
            logo: {
                x: 0,
                y: 0
            },
            text: {
                x: 0,
                y: 170
            }
        }
    },

    PAYINBONUS: {
        None        : 0,        // 없음
        FirstPayIn  : 1,        // 신규 첫충
        DailyFirst  : 2,        // 매일 첫충
        DailyPayIn  : 3         // 매충
    }

}