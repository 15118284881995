import { SpriteButton } from "../../../components/button/spritebutton";
import { ScrollView } from "../../../components/container/scrollview";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";
import { MessageHistoryItem } from "./message_history_item";
import { MessageListBox } from "./message_list_box";

export class MessageBox extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.config = config;
        this.scene = scene;
        this.initGraph();
    }

    initGraph() {
        let width = 600;
        this.action_area = this.scene.make.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.add(this.action_area);
        this.action_area.on("pointerdown", (e) => {
            if(e.x > width) this.close();
        })

        var keyObj = this.scene.input.keyboard.addKey('Enter');
        keyObj.on('down', (event) => {
            this.input_message.htmlBodyClickEvent();
            this.onClickMessageSend();
        });

        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.6
            }
        });
        this.back.fillRect(0, 0, width, this.scene.ScreenHeight);
        this.back.fillStyle(0x111111, 0.5);
        this.back.fillRect(0, 0, width, 60);
        this.back.fillRect(0, this.scene.ScreenHeight - 90, width, 90);
        this.back.fillStyle(0xffffff, 1);
        this.back.fillRoundedRect(40, this.scene.ScreenHeight - 70, 450, 50, 25);
        this.add(this.back);

        this.title = TextClass.plainText1(this.scene, {
            x: width / 2,
            y: 30,
            text: translate('chat_history'),
            color: "#dddddd",
            fontSize: 24,
            fontFamily: "GmarketSansMedium"
        }).setOrigin(0.5, 0.5);
        this.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: 550,
            y: 15,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.input_message = new InputField(this.scene, {
            x: 40,
            y: this.scene.ScreenHeight - 70,
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: 40,
                    y: this.scene.ScreenHeight - 70,
                },
                width: 350,
                height: 50,
                placeholder: translate('chat_message_placeholder'),
                color: "#777777",
                fontSize: 24
            }
        });
        this.add(this.input_message);

        this.btn_message_list = new SpriteButton(this.scene, {
            x: 395,
            y: 7,
            origin: [0, 0],
            atlas: this.config.atlas,
            normal: "icon_message_list.png",
            onClicked: this.onClickMessageList.bind(this)
        });
        this.input_message.add(this.btn_message_list);
        this.btn_message_list.setScale(0.9);

        this.btn_message_send = new SpriteButton(this.scene, {
            x: 515,
            y: this.scene.ScreenHeight - 70,
            origin: [0, 0],
            atlas: this.config.atlas,
            normal: "icon_message_send.png",
            onClicked: this.onClickMessageSend.bind(this)
        });
        this.add(this.btn_message_send);

        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: 70,
            width: 560,
            height: this.scene.ScreenHeight - 170,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: 0,
                y: 70,
            },
            action_rect: {
                width: 500,
                height: this.scene.ScreenHeight - 170
            }
        });
        this.add(this.scrollView);
        this.setContent(this.scene.messageHistory);

        this.messageListBox = new MessageListBox(this.scene, {
            x: 280,
            y: this.scene.ScreenHeight - 600,
            callback: this.setMessage.bind(this)
        });
        this.add(this.messageListBox);
        this.messageListBox.setVisible(false);
    }

    setContent(list) {
        this.listContainer.removeAll(true);
        let y = 0;
        for(let i = 0 ; i < list.length ; i ++) {
            let item = new MessageHistoryItem(this.scene, {
                x: 0,
                y: y,
                width: 540,
                data: list[i],
                min_width: 200
            });
            this.listContainer.add(item);
            y += item.height;
        }
        this.listContainer.height = y;
        this.scrollView.updateScroll();
        this.scrollView.scrollToBottom();
    }

    onClickMessageList() {
        this.messageListBox && this.messageListBox.setVisible(!this.messageListBox.visible);
    }

    setMessage(message) {
        this.input_message.setValue(message);
    }

    onClickMessageSend() {
        if(this.input_message.value == "") return;
        this.scene.requestMessage(this.input_message.value);
        this.setMessage("");
        this.close();
    }

    close() {
        this.messageListBox.setVisible(false);
        this.setVisible(false);
    }

    setChatPossible(chatPossible) {
        this.btn_message_send.setVisible(chatPossible);
        this.btn_message_list.setVisible(chatPossible);
        this.input_message.setDisable(!chatPossible, chatPossible ? 'chat_message_placeholder' : 'chat_blocked');
    }
}