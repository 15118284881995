import { TextClass } from '../../../components/text/text';
import * as NumberUtil from '../../../util/number.js';
import LobbyDef from '../../../def/lobbydef';
import { IconButton } from '../../../components/button/iconbutton';
import { translate } from '../../../util/other';

export class TabHoldemTableRow extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
    }

    initGraphForPC() {
        this.back = this.scene.add.sprite(0,0,this.config.atlas, this.config.back);
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.id = this.config.content.id;

        this.securityIcon = this.scene.add.sprite(LobbyDef.tabHoldemTable.body[this.scene.orientation].x[0], this.back.height / 2 - 6, this.config.atlas, this.config.content.security ? 'security_on_icon.png' : 'security_off_icon.png');
        this.securityIcon.setOrigin(0.5, 0.5);
        this.add(this.securityIcon);

        this.title = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[1],
            y: this.back.height / 2 - 3,
            text: this.config.content.title,
            fontSize: 20,
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.title);

        this.mode = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[2],
            y: this.back.height / 2 - 3,
            text: this.config.content.mode,
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.mode);

        this.blinds = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[3],
            y: this.back.height / 2 - 3,
            text: NumberUtil.convertNumberWithComma(this.config.content.blinds.sb) + "/" + NumberUtil.convertNumberWithComma(this.config.content.blinds.bb),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.blinds);

        this.buyin = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[4],
            y: this.back.height / 2 - 3,
            text: NumberUtil.convertNumbersWithUnit(this.config.content.buyin.min, "en") + "~" + NumberUtil.convertNumbersWithUnit(this.config.content.buyin.max, "en"),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.buyin);

        this.ante = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[5],
            y: this.back.height / 2 - 3,
            text: NumberUtil.convertNumberWithComma(this.config.content.ante),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.ante);

        this.player_count = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[6],
            y: this.back.height / 2 - 3,
            text: this.config.content.player_count + "/" + this.config.content.seat_count,
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#d7bba3",
        }).setOrigin(0.5, 0.5);
        this.add(this.player_count);
        if(this.config.content.player_count < this.config.content.seat_count) {
            this.btn_join = new IconButton(this.scene, {
                x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[7],
                y: 12,
                atlas: this.config.atlas,
                normal: {
                    back: 'btn_join_back_normal.png',
                    icon: 'btn_join_icon.png',
                    icon_position: {
                        x: 98,
                        y: 11
                    }
                },
                hover: {
                    back: 'btn_join_back_hover.png',
                    icon: 'btn_join_icon.png',
                    icon_position: {
                        x: 98,
                        y: 11
                    }
                },
                clicked: {
                    back: 'btn_join_back_clicked.png',
                    icon: 'btn_join_icon.png',
                    icon_position: {
                        x: 98,
                        y: 11
                    }
                },
                text: {
                    x: 54,
                    x_pos: true,
                    y: 9,
                    value: translate('join'),
                    fontSize: 18,
                    gradient: ['#d6cece', '#ffffff'],
                    origin: [0.5, 0]
                },
                interactive: false
                // onClicked: () => {
                //     console.log(this.config.content);
                //     this.scene.onClickedJoinBtn(this.id, this.config.content.buyin);
                // }
            });
            this.add(this.btn_join);

            this.click_area = {
                x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[7],
                y: 12,
                width: 116,
                height: 40
            }
        } else {
            this.click_area = {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            }
        }
        this.height = 60;
    }

    initGraphForMobile() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, this.config.back);
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.id = this.config.content.id;

        this.securityIcon = this.scene.add.sprite(LobbyDef.tabHoldemTable.body[this.scene.orientation].x[0], LobbyDef.tabHoldemTable.body[this.scene.orientation].y[0], this.config.atlas, this.config.content.security ? 'security_on_icon.png' : 'security_off_icon.png');
        this.securityIcon.setOrigin(0.5, 0.5);
        this.add(this.securityIcon);

        this.title = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[1],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].y[0],
            text: "(" + this.config.content.player_count + "/" + this.config.content.seat_count + ") " + this.config.content.title,
            fontSize: 20,
            color: "#d7bba3",
        }).setOrigin(0, 0.5);
        this.add(this.title);
        if(this.config.content.player_count < this.config.content.seat_count) {
            this.btn_join = new IconButton(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[2],
            y: 20,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_join_back_normal.png',
                icon: 'btn_join_icon.png',
                icon_position: {
                    x: 98,
                    y: 11
                }
            },
            hover: {
                back: 'btn_join_back_hover.png',
                icon: 'btn_join_icon.png',
                icon_position: {
                    x: 98,
                    y: 11
                }
            },
            clicked: {
                back: 'btn_join_back_clicked.png',
                icon: 'btn_join_icon.png',
                icon_position: {
                    x: 98,
                    y: 11
                }
            },
            text: {
                x: 54,
                x_pos: true,
                y: 9,
                value: translate('join'),
                fontSize: 18,
                gradient: ['#d6cece', '#ffffff'],
                origin: [0.5, 0]
            },
            interactive: false
            // onClicked: () => {
            //     this.scene.onClickedJoinBtn(this.id, this.config.content.buyin);
            // }
            });
            this.add(this.btn_join);
            this.click_area = {
                x: LobbyDef.tabHoldemTable.body[this.scene.orientation].x[2],
                y: 20,
                width: 116,
                height: 40
            }
        } else {
            this.click_area = {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            }
        }
        this.height = 154;

        this.mode_label = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[0],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[0],
            text: translate('speed_mode'),
            fontSize: 16,
            color: "#777777",
        }).setOrigin(0.5, 0.5);
        this.add(this.mode_label);

        this.mode_value = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[0],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[1],
            text: this.config.content.mode,
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#b09985",
        }).setOrigin(0.5, 0.5);
        this.add(this.mode_value);

        this.divider0 = this.scene.add.sprite(LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.x[0], LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.y, this.config.atlas, "tab_holdem_content_divider.png");
        this.divider0.setOrigin(0.5, 0.5);
        this.add(this.divider0);

        this.blinds_label = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[1],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[0],
            text: translate('blinds'),
            fontSize: 16,
            color: "#777777",
        }).setOrigin(0.5, 0.5);
        this.add(this.blinds_label);

        this.blinds_value = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[1],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[1],
            text: NumberUtil.convertNumbersWithUnit(this.config.content.blinds.sb, "en") + "/" + NumberUtil.convertNumbersWithUnit(this.config.content.blinds.bb, "en"),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#b09985",
        }).setOrigin(0.5, 0.5);
        this.add(this.blinds_value);

        this.divider1 = this.scene.add.sprite(LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.x[1], LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.y, this.config.atlas, "tab_holdem_content_divider.png");
        this.divider1.setOrigin(0.5, 0.5);
        this.add(this.divider1);

        this.buyin_label = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[2],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[0],
            text: translate('buyin'),
            fontSize: 16,
            color: "#777777",
        }).setOrigin(0.5, 0.5);
        this.add(this.buyin_label);

        this.buyin_value = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[2],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[1],
            text: NumberUtil.convertNumbersWithUnit(this.config.content.buyin.min, "en") + "~" + NumberUtil.convertNumbersWithUnit(this.config.content.buyin.max, "en"),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#b09985",
        }).setOrigin(0.5, 0.5);
        this.add(this.buyin_value);

        this.divider2 = this.scene.add.sprite(LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.x[2], LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.divider.y, this.config.atlas, "tab_holdem_content_divider.png");
        this.divider2.setOrigin(0.5, 0.5);
        this.add(this.divider2);

        this.ante_label = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[3],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[0],
            text: translate("ante"),
            fontSize: 16,
            color: "#777777",
        }).setOrigin(0.5, 0.5);
        this.add(this.ante_label);

        this.ante_value = TextClass.plainText1(this.scene, {
            x: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.x[3],
            y: LobbyDef.tabHoldemTable.body[this.scene.orientation].bottom.y[1],
            text: NumberUtil.convertNumbersWithUnit(this.config.content.ante, "en"),
            fontSize: 20,
            fontFamily: "NanumSquareR",
            color: "#b09985",
        }).setOrigin(0.5, 0.5);
        this.add(this.ante_value);
    }

    onHover() {
        this.btn_join.setHover();
    }

    onRelease() {
        try {
            this.btn_join.setRelease();
        } catch (error) {
        }
    }

    clickedEffect() {
        this.btn_join.setClicked();
    }

    onClick() {
        this.scene.onClickedJoinBtn(this.id, this.config.content.blinds, this.config.content.security);
    }
}