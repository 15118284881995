import { TextClass } from "../../../components/text/text";
import * as DateTimeUtil from "../../../util/datetime";
import { translate } from "../../../util/other";

export class BreakTimeDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.leftTime = 0;
        this.initGraph();
    }

    initGraph() {
        this.back_graphics = this.scene.add.sprite(0, 0, this.config.atlas, "notice_back.png").setOrigin(0.5, 0);
        this.add(this.back_graphics);
        this.back_graphics.setScale(1, 3.3);
        this.back = this.scene.add.sprite(0, 80, this.config.atlas, "break_time_back.png").setOrigin(0.5, 0.5);
        this.add(this.back);
        this.back.setScale(2);
        this.typeLabel = TextClass.plainText1(this.scene, {
            x: 0,
            y: 5,
            text: this.config.type == 0 ? translate('trounament_breaktime1') : translate('trounament_breaktime2'),
            fontFamily: "NanumSquareR",
            color: "#ffff00",
            fontSize: 24
        }).setOrigin(0.5, 0);
        this.add(this.typeLabel);
        this.timeLabel = TextClass.plainText1(this.scene, {
            x: 0,
            y: 40,
            text: "00:00:00",
            fontFamily: "DigitalFont",
            color: "#ffffff",
            fontSize: 72
        }).setOrigin(0.5, 0);
        this.add(this.timeLabel);
    }

    setType(type) {
        this.config.type = type;
        this.typeLabel.setText(this.config.type == 0 ? translate('trounament_breaktime1') : translate('trounament_breaktime2'));
    }

    setLeftTime(ms) {
        this.leftTime = ms;
        this.timeLabel.setText(DateTimeUtil.convertToLeftTime(ms));
    }
}