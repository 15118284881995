import { SpriteButton } from "../../../../components/button/spritebutton";
import { ScrollView } from "../../../../components/container/scrollview";
import { TextClass } from "../../../../components/text/text";
import * as NumberUtil from "../../../../util/number";
import { translate } from "../../../../util/other";

export class BlindsTable extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 535 : 595, this.scene.orientation == "pc" ? 755 : 920)
        this.back.fillRectShape(rect);
        this.back.lineStyle(1, 0x222222, 1);
        this.back.strokeRoundedRect(0, this.scene.orientation == "pc" ? 85 : 170, this.scene.orientation == "pc" ? 530 : 590, this.scene.orientation == "pc" ? 670 : 740, 15);
        this.add(this.back);
        this.back.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 470 : 520,
            y: this.scene.orientation == "pc" ? 110 : 195,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_red.png",
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_close);

        this.name = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 107 : 194,
            text: translate('blinds_tree'),
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', '#ffffff']
        }).setOrigin(0, 0);
        this.add(this.name);

        this.tableHeader = this.scene.add.container(this.scene.orientation == "pc" ? 30 : 35, this.scene.orientation == "pc" ? 162 : 257);
        this.add(this.tableHeader);

        this.posX = {
            pc: [37, 200, 380],
            mobile: [40, 230, 420]
        }
        let headers = [translate('level'), translate('blinds'), translate('ante')];
        for(let i = 0 ; i < headers.length ; i ++) {
            let col = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][i],
                y: 0,
                text: headers[i],
                color: "#777777",
                fontSize: this.scene.orientation == "pc" ? 18 : 20
            }).setOrigin(0.5, 0.5);
            this.tableHeader.add(col);
        }

        this.tableBody = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 200 : 300,
            width: this.scene.orientation == "pc" ? 470 : 515,
            height: this.scene.orientation == "pc" ? 536 : 600,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.tableBody,
            mask: {
                x: this.scene.orientation == "pc" ? 690 : 80,
                y: this.scene.orientation == "pc" ? 423 : 470
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 440 : 485,
                height: this.scene.orientation == "pc" ? 536 : 600,
            }
        });
        this.add(this.scrollView);

        // let testBlindsTableData = [];
        // for(let i = 1 ; i <= 70 ; i ++) {
        //     testBlindsTableData.push({
        //         level: i,
        //         sb: i * 50,
        //         bb: i * 100,
        //         ante: 15 * i
        //     });
        // }
        // this.setData(testBlindsTableData);
    }

    close() {
        this.setVisible(false);
    }

    setData(blinds) {
        this.tableBody.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 30 : 34;
        for(let i = 0 ; i < blinds.length ; i ++) {
            let item = this.scene.add.container(0, i * item_height);
            let back = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: i % 2 ? 0x000000 : 0x111111,
                    alpha: 1
                }
            });
            back.fillRect(0, 0, this.scene.orientation == "pc" ? 450 : 495, item_height);
            item.add(back);

            let col0 = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][0],
                y: item_height / 2,
                text: blinds[i].level,
                color: "#ffffff",
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 14 : 16
            }).setOrigin(0.5, 0.5);
            item.add(col0);
            let col1 = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][1],
                y: item_height / 2,
                text: NumberUtil.convertNumberAsBlind(blinds[i].sb) + "/" + NumberUtil.convertNumberAsBlind(blinds[i].bb),
                color: "#ffffff",
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 14 : 16
            }).setOrigin(0.5, 0.5);
            item.add(col1);
            let col2 = TextClass.plainText1(this.scene, {
                x: this.posX[this.scene.orientation][2],
                y: item_height / 2,
                text: NumberUtil.convertNumberAsBlind(blinds[i].ante),
                color: "#ffffff",
                fontFamily: "NanumSquareR",
                fontSize: this.scene.orientation == "pc" ? 14 : 16
            }).setOrigin(0.5, 0.5);
            item.add(col2);
            
            this.tableBody.add(item);
        }
        this.tableBody.height = item_height * blinds.length;
        this.scrollView.updateScroll();
    }
}