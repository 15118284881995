import { SpriteButton } from "../../../components/button/spritebutton";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import * as DateTimeUtil from "../../../util/datetime";

export class ChargeHistoryItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "row_back_" + this.scene.orientation +".png").setOrigin(0, 0);
        this.add(this.back);
        this.dateText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 18 : 365,
            y: this.scene.orientation == "pc" ? 19 : 40,
            text: DateTimeUtil.convertDefaultDateTime(this.config.updated_at),
            fontSize: 14,
            color: "#777777"
        }).setOrigin(0, 0.5);
        this.add(this.dateText);
        if(this.config.bonus_type && this.config.bonus_type > 0) {
            let bonusTextColor = ["#ffffff", "#ffd86c", "#6cddff", "#6e6cff"]
            // let bonusTextList = ["", "신규첫충", "매일첫충", "매충"];
            let bonusTextList = ["", "First", "Daily", "Recharge"];
            this.bonusTypeText = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 235 : 575,
                y: this.scene.orientation == "pc" ? 19 : 40,
                text: bonusTextList[this.config.bonus_type],
                fontSize: 16,
                color: bonusTextColor[this.config.bonus_type],
                fontFamily: "NanumsquareR"
            }).setOrigin(0.5, 0.5);
            this.add(this.bonusTypeText);
        }
        this.amountText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 18 : 30,
            y: this.scene.orientation == "pc" ? 50 : 40,
            text: NumberUtil.convertNumberWithComma(this.config.amount) + "php",
            fontSize: 28,
            gradient: ["#ffe0cf", "#ffffff"]
        }).setOrigin(0, 0.5);
        this.add(this.amountText);
        let state = ["pending", "success", "cancel", "refund"]
        this.btn = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 200 : 540,
            y: this.scene.orientation == "pc" ? 53 : 43,
            atlas: this.config.atlas,
            normal: "icon_charge_" + state[this.config.state] + ".png",
            origin: [0, 0.5]
        });
        this.add(this.btn);
    }
}