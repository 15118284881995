import { SpriteButton } from "../../../components/button/spritebutton";
import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class TabHistoryHelp extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;

        this.help = this.initHelpList();
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.backGraphic.fillRect(-20, 0, this.scene.orientation == "pc" ? 980 : 630, this.scene.orientation == "pc" ? 530 : 1110);
        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 530 : 1110);
        this.add(this.backGraphic);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.mainContainer = this.scene.add.container(0, 0);
        this.add(this.mainContainer);
        this.backBorder = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.backBorder.lineStyle(1, 0x222222, 1)
        this.backBorder.strokeRoundedRect(0, this.scene.orientation == "pc" ? 99 : 105, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 420 : 1025);
        this.mainContainer.add(this.backBorder);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 35,
            y: this.scene.orientation == "pc" ? 122 : 128,
            text: translate('vocabulary'),
            fontSize: this.scene.orientation == "pc" ? 24 : 26,
            gradient: ['#ffe1d0', "#ffffff"]
        });
        this.mainContainer.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 870 : 525,
            y: this.scene.orientation == "pc" ? 120 : 127,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_close);

        let x = this.scene.orientation == "pc" ? 35 : 39;
        let y = 9;//this.scene.orientation == "pc" ? 203 : 230;
        let width = this.scene.orientation == "pc" ? 838 : 490;

        let content = this.scene.add.container(0, 0);
        let contentHeight = 0;
        for(let i = 0 ; i < this.help.length ; i ++) {
            let symbol = TextClass.plainText1(this.scene, {
                x: x,
                y: y,
                fontSize: 12,
                fontFamily: "NanumSquareR",
                color: "#9e2700",
                text: "■"
            }).setOrigin(0, 0.5);
            content.add(symbol);
            let title = TextClass.plainText1(this.scene, {
                x: x + 15,
                y: y,
                fontSize: 22,
                color: "#ffffff",
                text: this.help[i].title
            }).setOrigin(0, 0.5);
            content.add(title);
            y += 9 + 20;
            contentHeight += title.height + 20;
            let description = TextClass.plainText1(this.scene, {
                x: x,
                y: y,
                fontSize: 18,
                fontFamily: "NanumSqureEB",
                color: "#777777",
                text: this.help[i].description
            });
            content.add(description);
            description.setStyle({
                wordWrap: { width: width, useAdvancedWrap: true }
            });
            contentHeight += description.height + 20;
            if(i < this.help.length - 1) {
                y += description.height + 20;
                let line = this.scene.add.graphics({
                    x: x,
                    y: y
                });
                line.lineStyle(1, 0x222222, 1);
                line.beginPath();
                line.moveTo(0, 0);
                line.lineTo(width, 0);
                line.closePath();
                line.strokePath();
                content.add(line);
                y += 21 + 9;
                contentHeight += 21;
            }
        }

        content.height = contentHeight;

        this.scrollViewContent = new ScrollView(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 195 : 220,
            width: width + 60,
            height: this.scene.orientation == "pc" ? 282 : 866,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: content,
            mask: {
                x: this.scene.orientation == "pc" ? 520 : 115,
                y: this.scene.orientation == "pc" ? 525 : 390
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 845 : 490,
                height: this.scene.orientation == "pc" ? 285 : 866
            }
        });
        this.mainContainer.add(this.scrollViewContent);
    }

    initHelpList() {
        return [{
            title: translate('help_vpip'),
            description: translate('help_vpip_description')
        }, {
            title: translate('help_pfr'),
            description: translate('help_pfr_description')
        }, {
            title: translate('help_wtsd'),
            description: translate('help_wtsd_description')
        }, {
            title: translate('help_three_bet'),
            description: translate('help_three_bet_description')
        }, {
            title: translate('help_c_bet'),
            description: translate('help_c_bet_description')
        }, {
            title: translate('help_fold_to_three_bet'),
            description: translate('help_fold_to_three_bet_description')
        }, {
            title: translate('help_fold_to_c_bet'),
            description: translate('help_fold_to_c_bet_description')
        }, {
            title: translate('help_steal'),
            description: translate('help_steal_description')
        }, {
            title: translate('help_check_raise'),
            description: translate('help_check_raise_description')
        }]
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        this.scrollViewContent.setVisible(false);
        this.scene.tweens.add({
            targets: this.mainContainer,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.orientation == "pc" ? 470 : 295,
                to: this.mainContainer.x
            },
            y: {
                from: this.scene.orientation == "pc" ? 300 : 615,
                to: this.mainContainer.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.scrollViewContent.setVisible(true);
            }
        })
    }
}