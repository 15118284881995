import { TextClass } from "../../../components/text/text";
import * as DateTimeUtil from "../../../util/datetime";

export class CustomerItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.collapsed = true;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "row_title_back_" + this.scene.orientation + ".png");
        this.back.setOrigin(0, 0);
        this.add(this.back);
        let titleText = this.config.title;
        let limit = this.scene.orientation == "pc" ? 25 : 19;
        if(titleText.length > limit) titleText = titleText.substring(0, limit - 1) + "...";
        this.title = TextClass.plainText1(this.scene, {
            x: 36,
            y: 28,
            text: titleText,
            fontSize: this.scene.orientation == "pc" ? 24 : 22,
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0, 0);
        this.title.setStyle({
            wordWrap: { width: this.scene.orientation == "pc" ? 600 : 340, useAdvancedWrap: true }
        });
        this.title.setLineSpacing(15);
        this.add(this.title);

        this.collapsed_height = 28 * 2 + this.title.height;
        this.back.setScale(1, this.collapsed_height / this.back.height);
        this.height = this.collapsed_height;

        this.date = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 658 : 380,
            y: 32,
            text: DateTimeUtil.convertShotDateWithDot(this.config.updated_at),
            fontSize: this.scene.orientation == "pc" ? 20 : 16,
            color: "#777777"
        }).setOrigin(0, 0);
        this.add(this.date);

        this.status = this.scene.add.sprite(this.scene.orientation == "pc" ? 794 : 478, this.scene.orientation == "pc" ? 21 : 27, this.config.atlas, "answer_" + (this.config.status == 0 ? "waiting" : "finished") + "_" + this.scene.orientation + ".png");
        this.status.setOrigin(0, 0);
        this.add(this.status);

        this.arrow_icon = this.scene.add.sprite(this.scene.orientation == "pc" ? 922 : 588, this.scene.orientation == "pc" ? 34 : 34, this.config.atlas, "arrow_down.png");
        this.arrow_icon.setOrigin(0, 0);
        this.add(this.arrow_icon);

        this.question = TextClass.plainText1(this.scene, {
            x: 36,
            y: this.title.y + this.title.height + (this.scene.orientation == "pc" ? 30 : 40),
            text: this.config.question,
            fontSize: 20,
            color: "#777777"
        }).setOrigin(0, 0);
        this.question.setStyle({
            wordWrap: { width: this.scene.orientation == "pc" ? 860 : 540, useAdvancedWrap: true }
        });
        this.question.setLineSpacing(12);
        this.add(this.question);
        this.question.setVisible(false);

        this.expand_height = this.question.y + this.question.height + (this.scene.orientation == "pc" ? 20 : 30); 

        if(this.config.status == 1) {
            this.divider = this.scene.add.graphics({
                x: 36,
                y: this.question.y + this.question.height + (this.scene.orientation == "pc" ? 20 : 30),
                fillStyle: {
                    color: 0x777777,
                    alpha: 1
                }
            });
            this.divider.fillRect(0, 0, this.scene.orientation == "pc" ? 908 : 570, 1);
            this.add(this.divider);
            this.divider.setVisible(false);

            this.icon_answer = this.scene.add.sprite(36, this.divider.y + (this.scene.orientation == "pc" ? 20 : 30), this.config.atlas, "icon_answer.png");
            this.icon_answer.setOrigin(0, 0);
            this.add(this.icon_answer);
            this.icon_answer.setVisible(false);

            this.answer = TextClass.plainText1(this.scene, {
                x: 85,
                y: this.icon_answer.y + this.icon_answer.height + 10,
                text: this.config.answer,
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(0, 0);
            this.answer.setStyle({
                wordWrap: { width: this.scene.orientation == "pc" ? 808 : 488, useAdvancedWrap: true }
            });
            this.answer.setLineSpacing(12);
            this.add(this.answer);
            this.answer.setVisible(false);
            this.expand_height = this.answer.y + this.answer.height + 28;
        }
    }

    onClick() {
        this.collapsed = !this.collapsed;
        this.arrow_icon.setTexture(this.config.atlas, "arrow_" + (this.collapsed ? "down" : "up") +".png");
        this.height = this.collapsed ? this.collapsed_height : this.expand_height;
        this.back.setTexture(this.config.atlas, "row_" + (this.collapsed ? "title" : "content") + "_back_" + this.scene.orientation + ".png")
        this.back.setScale(1, (this.collapsed ? this.collapsed_height : this.expand_height) / this.back.height);
        this.parentContainer.parentContainer.parentContainer.parentContainer.parentContainer.arrangeContent();
        this.question.setVisible(!this.collapsed)
        this.divider && this.divider.setVisible(!this.collapsed);
        this.icon_answer && this.icon_answer.setVisible(!this.collapsed);
        this.answer && this.answer.setVisible(!this.collapsed);
    }
}