import { TextClass } from "../../../components/text/text";
import * as DateTimeUtil from "../../../util/datetime";
import * as NumberUtil from "../../../util/number";
import { translate } from "../../../util/other";

export class TournamentItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let back = this.scene.add.sprite(50, 0, this.config.atlas, "tournament_back_" + this.scene.orientation + ".png").setOrigin(0, 0);
        this.add(back);
        let table = this.scene.add.sprite(0, this.scene.orientation == "pc" ? 56 : 76, this.config.atlas, "tournament_table.png").setOrigin(0, 0.5);
        this.add(table);
        let buyin = TextClass.plainText1(this.scene, {
            x: 24,
            y: (this.scene.orientation == "pc" ? 56 : 76) - (this.config.buyin > 0 ? 0 : 15),
            text: "바이인 : ",
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0, 0.5);
        this.add(buyin);
        // let ticket = this.scene.add.sprite(93, this.scene.orientation == "pc" ? 56 : 76, "ShopAtlas_" + this.scene.tema, "icon_tournamemt_ticket2.png").setOrigin(0, 0.5);
        // this.add(ticket);
        // ticket.setScale(0.6);
        let buyinValue = TextClass.plainText1(this.scene, {
            x: 120,
            y: (this.scene.orientation == "pc" ? 56 : 76) - (this.config.buyin > 0 ? 0 : 15),
            text: this.config.buyin > 0 ? NumberUtil.convertNumbersWithUnit(this.config.buyin) : "무료",
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.add(buyinValue);

        if(this.config.buyin == 0) {
            let prizeLabel = TextClass.plainText1(this.scene, {
                x: 24,
                y: (this.scene.orientation == "pc" ? 56 : 76) + (this.config.buyin > 0 ? 0 : 15),
                text: "총상금 : ",
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(0, 0.5);
            this.add(prizeLabel);
            let prizeValue = TextClass.plainText1(this.scene, {
                x: 120,
                y: (this.scene.orientation == "pc" ? 56 : 76) + (this.config.buyin > 0 ? 0 : 15),
                text: NumberUtil.convertNumbersWithUnit(this.config.prize),
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(0.5, 0.5);
            this.add(prizeValue);
        }

        let title = TextClass.plainText1(this.scene, {
            x: 214,
            y: 32,
            text: this.config.title,
            fontSize: 24,
            color: "#ffc97c"
        }).setOrigin(0, 0);
        this.add(title);
        let start_time = TextClass.plainText1(this.scene, {
            x: 214,
            y: 66,
            text: "시작시간 : " + DateTimeUtil.convertToTournamentStartTime(this.config.start_time),
            fontFamily: "NanumSquareR",
            fontSize: 18,
            color: "#b84700"
        }).setOrigin(0, 0);
        this.add(start_time);

        let x = this.scene.orientation == "pc" ? 633 : 228;
        let y = this.scene.orientation == "pc" ? 59 : 115;
        let icon_user = this.scene.add.sprite(x, y, this.config.atlas, "icon_user.png").setOrigin(0.5, 0.5);
        this.add(icon_user);
        let player_count = TextClass.plainText1(this.scene, {
            x: x + 45,
            y: y,
            text: this.config.player_count + "/" + this.config.total_entry,
            fontSize: 16,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.add(player_count);
        let divider = this.scene.add.graphics({
            x: x + 84,
            y: y,
            fillStyle: {
                color: 0x777777,
                alpha: 1
            }
        });
        divider.fillRect(0, -10, 1, 20);
        this.add(divider);
        let clock = this.scene.add.sprite(x + 112, y, this.config.atlas, "icon_clock.png").setOrigin(0.5, 0.5);
        this.add(clock);
        let blind_up_time = TextClass.plainText1(this.scene, {
            x: x + 156,
            y: y,
            text: this.config.blinds_up_time + translate('min_unit'),
            fontSize: 16,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.add(blind_up_time);

        this.width = back.width;
        this.height = back.height;
    }

    onClick() {
        this.config.onClick && this.config.onClick(this.config.id);
    }
}