import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class MessageListBox extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.config = config;
        this.scene = scene;
        this.initGraph();
    }

    initGraph() {
        this.border = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x333333,
                alpha: 1
            }
        });
        this.border.fillRoundedRect(0, 0, 300, 500, 10);
        this.border.lineStyle(3, 0x999999, 1);
        this.border.strokeRoundedRect(0, 0, 300, 500, 10);
        this.add(this.border);

        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: 0,
            y: 10,
            width: 285,
            height: 480,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.listContainer,
            mask: {
                x: 220,
                y: this.scene.ScreenHeight - 600 + 10
            },
            action_rect: {
                width: 260,
                height: 480
            }
        });
        this.add(this.scrollView);

        let defaultMsgList = translate('chat_message_sample_list');
        let messages = [];
        for(let i = 0 ; i < defaultMsgList.length ; i ++) {
            messages.push({
                id: i + 1,
                message: defaultMsgList[i]
            })
        }
        this.setContent(messages);
    }

    setContent(messages) {
        this.listContainer.removeAll(true);
        let item_height = 50;
        for(let i = 0 ; i < messages.length ; i ++) {
            let item = this.scene.add.container(10, item_height * i);
            // let back = this.scene.add.graphics({
            //     x: 0,
            //     y: 0,
            //     fillStyle: {
            //         color: 0x333333,
            //         alpha: 1
            //     }
            // });
            // back.fillRect(0, 0, 350, item_height - 3);
            // item.add(back);
            let text = TextClass.plainText1(this.scene, {
                x: 25,
                y: 13,
                text: messages[i].message,
                fontSize: 22,
                color: "#ffffff"
            }).setOrigin(0, 0);
            item.add(text);
            this.listContainer.add(item);
            item.height = 60;
            item.onClick = () => {
                this.onClickMessage(messages[i].id, messages[i].message)
            }
        }
        this.listContainer.height = item_height * messages.length;
        this.scrollView.updateScroll();
    }

    onClickMessage(id, message) {
        this.config.callback && this.config.callback(message);
        this.close();
    }

    close() {
        this.setVisible(false);
    }
}