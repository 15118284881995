import { Avatar } from "../../../components/avatar.js/avatar";
import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { TextClass } from "../../../components/text/text";
import CommonDef from "../../../def/commondef";
import * as NumberUtil from "../../../util/number";

export class RankDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: "message_box_back.png",
            light: "card_light.png",
            scale: [1.5, this.config.final ? 2 : 1.2],
            title: {
                text: "RANKING",
                y: 30,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        if(this.config.myRank > 0) {
            this.myRank_back = this.scene.add.graphics({
                x: -327,
                y: 90,
                fillStyle: {
                    color: 0xc22f00,
                    alpha: 1
                }
            });
            this.myRank_back.fillRect(0, 0, 654, 100);
            this.cardContainer.add(this.myRank_back);

            if(this.config.myRank == 1) {
                this.flair = this.scene.add.sprite(0, -20, "RankWinnerFlair").setOrigin(0.5, 0);
                this.cardContainer.add(this.flair);
            }

            this.myRankLabel = this.scene.add.sprite(20, 140, this.config.atlas, "myrank_label.png").setOrigin(1, 0.5);
            this.cardContainer.add(this.myRankLabel);

            if(this.config.myRank < 4 && this.config.myAward > 0) {
                this.rankIcon = this.scene.add.sprite(90, 140, this.config.atlas, "rank_" + this.config.myRank + ".png").setOrigin(0.5, 0.5);
                this.cardContainer.add(this.rankIcon);
                if(this.config.myRank == 1)  {
                    this.rankIcon.setScale(1.3);
                    this.rankIcon.y = 145
                }
            } else {
                this.rankIcon = this.scene.add.sprite(90, 140, this.config.atlas, "rank_" + (this.config.myAward > 0 ? "prize.png" : "no_prize.png")).setOrigin(0.5, 0.5);
                this.cardContainer.add(this.rankIcon);
                this.rankValue = this.scene.add.text(90, 140, this.config.myRank, {
                    fontSize: this.config.myRank < 100 ? 48 : 36,
                    fontStyle: "bold",
                    stroke: "#000000",
                    strokeThickness: 6,
                }).setOrigin(0.5, 0.5);
                this.cardContainer.add(this.rankValue);
            }
        }

        if(this.config.final) {
            this.listContainer = this.scene.add.container(0, 0);
            this.scrollView = new ScrollView(this.scene, {
                x: -310,
                y: 210,
                width: 620,
                height: 400,
                atlas: "DialogAtlas",
                thumb: 'scroll_thumb_' + this.scene.tema + '.png',
                content: this.listContainer,
                mask: {
                    x: this.scene.orientation == "pc" ? 640 : 70,
                    y: this.scene.orientation == "pc" ? 380 : 480
                },
                action_rect: {
                    width: 590,
                    height: 430,
                }
            });
            this.cardContainer.add(this.scrollView);
            this.setPlayers(this.config.players);
        } else {
            this.me = new Avatar(this.scene, {
                x: -240,
                y: 180,
                atlas: "AvatarAtlas",
                avatar: CommonDef.Me.avatar,
                border: CommonDef.Me.bordericon
            });
            this.cardContainer.add(this.me);
            this.userid = new TextClass.plainText1(this.scene, {
                x: -160,
                y: 210,
                text: CommonDef.Me.loginid,
                fontSize: 24,
                color: "#dddddd"
            }).setOrigin(0, 0);
            this.cardContainer.add(this.userid);

            this.nickname = new TextClass.plainText1(this.scene, {
                x: -160,
                y: 250,
                text: CommonDef.Me.nickname || translate('nickname'),
                fontSize: 24,
                color: "#dddddd"
            }).setOrigin(0, 0);
            this.cardContainer.add(this.nickname);

            this.award_label = TextClass.plainText1(this.scene, {
                x: 0,
                y: 250,
                text: translate('prize') + ":",
                fontSize: 24,
                color: "#ffd700"
            }).setOrigin(0, 0.5);
            this.cardContainer.add(this.award_label);
            this.award_icon = this.scene.add.sprite(70, 250, this.config.atlas, "tournament_prize_icon.png").setOrigin(0, 0.5);
            this.cardContainer.add(this.award_icon);
            this.award_amount = TextClass.plainText1(this.scene, {
                x: 120,
                y: 250,
                text: NumberUtil.convertNumberWithComma(this.config.myAward),
                color: "#ffd700",
                fontSize: 24
            }).setOrigin(0, 0.5);
            this.cardContainer.add(this.award_amount);
        }

        this.btn_out = new IconButton(this.scene, {
            x: this.config.final ? -100 : -200,
            y: this.config.final ? 630 : 340,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png',
            },
            hover: {
                back: 'btn_cancel_back_hover.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png'
            },
            scale: [0.8, 0.8],
            text: {
                x: 47,
                y: 12,
                value: translate('leave_room'),
                fontSize: 30,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnOut.bind(this)
        });
        this.cardContainer.add(this.btn_out);

        if(!this.config.final) {
            this.btn_observing = new IconButton(this.scene, {
                x: 0,
                y: 340,
                atlas: this.config.atlas,
                normal: {
                    back: 'btn_ok_back_normal.png',
                },
                hover: {
                    back: 'btn_ok_back_hover.png'
                },
                clicked: {
                    back: 'btn_ok_back_clicked.png'
                },
                scale: [0.8, 0.8],
                text: {
                    x: 36,
                    y: 12,
                    value: translate('observe'),
                    fontSize: 30,
                    gradient: ['#ffe0cf', '#ffffff'],
                    fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
                },
                onClicked: this.close.bind(this)
            });
            this.cardContainer.add(this.btn_observing);
        }
    }

    close() {
        this.destroy();
    }

    onClickedBtnOut() {
        this.scene.onClickedBtnOut();
    }

    setPlayers(players) {
        this.listContainer.removeAll(true);
        let height = 0;
        for(let i = 0 ; i < players.length ; i ++) {
            let item_height = 106;
            let player = this.scene.add.container(0, item_height * i);
            let back = this.scene.add.graphics({
                x: 0,
                y: 0,
                fillStyle: {
                    color: i % 2 ? 0x000000 : 0x222222,
                    alpha: 1
                }
            });
            back.fillRect(0, 0, 590, item_height);
            player.add(back);
            let rank_label = TextClass.plainText1(this.scene, {
                x: 30,
                y: item_height / 2,
                text: players[i].rank,
                fontSize: 24,
                color: "#ffff00"
            }).setOrigin(0, 0.5);
            player.add(rank_label);
            let avatar = new Avatar(this.scene, {
                x: 130,
                y: 5,
                atlas: "AvatarAtlas",
                avatar: players[i].avatar,
                border: players[i].border,
                mask: {
                    x: this.scene.orientation == "pc" ? 779: 192,
                    y: (this.scene.orientation == "pc" ? 433 : 535) + item_height * i
                }
            });
            player.add(avatar);
            avatar.setScale(0.8);
            player.onMove = (deltaX, deltaY, animFlag) => {
                avatar.move(deltaX, deltaY, animFlag)
            }
            let userid = TextClass.plainText1(this.scene, {
                x: 226,
                y: 40,
                text: players[i].userid,
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(0, 0.5);
            player.add(userid);
            let nickname = TextClass.plainText1(this.scene, {
                x: 226,
                y: 70,
                text: players[i].nickname,
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(0, 0.5);
            player.add(nickname);
            let amount = TextClass.plainText1(this.scene, {
                x: 570,
                y: item_height / 2,
                text: NumberUtil.convertNumberWithComma(players[i].award.amount),
                fontSize: 20,
                color: "#ffffff"
            }).setOrigin(1, 0.5);
            player.add(amount);
            this.listContainer.add(player);
            height += item_height;
        }
        this.listContainer.height = height;
        this.scrollView.updateScroll();
    }
}