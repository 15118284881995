export default {
    // Loading
    "network_error_title": "Network Error",
    "network_error_content1": "Can't connect to the server.\nTry again later.",
    "network_error_content2": "Network connection failed.\nTry again later.",
    "version_warning_title": "Version upgrade",
    "version_warning_content": "Version was upgraded.\nPlease update to latest version.",
    "version_update": "Update",
    // End
    
    // Signin, Sign up
    "login": "Login",
    "register": "Sign Up",
    "login_id": "User ID",
    "password": "Password",
    "require_login_id": "Please input User ID.",
    "require_password": "Please input Password.",
    "login_error_already_login": "Already logged in with this account.",
    "login_error_wrong_account": "User ID or Password is not correct.",
    "login_error_blocked_account": "Your account was suspended.",
    "double_login": "Double login from IP %1, You are logout.",
    "maintaining": "Server is maintaining. Try again later.",
    "login_error_pending_account": "Your account is awaiting approve",
    "user_id_placeholder": "4~12 digits, lowercase letters",
    "double_check": "Check",
    "payment_method": "Payment Method",
    "payment_method_placeholder": "Please Select Payment Method",
    "password_confirm": "Password Confirm",
    "password_placeholder": "6~12 digits, lowercase letters",
    "password_confirm_placeholder": "Password Confirm",
    "account_number": "Account Number",
    "account_number_placeholder": "Account Number",
    "bank_owner": "Bank Owner",
    "bank_owner_placeholder": "Bank Owner",
    "nickname": "Nickname",
    "nickname_placeholder": "Nickname",
    "phone_number": "Phone Number",
    "phone_number_placeholder": "Only Digits without '-'",
    "verify": "Verify",
    "signup_code": "Signup Code",
    "signup_code_placeholder": "Signup Code",
    "recommander_id": "Recommander ID",
    "recommander_id_placeholder": "Recommander ID",
    "verify_code": "Verify Code",
    "verify_code_placeholder": "Verify Code",
    "ok": "OK",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "signup_ok": "Sign Up",
    "signup_error": [
        "Sign up was failed.",
        "UserID is already exist.",
        "Please check your UserID.",
        "Nickname is already exist.",
        "Please check your Nickname.",
        "Duplicated phone number.",
        "Invalid phone number format.",
        // "인증코드가 정확하지 않습니다.",
        // "비밀번호를 확인해주세요.",
        // "은행정보를 정확히 입력해주세요.",
        // "폰인증을 1분후에 다시 시도해주세요."
    ],
    "phone_verify_code_sent": "Verify code was sent.",
    // end

    // Waiting Room
    "notification": "Notification",
    "tournament_notification": "Tournament",
    "event_notification": "Event",
    "my_money": "Money",
    "my_point": "Point",
    "today_betting": "Today Bet",
    "total_betting": "Total Bet",
    "my_page": "My Page",
    "go_to_shop": "Go To Shop",
    "online_player_count": "Online",
    "gaming_player_count": "Gaming",
    "waiting_player_count": "Waiting",
    "room_count": "Rooms",
    "player_count_unit": "",
    "room_count_unit": "",
    "join_lobby": "Join Lobby",
    "shinepoker_shop": "ShinePoker Shop",

    "profile": "Profile",
    "my_history": "History",
    "inventory": "Inventory",
    
    // Nickname
    "change_nickname": "Change Nickname",
    "change_nickname_placeholder": "Your new nickname",
    "change_nickname_desc1": "",
    "change_nickname_desc2": "to change nickname.",
    "change_nickname_desc3": "Max 8 ditigs or letters.\nA nickname that aims to curse and slander others\nwill be warned or changed.",
    "change_confirm": "Change",
    "require_nickname": "Please input nickname.",
    "change_nickname_success": "Nickname changed successfully.",
    "change_nickname_error1": "Nickname change request failed.",
    "change_nickname_error2": "Your balance is not enough.",
    "change_nickname_error3": "Nickname is too long.",

    // Avatar
    "change_avatar_error_file": "Avatar should be image file.",
    "change_avatar_error_size": "File size should be under 256KB.",
    "change_avatar_success": "Avatar was uploaded successfully.",
    "change_avatar_error": "Avatar uploading was failed.",


    // History
    "cash_holdem": "Holdem",
    "tournament": "Tournament",
    "vpip": "VPIP",
    "pfr": "PFR",
    "steal": "Steal",
    "check_raise": "Check-Raise",
    "three_bet": "3-Bet",
    "fold_to_three_bet": "Fold to 3-Bet",
    "c_bet": "C-Bet",
    "fold_to_c_bet": "Fold to C-Bet",
    "total_game": "Total Games",
    "total_hand": "Total Hands",
    "wtsd": "WTSD",
    'wssd': "W$SD",
    "win_count": "Win",
    "roi": "ROI",
    
    "vocabulary": "Vocabulary",
    "help_vpip": "VPIP",
    "help_vpip_description": "VPIP is a percentage that shows how often a player voluntarily (when not in the blinds) decides to put money into the Pot.",
    "help_pfr": "PFR",
    "help_pfr_description": "It is a sign of how many hands a player raises on the first betting round (preflop in Hold'em).",
    "help_wtsd": "WTSD",
    "help_wtsd_description": "WTSD stands for went to showdown and is a measure of how often a player goes to showdown after seeing a flop. WTSD stands for went to showdown and is a measure of how often a player goes to showdown after seeing a flop. ",
    "help_three_bet": "3-Bet",
    "help_three_bet_description": "The term 3-bet most commonly refers to the first re-raise before the flop, although the term also refers to the first re-raise after the flop.",
    "help_c_bet": "C-Bet",
    "help_c_bet_description": "In No Limit Hold'em, one of the most effective strategies is the continuation bet (or 'c-bet'). This is simply when the player who raises pre-flop continues their aggressive play by betting again on the flop. It can be done as either a bluff or a bet with a good poker hand for value.",
    "help_fold_to_three_bet": "Fold to 3-Bet",
    "help_fold_to_three_bet_description": "It is a measure of how often an opener folds when they have been 3-Bet",
    "help_fold_to_c_bet": "Fold to C-Bet",
    "help_fold_to_c_bet_description": "It is a measure of how often an opener folds when they have been C-Bet on flop.",
    "help_steal": "Steal %",
    "help_steal_description": "A type of a bluff, a raise during the first betting round made with an inferior hand and meant to make other players fold superior hands because of shown strength.",
    "help_check_raise": "Check-Raise",
    "help_check_raise_description": "A check-raise in poker is a common deceptive play in which a player checks early in a betting round, hoping someone else will open.",

    // Inventory    
    "use_inventory": "Use",
    "change_border_success": "The border was applied.",
    "change_border_error1": "Border applying was failed",
    "change_border_error2": "This border is not released.",
    "use_inventory_success": "Item was used successfully.",
    "use_inventory_error1": "Using item failed.",
    "use_inventory_error2": "This item was already used.",
    
    // password
    "change_password": "Change Password",
    "current_password": "Current Password",
    "current_password_placeholder": "Current Password",
    "new_password": "New Password",
    "new_password_placeholder": "New Password",
    "new_password_confirm_placeholder": "New Password Confirm",
    "error_password_not_match": "Password is not matched.",
    "change_password_success": "Password changed successfully.",
    "change_password_error": "Password change failed.",

    // Shop
    "vip_privilege": "VIP Privilege",
    "date_unit": "Days",
    "allin_equity": "Allin\nEquity",
    "rabbit_huntting": "Rabbit\nHuntting",
    "private_border": "Private\nBorder",
    "carrier_report": "Careful\nCarrier\nReport",
    "lival_data": "Careful\nLive\nData",
    "lival_senior_data": "Live\nSenior\nData",
    "free_emoticon": "Free\nEmoticon",
    "free_timebank": "Free\nTimebank",
    "use_vip_card": "Use VIP Card",
    "use_vip_card_directly": "Do you want to use the VIP Card now?",
    "use_directly": "Yes",
    "buy_item": "Buy Item",
    "ask_buy_item": "Do you really want to buy\n%1 by paying %2?",
    "available_count": "%1times Available",
    "available_days": "%1days Available",
    "available_forever": "Available Forever",
    "buy_shopitem_success": "Buy item success.",
    "buy_shopitem_error1": "Buy item failed.",
    "buy_shopitem_error2": "Not enough balance.",
    "buy_shopitem_error3": "You have already bought this item.",
    // End

    // Lobby
    "safe": "Safe",
    "point": "Point",
    "customer": "Customer",
    "event": "Event",
    "payin": "Pay In",
    "payout": "Pay Out",
    "tab_holdem": "NLH",
    "tab_omaha": "Omaha",
    "tab_tournament": "Tournament",

    // holdem tab
    "refresh": "Refresh",
    "create_room": "Create",
    "quick_join": "Quick Join",
    "secure": "Secure",
    "room_title": "Title",
    "buyin": "ByuIn",
    "room_player_count": "Players",
    "blinds": "Blinds",
    "ante": "Ante",
    "speed_mode": "Mode",
    "speed_labels": ["Fast", "Normal", "Slow"],
    "join": "Join",
    "ante_yes": "Yes",
    "ante_no": "No",
    "room_title_placeholder": "Room Title.",
    "room_password_placeholder": "If not set, will be public.",
    "room_title_list": [
        "Fast playing!",
        "Enjoy gaming",
        "Manner Players.",
        "Speed Game!",
        "Welcome anybody.",
        "Alling gaming",
        "Come on please",
        "Beginners"
    ],
    "create_room_game_mode": "Game Speed",
    "second_unit": "s",
    "no_ante": "No",
    "buyin_amount": "Buyin",
    "create_room_error_title": "Please input room title.",
    "join_failed": "Join room failed.",
    "create_room_failed": "Create room failed.",
    "not_enough_balance": "Not enough balance.",
    "overflow_buyin": "Please select valid buyin.",
    "join_error1": "Join room failed.",
    "join_error2": "Buyin is out of your balance.",
    "join_error3": "Please select valid buyin.",
    "join_error4": "The room is invalid.",
    "join_error5": "Passowrd is wrong.",
    "join_error6": "You was blocked from this room.",
    "no_valid_rooms": "There is no valid rooms.",

    // tournament tab
    "filter_all": "All",
    "free": "Free",
    "h_100k": ">100k",
    "h_1m": ">1M",
    "tournament_details": "Details",
    "table": "Table",
    "blinds_tree": "Blinds Tree",
    "level": "Level",
    "blind_up": "Bind up",
    "last_reg": "Last Reg",
    "cur_level": "Current Lv.",
    "total_prize": "Pool",
    "entry": "Entry",
    "entry_range": "Entry range",
    "start_chip": "Start Chip",
    "av_stack": "Av Stack",
    "rebuy": "Rebuy",
    "addon": "Addon",
    "break": "Break",
    "tournament_register": "Register",
    "player_chip": "Chip",
    "rank_unit": "",
    "ranking": "Ranking",
    "prize": "Prize",
    "left_tables": "Left tables",
    "observe": "Observe",
    "game_info": "Game detail",
    "tournament_register_confirm": "Register",
    "tournament_register_cancel": "Cancel",
    "tournament_register_again": "Rebuy",
    "tournament_fee": "Fee",
    "tournament_rebuy_no_limit": "No limit",
    "tournament_rebuy_not_allow": "Not allowed",
    "tournament_rebuy_count_unit": "",
    "tournament_state_waiting": "Waiting",
    "tournament_state_starting": "Starting",
    "tournament_state_playing": "Playing",
    "tournament_state_finished": "Finished",
    "tournament_start_time": "Start Time",
    "tournament_addon_no": "No",
    "tournament_break_no": "No",
    "tournament_break": "%2mins ever %1mins",
    "min_unit": "min",
    "tournament_register_error1": "Tournament register failed.",
    "tournament_register_error2": "Not enough balance.",
    "tournament_register_error3": "Overflow rebuy count.",
    "tournament_register_error4": "Tournament register not allowed.",
    "tournament_register_error5": "Ticket was already used.",
    "tournament_register_error6": "Invalid ticket.",
    "tournament_register_error7": "This tournament doesn't provide ticket.",
    "tournament_register_error8": "This tournament is playing now.",
    "tournament_register_error9": "You need to pass 3 days attendances.",
    "tournament_unregister_error1": "Unregister failed.",
    "tournament_unregister_error2": "Not allowed unregister",
    "tournament_unregister_error3": "Can't unregister tournament bought with ticket",
    "tournament_observe_error": "Observing failed.",
    "tournament_addon_error1": "Addon failed.",
    "tournament_addon_error2": "Not allowed addon.",
    "tournament_addon_error3": "Not enough balance.",
    "tournament_addon_error4": "You didn't register this tournament.",
    "tournament_addon_error5": "You are out of this tournament.",
    "tournament_addon_error6": "Tournament is not addon status.",
    "tournament_addon_error7": "You have already addon.",
    "tournament_addon_count": "Addon count",
    "left_time_seconds": "Left Time: %s seconds",
    "tournament_unregister_warning": "Can't unregister before 1 min to start.",
    "trounament_breaktime1": "Waiting",
    "tournament_breaktime2": "Break Time",

    // Safe
    "deposit": "Deposit",
    "withdraw": "Withdraw",
    "current_money": "Current Money",
    "safe_stored_money": "Safe Stored Money",
    "deposit_amount": "Deposit Amount",
    "withdraw_amount": "Withdraw Amount",
    "amount_placeholder": "Please input amount",
    "depsit_confirm": "Deposit",
    "withdraw_confirm": "Withdraw",
    "safe_history": "Safe Hisotry",
    "safe_deposit_success": "Safe deposit success.",
    "safe_deposit_error1": "Safe deposit failed.",
    "safe_deposit_error2": "Please check the deposit amount.",
    "safe_withdraw_success": "Safe withdraw success.",
    "safe_withdraw_error1": "Safe withdraw failed",
    "safe_withdraw_error2": "Please check the withdraw amount.",

    // point
    "current_point": "My Point",
    "convert_point": "Point to Exchange",
    "convert_point_btn": "Exchange",
    "convert_point_history": "History",
    "convert_point_success": "Point exchange success.",
    "convert_point_error1": "Point exchange failed.",
    "convert_point_error2": "Please check your point.",

    // customer
    "customer_history": "My Questions",
    "question_confirm": "Ask",
    "question": "Question",
    "question_title_placeholder": "Please input title",
    "question_content_placeholder": "Write your question here.\nWe will reply soon.",
    "customer_confirm": "Send",
    "customer_question_error": "Question was not sent.",

    // Event
    "attendance_event": "Attendance",
    "accept": "Accept",
    "accepted": "Accepted",
    "congratulations": "Congratulations!",
    "event_accept_error": "Event item accept is failed.",

    // 충환전
    "payin_title": "Pay In",
    "payin_ask_account": "고객센터로 계좌문의 부탁드립니다.",
    "warning_payin": [
        "반드시 가입시 예금주명과 같은 이름으로 입금부탁드립니다.",
        "반드시 선입금후 구매확정 눌러주세요"
    ],
    "payin_username": "입금자",
    "payin_amount": "입금금액",
    "payin_amount_placeholder": "충전금액을 입력해주세요. (필수)",
    "reset": "RESET",
    "bonus_type": "보너스 선택",
    "no_bonus": "선택하지 않음",
    "payin_history": "충전내역",
    "payin_confirm": "구매확정",
    "payout_title": "환전하기",
    "bank_name": "은행명",
    "payout_amount": "환전 금액",
    "payout_amount_placeholder": "환전 금액을 입력해주세요",
    "payout_history": "환전내역",
    "payout_confirm": "판매확정",
    "success_payin": "충전요청이 성공했습니다.",
    "error_payin": [
        "충전요청이 실패했습니다.",
        "현재 대기중인 요청이 있습니다."
    ],
    "success_payout": "환전요청이 성관적으로 완료되었습니다.",
    "error_payout": [
        "환전요청이 실패했습니다.",
        "환전요청 금액이 보유금액을 초과합니다.",
        "출금조건이 만족하지 않습니다.",
        "현재 대기중인 요청이 있습니다."
    ],

    // Note
    "note_box": "InBox",
    "have_unread_notes": "You have unread notes.",
    "unread_notes": "Unread Notes",
    "note_count_unit": "",

    // Setting
    "setting": "Settings",
    "system": "System",
    "template": "Template",
    "background_volume": "Background",
    "effect_volume": "Effect",
    "screen_mode": "Screen",
    "portrait": "Portrait",
    'auto': 'Auto',
    "landscape": "Landscape",
    "voice": "Voice",
    "male": "Male",
    "female": "Female",
    "save": "Save",
    "ui_mode": "UI Mode",
    "ui_light": "Light",
    "ui_dark": "Dark",
    "game_display": "Display",
    "card_style": "Card",
    "release": "Release",
    "apply": "Apply",

    // Holdem Game
    "game_history": "Game History",
    "hand_card": "Hand",
    "betting_amount": "Betting",
    "result": "Win",
    "chat_history": "Messages",
    "chat_message_placeholder": "Please input message.",
    "chat_blocked": "You are not allowed to chat",
    "chat_message_sample_list": [
        "How are you?",
        "Good luck",
        "Well done.",
        "Nice gaming.",
        "Be careful",
        "Fighting",
        "Let's go",
        "Good bye!",
        "Killing me",
        "Hurry up",
        "Fuck",
        "Stupid",
        "Oh my god!",
        "Thank you",
        "See you soon",
        "Please don't fold",
        "Let's go to allin",
        "I'm the best here"
    ],
    "player_profile": "Player Profile",
    "emoticon": "EMOTICON",
    "emoticon_left_count": "Left Emoticons",
    "report": "Report",
    "profile_report_warning": "If you report player profile, admin will check carefully and the player's account may be suspended. But if your report is fake, your account may be blocked.",
    "chat_report_warning": "Once message report, admin will check and the player will be force leave room and block chatting. But if your report is fake, your account may be blocked.",
    "report_confirm": "Do you really want to report?",
    "report_empty_error": "Please inupt report reason",
    "leave_room_error": "Leaving room failed.",
    "report_error": "Report failed.",
    "leave_room": "Leave",
    // End
}