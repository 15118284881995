import { InventoryItem } from "../../../components/container/inventoryitem";
import { ScrollView } from "../../../components/container/scrollview";
import { TournamentTicketRegister } from "./tournament_ticket_register";
import CommonDef from '../../../def/commondef';
import { VIPConfirmDialog } from "./vip_confirm";

export class TabInventory extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.listContainer = this.scene.add.container(0, 0);
        this.scrollView = new ScrollView(this.scene, {
            x: -5,
            y: 100,
            width: this.scene.orientation == "pc" ? 935 : 585,
            height: this.scene.orientation == "pc" ? 420 : 1025,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.listContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 485 : 80,
                y: this.scene.orientation == "pc" ? 432 : 270,
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 910 : 560,
                height: this.scene.orientation == "pc" ? 420 : 1025,
            }
        });
        this.add(this.scrollView);
    }

    setContent(content) {
        this.listContainer.removeAll(true);
        let item_height = this.scene.orientation == "pc" ? 95 : 119;
        for(let i = 0 ; i < content.length ; i++) {
            let x = 0;
            if(this.scene.orientation == "pc" && i % 2 == 1) x = 460;
            let y = 0;
            if(this.scene.orientation == "pc") y = item_height * Math.floor(i / 2);
            else y = item_height * i;
            this.listContainer.add(new InventoryItem(this.scene, {
                x: x,
                y: y,
                atlas: this.config.atlas,
                ...content[i],
                onClicked: this.onClickedItem.bind(this)
            }));
        }
        this.listContainer.height = item_height * Math.ceil(content.length / (this.scene.orientation == "pc" ? 2 : 1));
        this.scrollView.updateScroll();
    }

    onClickedItem(item) {
        if(item.type == 10) { // 토너 티켓
            this.tournamentTicketRegisterDlg = new TournamentTicketRegister(this.scene, {
                x: 0,
                y: 0,
                atlas: this.config.atlas,
                ticketid: item.id
            });
            this.add(this.tournamentTicketRegisterDlg);
            // CommonDef.gameNet.requestTournamentList(-1) // 바이인 10만원 토너 리스트 얻기
            CommonDef.gameNet.requestTournamentListByTicket(item.id);
        } else if(item.type < 4) { // vip 카드
            // 현재 vip카드 사용중인 경우 경고 팝업
            if(CommonDef.Me.viplevel > 0) {
                let vipConfirmDlg = new VIPConfirmDialog(this.scene, {
                    atlas: "ShopAtlas_" + this.scene.tema,
                    icon: item.icon,
                    onConfirm: () => {
                        this.requestUseVIPCard(item.id)
                    }
                });
                this.add(vipConfirmDlg);
            } else {
                this.requestUseVIPCard(item.id);
            }
        }
    }

    setTournamentList(tournamentList) {
        this.tournamentTicketRegisterDlg && this.tournamentTicketRegisterDlg.setContent(tournamentList)
    }

    requestUseVIPCard(id) {
        this.scene.requestInventoryUse(id);
    }
}