import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { ScrollView } from "../../../components/container/scrollview";
import { MobileDialog } from "../../../components/dialog/mobile_dialog";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import { ChargeHistoryItem } from "./charge_history_item";
import CommonDef from "../../../def/commondef";
import { DropDown } from "../../../components/dropdown.js/dropdown";
import { ChargeBonusItem } from "./charge_bonus_item";
import LobbyDef from "../../../def/lobbydef";

export class ChargeDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.amount = 0;
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.chargeContainer = this.scene.add.container(this.scene.orientation == "pc" ? -505 : 40, this.scene.orientation == "pc" ? 140 : 170);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.chargeContainer);

        this.btn_simpleSignup = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 6 : 4,
            y: -40,
            atlas: this.config.atlas,
            scale: [1, 0.8],
            normal: {
                back: "btn_simple_signup_back_" + this.scene.orientation + "_normal.png"
            },
            hover: {
                back: "btn_simple_signup_back_" + this.scene.orientation + "_hover.png"
            },
            clicked: {
                back: "btn_simple_signup_back_" + this.scene.orientation + "_clicked.png"
            },
            text: {
                x: this.scene.orientation == "pc" ? 100 : 90,
                y: this.scene.orientation == "pc" ? 18 : 14,
                // value: "고객센터로 계좌문의 부탁드립니다.",
                value: "Ask bank account number.",
                fontSize: this.scene.orientation == "pc" ? 28 : 36,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: () => {
                // window.open('https://www.google.com','_blank');
                this.scene.onClickedCustomer();
            }
        });
        this.chargeContainer.add(this.btn_simpleSignup);

        let warningIcon = this.scene.add.sprite(0, 48, this.config.atlas, "warning.png").setOrigin(0, 0).setScale(0.45);
        this.chargeContainer.add(warningIcon);

        let warningText = TextClass.plainText1(this.scene, {
            x: 80,
            y: 50,
            // text: "반드시 가입시 예금주명과 같은 이름으로 입금부탁드립니다.\n반드시 선입금후 구매확정 눌러주세요.",
            text: "Please use same name with bank onwer name.\nClick confirm button after deposit.",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(warningText);
        
        let ownerNameText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 122 : 132,
            text: "Name",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(ownerNameText);

        this.ownerNameField = new InputField(this.scene, {
            x: 0,
            y: (this.scene.orientation == "pc" ? 122 : 132) + 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 458 : 40,
                    y: this.scene.orientation == "pc" ? 433 : 332
                },
                width: 580,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: "",
                disabled: true,
                value: CommonDef.Me.ownername,
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.ownerNameField);

        let amountText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 244 : 264,
            text: "Deposit Amount",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(amountText);

        this.amountField = new InputField(this.scene, {
            x: 0,
            y: (this.scene.orientation == "pc" ? 244 : 264) + 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_long.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 460 : 40,
                    y: this.scene.orientation == "pc" ? 554 : 464
                },
                type: "number",
                width: 590,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: "Input amount (required)",
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.chargeContainer.add(this.amountField);

        let amountList = [0, 10000, 50000, 100000, 500000];
        for(let i = 0 ; i < 5 ; i ++) {
            let btn = new IconButton(this.scene, {
                x: (this.scene.orientation == "pc" ? 130 : 136) * i - 8,
                y: this.scene.orientation == "pc" ? 352 : 387,
                atlas: this.config.atlas,
                normal: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_normal.png"
                },
                hover: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_hover.png"
                },
                clicked: {
                    back: "charge_amount_btn_back_" + this.scene.orientation + "_clicked.png"
                },
                text: {
                    x: 70,
                    y: this.scene.orientation == "pc" ? 15 : 20,
                    value: i == 0 ? "RESET" : NumberUtil.convertNumberWithComma(amountList[i]),
                    fontSize: 24,
                    gradient: ['#d7d0d0', '#ffffff'],
                    origin: [0.5, 0]
                },
                onClicked: () => {
                    this.onClickedBtnAmount(amountList[i]);
                }
            });
            this.chargeContainer.add(btn);
        }

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 407 : 243,
            y: this.scene.orientation == "pc" ? 550 : 602,
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 95,
                y: 15,
                value: "Confirm",
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff'],
                origin: [0.5, 0]
            },
            onClicked: this.onClickedBtnConfirm.bind(this)
        });
        this.chargeContainer.add(this.btn_confirm);

        let bonusTypeText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 435 : 485,
            text: "Select Bonus",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.chargeContainer.add(bonusTypeText);

        this.bonusDropdownContainer = this.scene.add.container(10, 70);
        this.bonusType = new DropDown(this.scene, {
            x: -15,
            y: this.scene.orientation == "pc" ? 465 : 510,
            atlas: this.config.atlas,
            width: 640,
            height: 160,
            scale: [this.scene.orientation == "pc" ? 2 : 2.1, 1],
            normal: {
                back: "dropdown_back_normal.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 305,
                    y: 21
                }
            },
            clicked: {
                back: "dropdown_back_clicked.png"
            },
            hover: {
                back: "dropdown_back_hover.png"
            },
            placeholder: "",
            dropdown_content: this.bonusDropdownContainer,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 20
            }
        });
        this.chargeContainer.add(this.bonusType);

        this.historyContainer = this.scene.add.container(this.scene.orientation == "pc" ? this.config.x + 175 : 40, this.scene.orientation == "pc" ? this.config.y + 100 : 840)
        this.add(this.historyContainer);

        let historyText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: "Deposit history",
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.historyContainer.add(historyText);

        this.refreshText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 2,
            text: "Refresh",
            fontSize: 18,
            gradient: ['#d6cece', '#ffffff']
        }).setOrigin(0, 0);
        this.historyContainer.add(this.refreshText);
        this.refreshIcon = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 288 : 630,
            y: 0,
            atlas: this.config.atlas,
            origin: [1, 0],
            normal: "icon_refresh_" + this.scene.orientation + ".png"
        });
        this.historyContainer.add(this.refreshIcon);

        this.action_area = this.scene.add.graphics({
            x: this.scene.orientation == "pc" ? 195 : 532,
            y: 0,
            fillStyle: {
                color: 0xffff00,
                alpha: 0.5
            }
        });
        let rect = new Phaser.Geom.Rectangle(0, 0, 100, 25);
        this.historyContainer.add(this.action_area);
        this.action_area.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
        this.action_area.on("pointerdown", () => {
            CommonDef.gameNet.requestPayinList();
        });

        this.historyContent = this.scene.add.container(0, 0);

        this.scrollViewContainer = new ScrollView(this.scene, {
            x: 0,
            y: 32,
            width: this.scene.orientation == "pc" ? 323 : 667,
            height: this.scene.orientation == "pc" ? 527 : 434,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.historyContent,
            mask: {
                x: this.scene.orientation == "pc" ? 1125 : 40,
                y: this.scene.orientation == "pc" ? 272 : 872
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 300 : 640,
                height: this.scene.orientation == "pc" ? 527 : 434,
            }
        });
        this.historyContainer.add(this.scrollViewContainer);

        // this.setHistoryContent([{
        //     date_time: "2021.12.21 10:38:34",
        //     amount: 50000,
        //     status: "success",
        // }])
        // this.setHistoryContent([], this.config.history || []);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            scale: [1, 1.15],
            light: 'card_light.png',
            title: {
                text: "Deposit",
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 470,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: "Deposit",
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onClickedBtnAmount(amount) {
        amount == 0 && (this.amount = 0);
        this.amount += amount;
        this.amountField.setValue(this.amount);
    }

    setHistoryContent(content, bonusItems = []) {
        this.setBonusItems(bonusItems);
        this.historyContent.removeAll(true);
        let height = 90;
        for(let i = 0 ; i < content.length ; i ++) {
            let item = new ChargeHistoryItem(this.scene, {
                x: 0,
                y: i * height,
                atlas: this.config.atlas,
                ...content[i]
            });
            this.historyContent.add(item);
        }
        this.historyContent.height = content.length * height;
        this.scrollViewContainer.updateScroll();
    }

    onClickedBtnConfirm() {
        if(!this.ownerNameField.value || !this.amountField.value) return;
        this.scene.requestPayin(this.ownerNameField.value, "", this.amountField.value, this.bonusType.value);
    }

    popupAnimation() {
        this.chargeContainer.setVisible(false);
        this.historyContainer.setVisible(false);
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back",
            onComplete: () => {
                this.chargeContainer.setVisible(true);
                this.historyContainer.setVisible(true);
            }
        })
    }

    setBonusItems(bonusItems) {
        bonusItems.splice(0, 0, {type: LobbyDef.PAYINBONUS.None, percent: 0});
        this.bonusDropdownContainer.removeAll(true);
        // let bonusTextList = [
        //     "선택하지 않음",
        //     "신규 첫충전 3+2 5+3 8+4 10+5 (그외의 금액 충전시 10% 적용)",
        //     '매일 첫충전 "충전금액+10%" (최대한도 20만)',
        //     '재충전 "충전금액+5%" (최대한도 20만)'
        // ]
        let bonusTextList = [
            "No bonus",
            "The first charge, 3+2 5+3 8+4 10+5 (else 10%)",
            'Daily charge "amount+10%" (max 200k)',
            'Recharge "amount+5%" (max 200k)'
        ]
        for(let i = 0 ; i < bonusItems.length ; i ++) {
            let text = bonusTextList[bonusItems[i].type];
            if(i > 0) {
                if(bonusItems[i].type == 0) continue;
                // else if(bonusItems[i].type == 1) {
                //     text += " (그외의 금액 충전시 10% 적용)";
                // } else {
                //     // text += " (" + bonusItems[i].percent + "%)";
                //     text += ' "충전금액+10(최대한도 20만)';
                // }
            }
            let bonusItem = new ChargeBonusItem(this.scene, {
                x: 0,
                y: 10 + 40 * i,
                type: bonusItems[i].type,
                text: text,
                onClicked: this.onChangeBonusItem.bind(this)
            });
            this.bonusDropdownContainer.add(bonusItem)
        }
        this.onChangeBonusItem(LobbyDef.PAYINBONUS.None, bonusTextList[0]);
    }

    onChangeBonusItem(type, text) {
        this.bonusType.setValue(type);
        this.bonusType.setDisplayText(text);
        this.bonusType.activeDropDown(false);
    }
}