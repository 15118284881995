import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { ContentCard } from "../../../../components/card/contentcard";
import { ScrollView } from "../../../../components/container/scrollview";
import { MobileDialog } from "../../../../components/dialog/mobile_dialog";
import { DropDown } from "../../../../components/dropdown.js/dropdown";
import { InputField } from "../../../../components/input/input";
import { TextClass } from "../../../../components/text/text";
import { AnteItem } from "./ante_item";
import { BlindItem } from "./blind_item";
import { GameModeItem } from "./gamemode_item";
import { PlayerCountItem } from "./player_count_item";
import CommonDef from "../../../../def/commondef";
import { RoomTitleItem } from "./roomtitle_item";
import { translate } from "../../../../util/other";

export class CreateRoomDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();

        this.mainContainer = this.scene.add.container(this.scene.orientation == "pc" ? -335 : 40, this.scene.orientation == "pc" ? 146 : 170);
        (this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg).add(this.mainContainer);

        let gameTitleText = TextClass.plainText1(this.scene, {
            x: 0,
            y: 0,
            text: translate('room_title'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(gameTitleText);

        // this.roomTitleContent = this.scene.add.container(0, 0);
        // for(let i = 0 ; i < translate('room_title_list').length ; i ++) {
        //     this.roomTitleContent.add(new RoomTitleItem(this.scene, {
        //         x: 0,
        //         y: 50 * i,
        //         title: translate('room_title_list')[i],
        //         onClicked: this.onChangeRoomTitle.bind(this)
        //     }));
        // }
        // this.roomTitleContent.height = 50 * translate('room_title_list').length;

        // this.roomTitleDropdownContent = new ScrollView(this.scene, {
        //     x: 10,
        //     y: 70,
        //     width: this.scene.orientation == "pc" ? 280 : 300,
        //     height: 120,
        //     atlas: "DialogAtlas",
        //     thumb: 'scroll_thumb_' + this.scene.tema + '.png',
        //     content: this.roomTitleContent,
        //     mask: {
        //         x: this.scene.orientation == "pc" ? 625 : 45,
        //         y: this.scene.orientation == "pc" ? 458 : 273
        //     },
        //     action_rect: {
        //         width: 280,
        //         height: 120
        //     }
        // });

        // this.roomTitleDropdown = new DropDown(this.scene, {
        //     x: -7,
        //     y: 32,
        //     atlas: this.config.atlas,
        //     width: 320 * (this.scene.orientation == "pc" ? 0.96 : 1.02),
        //     height: 195,
        //     scale: [this.scene.orientation == "pc" ? 0.96 : 1.02, 1],
        //     normal: {
        //         back: "dropdown_back_normal.png",
        //         arrow: {
        //             texture: "dropdown_arrow.png",
        //             x: 283,
        //             y: 21
        //         }
        //     },
        //     clicked: {
        //         back: "dropdown_back_clicked.png"
        //     },
        //     hover: {
        //         back: "dropdown_back_hover.png"
        //     },
        //     placeholder: "",
        //     dropdown_content: this.roomTitleDropdownContent,
        //     displayText: {
        //         x: 28,
        //         y: 18,
        //         gradient: ["#b9b1b1", "#ffffff"],
        //         fontSize: 24
        //     }
        // });
        // this.mainContainer.add(this.roomTitleDropdown);
        // this.onChangeRoomTitle(translate('room_title_list')[0]);
        
        this.roomTitleField = new InputField(this.scene, {
            x: 0,
            y: 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 620 : 40,
                    y: this.scene.orientation == "pc" ? 386 : 202
                },
                width: 290,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: translate("room_title_placeholder"),
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.mainContainer.add(this.roomTitleField);

        

        let gamePasswordText = TextClass.plainText1(this.scene, {
            x: 350,
            y: 0,
            text: translate('password'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(gamePasswordText);
        
        this.passwordField = new InputField(this.scene, {
            x: 350,
            y: 32,
            atlas: this.config.atlas,
            back: "input_back_" + this.scene.orientation + "_short.png",
            input: {
                x: 20,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 975 : 390,
                    y: this.scene.orientation == "pc" ? 386 : 202
                },
                type: "password",
                width: 290,
                height: this.scene.orientation == "pc" ? 60 : 70,
                placeholder: translate("room_password_placeholder"),
                color: "#ffffff",
                fontSize: 24
            }
        });
        this.mainContainer.add(this.passwordField);

        let gameModeText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 121 : 131,
            text: translate('create_room_game_mode'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(gameModeText);

        this.gameModeContent = this.scene.add.container(10, 70);
        this.gameModeContent.add(new GameModeItem(this.scene, {
            x: 0,
            y: 10,
            second: 6,
            onClicked: this.onChangeGameMode.bind(this)
        }));
        this.gameModeContent.add(new GameModeItem(this.scene, {
            x: 0,
            y: 50,
            second: 9,
            onClicked: this.onChangeGameMode.bind(this)
        }));
        this.gameModeContent.add(new GameModeItem(this.scene, {
            x: 0,
            y: 90,
            second: 30,
            onClicked: this.onChangeGameMode.bind(this)
        }));

        this.gameModeDropdown = new DropDown(this.scene, {
            x: -7,
            y: this.scene.orientation == "pc" ? 153 : 162,
            atlas: this.config.atlas,
            width: 320 * (this.scene.orientation == "pc" ? 0.96 : 1.02),
            height: 200,
            scale: [this.scene.orientation == "pc" ? 0.96 : 1.02, 1],
            normal: {
                back: "dropdown_back_normal.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 283,
                    y: 21
                }
            },
            clicked: {
                back: "dropdown_back_clicked.png"
            },
            hover: {
                back: "dropdown_back_hover.png"
            },
            placeholder: "",
            dropdown_content: this.gameModeContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.mainContainer.add(this.gameModeDropdown);
        this.onChangeGameMode(6);

        let playerCountText = TextClass.plainText1(this.scene, {
            x: 350,
            y: this.scene.orientation == "pc" ? 121 : 131,
            text: translate('room_player_count'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(playerCountText);

        this.playerCountContent = this.scene.add.container(10, 70);
        this.playerCountContent.add(new PlayerCountItem(this.scene, {
            x: 0,
            y: 10,
            count: 6,
            onClicked: this.onChangePlayerCount.bind(this)
        }));
        this.playerCountContent.add(new PlayerCountItem(this.scene, {
            x: 0,
            y: 50,
            count: 9,
            onClicked: this.onChangePlayerCount.bind(this)
        }));

        this.playerCoundDropdown = new DropDown(this.scene, {
            x: 343,
            y: this.scene.orientation == "pc" ? 153 : 162,
            atlas: this.config.atlas,
            width: 320 * (this.scene.orientation == "pc" ? 0.96 : 1.02),
            height: 160,
            scale: [this.scene.orientation == "pc" ? 0.96 : 1.02, 1],
            normal: {
                back: "dropdown_back_normal.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 283,
                    y: 21
                }
            },
            clicked: {
                back: "dropdown_back_clicked.png"
            },
            hover: {
                back: "dropdown_back_hover.png"
            },
            placeholder: "",
            dropdown_content: this.playerCountContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.mainContainer.add(this.playerCoundDropdown);
        this.onChangePlayerCount(9);


        let blindText = TextClass.plainText1(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 242 : 262,
            text: translate('blinds'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(blindText);

        this.blindListContent = this.scene.add.container(0, 0);
        this.anteContent = this.scene.add.container(10, 70);

        let anteText = TextClass.plainText1(this.scene, {
            x: 350,
            y: this.scene.orientation == "pc" ? 242 : 262,
            text: translate('ante'),
            fontFamily: "GmarketSansMedium",
            fontSize: 20,
            color: "#f58a00"
        }).setOrigin(0, 0);
        this.mainContainer.add(anteText);

        this.anteDropdown = new DropDown(this.scene, {
            x: 343,
            y: this.scene.orientation == "pc" ? 274 : 294,
            atlas: this.config.atlas,
            width: 320 * (this.scene.orientation == "pc" ? 0.96 : 1.02),
            height: 160,
            scale: [this.scene.orientation == "pc" ? 0.96 : 1.02, 1],
            normal: {
                back: "dropdown_back_normal.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 283,
                    y: 21
                }
            },
            clicked: {
                back: "dropdown_back_clicked.png"
            },
            hover: {
                back: "dropdown_back_hover.png"
            },
            placeholder: "",
            dropdown_content: this.anteContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.mainContainer.add(this.anteDropdown);


        for(let i = 0 ; i < CommonDef.blinds.length ; i ++) {
            this.blindListContent.add(new BlindItem(this.scene, {
                x: 0,
                y: 50 * i,
                ...CommonDef.blinds[i],
                onClicked: this.onChangeblind.bind(this)
            }));
        }
        this.blindListContent.height = 50 * CommonDef.blinds.length;

        this.blindDropdownContent = new ScrollView(this.scene, {
            x: 10,
            y: 70,
            width: this.scene.orientation == "pc" ? 280 : 300,
            height: 120,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_' + this.scene.tema + '.png',
            content: this.blindListContent,
            mask: {
                x: this.scene.orientation == "pc" ? 625 : 45,
                y: this.scene.orientation == "pc" ? 700 : 535
            },
            action_rect: {
                width: 280,
                height: 120
            }
        });

        this.blindDropdown = new DropDown(this.scene, {
            x: -7,
            y: this.scene.orientation == "pc" ? 274 : 294,
            atlas: this.config.atlas,
            width: 320 * (this.scene.orientation == "pc" ? 0.96 : 1.02),
            height: 195,
            scale: [this.scene.orientation == "pc" ? 0.96 : 1.02, 1],
            normal: {
                back: "dropdown_back_normal.png",
                arrow: {
                    texture: "dropdown_arrow.png",
                    x: 283,
                    y: 21
                }
            },
            clicked: {
                back: "dropdown_back_clicked.png"
            },
            hover: {
                back: "dropdown_back_hover.png"
            },
            placeholder: "",
            dropdown_content: this.blindDropdownContent,
            displayText: {
                x: 28,
                y: 18,
                gradient: ["#b9b1b1", "#ffffff"],
                fontSize: 24
            }
        });
        this.mainContainer.add(this.blindDropdown);
        this.onChangeblind({
            sb: 100, 
            bb: 200
        });


        this.btn_cancel = new IconButton(this.scene, {
            x: 140,
            y: this.scene.orientation == "pc" ? 375 : 425,
            atlas: this.config.atlas,
            normal: {
                back: "btn_cancel_back_normal.png"
            },
            hover: {
                back: "btn_cancel_back_hover.png"
            },
            clicked: {
                back: "btn_cancel_back_clicked.png"
            },
            text: {
                x: 70,
                y: 15,
                value: translate('cancel'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_cancel);

        this.btn_confirm = new IconButton(this.scene, {
            x: 340,
            y: this.scene.orientation == "pc" ? 375 : 425,
            atlas: this.config.atlas,
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 43,
                y: 15,
                value: translate('create_room'),
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.onClickedBtnCreate.bind(this)
        });
        this.mainContainer.add(this.btn_confirm);

        // this.roomTitleDropdown.setDepth(3);
        this.gameModeDropdown.setDepth(2);
        this.playerCoundDropdown.setDepth(2);
        this.blindDropdown.setDepth(1);
        this.anteDropdown.setDepth(1);
        this.mainContainer.sort("depth");
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            scale: [0.67, 0.9],
            light: 'card_light.png',
            title: {
                text: this.config.name,
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 300,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: this.config.name,
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.scene.requestLobbyList();
        this.destroy();
    }

    onChangeRoomTitle(title) {
        // this.roomTitleDropdown.setValue(title);
        // this.roomTitleDropdown.setDisplayText(title);
        // this.roomTitleDropdown.activeDropDown(false);
    }

    onChangeGameMode(second) {
        this.gameModeDropdown.setValue(second);
        this.gameModeDropdown.setDisplayText(second + translate('second_unit'));
        this.gameModeDropdown.activeDropDown(false);
    }

    onChangePlayerCount(count) {
        this.playerCoundDropdown.setValue(count);
        this.playerCoundDropdown.setDisplayText(count + translate('player_count_unit'));
        this.playerCoundDropdown.activeDropDown(false);
    }

    onChangeblind(blinds) {
        this.blindDropdown.setValue(blinds.sb);
        this.blindDropdown.setDisplayText(blinds.sb + "/" + blinds.bb);
        this.blindDropdown.activeDropDown(false);
        this.anteDropdown.activeDropDown(false);

        this.anteContent.removeAll(true);
        this.anteContent.add(new AnteItem(this.scene, {
            x: 0,
            y: 10,
            ante: 0,
            onClicked: this.onChangeAnte.bind(this)
        }));
        this.anteContent.add(new AnteItem(this.scene, {
            x: 0,
            y: 50,
            ante: blinds.sb,
            onClicked: this.onChangeAnte.bind(this)
        }));
        
        this.onChangeAnte(0);
    }

    onChangeAnte(ante) {
        this.anteDropdown.activeDropDown(false);
        this.anteDropdown.setValue(ante);
        this.anteDropdown.setDisplayText(ante > 0 ? `${translate('ante_yes')} (${ante})` : translate('ante_no'));
    }

    popupAnimation() {
        let target = this.scene.orientation == "pc" ? this.cardContainer : this.mobileDlg
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    onClickedBtnCreate() {
        if(!this.roomTitleField.value) {
            this.scene.showToast("failed", translate('create_room_error_title'));
            return;
        }
        let mode = 0;
        if(this.gameModeDropdown.value == 6) mode = 0;
        else if(this.gameModeDropdown.value == 9) mode = 1;
        else if(this.gameModeDropdown.value == 30) mode = 2;
        let roomInfo = {
            title: this.roomTitleField.value,
            password: this.passwordField.value,
            mode: mode,
            seat_count: this.playerCoundDropdown.value,
            blinds: {
                sb: this.blindDropdown.value,
                bb: this.blindDropdown.value * 2
            },
            ante: this.anteDropdown.value
        }
        this.scene.requestCreateRoom(roomInfo);
    }
}