import { IconButton } from "../button/iconbutton";
import { TextClass } from "../text/text";

export class InventoryItem extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "inventory_item_back.png");
        this.back.setOrigin(0, 0);
        this.add(this.back);

        this.icon = this.scene.add.sprite(73, 40, "ShopAtlas_" + this.scene.tema, this.config.icon);
        this.icon.setOrigin(0.5, 0.5);
        this.add(this.icon);
        this.icon.setScale(50 / this.icon.height);

        this.name = TextClass.plainText1(this.scene, {
            x: 142,
            y: 20,
            text: this.config.name,
            fontSize: 20,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.add(this.name);

        this.descriptionText = TextClass.plainText1(this.scene, {
            x: 142,
            y: 47,
            text: this.config.description,
            fontSize: 16,
            color: "#777777"
        }).setOrigin(0, 0);
        this.add(this.descriptionText);

        this.btn_confirm = new IconButton(this.scene, {
            x: 333,
            y: 15,
            atlas: this.config.atlas,
            scale: [0.6, 1],
            normal: {
                back: "btn_ok_back_normal.png"
            },
            hover: {
                back: "btn_ok_back_hover.png"
            },
            clicked: {
                back: "btn_ok_back_clicked.png"
            },
            text: {
                x: 30,
                y: 15,
                value: "Use",
                fontSize: 20,
                gradient: ['#ffe0cf', '#ffffff']
            }
        });
        this.add(this.btn_confirm);

        let scale = this.scene.orientation == "pc" ? 1 : 1.2;
        this.setScale(scale);

        this.height = 80 * scale;
        this.width = 440 * scale;
        this.click_area = {
            x: 333 * scale,
            y: 15 * scale,
            width: 100 * scale,
            height: 45 * scale
        }
    }

    onClick() {
        this.config.onClicked && this.config.onClicked(this.config)
    }

    onHover() {
        this.btn_confirm.setHover();
    }

    onRelease() {
        this.btn_confirm.setRelease();
    }

    clickedEffect() {
        this.btn_confirm.setClicked();
    }
}