import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { ContentCard } from "../../../components/card/contentcard";
import { TextClass } from "../../../components/text/text";
import * as NumberUtil from "../../../util/number";
import * as DateTimeUtil from "../../../util/datetime";
import { translate } from "../../../util/other";

export class TournamentHoldemRegisterDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: this.config.card_back,
            light: this.config.card_light,
            scale: this.config.scale || [1, 1],
            title: {
                text: this.config.rebuyFlag ? translate('tournament_register_again') : translate('tournament_register_confirm'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 150,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);

        let buyin_label = TextClass.plainText1(this.scene, {
            x: -180,
            y: 120,
            text: this.config.rebuyFlag ? translate('tournament_register_again') : translate('buyin'),
            color: "#aaaaaa",
            fontSize: 20,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
        }).setOrigin(0, 0);
        this.cardContainer.add(buyin_label);

        let buyin_value = TextClass.plainText1(this.scene, {
            x: 180,
            y: 120,
            text: this.config.rebuyFlag ? NumberUtil.convertNumberAsBlind(this.config.rebuy) : NumberUtil.convertNumberAsBlind(this.config.buyin + this.config.fee) + "(" + NumberUtil.convertNumberAsBlind(this.config.buyin) + " + " + NumberUtil.convertNumberAsBlind(this.config.fee) + ")",
            color: "#aaaaaa",
            fontSize: 20,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
        }).setOrigin(1, 0);
        this.cardContainer.add(buyin_value);

        let starttime_label = TextClass.plainText1(this.scene, {
            x: -180,
            y: 180,
            text: translate('tournament_start_time'),
            color: "#aaaaaa",
            fontSize: 20,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
        }).setOrigin(0, 0);
        this.cardContainer.add(starttime_label);

        let starttime_value = TextClass.plainText1(this.scene, {
            x: 180,
            y: 180,
            text: DateTimeUtil.convertDefaultDateTime(this.config.starttime),//"2022-03-15 12:00:00",
            color: "#aaaaaa",
            fontSize: 20,
            fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
        }).setOrigin(1, 0);
        this.cardContainer.add(starttime_value);

        if(this.config.rebuyFlag && this.config.countdown) {
            this.lefttime_label = TextClass.plainText1(this.scene, {
                x: 0,
                y: 225,
                text: translate('left_time_seconds'),
                color: "#aaaaaa",
                fontSize: 20,
            }).setOrigin(0.5, 0);
            this.cardContainer.add(this.lefttime_label);
            this.lefttime = 60;
            this.startIntervalLeftTime();
        } else {
            let warning_label = TextClass.plainText1(this.scene, {
                x: 0,
                y: 220,
                text: translate('tournament_unregister_warning'),
                color: "#aaaaaa",
                fontSize: 16,
            }).setOrigin(0.5, 0);
            this.cardContainer.add(warning_label);
        }

        this.btn_ok = new IconButton(this.scene, {
            x: 0,
            y: 270,
            atlas: this.config.atlas,
            normal: {
                back: this.config.btn_ok + '_normal.png',
            },
            hover: {
                back: this.config.btn_ok + '_hover.png'
            },
            clicked: {
                back: this.config.btn_ok + '_clicked.png'
            },
            scale: this.config.btn_scale || [1, 1],
            text: {
                x: 58,
                y: 10,
                value: translate('ok'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnOk.bind(this)
        });
        this.cardContainer.add(this.btn_ok);

        this.btn_cancel = new IconButton(this.scene, {
            x: -180,
            y: 270,
            atlas: this.config.atlas,
            normal: {
                back: this.config.btn_cancel + '_normal.png',
            },
            hover: {
                back: this.config.btn_cancel + '_hover.png'
            },
            clicked: {
                back: this.config.btn_cancel + '_clicked.png'
            },
            scale: this.config.btn_scale || [1, 1],
            text: {
                x: 58,
                y: 10,
                value: translate('cancel'),
                fontSize: 34,
                gradient: ['#ffe0cf', '#ffffff'],
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium"
            },
            onClicked: this.onClickedBtnCancel.bind(this)
        });
        this.cardContainer.add(this.btn_cancel);
    }

    close() {
        this.leftTimeInterval && clearInterval(this.leftTimeInterval);
        this.destroy();
    }

    popupAnimation() {
        let target = this.cardContainer;
        this.scene.tweens.add({
            targets: target,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.scene.ScreenWidth / 2,
                to: target.x
            },
            y: {
                from: this.scene.ScreenHeight / 2,
                to: target.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    onClickedBtnOk() {
        this.scene.requestRegisterTournament(this.config.tournamentid);
        this.close();
    }

    onClickedBtnCancel() {
        (this.config.rebuyFlag && this.scene.requestCancelTournamentRebuy) && this.scene.requestCancelTournamentRebuy(this.config.tournamentid);
        this.close();
    }

    startIntervalLeftTime() {
        this.leftTimeInterval = setInterval(() => {
            try {
                this.lefttime --;
                if(this.lefttime < 0) {
                    this.close();
                    return;
                }
                this.lefttime_label.setText(translate('left_time_seconds').replace('%s', this.lefttime))
            } catch (e) {
                clearInterval(this.leftTimeInterval);
            }
        }, 1000);
    }
}