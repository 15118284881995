export class TextClass {
    static plainText1(scene, config) { //그라디엔트 효과
        let textObject = scene.add.text(config.x, config.y, config.text, {
            fontFamily: config.fontFamily || (isMobile < 2 ? "NanumSquareB" : "NanumSquareR"),
            fontSize: (config.fontSize * (window.isMobile > 1 ? 0.9 : 1))  + "px",
            color: config.color,
            fontStyle: config.fontStyle || "bold"
        });
        if(config.gradient && config.gradient.length > 1) {
            let gradient = textObject.context.createLinearGradient(0, 0, 0, textObject.height);
            for(let i = 0 ; i < config.gradient.length ; i++) {
                gradient.addColorStop(i, config.gradient[i]);
            }
            textObject.setFill(gradient)
        }
        return textObject;
    }

    static plainText2(scene, config) {

    }
}