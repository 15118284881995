import { FilterButton } from "../../../components/button/filterbutton";
import { SelectButton } from "../../../components/button/selectbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { translate } from "../../../util/other";
import { TabHistoryData } from "./tab_history_data";
import { TabHistoryHelp } from "./tab_history_help";
import { TabHistoryReport } from "./tab_history_report";

export class TabHistory extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.dataContent = new TabHistoryData(this.scene, {
            x: 0,
            y: 0,
            atlas: this.config.atlas
        });
        this.add(this.dataContent);

        this.reportContent = new TabHistoryReport(this.scene, {
            x: 0,
            y: 0,
            atlas: this.config.atlas
        });
        this.add(this.reportContent);
        this.reportContent.setVisible(false);

        this.btn_select_type = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 842 : 490,
            y: this.scene.orientation == "pc" ? 95 : 98,
            origin: [0, 0],
            atlas: this.config.atlas,
            normal: "icon_hamberger_normal.png",
            hover: "icon_hamberger_hover.png",
            clicked: "icon_hamberger_clicked.png",
            onClicked: this.onClickBtnSelectType.bind(this)
        });
        this.add(this.btn_select_type);

        this.btn_help = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 897 : 542,
            y: this.scene.orientation == "pc" ? 95 : 98,
            origin: [0, 0],
            atlas: this.config.atlas,
            normal: "icon_help_normal.png",
            hover: "icon_help_hover.png",
            clicked: "icon_help_clicked.png",
            onClicked: this.onClickBtnHelp.bind(this)
        });
        this.add(this.btn_help);

        this.selectButton = new SelectButton(this.scene, {
            x: this.scene.orientation == "pc" ? 627 : 275,
            y: 145,
            atlas: this.config.atlas,
            back: "history_type_back.png",
            list: [{
                value: "holdem",
                x: 12,
                y: 23,
                normal: "btn_type_holdem_normal.png",
                hover: "btn_type_holdem_hover.png",
                clicked: "btn_type_holdem_clicked.png"
            }, {
                value: "tournament",
                x: 132,
                y: 23,
                normal: "btn_type_tournament_normal.png",
                hover: "btn_type_tournament_hover.png",
                clicked: "btn_type_tournament_clicked.png"
            }],
            onChange: this.onChnageType.bind(this)
        });
        this.add(this.selectButton);
        this.selectButton.setVisible(false);

        // this.filterButton = new FilterButton(this.scene, {
        //     x: this.scene.orientation == "pc" ? 315 : -15,
        //     y: this.scene.orientation == "pc" ? 122 : 126,
        //     atlas: this.config.atlas,
        //     back: "filter_back.png",
        //     button_back: "filter_btn_back.png",
        //     list: [{
        //         text: "데이터",
        //         x: 82,
        //         y: 0,
        //         value: "data"
        //     }, {
        //         text: "리포트",
        //         x: 232,
        //         y: 0,
        //         value: "report"
        //     }],
        //     color: {
        //         active: "#ffffff",
        //         inactive: "#662600"
        //     },
        //     fontSize: 20,
        //     onChange: this.onChangeFilter.bind(this)
        // });
        // this.add(this.filterButton);
    }

    onClickBtnSelectType() {
        // this.selectButton.setVisible(!this.selectButton.visible);
    }

    onClickBtnHelp() {
        let helpPage = new TabHistoryHelp(this.scene, {
            x: 0,
            y: 0,
            atlas: this.config.atlas
        });
        this.add(helpPage);
    }

    // onChangeFilter(filter) {
    //     this.dataContent.setVisible(filter == "data");
    //     this.reportContent.setVisible(filter == "report");
    //     this.selectButton.setValue("holdem");
    // }

    onChnageType(type) {
        if(this.dataContent.visible == true) {
            this.scene.requestUserProfileData(type == "cash_holdem" ? 0 : 1);
        } else {
            this.reportContent.setData(type == "cash_holdem" ? {
                type: translate('cash_holdem'),
                list: [{
                    date: "05/25",
                    games: 3,
                    hands: 197,
                    total: 840000
                }, {
                    date: "05/26",
                    games: 3,
                    hands: 197,
                    total: -58000
                }, {
                    date: "05/27",
                    games: 3,
                    hands: 197,
                    total: -337000
                }, {
                    date: "05/28",
                    games: 3,
                    hands: 197,
                    total: 240000
                }],
                chart: [
                    [30, 22, 45, -19, -33, 27, 53, 44, -38, 20, -10, -3, -29, 45, 21],
                    [20, 12, -45, 19, -23, 17, -33, 9, -13, -12, 30, -29],
                    [30, 22, 45, -19, -33, 27, 53]
                ]
            } : {
                type: translate('tournament'),
                list: [{
                    date: "05/25",
                    games: 3,
                    hands: 197,
                    total: 840000
                }, {
                    date: "05/26",
                    games: 3,
                    hands: 197,
                    total: -58000
                }, {
                    date: "05/27",
                    games: 3,
                    hands: 197,
                    total: -337000
                }, {
                    date: "05/28",
                    games: 3,
                    hands: 197,
                    total: 240000
                }],
                chart: [
                    [10, 32, -5, -19, 33, 17, 9, -44, -8, 20, -10, -13, 29, 45, 21],
                    [-10, 12, -55, 9, -3, 47, -23, 19, -23, -32, 20, 29],
                    [10, 32, -5, -19, 33, 17, 9]
                ]
            })
        }
    }

    setHistoryData(type, profile) {
        this.dataContent.setData({
            type: type == 0 ? translate('cash_holdem') : translate('tournament'),
            vpip: profile.vpip,
            pfr: profile.pfr,
            wtsd: profile.wtsd,
            wssd: profile.wssd,
            still: profile.steal,
            check_raise: profile.checkraise,
            three_bet: profile.threebet,
            fold_to_three_bet: profile.foldtothreebet,
            c_bet: profile.cbet,
            fold_to_c_bet: profile.foldtocbet,
            total_game: profile.totalgame,
            total_hand: profile.totalhand,
            winner: profile.winnercount,
            ROI: profile.roi
        })
    }
}