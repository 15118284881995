import { ScrollView } from "../../../../components/container/scrollview";
import { CardItem } from "./card_item";
import CommonDef from "../../../../def/commondef";

export class ChangeCardDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph()
    }

    initGraph() {
        this.displayImg = this.scene.add.sprite(515 / 2, 41 + 273 / 2, "TemaAtlas", CommonDef.Me.tabletemaicon + "_" + this.scene.tema + ".png");
        this.displayImg.setOrigin(0.5, 0.5);
        this.add(this.displayImg);
        this.displayImg.setScale(515 / this.displayImg.width, 273 / this.displayImg.height);
        
        this.cardImg = this.scene.add.sprite(515 / 2, 41 + 273 / 2, "TemaAtlas", CommonDef.Me.cardtemaicon + "_" + this.scene.tema + ".png");
        this.cardImg.setOrigin(0.5, 0.5);
        this.add(this.cardImg);
        this.cardImg.setScale(0.6)

        this.displayContainer = this.scene.add.container(0, 0);

        this.scrollView = new ScrollView(this.scene, {
            x: this.scene.orientation == "pc" ? 573 : 0,
            y: this.scene.orientation == "pc" ? 41 : 370,
            width: this.scene.orientation == "pc" ? 295 : 512,
            height: this.scene.orientation == "pc" ? 275 : 295,
            atlas: "DialogAtlas",
            thumb: 'scroll_thumb_red.png',
            content: this.displayContainer,
            mask: {
                x: this.scene.orientation == "pc" ? 1090 : 120,
                y: this.scene.orientation == "pc" ? 548 : 705
            },
            action_rect: {
                width: this.scene.orientation == "pc" ? 270 : 490,
                height: this.scene.orientation == "pc" ? 275 : 295,
            }
        });
        this.add(this.scrollView);
    }

    setCardContent(content) {
        this.displayContainer.removeAll(true);
        for(let i = 0 ; i < content.length ; i ++) {
            let x = 0;
            if(this.scene.orientation == "mobile" && i % 2 == 1) x = 255;
            let y = (this.scene.orientation == "pc" ? 183 : 200) * Math.floor(i / (this.scene.orientation == "pc" ? 1 : 2));
            this.displayContainer.add(new CardItem(this.scene, {
                x: x,
                y: y,
                atlas: this.config.atlas,
                ...content[i]
            }))
        }
        this.displayContainer.height = Math.ceil(content.length / (this.scene.orientation == "pc" ? 1 : 2)) * (this.scene.orientation == "pc" ? 183 : 200);
    }
}