import { Avatar } from "../../../components/avatar.js/avatar";

export class AvatarDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.back.fillRect(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 450 : 840);
        this.add(this.back);
        let cols = this.scene.orientation == "pc" ? 7 : 4;
        for(let i = 0 ; i < 21 ; i ++) {
            let x = (i % cols) * (this.scene.orientation == "pc" ? 135 : 150) + 60;
            let y = Math.floor(i / cols) * 130;
            let avatar_index = i % 12 + 1;
            let avatar = new Avatar(this.scene, {
                x: x,
                y: y,
                atlas: "AvatarAtlas",
                avatar: (avatar_index == 10 ? "9" : avatar_index) + ".png",
                mask: {
                    x: x + (this.scene.orientation == "pc" ? 490 : 80),
                    y: y + 60 + (this.scene.orientation == "pc" ? 413 : 251)
                },
                // onClick: this.onClickedAvatar.bind(this)
            });
            this.add(avatar);
        }
    }

    close() {
        this.destroy();
    }
}