import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { ContentCard } from "../../../../components/card/contentcard";
import { MobileDialog } from "../../../../components/dialog/mobile_dialog";
import { translate } from "../../../../util/other";

export class EventItemAcceptDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.scene.orientation == "pc" ? this.initGraphForPC() : this.initGraphForMobile();
        let icon_event_item = this.scene.add.sprite(this.scene.orientation == "pc" ? 0 : 375, this.scene.orientation == "pc" ? 200 : 330, this.config.atlas, "icon_event_item.png").setOrigin(0.5, 0.5);
        (this.scene.orientation == "pc" ? this.cardContainer : this. mobileDlg).add(icon_event_item);
        let btn = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 0 : 375,
            y: this.scene.orientation == "pc" ? 310 : 495,
            atlas: this.config.atlas,
            origin: [0.5, 0],
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 0,
                x_pos: true,
                y: this.scene.orientation == "pc" ? 15 : 18,
                origin: [0.5, 0],
                value: translate('confirm'),
                fontSize: 28,
                gradient: ["#ffe0cf", "#ffffff"]
            },
            onClicked: this.close.bind(this)
        });
        (this.scene.orientation == "pc" ? this.cardContainer : this. mobileDlg).add(btn);
    }

    initGraphForPC() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.7
            }
        });
        this.backGraphic.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.backGraphic);

        let rect = new Phaser.Geom.Rectangle(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.cardContainer = new ContentCard(this.scene, {
            x: this.config.x,
            y: this.config.y,
            atlas: this.config.atlas,
            back: 'card_back.png',
            scale: [0.43, 0.6],
            light: 'card_back_light.png',
            title: {
                text: translate('congratulations'),
                y: 46,
                fontFamily: isMobile < 2 ? "GmarketSansBold" : "GmarketSansMedium",
                fontSize: 34,
                gradient: ['#ffe1d0', '#ffffff']
            }
        })
        this.add(this.cardContainer);

        this.btn_close = new SpriteButton(this.scene, {
            x: 220,
            y: 46,
            origin: [0, 0],
            atlas: "DialogAtlas",
            normal: "close_" + this.scene.tema + ".png",
            onClicked: this.close.bind(this)
        });
        this.cardContainer.add(this.btn_close);
    }

    initGraphForMobile() {
        this.mobileDlg = new MobileDialog(this.scene, {
            title: translate('congratulations'),
            onClickClose: this.close.bind(this)
        });
        this.add(this.mobileDlg);
    }

    close() {
        this.destroy();
    }
}