import { IconButton } from "../../../components/button/iconbutton";
import { TextClass } from "../../../components/text/text";
import { translate } from "../../../util/other";

export class ConnectLimitDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.sprite(0, 0, this.config.atlas, "login_form_back.png").setOrigin(0.5, 0);
        this.add(this.back);
        this.title = TextClass.plainText1(this.scene, {
            x: 0,
            y: 60,
            text: translate("network_error_title"),
            fontSize: 48,
            fontFamily: isMobile < 2 ? "GMarketSansBold" : "GmarketSansMedium",
            gradient: ['#ffe0cf', '#ffffff']
        }).setOrigin(0.5, 0);
        this.add(this.title);
        this.description = TextClass.plainText1(this.scene, {
            x: 0,
            y: 160,
            text: translate("network_error_content2"),
            fontSize: 30,
            color: "#ffffff"
        }).setOrigin(0.5, 0);
        this.description.setStyle({
            wordWrap: { width: 400, useAdvancedWrap: true }
        });
        this.description.setLineSpacing(15);
        this.add(this.description);

        this.btn_confirm = new IconButton(this.scene, {
            x: -116,
            y: 280,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_login_back_normal.png'
            },
            hover: {
                back: 'btn_login_back_hover.png'
            },
            clicked: {
                back: 'btn_login_back_clicked.png'
            },
            text: {
                x: 80,
                y: 16,
                value: translate('ok'),
                fontSize: 32,
                gradient: ['#ffe2d1', '#ffffff']
            },
            onClicked: this.onClickBtnConfirm.bind(this)
        })
        this.add(this.btn_confirm);

        this.progressBack = this.scene.add.graphics({
            x: -200,
            y: 300,
            fillStyle: {
                color: 0x222222,
                alpha: 1
            }
        });
        this.progressBack.fillRoundedRect(0, 0, 400, 30, 15);
        this.add(this.progressBack);

        this.progressBar = this.scene.add.graphics({
            x: -200,
            y: 300
        });
        this.add(this.progressBar);

        this.initState();
    }

    initState() {
        this.btn_confirm.setVisible(true);
        this.progressBack.setVisible(false);
        this.progressBar.setVisible(false);
    }

    onClickBtnConfirm() {
        this.btn_confirm.setVisible(false);
        this.progressBack.setVisible(true);
        this.progressBar.setVisible(true);
        this.progressBar.clear();
        this.scene.tweens.addCounter({
            from: 0,
            to: 100,
            duration: 2000,
            onUpdate: (tween) => {
                let percent = tween.getValue() / 100;
                this.progressBar.clear();
                this.progressBar.fillStyle(0xed470b, 1);
                this.progressBar.fillRoundedRect(0, 0, 400 * percent, 30, 15);
            },
            onComplete: () => {
                this.callbackTimeout();
            }
        })
    }

    callbackTimeout() {
        this.close();
        this.scene.pingToServer();
    }

    close() {
        this.setVisible(false);
    }
}