import { FilterButton } from "../../../../components/button/filterbutton";
import { IconButton } from "../../../../components/button/iconbutton";
import { SpriteButton } from "../../../../components/button/spritebutton";
import { TextClass } from "../../../../components/text/text";
import { ChangeTemaDialog } from "./change_tema_dialog";
import CommonDef from "../../../../def/commondef";
import { translate } from "../../../../util/other";

export class SettingTema extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.add(this.back);
        this.back.fillRect(0, 0, this.scene.orientation == "pc" ? 940 : 590, this.scene.orientation == "pc" ? 480 : 580);

        this.filterText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 237 : 0,
            y: this.scene.orientation == "pc" ? 82 : 45,
            text: translate('ui_mode'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(0, 0.5);
        this.add(this.filterText);
        
        this.filterButton = new FilterButton(this.scene, {
            x: this.scene.orientation == "pc" ? 330 : -17,
            y: this.scene.orientation == "pc" ? 82 : 105,
            atlas: this.config.atlas,
            back: "voice_filter_back.png",
            button_back: "setting_filter_btn_back.png",
            list: [{
                text: translate('ui_light'),
                x: 69,
                y: 0,
                value: null//"blue"
            }, {
                text: translate('ui_dark'),
                x: 174,
                y: 0,
                value: "red"
            }],
            color: {
                active: "#ffffff",
                inactive: "#662600"
            },
            fontSize: 20,
            onChange: this.onChnageFilter.bind(this)
        });
        this.add(this.filterButton);
        this.filterButton.setValue("red");

        this.displayText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 237 : 0,
            y: this.scene.orientation == "pc" ? 190 : 205,
            text: translate('game_display'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(0, 0.5);
        this.add(this.displayText);

        this.btn_display_change = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 405 : 217,
            y: this.scene.orientation == "pc" ? 197 : 210,
            atlas: this.config.atlas,
            origin: [0, 0.5],
            normal: "btn_edit_normal_" + this.scene.orientation + ".png",
            hover: "btn_edit_hover_" + this.scene.orientation + ".png",
            clicked: "btn_edit_clicked_" + this.scene.orientation + ".png",
            onClicked: () => {
                this.onClickedBtnTemaChange("display");
            }
        });
        this.add(this.btn_display_change);

        this.displayContainer = this.scene.add.container(this.scene.orientation == "pc" ? 237 : 0, this.scene.orientation == "pc" ? 223 : 246);
        this.add(this.displayContainer);
        this.displayImg = this.scene.add.sprite(0, 0);
        this.displayImg.setOrigin(0, 0);
        this.displayContainer.add(this.displayImg);

        this.displayNameBack = this.scene.add.graphics({
            x: 0,
            y: this.displayImg.height,
            fillStyle: {
                color: 0x615757,
                alpha: 0.8
            }
        });
        this.displayNameBack.fillRect(0, 0, this.scene.orientation == "pc" ? 92 : 118, this.scene.orientation == "pc" ? -28 : -36);
        this.displayContainer.add(this.displayNameBack);

        this.displayName = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 46 : 59,
            y: this.displayImg.height - (this.scene.orientation == "pc" ? 14 : 18),
            text: "",
            fontSize: 23,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.displayContainer.add(this.displayName);


        this.cardText = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 495 : 327,
            y: this.scene.orientation == "pc" ? 190 : 205,
            text: translate('card_style'),
            fontFamily: "GmarketSansMedium",
            fontSize: this.scene.orientation == "pc" ? 22 : 20,
            color: "#f58a00"
        }).setOrigin(0, 0.5);
        this.add(this.cardText);

        this.btn_card_change = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 663 : 544,
            y: this.scene.orientation == "pc" ? 197 : 210,
            atlas: this.config.atlas,
            origin: [0, 0.5],
            normal: "btn_edit_normal_" + this.scene.orientation + ".png",
            hover: "btn_edit_hover_" + this.scene.orientation + ".png",
            clicked: "btn_edit_clicked_" + this.scene.orientation + ".png",
            onClicked: () => {
                this.onClickedBtnTemaChange("card");
            }
        });
        this.add(this.btn_card_change);

        this.cardContainer = this.scene.add.container(this.scene.orientation == "pc" ? 495 : 327, this.scene.orientation == "pc" ? 223 : 246);
        this.add(this.cardContainer);
        this.cardImg = this.scene.add.sprite(0, 0);
        this.cardImg.setOrigin(0, 0);
        this.cardContainer.add(this.cardImg);

        this.cardNameBack = this.scene.add.graphics({
            x: 0,
            y: this.cardImg.height,
            fillStyle: {
                color: 0x615757,
                alpha: 0.8
            }
        });
        this.cardNameBack.fillRect(0, 0, this.scene.orientation == "pc" ? 92 : 118, this.scene.orientation == "pc" ? -28 : -36);
        this.cardContainer.add(this.cardNameBack);

        this.cardName = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 46 : 59,
            y: this.displayImg.height - (this.scene.orientation == "pc" ? 14 : 18),
            text: "",
            fontSize: 23,
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);
        this.cardContainer.add(this.cardName);

        this.scene.orientation == "pc" && this.displayContainer.setScale(0.78);
        this.scene.orientation == "pc" && this.cardContainer.setScale(0.78);

        this.btn_cancel = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 277 : 80,
            y: this.scene.orientation == "pc" ? 403 : 488,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_cancel_back_normal.png',
            },
            hover: {
                back: 'btn_cancel_back_hover.png'
            },
            clicked: {
                back: 'btn_cancel_back_clicked.png'
            },
            text: {
                x: 70,
                y: 15,
                value: translate('cancel'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_cancel);

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 480 : 310,
            y: this.scene.orientation == "pc" ? 403 : 488,
            atlas: this.config.atlas,
            normal: {
                back: 'btn_confirm_back_normal.png',
            },
            hover: {
                back: 'btn_confirm_back_hover.png'
            },
            clicked: {
                back: 'btn_confirm_back_clicked.png'
            },
            text: {
                x: 45,
                y: 15,
                value: translate('save'),
                fontSize: 28,
                gradient: ['#d6cece', '#ffffff']
            },
            onClicked: this.close.bind(this)
        });
        this.add(this.btn_confirm);
    }

    close() {
        this.config.close && this.config.close();
    }

    onChnageFilter(value) {
        // this.scene.tema = value;
        // localStorage.setItem('tema', value);
        // this.displayImg && this.displayImg.setTexture("TemaAtlas", CommonDef.Me.tabletemaicon + "_" + this.scene.tema + ".png");
        // this.cardImg && this.cardImg.setTexture("TemaAtlas", CommonDef.Me.cardtemaicon + "_" + this.scene.tema + ".png");
    }

    onClickedBtnTemaChange(type) {
        let changeTemaDialog = new ChangeTemaDialog(this.scene, {
            x: 0,
            y: this.scene.orientation == "pc" ? 50 : 32,
            atlas: this.config.atlas,
            type: type
        });
        this.add(changeTemaDialog);
        changeTemaDialog.underlineTabContainer.tabs[0].content.setDisplayContent(this.tableTamaList);
        changeTemaDialog.underlineTabContainer.tabs[1].content.setCardContent(this.cardTemaList);
    }

    setTableTemaList(list) {
        this.tableTamaList = list;
        this.displayImg.setTexture("TemaAtlas", CommonDef.Me.tabletemaicon + "_" + this.scene.tema + ".png");
        this.displayName.setText(list.find(x => x.id == CommonDef.Me.tabletemaid).name);
    }

    setCardTemaList(list) {
        this.cardTemaList = list;
        this.cardImg.setTexture("TemaAtlas", CommonDef.Me.cardtemaicon + "_" + this.scene.tema + ".png");
        this.cardName.setText(list.find(x => x.id == CommonDef.Me.cardtemaid).name);
    }
}