import { TabHoldemTableRow } from "./tab_holdem_table_row";
import LobbyDef from '../../../def/lobbydef';

export class TabHoldemScrollViewContent extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        let content = this.config.content || [];
        for (var i = 0 ; i < content.length ; i++) {
            let row = new TabHoldemTableRow(this.scene, {
                x: 0,
                y: i * LobbyDef.tabHoldemTable.content[this.scene.orientation].row_height,
                atlas: this.config.atlas,
                back: "tab_holdem_table_row_back.png",
                content: content[i]
            })
            this.add(row);
        }
        this.height = content.length * LobbyDef.tabHoldemTable.content[this.scene.orientation].row_height;
    }

    setContent(content) {
        this.removeAll(true);
        this.config.content = content;
        this.initGraph();
    }
}