class BufferPack {
    //el = null;
    //bBE = false;
    //_sPattern = null;
    //_lenLut = null;
    //_elLut = null;
    constructor() {
        this.el = null;
        this.bBE = false;
        //this._super()
        this._sPattern = '(\\d+)?([AxcbBhHsSfdiIlL])(\\(([a-zA-Z0-9]+)\\))?'
        this._lenLut = {
            'A': 1, 'x': 1, 'c': 1, 'b': 1, 'B': 1, 'h': 2, 'H': 2, 's': 1,
            'S': 1, 'f': 4, 'd': 8, 'i': 4, 'I': 4, 'l': 8, 'L': 8
        }
        this._elLut = {
            'A': { en: this._EnArray.bind(this), de: this._DeArray.bind(this) },
            's': { en: this._EnString.bind(this), de: this._DeString.bind(this) },
            'S': { en: this._EnString.bind(this), de: this._DeNullString.bind(this) },
            'c': { en: this._EnChar.bind(this), de: this._DeChar.bind(this) },
            'b': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 1, bSigned: true, min: 0, max: Math.pow(2, 8) - 1 },
            'B': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 1, bSigned: false, min: 0, max: Math.pow(2, 8) - 1 },
            'h': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 2, bSigned: true, min: 0, max: Math.pow(2, 16) - 1 },
            'H': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 2, bSigned: false, min: 0, max: Math.pow(2, 16) - 1 },
            'i': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 4, bSigned: true, min: 0, max: Math.pow(2, 32) - 1 },
            'I': { en: this._EnInt.bind(this), de: this._DeInt.bind(this), len: 4, bSigned: false, min: 0, max: Math.pow(2, 32) - 1 },
            'l': { en: this._EnInt64.bind(this), de: this._DeInt.bind(this), len: 8, bSigned: true, min: 0, max: Math.pow(2, 64) - 1 },
            'L': { en: this._EnInt64.bind(this), de: this._DeInt.bind(this), len: 8, bSigned: false, min: 0, max: Math.pow(2, 64) - 1 },
            'f': { en: this._En754.bind(this), de: this._De754.bind(this), len: 4, mLen: 23, rt: Math.pow(2, -24) - Math.pow(2, -77) },
            'd': { en: this._En754.bind(this), de: this._De754.bind(this), len: 8, mLen: 52, rt: 0 }
        }
        return true
    };
    _DeArray(a, p, l) {
        return [a.slice(p, p + l)]
    };
    _EnArray(a, p, l, v,isheader) {
        for (var i = 0; i < l; a[p + i] = v[i] ? v[i] : 0, i++);
    };
    _DeChar(a, p) {
        //return String.fromCharCode(a[p]);
        return a[p]
    };
    _EnChar(a, p, v, isheader) {
        if (isheader) {
            a[p] = v.charCodeAt(0);
        }
        else {
            a[p + 0] = 4;
            a[p + 1] = v.charCodeAt(0);
        }
    };
    _DeInt(a, p) {
        var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, rv, i, f;
        for (rv = 0, i = lsb, f = 1; i != stop; rv += (a[p + i] * f), i += nsb, f *= 256);
        if (this.el.bSigned && (rv & Math.pow(2, this.el.len * 8 - 1))) {
            rv -= Math.pow(2, this.el.len * 8);
        }
        return rv
    };
    _EnInt(a, p, v, isheader) {
        if (isheader) {
            var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, i;
            v = (v < this.el.min) ? this.el.min : (v > this.el.max) ? this.el.max : v;
            for (i = lsb; i !== stop; a[p + i] = v & 0xff, i += nsb, v >>= 8);
        }
        else {
            a[p + 0] = 9;
            var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, i;
            v = (v < this.el.min) ? this.el.min : (v > this.el.max) ? this.el.max : v;
            for (i = lsb; i !== stop; a[p + 1 + i] = v & 0xff, i += nsb, v >>= 8);
        }
    }
    _DeInt64(a, p) {
        var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, rv, i, f;
        for (rv = 0, i = lsb, f = 1; i != stop; rv += (a[p + i] * f), i += nsb, f *= 256);
        if (this.el.bSigned && (rv & Math.pow(2, this.el.len * 8 - 1))) {
            rv -= Math.pow(2, this.el.len * 8);
        }
        return rv
    };
    _EnInt64(a, p, v, isheader) {
        if (isheader) {
            var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, i;
            v = (v < this.el.min) ? this.el.min : (v > this.el.max) ? this.el.max : v;
            for (i = lsb; i !== stop; a[p + i] = v & 0xffff, i += nsb, v >>= 8);
        }
        else {
            a[p + 0] = 11;
            var lsb = this.bBE ? (this.el.len - 1) : 0, nsb = this.bBE ? -1 : 1, stop = lsb + nsb * this.el.len, i;
            v = (v < this.el.min) ? this.el.min : (v > this.el.max) ? this.el.max : v;
            for (i = lsb; i !== stop; a[p + 1 + i] = v & 0xffff, i += nsb, v >>= 8);
        }
    }

    byteLength(str) {
        // returns the byte length of an utf8 string
        var s = str.length;
        for (var i = str.length - 1; i >= 0; i--) {
            var code = str.charCodeAt(i);
            if (code > 0x7f && code <= 0x7ff) s++;
            else if (code > 0x7ff && code <= 0xffff) s += 2;
            if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
        }
        return s;
    };

    fromUTF8Array(data, p, l) { // array of bytes
        var str = '', i;
        for (i = p; i < l; i++) {
            var value = data[i];

            if (value < 0x80) {
                str += String.fromCharCode(value);
            } else if (value > 0xBF && value < 0xE0) {
                str += String.fromCharCode((value & 0x1F) << 6 | data[i + 1] & 0x3F);
                i += 1;
            } else if (value > 0xDF && value < 0xF0) {
                str += String.fromCharCode((value & 0x0F) << 12 | (data[i + 1] & 0x3F) << 6 | data[i + 2] & 0x3F);
                i += 2;
            } else {
                // surrogate pair
                var charCode = ((value & 0x07) << 18 | (data[i + 1] & 0x3F) << 12 | (data[i + 2] & 0x3F) << 6 | data[i + 3] & 0x3F) - 0x010000;

                str += String.fromCharCode(charCode >> 10 | 0xD800, charCode & 0x03FF | 0xDC00);
                i += 3;
            }
        }

        return str;
    };

    // ASCII character strings
    _DeString(a, p, l) {
        //for (var rv = new Array(l), i = 0; i < l; rv[i] = String.fromCharCode(a[p+i]), i++);
        //return rv.join('');
        var rv = this.fromUTF8Array(a, p, l);
        return rv;
    };
    _EnString(a, p, l, v, isheader) {
        var sprLength = this.byteLength(v);
        //var lsb = this.bBE ? 1 : 0;
        //var nsb = this.bBE ? -1 : 1;
        //var stop = lsb + nsb * 2;
        if (!isheader) {
            var lsb = 0;
            var nsb = 1;
            var stop = 4;
            a[p + 0] = 18;
            for (i = lsb; i !== stop; a[p + 1 + i] = sprLength & 0xff, i += nsb, sprLength >>= 8);
            for (var i = 0, j = 0; i < l; i++) {
                var charcode = v.charCodeAt(i);

                if (charcode < 0x80) {
                    a[p + 5 + j] = charcode ? charcode : 0;

                    j += 1;
                }
                else if (charcode < 0x800) {
                    a[p + 5 + j] = charcode ? (0xc0 | (charcode >> 6)) : 0;
                    a[p + 5 + j + 1] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    j += 2;
                }
                else if (charcode < 0xd800 || charcode >= 0xe000) {
                    a[p + 5 + j] = charcode ? (0xe0 | (charcode >> 12)) : 0;
                    a[p + 5 + j + 1] = charcode ? (0x80 | ((charcode >> 6) & 0x3f)) : 0;
                    a[p + 5 + j + 2] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    j += 3;
                }
                // surrogate pair
                else {
                    // UTF-16 encodes 0x10000-0x10FFFF by
                    // subtracting 0x10000 and splitting the
                    // 20 bits of 0x0-0xFFFFF into two halves
                    charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (charcode & 0x3ff));

                    a[p + 5 + j] = charcode ? (0xf0 | (charcode >> 18)) : 0;
                    a[p + 5 + j + 1] = charcode ? (0x80 | ((charcode >> 12) & 0x3f)) : 0;
                    a[p + 5 + j + 2] = charcode ? (0x80 | ((charcode >> 6) & 0x3f)) : 0;
                    a[p + 5 + j + 3] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    i++;

                    j += 4;
                }
            }
        }
        else {
            for (var i = 0, j = 0; i < l; i++) {
                var charcode = v.charCodeAt(i);

                if (charcode < 0x80) {
                    a[p + j] = charcode ? charcode : 0;

                    j += 1;
                }
                else if (charcode < 0x800) {
                    a[p + j] = charcode ? (0xc0 | (charcode >> 6)) : 0;
                    a[p + j + 1] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    j += 2;
                }
                else if (charcode < 0xd800 || charcode >= 0xe000) {
                    a[p + j] = charcode ? (0xe0 | (charcode >> 12)) : 0;
                    a[p + j + 1] = charcode ? (0x80 | ((charcode >> 6) & 0x3f)) : 0;
                    a[p + j + 2] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    j += 3;
                }
                // surrogate pair
                else {
                    // UTF-16 encodes 0x10000-0x10FFFF by
                    // subtracting 0x10000 and splitting the
                    // 20 bits of 0x0-0xFFFFF into two halves
                    charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (charcode & 0x3ff));

                    a[p + j] = charcode ? (0xf0 | (charcode >> 18)) : 0;
                    a[p + j + 1] = charcode ? (0x80 | ((charcode >> 12) & 0x3f)) : 0;
                    a[p + j + 2] = charcode ? (0x80 | ((charcode >> 6) & 0x3f)) : 0;
                    a[p + j + 3] = charcode ? (0x80 | (charcode & 0x3f)) : 0;

                    i++;

                    j += 4;
                }
            }
        }
        //for (i = lsb; i !== stop; a[p + i] = sprLength & 0xff, i += nsb, sprLength >>= 8);

        
    };

    // ASCII character strings null terminated
    _DeNullString(a, p, l, v) {
        var str = this._DeString(a, p, l, v);
        return str.substring(0, str.length);
    };

    // Little-endian N-bit IEEE 754 floating point
    _De754(a, p) {
        var s, e, m, i, d, nBits, mLen, eLen, eBias, eMax;

        mLen = this.el.mLen;
        eLen = this.el.len * 8 - this.el.mLen - 1;
        eMax = (1 << eLen) - 1;
        eBias = eMax >> 1;

        i = this.bBE ? 0 : (this.el.len - 1); d = this.bBE ? 1 : -1; s = a[p + i]; i += d; nBits = -7;
        for (e = s & ((1 << (-nBits)) - 1), s >>= (-nBits), nBits += eLen; nBits > 0; e = e * 256 + a[p + i], i += d, nBits -= 8);
        for (m = e & ((1 << (-nBits)) - 1), e >>= (-nBits), nBits += mLen; nBits > 0; m = m * 256 + a[p + i], i += d, nBits -= 8);

        switch (e) {
            case 0:
                // Zero, or denormalized number
                e = 1 - eBias;
                break;
            case eMax:
                // NaN, or +/-Infinity
                return m ? NaN : ((s ? -1 : 1) * Infinity);
            default:
                // Normalized number
                m = m + Math.pow(2, mLen);
                e = e - eBias;
                break;
        }
        return (s ? -1 : 1) * m * Math.pow(2, e - mLen);
    };
    _En754(a, p, v, isheader) {
        var s, e, m, i, d, c, mLen, eLen, eBias, eMax;
        mLen = this.el.mLen, eLen = this.el.len * 8 - this.el.mLen - 1, eMax = (1 << eLen) - 1, eBias = eMax >> 1;

        s = v < 0 ? 1 : 0;
        v = Math.abs(v);
        if (isNaN(v) || (v == Infinity)) {
            m = isNaN(v) ? 1 : 0;
            e = eMax;
        } else {
            e = Math.floor(Math.log(v) / Math.LN2);			// Calculate log2 of the value

            if (v * (c = Math.pow(2, -e)) < 1) {
                e--; c *= 2;						// Math.log() isn't 100% reliable
            }

            // Round by adding 1/2 the significand's LSD
            if (e + eBias >= 1) {
                v += this.el.rt / c;                                           // Normalized:  mLen significand digits
            } else {
                v += this.el.rt * Math.pow(2, 1 - eBias);                        // Denormalized:  <= mLen significand digits
            }

            if (v * c >= 2) {
                e++; c /= 2;						// Rounding can increment the exponent
            }

            if (e + eBias >= eMax) {
                // Overflow
                m = 0;
                e = eMax;
            } else if (e + eBias >= 1) {
                // Normalized - term order matters, as Math.pow(2, 52-e) and v*Math.pow(2, 52) can overflow
                m = (v * c - 1) * Math.pow(2, mLen);
                e = e + eBias;
            } else {
                // Denormalized - also catches the '0' case, somewhat by chance
                m = v * Math.pow(2, eBias - 1) * Math.pow(2, mLen);
                e = 0;
            }
        }

        for (i = this.bBE ? (this.el.len - 1) : 0, d = this.bBE ? -1 : 1; mLen >= 8; a[p + i] = m & 0xff, i += d, m /= 256, mLen -= 8);
        for (e = (e << mLen) | m, eLen += mLen; eLen > 0; a[p + i] = e & 0xff, i += d, e /= 256, eLen -= 8);
        a[p + i - d] |= s * 128;
    };

    // Class data

    // Unpack a series of n elements of size s from array a at offset p with fxn
    _UnpackSeries(n, s, a, p) {
        for (var fxn = this.el.de, rv = [], i = 0; i < n; rv.push(fxn(a, p + i * s)), i++);
        return rv;
    };
    // Pack a series of n elements of size s from array v at offset i to array a at offset p with fxn
    _PackSeries(n, s, a, p, v, i, isheader) {
        for (var fxn = this.el.en, o = 0; o < n; fxn(a, p + o * s, v[i + o], isheader), o++);
    };
    _zip(keys, values) {
        var result = {};

        for (var i = 0; i < keys.length; i++) {
            result[keys[i]] = values[i];
        }

        return result;
    };
    // Unpack the octet array a, beginning at offset p, according to the fmt string
    unpack(fmt, a, p) {
        // Set the private this.bBE flag based on the format string - assume big-endianness
        this.bBE = (fmt.charAt(0) != '<');

        p = p ? p : 0;
        var re = new RegExp(this._sPattern, 'g');
        var m;
        var n;
        var s;
        var rk = [];
        var rv = [];

        while (m = re.exec(fmt)) {
            n = ((m[1] == undefined) || (m[1] == '')) ? 1 : parseInt(m[1]);

            if (m[2] === 'S') { // Null term string support
                n = 0; // Need to deal with empty  null term strings
                while (a[p + n] != null && a[p + n] != 0) {
                    n++;
                }
                //n++; // Add one for null byte
            }

            s = this._lenLut[m[2]];

            if ((p + n * s) > a.length) {
                return undefined;
            }

            switch (m[2]) {
                case 'A': case 's': case 'S':
                    rv.push(this._elLut[m[2]].de(a, p, n));
                    break;
                case 'c': case 'b': case 'B': case 'h': case 'H':
                case 'i': case 'I': case 'l': case 'L': case 'f': case 'd':
                    this.el = this._elLut[m[2]];
                    rv.push(this._UnpackSeries(n, s, a, p));
                    break;
            }

            rk.push(m[4]); // Push key on to array

            p += n * s;
        }

        rv = Array.prototype.concat.apply([], rv)

        if (rk.indexOf(undefined) !== -1) {
            return rv;
        } else {
            return this._zip(rk, rv);
        }
    };
    // Pack the supplied values into the octet array a, beginning at offset p, according to the fmt string
    packTo(fmt, a, p, values, isheader) {
        this.bBE = (fmt.charAt(0) != '<');
        var re = new RegExp(this._sPattern, 'g');
        var m;
        var n;
        var s;
        var i = 0;
        var j;
        // Set the private this.bBE flag based on the format string - assume big-endianness
        while (m = re.exec(fmt)) {
            n = ((m[1] == undefined) || (m[1] == '')) ? 1 : parseInt(m[1]);
            // Null term string support
            if (m[2] === 'S') {
                //n = values[i].length + 2; // Add one for null byte
                n = values[i].length; // Add one for null byte
            }
            s = this._lenLut[m[2]];

            if ((p + n * s) > a.length) {
                return false;
            }

            switch (m[2]) {
                case 'A': case 's': case 'S':
                    if ((i + 1) > values.length) { return false; }
                    this._elLut[m[2]].en(a, p, n, values[i], isheader);
                    i += 1;
                    break;
                case 'c': case 'b': case 'B': case 'h': case 'H':
                case 'i': case 'I': case 'l': case 'L': case 'f': case 'd':
                    this.el = this._elLut[m[2]];
                    if ((i + n) > values.length) { return false; }
                    this._PackSeries(n, s, a, p, values, i, isheader);
                    i += n;
                    break;
                case 'x':
                    for (j = 0; j < n; j++) { a[p + j] = 0; }
                    break;
            }
            p += n * s;
        }
        return a;
    };
    // Pack the supplied values into a new octet array, according to the fmt string
    pack(fmt, values, isheader) {
        //return this.packTo(fmt, new Buffer(this.calcLength(fmt, values)), 0, values);
        return this.packTo(fmt, new Array(this.calcLength(fmt, values, isheader)), 0, values, isheader);
    };
    // Determine the number of bytes represented by the format string
    calcLength(format, values,isheader) {
        var re = new RegExp(this._sPattern, 'g'), m, sum = 0, i = 0;
        while (m = re.exec(format)) {
            var n = (((m[1] == undefined) || (m[1] == '')) ? 1 : parseInt(m[1])) * this._lenLut[m[2]];

            if (m[2] === 'S') {
                //n = values[i].length + 2; // Add one for null byte
                if (isheader) {
                    n = values[i].length + 2; // updated by bismarck
                }
                else {
                    n = values[i].length + 4; // updated by bismarck
                }
            }
            sum += n;
            if (m[2] !== 'x') {
                i++;
            }
        }
        if (isheader) {
            return sum;//updated by bismarck
        }
        else {
            return sum + 1;//updated by bismarck
        }
    }
}
module.exports = BufferPack