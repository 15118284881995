export default {
    // 로딩
    "network_error_title": "네트워크 오류",
    "network_error_content1": "서버에 접속이 실패했습니다.\n네트워크 연결을 확인해주세요.",
    "network_error_content2": "네트워크 접속 초과로 인하여 잠시후에 다시 시도해주세요.",
    "version_warning_title": "업데이트 경고",
    "version_warning_content": "버전이 업데이트 되었습니다.\n최신버전으로 업데이트 해주세요.",
    "version_update": "업데이트",
    // 마감

    // 로그인, 가입
    "login": "로그인",
    "register": "회원가입",
    "login_id": "아이디",
    "password": "비밀번호",
    "require_login_id": "로그인 아이디를 입력해주세요.",
    "require_password": "비밀번호를 입력해주세요.",
    "login_error_already_login": "이미 로그인했습니다.",
    "login_error_wrong_account": "아이디/비밀번호가 정확하지 않습니다.",
    "login_error_blocked_account": "계정이 차단되었습니다.",
    "double_login": "%1에서 중복로그인 하여 로그아웃되었습니다.",
    "maintaining": "점검중입니다. 후에 다시 시도해주세요.",
    "login_error_pending_account": "관리자의 승인이 대기중입니다.",
    "user_id_placeholder": "4~12자의 영문 소문자ㆍ숫자",
    "double_check": "중복확인",
    "payment_method": "입금은행",
    "payment_method_placeholder": "입금은행을 선택해주세요. (필수)",
    "password_confirm": "비밀번호 확인",
    "password_placeholder": "6~12자의 영문 소문자ㆍ숫자",
    "password_confirm_placeholder": "비밀번호 확인",
    "account_number": "계좌번호",
    "account_number_placeholder": "계좌번호 입력",
    "bank_owner": "예금주명",
    "bank_owner_placeholder": "예금주명 입력",
    "nickname": "닉네임",
    "nickname_placeholder": "닉네임(8자 미만)",
    "phone_number": "전화번호",
    "phone_number_placeholder": "(-)없이 숫자만 입력",
    "verify": "인증하기",
    "signup_code": "가입코드",
    "signup_code_placeholder": "가입코드 입력",
    "recommander_id": "추천인 아이디",
    "recommander_id_placeholder": "추천인 아이디",
    "verify_code": "인증번호",
    "verify_code_placeholder": "인증번호 입력",
    "ok": "OK",
    "confirm": "확인",
    "cancel": "취소",
    "signup_ok": "가입완료",
    "signup_error": [
        "회원가입이 실패했습니다.",
        "아이디가 중복되었습니다.",
        "아이디를 확인해주세요.",
        "닉네임이 중복되었습니다.",
        "닉네임을 확인해주세요.",
        "전화번호가 중복되었습니다.",
        "전화번호를 확인해주세요.",
        // "인증코드가 정확하지 않습니다.",
        // "비밀번호를 확인해주세요.",
        // "은행정보를 정확히 입력해주세요.",
        // "폰인증을 1분후에 다시 시도해주세요."
    ],
    "phone_verify_code_sent": "전화번호로 인증번호를 발송했습니다.",
    // 마감

    // 대기실
    "notification": "공지사항",
    "tournament_notification": "토너먼트 공지",
    "event_notification": "이벤트",
    "my_money": "보유머니",
    "my_point": "보유포인트",
    "today_betting": "오늘 베팅",
    "total_betting": "총 베팅",
    "my_page": "마이페이지",
    "go_to_shop": "상점 바로가기",
    "online_player_count": "접속자",
    "gaming_player_count": "게임중",
    "waiting_player_count": "대기중",
    "room_count": "개설방",
    "player_count_unit": "명",
    "room_count_unit": "개",
    "join_lobby": "로비 입장하기",
    "shinepoker_shop": "샤인포커 상점",
    
    "profile": "프로필",
    "my_history": "나의 전적",
    "inventory": "인벤토리",

    // 닉네임
    "change_nickname": "닉네임 변경",
    "change_nickname_placeholder": "닉네임을 입력하세요",
    "change_nickname_desc1": "닉네임 변경 비용은",
    "change_nickname_desc2": "입니다.",
    "change_nickname_desc3": "8자 이내로만 설정할 수 있으며\n욕설, 타인비방을 목적으로 하는 닉네임은\n경고 및 수정 될 수 있습니다.",
    "change_confirm": "변경완료",
    "require_nickname": "닉네임을 입력하세요.",
    "change_nickname_success": "닉네임이 성공적으로 변경되었습니다.",
    "change_nickname_error1": "닉네임변경이 실패했습니다.",
    "change_nickname_error2": "보유금액이 충분하지 않습니다.",
    "change_nickname_error3": "닉네임은 8자미만이어야 합니다.",

    // 아바타
    "change_avatar_error_file": "이미지 파일만 업로드 가능합니다.",
    "change_avatar_error_size": "이미지 사이즈는 256KB이하여야 합니다.",
    "change_avatar_success": "아바타가 성공적으로 업로드되었습니다.",
    "change_avatar_error": "아바타업로드가 실패했습니다.",

    
    // History
    "cash_holdem": "캐쉬홀덤",
    "tournament": "토너먼트",
    "vpip": "VPIP",
    "pfr": "PFR",
    "steal": "스틸",
    "check_raise": "체크-레이즈",
    "three_bet": "3-벳",
    "fold_to_three_bet": "폴드 to 3-벳",
    "c_bet": "C-벳",
    "fold_to_c_bet": "폴드 to C-벳",
    "total_game": "총 게임",
    "total_hand": "총 핸드",
    "wtsd": "WTSD",
    'wssd': "W$SD",
    "win_count": "승자",
    "roi": "ROI",

    "vocabulary": "용어설명",
    "help_vpip": "VPIP",
    "help_vpip_description": "‘Voluntarily Put $ In Pot’의 줄임말로, 플레이어가 프리플롭 단계에서 적극적으로 팟에 베팅하는 빈도를 나타냅니다. 콜이나 레이즈가 아니라면 스몰 블라인와 빅 블라인드는 제외됩니다.",
    "help_pfr": "PFR",
    "help_pfr_description": "‘Preflop Raise’의 줄임말로, 플레이어가 프리플롭 단계에서 레이즈하는 빈도를 나타냅니다.",
    "help_wtsd": "WTSD",
    "help_wtsd_description": "‘Went to Showdown’의 줄임말로, 플레이어가 플롭을 보고 나서 쇼다운까지 가는 빈도를 나타냅니다. W$SD%는 ‘Won $ at Showdown’의 줄임말로서, 플레이어가 쇼다운에서 이긴 빈도입니다.",
    "help_three_bet": "3-벳",
    "help_three_bet_description": "블리인드와 레이즈 후, 프리플롭 단계에서 플레이어가 세 번째로 베팅하는 빈도입니다.",
    "help_c_bet": "C-벳",
    "help_c_bet_description": "‘Continutation Bet’의 줄임말로, 플레이어가 프리플롭 단계에서 레이즈를 하고 플롭에서도 계속해서 베팅하는 빈도를 나타냅니다.",
    "help_fold_to_three_bet": "폴드 to 3-벳",
    "help_fold_to_three_bet_description": "프리플롭에서 상대가 3-벳 한 경우, 폴드한 퍼센티지.",
    "help_fold_to_c_bet": "폴드 to C-벳",
    "help_fold_to_c_bet_description": "플롭에서 상대가 C-벳 한 경우, 폴드한 퍼센티지.",
    "help_steal": "스틸 %",
    "help_steal_description": "팟이 오픈되지 않았을 때 CO, BTN, SB 포지션에서 레이즈한 퍼센티지.",
    "help_check_raise": "체크-레이즈",
    "help_check_raise_description": "플롭에서 먼저 체크하고, 상대가 베팅한 이후 레이즈한 퍼센티지.",

    // Inventory    
    "use_inventory": "등록",
    "change_border_success": "테두리가 사용되었습니다.",
    "change_border_error1": "테두리사용이 실패했습니다.",
    "change_border_error2": "사용가능한 테두리가 아닙니다.",
    "use_inventory_success": "아이템이 사용되었습니다.",
    "use_inventory_error1": "아이템사용이 실패했습니다.",
    "use_inventory_error2": "이미 사용된 아이템입니다.",

    // Password
    "change_password": "비밀번호 변경",
    "current_password": "현재 비밀번호",
    "current_password_placeholder": "현재 비밀번호 입력(필수)",
    "new_password": "변경할 비밀번호",
    "new_password_placeholder": "변경할 비밀번호 입력(필수)",
    "new_password_confirm_placeholder": "비밀번호 확인(필수)",
    "error_password_not_match": "비밀번호가 맞지 않습니다.",
    "change_password_success": "비밀번호가 성공적으로 변경되었습니다.",
    "change_password_error": "비밀번호변경이 실패했습니다.",

    // Shop
    "vip_privilege": "VIP 특권",
    "date_unit": "일",
    "allin_equity": "올인\n이쿼티",
    "rabbit_huntting": "래빗\n헌팅",
    "private_border": "전용\n테두리",
    "carrier_report": "상세\n커리어\n보고서",
    "lival_data": "상세\n라이벌\n데이터",
    "lival_senior_data": "라이벌의\n상급\n데이터",
    "free_emoticon": "무료\n이모티콘",
    "free_timebank": "무료\n타임뱅크",
    "use_vip_card": "VIP카드 사용",
    "use_vip_card_directly": "구매하신 VIP카드를 바로 사용하시겠습니까?",
    "use_directly": "바로 사용",
    "buy_item": "아이템 구매",
    "ask_buy_item": "정말 %1을(를) %2에\n구매하시겠습니까?",
    "available_count": "%1회 사용 가능",
    "available_days": "%1일 사용 가능",
    "available_forever": "영구사용 가능",
    "buy_shopitem_success": "구매요청이 성과적으로 완료되었습니다.",
    "buy_shopitem_error1": "구매요청이 실패했습니다.",
    "buy_shopitem_error2": "보유금액이 충분하지 않습니다.",
    "buy_shopitem_error3": "이미 구매한 아이템입니다.",
    // 마감

    // 로비
    "safe": "금고",
    "point": "포인트",
    "customer": "고객센터",
    "event": "이벤트",
    "payin": "충전",
    "payout": "환전",
    "tab_holdem": "홀덤",
    "tab_omaha": "오마하",
    "tab_tournament": "토너먼트",

    // 홀덤탭
    "refresh": "새로고침",
    "create_room": "방만들기",
    "quick_join": "빠른 입장",
    "secure": "비밀",
    "room_title": "방제목",
    "buyin": "바이인",
    "room_player_count": "방인원",
    "blind": "블라인드",
    "ante": "앤티",
    "speed_mode": "모드",
    "speed_labels": ["빠름", "보통", "느림"],
    "join": "입장하기",
    "ante_yes": "있음",
    "ante_no": "없음",
    "room_title_placeholder": "방제목",
    "room_password_placeholder": "미 입력시 공개방으로 생성됨",
    "room_title_list": [
        "빠르게 한게임 해요!",
        "여유롭게 즐겨요~",
        "매너 있는분들 오세요~",
        "스피드 게임!",
        "아무나 환영합니다.",
        "올인까지 한판!",
        "즐겁게 게임해요~",
        "타짜들 모여라~"
    ],
    "create_room_game_mode": "게임모드(속도)",
    "second_unit": "초",
    "no_ante": "없음",
    "buyin_amount": "바이인 금액",
    "create_room_error_title": "방 제목을 입력해주세요.",
    "join_failed": "입장이 실패했습니다.",
    "create_room_failed": "방 만들기가 실패했습니다.",
    "not_enough_balance": "보유금액이 충분하지 않습니다.",
    "overflow_buyin": "바이인 금액을 정확하게 선택해주세요.",
    "join_error1": "방입장이 실패했습니다.",
    "join_error2": "바이인금액이 보유금액을 초과합니다.",
    "join_error3": "바이인금액을 정확하게 선택해주세요.",
    "join_error4": "방이 유효하지 않습니다.",
    "join_error5": "비밀번호가 맞지 않습니다.",
    "join_error6": "강퇴된 방에 다시 입장할 수 없습니다.",
    "no_valid_rooms": "입장가능한 방이 없습니다.",

    // 토너먼트
    "filter_all": "모두 보기",
    "free": "무료",
    "h_100k": "10만 이상",
    "h_1m": "100만 이상",
    "tournament_details": "세부정보",
    "table": "테이블",
    "blinds_tree": "블라인드 구조",
    "level": "레벨",
    "blind_up": "블라인드 업",
    "last_reg": "늦은 등록",
    "cur_level": "현재 레벨",
    "total_prize": "상금풀",
    "entry": "엔트리",
    "entry_range": "엔트리 범위",
    "start_chip": "스타팅칩",
    "av_stack": "평균 스택",
    "rebuy": "리바이",
    "addon": "애드온",
    "break": "휴식",
    "tournament_register": "등록",
    "player_chip": "보유칩",
    "rank_unit": "위",
    "ranking": "랭킹",
    "prize": "상금",
    "left_tables": "남은 테이블",
    "observe": "관전하기",
    "game_info": "게임정보",
    "tournament_register_confirm": "등록하기",
    "tournament_register_cancel": "등록취소",
    "tournament_register_again": "재등록",
    "tournament_fee": "수수료",
    "tournament_rebuy_no_limit": "무제한",
    "tournament_rebuy_not_allow": "불가",
    "tournament_rebuy_count_unit": "회",
    "tournament_state_waiting": "준비중",
    "tournament_state_starting": "시작준비",
    "tournament_state_playing": "진행중",
    "tournament_state_finished": "완료됨",
    "tournament_start_time": "시작시간",
    "tournament_addon_no": "취소",
    "tournament_break_no": "취소",
    "tournament_break": "%1분마다 %2분 휴식",
    "min_unit": "min",
    "tournament_register_error1": "토너먼트 등록이 실패했습니다.",
    "tournament_register_error2": "보유금액이 부족합니다.",
    "tournament_register_error3": "리바이 횟수를 초과했습니다.",
    "tournament_register_error4": "등록이 불가합니다.",
    "tournament_register_error5": "해당 티켓은 이미 사용되었습니다.",
    "tournament_register_error6": "티켓이 유효하지 않습니다.",
    "tournament_register_error7": "해당 티켓으로 입장가능한 토너먼트가 아닙니다.",
    "tournament_register_error8": "현재 진행중인 토너먼트입니다.",
    "tournament_register_error9": "3회이상 출석인증하셔야 토너먼트 등록 가능합니다.",
    "tournament_unregister_error1": "등록취소가 실패했니다.",
    "tournament_unregister_error2": "등록취소가 불가합니다.",
    "tournament_unregister_error3": "티켓으로 등록한 토너는 등록취소가 불가합니다.",
    "tournament_observe_error": "관전이 실패했습니다.",
    "tournament_addon_error1": "애드온이 실패했습니다.",
    "tournament_addon_error2": "애드온이 불가합니다.",
    "tournament_addon_error3": "보유머니가 충분하지 않습니다.",
    "tournament_addon_error4": "현재 토너먼트에 등록하지 않았습니다.",
    "tournament_addon_error5": "현재 토너먼트에 남아 있지 않습니다.",
    "tournament_addon_error6": "애드온 상태가 아닙니다.",
    "tournament_addon_error7": "이미 애드온 했습니다.",
    "tournament_addon_count": "애드온 횟수",
    "left_time_seconds": "남은 시간: %s초",
    "tournament_unregister_warning": "시작하기 1분전에는 등록을 취소할 수 없습니다.",
    "trounament_breaktime1": "대기시간",
    "tournament_breaktime2": "휴식시간",

    // 금고
    "deposit": "입금",
    "withdraw": "출금",
    "current_money": "보유한 금액",
    "safe_stored_money": "금고 보관중인 금액",
    "deposit_amount": "입금 금액",
    "withdraw_amount": "출금 금액",
    "amount_placeholder": "금액을 입력해주세요. (필수)",
    "depsit_confirm": "입금하기",
    "withdraw_confirm": "출금하기",
    "safe_history": "금고 보관 내역",
    "safe_deposit_success": "입금이 성과적으로 완료되었습니다.",
    "safe_deposit_error1": "입금이 실패했습니다.",
    "safe_deposit_error2": "입금금액이 보유금액보다 큽니다.",
    "safe_withdraw_success": "출금이 성과적으로 완료되었습니다.",
    "safe_withdraw_error1": "출금이 실패했습니다.",
    "safe_withdraw_error2": "출금금액이 금고금액보다 큽니다.",

    // point
    "current_point": "보유한 포인트",
    "convert_point": "전환 포인트",
    "convert_point_btn": "전환하기",
    "convert_point_history": "포인트 전환 내역",
    "convert_point_success": "포인트전환이 성과적으로 완료되었습니다.",
    "convert_point_error1": "포인트전환이 실패했습니다.",
    "convert_point_error2": "전환요청 포인트가 보유포인트를 초과합니다.",

    // 문의
    "customer_history": "문의 내역",
    "question_confirm": "문의하기",
    "question": "문의하기",
    "question_title_placeholder": "문의 제목을 입력해주세요(필수)",
    "question_content_placeholder": "궁금하신 내용을 남겨주세요.\n빠른 답변 드리겠습니다.",
    "customer_confirm": "작성완료",
    "customer_question_error": "문의작성이 실패했습니다.",

    // 이벤트
    "attendance_event": "출석이벤트",
    "accept": "수령하기",
    "accepted": "수령됨",
    "congratulations": "축하합니다!",
    "event_accept_error": "이벤트 선물 수령이 실패했습니다.",

    // 충환전
    "payin_title": "충전하기",
    "payin_ask_account": "고객센터로 계좌문의 부탁드립니다.",
    "warning_payin": [
        "반드시 가입시 예금주명과 같은 이름으로 입금부탁드립니다.",
        "반드시 선입금후 구매확정 눌러주세요"
    ],
    "payin_username": "입금자",
    "payin_amount": "입금금액",
    "payin_amount_placeholder": "충전금액을 입력해주세요. (필수)",
    "reset": "RESET",
    "bonus_type": "보너스 선택",
    "no_bonus": "선택하지 않음",
    "payin_history": "충전내역",
    "payin_confirm": "구매확정",
    "payout_title": "환전하기",
    "bank_name": "은행명",
    "payout_amount": "환전 금액",
    "payout_amount_placeholder": "환전 금액을 입력해주세요",
    "payout_history": "환전내역",
    "payout_confirm": "판매확정",
    "success_payin": "충전요청이 성공했습니다.",
    "error_payin": [
        "충전요청이 실패했습니다.",
        "현재 대기중인 요청이 있습니다."
    ],
    "success_payout": "환전요청이 성관적으로 완료되었습니다.",
    "error_payout": [
        "환전요청이 실패했습니다.",
        "환전요청 금액이 보유금액을 초과합니다.",
        "출금조건이 만족하지 않습니다.",
        "현재 대기중인 요청이 있습니다."
    ],

    // 쪽지
    "note_box": "쪽지함",
    "have_unread_notes": "읽지 않은 쪽지가 있습니다.",
    "unread_notes": "읽지 않은 쪽지",
    "note_count_unit": "개",

    // 설정
    "setting": "설정하기",
    "system": "시스템",
    "template": "테마",
    "background_volume": "배경음악",
    "effect_volume": "효과음",
    "screen_mode": "화면방식",
    "portrait": "세로모드",
    'auto': '자동',
    "landscape": "가로모드",
    "voice": "보이스",
    "male": "남성",
    "female": "여성",
    "save": "저장하기",
    "ui_mode": "UI 모드",
    "ui_light": "라이트",
    "ui_dark": "다크",
    "game_display": "게임 디스플레이",
    "card_style": "카드 스타일",
    "release": "잠금해제",
    "apply": "적용하기",
    
    // 홀덤
    "game_history": "게임 이력",
    "hand_card": "핸드 카드",
    "betting_amount": "베팅금액",
    "result": "결과",
    "chat_history": "채팅내역",
    "chat_message_placeholder": "메시지를 입력하세요",
    "chat_blocked": "채팅이 금지되었습니다.",
    "chat_message_sample_list": [
        "안녕하세요",
        "ㅋㅋㅋ",
        "운이 좋네요",
        "행운을 빌어요",
        "조심하세요",
        "화이팅!",
        "안녕히 가세요",
        "죽을래?",
        "빨리빨리",
        "바보자식",
        "이런 젠장",
        "아쉽네요",
        "감사합니다",
        "다음에 봐요",
        "죽지마라",
        "올인가자",
        "드루와",
        "내가 이구역 짱"
    ],
    "player_profile": "플레이어 프로필",
    "emoticon": "EMOTICON",
    "emoticon_left_count": "이모티콘 남은 횟수",
    "report": "신고",
    "profile_report_warning": "유저 프로필 신고 시 관리자 확인 후 이용정지, 영구정지등 합당한 조치가 취해지며 고의적, 허위 신고의 경우 신고자 또한 제재를 받을 수 있습니다.",
    "chat_report_warning": "채팅 신고 시 관리자 확인 후 강제퇴장, 채팅금지 등 합당한 조치가 취해지며 고의적, 허위 신고의 경우 신고자 또한 제재를 받을 수 있습니다.",
    "report_confirm": "그래도 신고하시겠습니까?",
    "report_empty_error": "신고내용을 입력해주십시오.",
    "leave_room_error": "방 나가기 실패했습니다.",
    "report_error": "신고가 실패했습니다.",
    "leave_room": "나가기",
    // 마감
}