import { IconButton } from "../../../components/button/iconbutton";
import { SpriteButton } from "../../../components/button/spritebutton";
import { InputField } from "../../../components/input/input";
import { TextClass } from "../../../components/text/text";
import CommonDef from "../../../def/commondef";
import { translate } from "../../../util/other";

export class EditNicknameDialog extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
        this.popupAnimation();
    }

    initGraph() {
        this.backGraphic = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.backGraphic.fillStyle(0x000000, this.scene.orientation == "pc" ? 0.7 : 1);
        this.backGraphic.fillRect(0, 60, this.scene.orientation == "pc" ? 935 : 590, this.scene.orientation == "pc" ? 485 : 1100, 0);
        this.add(this.backGraphic);
        let rect = new Phaser.Geom.Rectangle(0, 60, this.scene.orientation == "pc" ? 935 : 590, this.scene.orientation == "pc" ? 485 : 1100);
        this.backGraphic.setInteractive(rect, Phaser.Geom.Rectangle.Contains);

        this.mainContainer = this.scene.add.container(this.scene.orientation == "pc" ? 269 : 0, this.scene.orientation == "pc" ? 98 : 105)
        this.add(this.mainContainer);
        this.contentBack = this.scene.add.graphics({
            x: 0,
            y: 0
        });
        this.contentBack.fillStyle(0x000000, 1);
        this.contentBack.lineStyle(1, 0x222222, 1);
        this.contentBack.fillRoundedRect(0, 0, this.scene.orientation == "pc" ? 400 : 587, this.scene.orientation == "pc" ? 420 : 510, 4);
        this.contentBack.strokeRoundedRect(0, 0, this.scene.orientation == "pc" ? 400 : 587, this.scene.orientation == "pc" ? 420 : 510, 4);
        this.mainContainer.add(this.contentBack);

        this.title = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 32 : 42,
            y: 24,
            text: translate('change_nickname'),
            fontSize: 26,
            gradient: ['#ffe0cf', '#ffffff']
        });
        this.mainContainer.add(this.title);

        this.btn_close = new SpriteButton(this.scene, {
            x: this.scene.orientation == "pc" ? 340 : 525,
            y: 24,
            origin: [0, 0],
            scale: 0.9,
            atlas: "DialogAtlas",
            normal: "close_red.png",
            onClicked: this.close.bind(this)
        });
        this.mainContainer.add(this.btn_close);

        this.nickname_field = new InputField(this.scene, {
            x: this.scene.orientation == "pc" ? 30 : 124,
            y: this.scene.orientation == "pc" ? 97 : 118,
            displayTextY: 0,
            align: "center",
            atlas: this.config.atlas,
            back: "nickname_field_back.png",
            input: {
                x: 0,
                y: 0,
                absolute: {
                    x: this.scene.orientation == "pc" ? 790 : 205,
                    y: this.scene.orientation == "pc" ? 527 : 393,
                },
                width: 340,
                height: 60,
                placeholder: translate('change_nickname_placeholder'),
                value: CommonDef.Me.nickname,
                color: "#777777",
                fontSize: 26
            }
        });
        this.mainContainer.add(this.nickname_field);

        let text1 = TextClass.plainText1(this.scene, {
            x: this.scene.orientation == "pc" ? 48 : 130,
            y: this.scene.orientation == "pc" ? 177 : 213,
            text: translate('change_nickname_desc1'),
            fontSize: this.scene.orientation == "pc" ? 20 : 24,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.mainContainer.add(text1);

        this.priceText = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 48 : 130) + text1.width + 6,
            y: this.scene.orientation == "pc" ? 177 : 213,
            text: "10,000php",
            fontSize: this.scene.orientation == "pc" ? 20 : 24,
            color: "#d7bba3"
        }).setOrigin(0, 0);
        this.mainContainer.add(this.priceText);

        let text2 = TextClass.plainText1(this.scene, {
            x: (this.scene.orientation == "pc" ? 48 : 130) + text1.width + 6 + this.priceText.width + 6,
            y: this.scene.orientation == "pc" ? 177 : 213,
            text: translate('change_nickname_desc2'),
            fontSize: this.scene.orientation == "pc" ? 20 : 24,
            color: "#ffffff"
        }).setOrigin(0, 0);
        this.mainContainer.add(text2);

        let change_nickname_descriptions = translate('change_nickname_desc3').split("\n");
        for(let i = 0 ; i < change_nickname_descriptions.length ; i ++) {
            let descriptionText = TextClass.plainText1(this.scene, {
                x: this.scene.orientation == "pc" ? 200 : 295,
                y: this.scene.orientation == "pc" ? 216 + 24 * i : 265 + 32 * i,
                text: change_nickname_descriptions[i],
                fontSize: this.scene.orientation == "pc" ? 16 : 20,
                color: '#777777',
            }).setOrigin(0.5, 0);
            this.mainContainer.add(descriptionText);
        }

        this.btn_confirm = new IconButton(this.scene, {
            x: this.scene.orientation == "pc" ? 105 : 205,
            y: this.scene.orientation == "pc" ? 315 : 396,
            atlas: this.config.atlas,
            scale: [0.82, 0.95],
            normal: {
                back: "btn_confirm_back_normal.png"
            },
            hover: {
                back: "btn_confirm_back_hover.png"
            },
            clicked: {
                back: "btn_confirm_back_clicked.png"
            },
            text: {
                x: 43,
                y: 17,
                value: translate('change_confirm'),
                fontSize: 28,
                gradient: ['#ffe0cf', '#ffffff']
            },
            onClicked: this.changeNickName.bind(this)
        });
        this.mainContainer.add(this.btn_confirm);
    }

    close() {
        this.destroy();
    }

    popupAnimation() {
        this.scene.tweens.add({
            targets: this.mainContainer,
            scale: {
                from : 0,
                to: 1
            },
            x: {
                from: this.mainContainer.x + (this.scene.orientation == "pc" ? 200 : 290),
                to: this.mainContainer.x
            },
            y: {
                from: 60,
                to: this.mainContainer.y
            },
            duration: 150,
            ease: "Back"
        })
    }

    changeNickName() {
        if(!this.nickname_field.value) {
            this.scene.showToast("failed", translate('require_nickname'));
            return;
        }
        if(this.nickname_field.value.length > 8) {
            this.scene.showToast("failed", translate('change_nickname_error3'));
            return;
        }
        this.scene.requestChangeNickname(this.nickname_field.value);
    }
}